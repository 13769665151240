<div class="component-main center-item">
  <h2 style="text-align-last: center;">{{ 'Customers Dashboard' | translate }}</h2>
  <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
    <button mat-fab color="primary" (click)="refresh()" [matTooltip]="'Refresh' | translate">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div fxLayout="column" class="center-item" style="width: 80%;">
    <mat-accordion class="my-headers-align">
      <div *ngFor="let customer of customers" (ngInit)="setTime(customer)">
        <mat-expansion-panel style="margin: 10px;" (opened)="expansionHeader(customer)">

          <mat-expansion-panel-header>
            <mat-panel-title style="text-align: start; align-items: center;">
              <div fxFlex="25">
                {{ customer.name }}
              </div>
              <div fxFlex="25">
                {{ customer.description }}
              </div>
              <div fxFlex="25">
                {{ 'Max active users' | translate }}: {{ customer.maxActiveUsers }}
              </div>
              <div fxFlex="25">
                {{ 'Created' | translate}}: {{ customer.created | date:'yyyy-MM-dd' }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div fxFlex fxLayout="column">
              <mat-card appearance="outlined" style="margin: 5px;">
                <mat-card-header>
                  <mat-card-title>{{'Authorisations' | translate }}</mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="10">
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer, 'Demo')"
                      [style.backgroundColor]="remainingDays <= 0 ? 'red' : 'yellowgreen'"
                      *ngIf="customer.isDemo == 1">
                      Demo
                    </button>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer, 'Courses')"
                      [style.backgroundColor]="!customer.coursesEnabled ? 'red' : 'yellowgreen'">
                      {{customer.coursesEnabled ? ('Courses enable' | translate): ('Courses disable' | translate)}}
                    </button>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Authorized')"
                      [style.backgroundColor]="!customer.authorized ? 'red' : 'yellowgreen'">
                      {{customer.authorized ? ('Authorized' | translate): ('Not authorized' | translate)}}
                    </button>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Masters')"
                      [style.backgroundColor]="!customer.mastersEnabled ? 'red' : 'yellowgreen'">
                      {{customer.mastersEnabled ? ('Masters enable' | translate): ('Masters disable' | translate)}}
                    </button>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Smily')"
                      [style.backgroundColor]="!customer.smilyEnabled ? 'red' : 'yellowgreen'">
                      {{customer.smilyEnabled ? ('Smily enable' | translate): ('Smily disable' | translate)}}
                    </button> 
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Streaming')"
                      [style.backgroundColor]="!customer.streamingEnabled ? 'red' : 'yellowgreen'">
                      {{customer.streamingEnabled ? ('Streaming enable' | translate): ('Streaming disable' | translate) }}
                    </button>
                  </div>
                  <div>
                    <button style="vertical-align:bottom" (click)="popupOpen(customer,'Billing')"
                    [matTooltip]="customer.invoicePerMonth === 0 ? ('Enable billing' | translate ) : ( 'Disable billing' | translate )"
                    [style.background-color]="customer.invoicePerMonth === 1 || customer.invoicePerMonth === 2 ? 'yellowgreen' : 'red'" mat-raised-button>
                        {{ customer.invoicePerMonth === 1 || customer.invoicePerMonth === 2 ? ('Billing enabled' | translate ) : ('Billing disabled' | translate )}}
                    </button>
                  </div>
                  <div>
                    <button style="vertical-align:bottom" (click)="popupOpen(customer,'Billing period')"
                     [matTooltip]="'Change billing period' | translate"
                     mat-raised-button [color]="customer.invoicePerMonth === 1 ? 'primary' : 'accent'" [disabled]="customer.invoicePerMonth === 0">
                        {{ customer.invoicePerMonth === 1 ? ('Monthly billing' | translate) : customer.invoicePerMonth === 2 ? ('Quarterly billing' | translate) : ('Monthly billing' | translate )}}
                    </button>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Low resolution')"
                      [style.backgroundColor]="!customer.lowResEnabled ? 'red' : 'yellowgreen'">
                      {{customer.lowResEnabled ? ('Low resolution enable' | translate): ('Low resolution disable' | translate) }}
                    </button>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card appearance="outlined" style="margin: 5px;">
                <mat-card-header>
                  <mat-card-title>{{'User options' | translate }}</mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="row" fxLayoutAlign="space-evenly">
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Max active users')" [style.backgroundColor]="customer.maxActiveUsers ? 'grey' : ''">
                      <p>{{!customer.maxActiveUsers ? (''): (customer.maxActiveUsers) }} <mat-icon>edit</mat-icon></p>
                    </button>
                  <p>{{'Max active users' | translate}}</p>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Max passive users')" [style.backgroundColor]="customer.maxPassiveUsers ? 'grey' : ''">
                      <p>{{!customer.maxPassiveUsers ? (''): (customer.maxPassiveUsers) }} <mat-icon>edit</mat-icon></p>
                    </button>
                    <p>{{'Max passive users' | translate}}</p>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Storage size')" [style.backgroundColor]="customer.maxCourseSize ? 'grey' : ''">
                      <p>{{!customer.maxCourseSize ? (''): (customer.maxCourseSize) }} GB <mat-icon>edit</mat-icon></p>
                    </button>
                    <p>{{'Storage size' | translate}}</p>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Font size')" [style.backgroundColor]="customer.fontSize ? 'grey' : ''">
                      <p>{{!customer.fontSize ? (''): (customer.fontSize) }} px <mat-icon>edit</mat-icon></p>
                    </button>
                    <p>{{'Font size' | translate}}</p>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Font name')" [style.backgroundColor]="customer.fontName ? 'grey' : ''">
                      <p>{{!customer.fontName ? (''): (customer.fontName) }} <mat-icon>edit</mat-icon></p>
                    </button>
                    <p>{{'Font name' | translate}}</p>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Write in overlay')" [style.backgroundColor]="customer.logoText ? 'grey' : ''">
                      <p>{{!customer.logoText ? (''): (customer.logoText) }} <mat-icon>edit</mat-icon></p>
                    </button>
                    <p>{{'Write in overlay' | translate}}</p>
                  </div>
                  <div>
                    <button mat-flat-button (click)="popupOpen(customer,'Customer code')" [style.backgroundColor]="customer.iButtonUserName ? 'grey' : ''">
                      <p>{{!customer.iButtonUserName ? '' : (customer.iButtonUserName) }} <mat-icon>edit</mat-icon></p>
                    </button>
                    <p>{{'Customer code' | translate}}</p>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card appearance="outlined" *ngIf="customer.isDemo == 1" style="margin: 5px;">
                <mat-card-header>
                  <mat-card-title>{{"Demo" | translate}} {{"validity time" | translate}}</mat-card-title>
                </mat-card-header>
                <mat-card-content style="margin-top: 2.5vh;">
                  <div>
                    <mat-progress-bar color="primary" mode="determinate" [value]="remainingDaysPerc" style="height: 1vh;"></mat-progress-bar>
                  </div>
                  <div fxLayoutAlign="space-between end"
                    style="margin-top: 2.5vh; font-size: 14px; margin-bottom: 1.5vh;">
                    <div>{{ customer.created | date:'yyyy-MM-dd'}}</div>
                    <div>{{ remainingDays }} {{"Days left" | translate}}</div>
                    <div>{{ expirationDate | date:'yyyy-MM-dd'}}</div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card appearance="outlined" fxLayout="column" style="margin: 5px;">
                <mat-card-header>
                  <mat-card-title>{{ 'Activity' | translate }}</mat-card-title>
                </mat-card-header>
                <div class="graph" fxFlex style="margin-top: 4h; margin-bottom: 4vh;"
                  fxLayoutAlign="space-around center">
                  <div fxFlex="45" fxLayoutAlign="center center">
                    <div fxLayout="column" fxFlex fxLayoutAlign="center center"
                      [ngClass]="{'opacità':nullGraph[1]}">
                      <mat-card-title style="font-size: 14px;">{{"Users activity" | translate}} ({{typeTime[1]}})</mat-card-title>
                      <canvas baseChart 
                        width="400px" 
                        height="110px" 
                        [datasets]="lineChartData[1]"
                        [labels]="lineChartLabels[1]"
                        [options]="lineChartOptions"
                        [type]="lineChartType">
                      </canvas>
                    </div>
                    <mat-card-title *ngIf="nullGraph[1]" style="position: absolute">No Data</mat-card-title>
                  </div>
                  <div fxFlex="45" fxLayout="column" fxLayoutAlign="space-evenly none">
                    <mat-card-title fxLayoutAlign="space-evenly center" style="font-size: 14px;">
                      {{"Registered passive users" | translate}}: {{customer.activeUsers}} ({{activeUsersPerc}}%
                      {{"Used" | translate}})</mat-card-title>
                    <div>
                      <mat-progress-bar color="primary" mode="determinate" [value]="activeUsersPerc"
                        style="height: 0.7vh; margin-top: 2vh;"></mat-progress-bar>
                      <div fxLayout="row" fxLayoutAlign="space-between end"
                        style="margin-top: 2vh; font-size: 18px; margin-bottom: 0.5vh;">
                        <div>0</div>
                        <div>{{customer.maxPassiveUsers}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>

              <mat-card appearance="outlined" fxLayout="column" style="margin: 5px;">
                <mat-card-header>
                  <mat-card-title>Streaming</mat-card-title>
                    <div fxLayoutAlign="end center" fxLayout="row" fxFlex="87.3" fxLayoutGap="20px">
                      <button class="btn-update" 
                        mat-raised-button color="primary" 
                        (click)="exportStreamingTimeToCsv(customer.id,customer.name)"
                        [matTooltip]="'Save csv' | translate">
                        <mat-icon matListIcon>save</mat-icon>
                      </button>
                      <mat-form-field  name="chartTypeStreaming" appearance="fill" style="width:150px">
                        <mat-label  [style.color]="darkService.isSetDark ? 'white' : ''">{{'Chart type' | translate}}</mat-label>
                        <mat-select [(value)]="selectedType">
                          <mat-option 
                          [style.color]="darkService.isSetDark ? 'white' : ''"  
                          *ngFor="let type of types"  
                          [value]="type">
                            {{type | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                </mat-card-header>
               
                <div>
                  <div fxLayoutAlign="center center" fxFlex="50">
                    <div fxLayout="column" fxFlex fxLayoutAlign="center center"
                      [ngClass]="{'opacità':nullGraph[0]}" style="margin: 2vh;">
                      <mat-card-title style="font-size: 14px;">{{"Trasmitted streaming time" | translate}}
                        ({{typeTime[0]}})
                      </mat-card-title>
                      <canvas baseChart 
                        width="400px" 
                        height="110px" 
                        [datasets]="lineChartData[0]"
                        [labels]="lineChartLabels[0]" 
                        [options]="lineChartOptions" 
                        [type]="selectedType">
                      </canvas>
                    </div>
                    <mat-card-title *ngIf="nullGraph[0]" style="position: absolute">No Data</mat-card-title>
                  </div>
                  <div fxLayoutAlign="center center" fxFlex="50">
                    <div fxFlex fxLayout="column" fxLayoutAlign="center center"
                      [ngClass]="{'opacità':nullGraph[2]}" style="margin: 2vh;">
                      <mat-card-title style="font-size: 14px;">{{"Total streaming time" | translate}}
                        ({{"sent and received" | translate}}) ({{typeTime[2] | translate}})</mat-card-title>
                      <canvas baseChart 
                        width="400px" 
                        height="110px" 
                        [datasets]="lineChartData[2]"
                        [labels]="lineChartLabels[2]"
                        [options]="lineChartOptions"
                        [type]="selectedType">
                      </canvas>
                    </div>
                    <mat-card-title *ngIf="nullGraph[2]" style="position: absolute">No Data</mat-card-title>
                  </div>
                </div>
              </mat-card>

              <mat-card appearance="outlined" fxLayout="column" style="margin: 5px;" *ngIf="customerData != null">
                <mat-card-header>
                  <mat-card-title>{{ 'Storage' | translate }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div fxLayoutAlign="center center" fxFlex="25">
                    {{ ('There are' | translate) + ' ' + customerData.blobCount + ' files ' + ('available' | translate) }}
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="25">
                    <div fxLayout="row" style="margin-bottom: 10px;">
                      {{ 'Space used' | translate }}
                    </div>
                    <app-spinner-container
                      color="primary"
                      [diameter]="75"
                      [strokeWidth]="5"
                      [value]="getStorageValue(customerData)"
                      [displayWith]="spinnerValue">
                    </app-spinner-container>
                  </div>
                  <div fxLayoutAlign="center center" fxFlex>
                    {{ ('The customer' | translate) + ' ' + customerData.customer.name + ' ' + ('has at his disposal' | translate) + ' ' + (customerData.maxSize - customerData.currentSize).toFixed(2) + 'GB ' + ('of the total' | translate) + ' ' + customerData.maxSize + 'GB' }}
                  </div>
                </mat-card-content>
              </mat-card>

            </div>
          </ng-template>

        </mat-expansion-panel>
      </div>
    </mat-accordion>
    <div *ngIf="customers.length == 0">
      <app-error-message customClass="warning"
        [errorTitle]="'There isnt any data' | translate"
        [errorContent]="'There arent customers with courses enabled' | translate">
      </app-error-message>
    </div>
  </div>
</div>

import { AfterViewInit, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { StudentsAnswerTableDataSource, } from './students-answer-table-datasource';
import { SurveyService } from 'src/app/services/survey.service';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
  selector: 'app-students-answer-table',
  templateUrl: './students-answer-table.component.html',
  styleUrls: ['./students-answer-table.component.scss']
})
export class StudentsAnswerTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<UserDTO>;
  dataSource: StudentsAnswerTableDataSource;
  dataSrc=new MatTableDataSource<UserDTO>();
  @Input() idClassroom: number;
  @Input() idLesson: number;
  @Output()  onAnswer = new EventEmitter<UserDTO>();
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [ 'name', 'survey' ];

  constructor(private surveyService: SurveyService) { }

  ngOnInit() {
    this.dataSource = new StudentsAnswerTableDataSource();
    
  }

  ngAfterViewInit() {
    this.surveyService.getSurveyAnswersClassroom(this.idClassroom, this.idLesson)
    .subscribe(res => {
      console.log('students',res);
     
      /* 
      this.dataSource.data=res ;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.table.dataSource = this.dataSource;
      */

      this.dataSrc.data = res;
      this.dataSrc.sort = this.sort;
      this.dataSrc.paginator = this.paginator;
      this.table.dataSource = this.dataSrc;
      if (res.length>0){
        if(res[0].surveyAnswer.length>0){
          if (res[0].surveyAnswer[0].surveyJSON!==''){
            this.onAnswer.emit(res[0])
          }
        }
      }
     });
  }

  showAnswer(studentAnswer: UserDTO){
    const student=studentAnswer.surname+ ' '+studentAnswer.name;
    const answer= studentAnswer.surveyAnswer[0];
    this.onAnswer.emit(studentAnswer)
  }

  doFilter(e) {
    this.dataSrc.filter = e.target.value.trim().toLowerCase();
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { CalendarService } from 'src/app/services/calendar.service';
import { GenericPopupComponent, GenericPopupData } from '../generic-popup/generic-popup.component';

@Component({
  selector: 'app-import-users-lessons',
  templateUrl: './import-users-lessons.component.html',
  styleUrls: ['./import-users-lessons.component.scss']
})
export class ImportUsersLessonsComponent implements OnInit {

  sending: boolean = false;
  mediaSrc: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);

  constructor(private dialogRef: MatDialogRef<ImportUsersLessonsComponent>,
              private dialog: MatDialog,
              private calendarService: CalendarService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) { }

  ngOnInit(): void { }

  async addEvents() {
    this.toggleDisable(true);

    this.calendarService.importExternalData(this.mediaSrc.value)
      .subscribe({
        next: async (res: any) => {
          if (res.Message) {
            let formattedMsg = res.Message.split(',').join('\n');

            this.dialog.open(GenericPopupComponent, {
              width: '500px',
              data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Import log')),
                body: formattedMsg,
                hideCancelBtn: true
              }
            });
          }

          this.dialogRef.close(true);
        },
        error: err => {
          console.error(err);
          this.snackBar.open(err.error.Message, '', { duration: 3000 })
          this.toggleDisable(false);
        }
      });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if(toggle) {
      this.mediaSrc.disable();
    } else {
      this.mediaSrc.enable();
    }
  }

}

<div fxLayout="column" class="component-main">

    <div  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <button mat-stroked-button (click)="goBack()">
        <mat-icon matListIcon>arrow_back</mat-icon>
        {{ 'Back' | translate }}
    </button>
</div>

<mat-tab-group (selectedTabChange)="loadAnalytics()">
    <mat-tab [label]="'Students' | role"> 
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start">
            <mat-card appearance="outlined" class="mat-elevation-z4" fxFlex="50">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Students' | role | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-students-answer-table [idClassroom]="idClassroom" [idLesson]="idLesson" (onAnswer)="showAnswer($event)"></app-students-answer-table>
                </mat-card-content>
            </mat-card>

              <div fxFlex="50%" fxLayout="column" fxLayoutGap="10px"> 

                <mat-card appearance="outlined" class="mat-elevation-z4" *ngIf="SurveyVisible">
                    <mat-card-header>
                        <mat-card-title>
                            {{ 'Survey' | translate }} by {{selectedStudent}}
                        </mat-card-title>
                        <mat-card-subtitle>{{surveyDto.name}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center"  >

                            <ng-container *ngIf="selectedScoreA">
                            <mat-form-field fxFlex="10" color="primary">
                                <mat-label>Automatic score</mat-label>
                                <input matInput  [value]="selectedScoreA" readonly>
                              </mat-form-field>
                            </ng-container>

                            <mat-form-field fxFlex="10" color="primary">
                               <mat-label>Score</mat-label>
                               <input matInput  [(ngModel)]="selectedScore" >
                             </mat-form-field>
       
                             <mat-form-field fxFlex color="primary">
                               <mat-label>Score note</mat-label>
                               <input matInput  [(ngModel)]="selectedScoreNote" >
                             </mat-form-field>

                             <button mat-stroked-button (click)="saveScore()" [matTooltip]="'Save score' | translate">
                                <mat-icon matListIcon color="green">check</mat-icon>
                            </button>

                         </div>
                    </mat-card-content>
                </mat-card>
                <div class="survey-container">
                    <survey [model]="surveyModel"></survey>
              </div>
             
           </div>
        </div>

    </mat-tab>
    <mat-tab [label]="'Data' | translate">     
        <div>
            <div id="surveyResult"></div>
       </div>
    </mat-tab>

  </mat-tab-group>

</div>

import { UserDTO } from './userDTO';
import { CourseBlogDTO } from './courseBlogDTO';
import { CourseLikesDTO } from './courseLikesDTO';
import { LessonSessionDTO } from './lessonSessionDTO';
import { CourseFavouriteDTO } from './courseFavouriteDTO';
import { CourseDTO } from './courseDTO';
import { CourseContentTrackerDTO } from './courseContentTrackerDTO';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { TranslationDTO } from './translationDTO';

export class CourseContentDTO {
    public id: number;
    public idParent: number;
    public idCourse: number;
    public idAuthor: number;
    public idLesson: number;
    public idScormCloudApplication: number;
    public type: number;
    public name: string;
    public idStringName: number;
    public description: string;
    public idStringDescription: number;
    public mediaUrl: string;
    public mediaSize: number;
    public mediaLanguage: string;
    public mediaSubtitlesLanguages: string;
    public mediaSubtitlesAvailable: number;
    public headerImageUrl: string;
    public downloadable: number;
    public hashTag: string[];
    public subtitles: Subtitle[];
    public orderIndex: number;
    public duration: number;
    public state: number;
    public created: Date;
    public author: UserDTO;
    public courseBlog: CourseBlogDTO[];
    public courseLikes: CourseLikesDTO[];
    public lessonSession: LessonSessionDTO;
    public courseFavourite: CourseFavouriteDTO[];
    public course: CourseDTO;
    public childrenCourseContent: CourseContentDTO[];
    public nameTranslation: TranslationDTO;
    public descriptionTranslation: TranslationDTO;

    public courseContentTracker: CourseContentTrackerDTO[]; //Utilizzato solo dalla lista preferiti

    public contentHelper?: ContentHelper; //Utilizzato solo per mostrate i contenuti in tabella, non presente nel DTO

    constructor() {
        this.id = 0;
    }
}

export class Subtitle {
    public language: string;
    public url: string;
}

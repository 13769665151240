import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { StudentData } from 'src/app/models/studentData';
import { CourseService } from 'src/app/services/course.service';
import { Location } from '@angular/common';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { firstBy } from 'thenby';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { CourseViews, UserCourseViews } from 'src/app/models/courseView';
import { CourseView, CsvExporterService, HASHTAGS_HEADERS, StudentView } from 'src/app/services/csv-exporter.service';
import { CourseDTO } from 'src/app/models/dto/courseDTO';
import { AuthService } from 'src/app/services/auth.service';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { UserRole } from 'src/app/models/userRole';

@Component({
  selector: 'app-course-analytics',
  templateUrl: './course-analytics.component.html',
  styleUrls: ['./course-analytics.component.scss']
})
export class CourseAnalyticsComponent implements OnInit {

  course: CourseDTO;
  userCourseViews: UserCourseViews[];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "#6f6f6f"
        },
        ticks: {
          color: "#868585"
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#6f6f6f"
        },
        ticks: {
          color: "#868585"
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  barChartLabels: string[] = [];

  barChartLabels3: string[] = ['tot visualizzazioni'];
  barChartType: ChartType = 'bar';
  barChartPlugins = [DataLabelsPlugin];

  barChartData: ChartDataset[] = [{ data: [0], label: '', backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];
  barChartData3: ChartDataset[] = [{ data: [0], label: '', backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];

  monthsName: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  year: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  courseId: number;
  userData: StudentData[] = [];
  years: number[] = [];
  months: number[] = [];
  name: string;
  surname: string;
  contentDuration: number;
  viewCount: number[] = [];
  viewCountbyId: number[] = [];
  dataTracker: CourseViews[] = [];
  dataParticipant: UserDTO[] = [];
  studSelected: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  dataSrc = new MatTableDataSource<any>();
  displayedColumns = ['name', 'data'];
  totViewAll : number = 0;

  constructor(private location: Location,
    private route: ActivatedRoute,
    private exporter: CsvExporterService,
    private auth: AuthService,
    private courseService: CourseService,
    private rolePipe: RolePipe) { }

  ngOnInit(): void {
    this.courseId = +this.route.snapshot.paramMap.get('id');
    this.getStudentsViews(this.courseId);
    this.courseService.getCourseContent(this.courseId,true).subscribe(output => {
      this.course = output;
    })
    this.courseService.getCourseView(this.courseId).subscribe(output => {
      this.dataTracker = output;
      this.dataTracker.sort(firstBy((d: any) => d.year, 1).thenBy((d: any) => d.month, 1));

      if (this.dataTracker.length != 0) {
        this.dataTracker.forEach(d => {
          if (!this.years.includes(d.year))
            this.years.push(d.year);
        });

        console.log(this.dataTracker)
        this.yearChange()
        this.viewForAll()
      }
    })

  }

  dateDiffInHours(a: Date, b: Date) {
    // Esclude l'ora ed il fuso orario
    let date1 = new Date(a)
    let date2 = new Date(b)
    var utc1 = Math.floor(date1.getTime() / 1000.0)
    var utc2 = Math.floor(date2.getTime() / 1000.0)

    return Math.floor((utc2 - utc1) / 60);
  }



  yearChange() {
    var viewCourse: number[] = [];
    this.months = [];
    this.barChartData = [];
    this.barChartLabels = [];
    if (this.year.value == 0) {
      this.years.forEach(y => {
        this.dataTracker.forEach(d => {
          if (y == d.year) {
            viewCourse.push(d.totalViews);
            this.barChartLabels.push(this.monthsName[d.month - 1] + " " + d.year.toString());
          }
        });
      });
      this.barChartData.push({ data: viewCourse, label: "Views", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' });
    } else {
      this.dataTracker.forEach(d => {
        if (!this.months.includes(d.month) && d.year == this.year.value) {
          this.months.push(d.month);
          viewCourse.push(d.totalViews);
        }
      });
      this.barChartData = [{ data: viewCourse, label: "Views", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];
      this.months.forEach(m => {
        this.barChartLabels.push(this.monthsName[m - 1]);
      });
    }
  }


  goBack() {
    this.location.back();
  }

  viewForAll(){
    this.totViewAll = 0;
    this.barChartData3 = [];
    this.barChartLabels3 = [];
    let viewCourseAll: number[] = [];

    this.dataTracker.forEach(d => {
        this.totViewAll += d.totalViews;
    });

    this.barChartLabels3.push("All Views");
    viewCourseAll.push(this.totViewAll);
    this.barChartData3.push({ data: viewCourseAll, label: "Views", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' });
  }

  viewForStudent(searchId: number) {
    this.barChartData3 = [];
    this.barChartLabels3 = [];
    let viewbyId = [];
    let totAllView = 0;
    this.courseService.getCourseViewById(this.courseId, searchId).subscribe(output => {
      output.forEach(element => {
        totAllView += element.totalViews;
      });

      viewbyId.push(totAllView);

      this.barChartLabels3.push("Views");
      this.barChartData3 = [{ data: viewbyId, label: "Views", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];
    });
  }

  ngAfterViewInit() {
    this.courseService.getCourseParticipants(this.courseId).subscribe(res => {
      this.dataParticipant = res
      console.log('course', res);
      this.dataSrc.data = res;
      this.dataSrc.sort = this.sort;
      this.dataSrc.paginator = this.paginator;
      this.table.dataSource = this.dataSrc;
    });
  }

  doFilter(e) {
    this.dataSrc.filter = e.target.value.trim().toLowerCase();
  }

  getStudentsViews(courseId: number) {
    this.courseService.getCourseStudentsView(this.courseId).subscribe(res => {
      this.userCourseViews = res;
    })
  }
  
  filterViewByStudentId(studentId:number) {
    let totAllView: number = 0;
    let userViews = this.userCourseViews.filter(c => c.idUser === studentId);
    userViews.forEach(element => {
      totAllView += element.totalViews;
    });
    return totAllView;
  }

  exportTotalViewsToCsv() {
    this.dataTracker.sort(firstBy((d: any) => d.year, -1).thenBy((d: any) => d.month, -1));
    let data: CourseView[] = [];
    this.dataTracker?.forEach(element => {
      data.push({
        name: this.course.name,
        author: this.course.author.name + ' ' + this.course.author.surname,
        description: this.course.description,
        header: this.course.header,
        footer: this.course.footer,
        summary: this.course.showcaseSummary,
        year: element.year,
        month: element.month,
        totalViews: element.totalViews,
        hashtag1: this.course.hashTag[0] ?? "",
        hashtag2: this.course.hashTag[1] ?? "",
        hashtag3: this.course.hashTag[2] ?? "",
        hashtag4: this.course.hashTag[3] ?? "",
        hashtag5: this.course.hashTag[4] ?? "",
        hashtag6: this.course.hashTag[5] ?? "",
        hashtag7: this.course.hashTag[6] ?? "",
        hashtag8: this.course.hashTag[7] ?? "",
        hashtag9: this.course.hashTag[8] ?? "",
        hashtag10: this.course.hashTag[9] ?? "",
      })
    })

    this.exporter.exportDataToCsv(
      data,
      ["Name", "Author", "Description", "Header", "Footer", "Summary", "Year", "Month", "Total Views", ...HASHTAGS_HEADERS],
      `Total Views of ${this.course.name}`
    );
  }

  exportTotalViewsStudentsToCsv() {
    let data: StudentView[] = []
    this.dataParticipant.forEach(student => {
      data.push({
        name: student.name,
        surname: student.surname,
        email: student.email,
        role: this.rolePipe.transform(UserRole.role(student)),
        city: student?.idprovinceNavigation?.name === undefined ? null : student?.idprovinceNavigation?.name,
        qualification: student?.idqualificationNavigation?.name  === undefined ? null : student?.idqualificationNavigation?.name,
        telephone: student.telephone,
        views: this.filterViewByStudentId(student.id),
        courseName: this.course.name,
        courseAuthor: this.course.author.name + ' ' + this.course.author.surname,
        courseDescription: this.course.description,
        courseHeader: this.course.header,
        courseFooter: this.course.footer,
        courseSummary: this.course.showcaseSummary,
        hashtag1: this.course.hashTag[0] ?? "",
        hashtag2: this.course.hashTag[1] ?? "",
        hashtag3: this.course.hashTag[2] ?? "",
        hashtag4: this.course.hashTag[3] ?? "",
        hashtag5: this.course.hashTag[4] ?? "",
        hashtag6: this.course.hashTag[5] ?? "",
        hashtag7: this.course.hashTag[6] ?? "",
        hashtag8: this.course.hashTag[7] ?? "",
        hashtag9: this.course.hashTag[8] ?? "",
        hashtag10: this.course.hashTag[9] ?? "",
      })
    })

    this.exporter.exportDataToCsv(
      data,
      ["Name", "Surname", "Email", "Role", "Province", "Qualification", "Telephone", "Views", "Course Name", "Course Author", "Course Description", "Course Header", "Course Footer", "Course Summary", ...HASHTAGS_HEADERS],
      `Total Views of participants of ${this.course.name}`
    );
}

isStudent() {
  return this.auth.isStudent();
}


}

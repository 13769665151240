<h1 mat-dialog-title>{{ 'Add teachers' | translate }}</h1>
<mat-dialog-content fxLayout="column">
    <mat-selection-list [(ngModel)]="selectedTeachers">
        <mat-list-option *ngFor="let teacher of teachers" [value]="teacher.id">
            {{ teacher.surname + ' ' + teacher.name }}
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="addTeachers()" [disabled]="selectedTeachers.length === 0">
        {{ 'Add teachers' | translate }}
    </button>
</mat-dialog-actions>

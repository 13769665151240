import { Injectable, EventEmitter, Output } from '@angular/core';
import { StudentsVideoStatus } from 'src/app/models/studentsVideoStatus';
import { PublisherProperties, Session, SignalEvent, Connection } from 'openvidu-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StartStudentVideoDialogComponent } from 'src/app/pages/video-session/start-student-video-dialog/start-student-video-dialog.component';
import { AuthService } from 'src/app/services/auth.service';

export interface StudentStreamData {
    token: string;
    cameraOptions: PublisherProperties;
    connectionId: string;
    streamId: string;
}

export interface StudentVideoDialogData {
    accepted: boolean;
    cameraOptions: PublisherProperties;
    from: Connection;
}

@Injectable({
  providedIn: 'root'
})
export class TeacherFeedbackService {

    public connectionStatus: number = 0;        // 0: stand by; 1: waiting authorization from student; 2: connection active
    public busy: boolean;
    public teacherConnectedWith: string;

    private ready: boolean;
    private session: Session;
    private isTeacher: boolean;
    private senderConnectionId: string;
    private teacherAskTimeout: any;
    private teacherFrom: Connection;

    private studentVideoDialog: MatDialogRef<StartStudentVideoDialogComponent>;

    // private connectionList: Connection[];
    public connectionList: Connection[];


    @Output()
    startStudentStreamEvent: EventEmitter<StudentVideoDialogData> = new EventEmitter<StudentVideoDialogData>();

    @Output()
    receivingStudentStreamEvent: EventEmitter<StudentStreamData> = new EventEmitter<StudentStreamData>();

    @Output()
    closeVideoStreamEvent: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    connectionStatusEvent: EventEmitter<number> = new EventEmitter<number>();

    constructor(private snackBar: MatSnackBar, public dialog: MatDialog, private auth: AuthService) {
        this.busy = false;
        this.ready = false;
        this.connectionStatus = 0;
        this.connectionList = [];
        
    }

    /*
        Teacher             Student
          |                     |   
          |  >-- Richiesta -->  |   
          |  <-- Risposta  --<  |   + session id
          |  >-- Chiusura  -->  |   
          |  <-- Risposta  --<  |   
          |                     |   
          |                     |   

    */

    readonly TIMEOUT_OPEN_CAMERA = 22000;
    readonly TIMEOUT_OPEN_CAMERA_DELTA = 2000;
    readonly TIMEOUT_SHORT_MSG = 5000;
    readonly TIMEOUT_LONG_MSG = 10000;

    readonly CMD_ASK_START_VIDEO = "ask-start-video";
    readonly CMD_ACK_START_VIDEO = "ack-start-video";

    readonly CMD_ASK_CLOSE_VIDEO = "ask-close-video";
    readonly CMD_ACK_CLOSE_VIDEO = "ack-close-video";

    readonly PARAM_EMPTY = "";
    readonly PARAM_YES = "yes";
    readonly PARAM_NO = "no";

    setupService(session: Session, isTeacher: boolean, senderConnectionId: string) {

        // se insegnante
        //  si iscrive all'evento di ACK-RICHIESTA APERTURA
        //  si iscrive all'evento di ACK-CHIUSURA
        // se studente
        //  si iscrive all'evento di RICHIESTA APERTURA
        //  si iscrive all'evento di CHIUSURA STREAM

        this.busy = false;
        this.connectionStatus = 0;
        this.session = session;
        this.isTeacher = isTeacher;
        this.senderConnectionId = senderConnectionId;

        

        console.log(`Setting up the Teacher Feedback Service. Session: ${this.session.sessionId} isTeacher: ${this.isTeacher} senderConnectionId: ${senderConnectionId}`);

        if (isTeacher) {

            // TEACHER

            this.session.on(`signal:${this.CMD_ACK_START_VIDEO}`, (event: SignalEvent) => {
                console.log(event.data); // Message
                console.log(event.from); // Connection object of the sender
                console.log(event.type); // The type of message ("my-chat")

                //this.myAlert(`Student ${event.from.connectionId} answered: ${event.data}`);

                clearTimeout(this.teacherAskTimeout);

                if (event.data == this.PARAM_YES) {
                    this.myAlert("Student accepted to open the camera");
                    this.teacherConnectedWith = event.from.connectionId;
                    this.busy = true;
                    this.connectionStatus = 2;
                    this.connectionStatusEvent.emit(this.connectionStatus);
                } else if (event.data == this.PARAM_NO) {
                    this.myAlert("Student denied to open the camera", "Dismiss", this.TIMEOUT_LONG_MSG);
                    this.connectionStatus = 0;
                    this.connectionStatusEvent.emit(this.connectionStatus);
                } else {
                    this.connectTeacherWithStudent(JSON.parse(event.data));
                }
            });

            this.session.on(`signal:${this.CMD_ACK_CLOSE_VIDEO}`, (event: SignalEvent) => {
                console.log(event.data); // Message
                console.log(event.from); // Connection object of the sender
                console.log(event.type); // The type of message ("my-chat")

                if (event.data == this.PARAM_YES) {
                    //this.myAlert(`Student ${event.from.connectionId} closed the camera`);
                    this.myAlert(`Student closed the camera`);
                    this.closeVideoStreamEvent.emit();
                    this.teacherConnectedWith = "";
                    this.busy = false;
                    this.connectionStatus = 0;
                    this.connectionStatusEvent.emit(this.connectionStatus);
                } else {
                    //this.myAlert(`Student ${event.from.connectionId} did not closed the camera`);
                    this.myAlert(`Student did not close the camera`);
                }
            });



        } else {

            // STUDENT

            this.session.on(`signal:${this.CMD_ASK_START_VIDEO}`, (event: SignalEvent) => {
                console.log(event.data); // Message
                console.log(event.from); // Connection object of the sender
                console.log(event.type); // The type of message ("my-chat")

                this.teacherFrom = event.from;

                this.showStudentVideoDialog(event.from); // confirm(`Teacher ${event.from.connectionId} asked to start video! Do you want to continue?`);
            });

            this.session.on(`signal:${this.CMD_ASK_CLOSE_VIDEO}`, (event: SignalEvent) => {
                console.log(event.data); // Message
                console.log(event.from); // Connection object of the sender
                console.log(event.type); // The type of message ("my-chat")

                //this.myAlert(`Teacher ${event.from.connectionId} asked to close video!`);
                this.myAlert(`Your teacher asked to close the camera`);

                this.closeVideoStreamEvent.emit();

                this.sendMessage(this.CMD_ACK_CLOSE_VIDEO, this.PARAM_YES, [ event.from ]);
            });    
        }



        this.ready = true;
    }

    disposeService() {
        if (!this.ready) {
            return;
        }

        console.log(`Disposing the Teacher Feedback Service. Session: ${this.session.sessionId} isTeacher: ${this.isTeacher}`);
        this.session = null;
        this.ready = false
    }

    connectionCreated(connection: Connection) {
        this.connectionList.push(connection);
    }

    connectionDestroyed(connection: Connection) {
        if (this.teacherConnectedWith == connection.connectionId) {
            this.teacherConnectedWith = "";
            this.busy = false;
        }
        var removeIndex = this.connectionList.map(function(item) { return item.connectionId; }).indexOf(connection.connectionId);
        if (removeIndex >= 0) {
            this.connectionList.splice(removeIndex, 1);
        }
    }

    askStudentToStartVideo(student: StudentsVideoStatus) {
        if (!this.ready) {
            return;
        }

        var connectionIdx = this.connectionList.map(function(item) { return item.connectionId; }).indexOf(student.connectionId);
        if (connectionIdx >= 0) {
            var connection = this.connectionList[connectionIdx];
            this.sendMessage(this.CMD_ASK_START_VIDEO, this.PARAM_EMPTY, [connection]);
            this.connectionStatus = 1;
            this.connectionStatusEvent.emit(this.connectionStatus);
            this.myAlert("Requesting the student to open the camera... Please Wait.", "Dismiss", this.TIMEOUT_LONG_MSG);
            this.teacherAskTimeout = setTimeout(() => {
                this.myAlert("Student did not open the camera in time.", "Dismiss", this.TIMEOUT_LONG_MSG);
                this.connectionStatus = 0;
                this.connectionStatusEvent.emit(this.connectionStatus);
            }, this.TIMEOUT_OPEN_CAMERA);
        } else {
            this.myAlert("Warning: the student is not available anymore. Please refresh your Students list.")
        }

    }

    askStudentToCloseVideo(student: StudentsVideoStatus) {
        if (!this.ready) {
            return;
        }

        if (!this.busy) {
            return;
        }

        var connectionIdx = this.connectionList.map(function(item) { return item.connectionId; }).indexOf(student.connectionId);
        if (connectionIdx >= 0) {
            var connection = this.connectionList[connectionIdx];
            this.sendMessage(this.CMD_ASK_CLOSE_VIDEO, this.PARAM_EMPTY, [connection]);
        }
    }

/*     askToChat(student: StudentsVideoStatus){
       console.log('EEE askToChat ' + student.connectionId);
       const connection = this.connectionList.find(con => con.connectionId === student.connectionId)
       if (connection){
           // Ettore
           const item=this.chatList.find(cl => cl.student.connectionId == student.connectionId)
           if (!item){
            console.log('EEE studente NON presente');
            this.chatList.push({session: this.session, connection, student, msg: '', tipo: 'chat' });
           } else {
               console.log('EEE studente già presente');
           }

           console.log('EEE trovata connessione ');
       }
    } */

 /*     chatFindConnection(connectionId: string): Connection {
         return this.chatList.find(con => con.connectionId === connectionId )
     } */


/*     askChatToTeacher() {
            // Ettore la connessione con il teacher
            // this.teacherFrom = this.session.remoteConnections
            const key=Object.keys(this.session.remoteConnections)[0];
            const utente =this.auth.getCurrentUser();
            const teacherConnection = this.session.remoteConnections[key];
            // const teacherConnection = this.allCon.find(con=>con.connectionId == id)
            if (teacherConnection){
               this.session
                .signal({
                    data: utente.name + ':' + utente.surname, // Any string (optional)
                    to: [teacherConnection], // Array of Connection objects (optional. Broadcast to everyone if empty)
                    type: "r-chat", // The type of message (optional)
                })
                .then(() => {
                    console.log("Message successfully sent to teacher");
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    } */

 /*    creaChatConTeacher(connection: Connection, msg: string) {
        if (connection && this.chatList.length === 0){
            this.chatList.push({session: this.session, connection, student: null, msg, tipo: 'chat'});
        }
    }
    closeChat(connection: Connection){
        const idx = this.chatList.findIndex(ch=>ch.connection == connection )
        if (idx > -1) {
            this.chatList.splice(idx, 1);
        }
    } */


    showStudentVideoDialog(from: Connection) {

        if (!this.studentVideoDialog) {
            this.studentVideoDialog = this.dialog.open(StartStudentVideoDialogComponent, {
                width: '350px'
            });
            setTimeout(() => {
                if (this.studentVideoDialog) {
                    this.studentVideoDialog.close();
                    this.studentVideoDialog = null;
                }
            }, this.TIMEOUT_OPEN_CAMERA - this.TIMEOUT_OPEN_CAMERA_DELTA);
            this.studentVideoDialog.afterClosed().subscribe((result: StudentVideoDialogData) => {
                console.log('The dialog was closed');
                if (!!result) {
                    console.log(result);
                    this.studentVideoDialog = null;
                    result.from = from;
                    if (result.accepted) {
                        this.startStudentStreamEvent.emit(result);
                        this.sendMessage(this.CMD_ACK_START_VIDEO, this.PARAM_YES, [ from ]);
                    } else {
                        this.sendMessage(this.CMD_ACK_START_VIDEO, this.PARAM_NO, [ from ]);
                    }
                }
            });
        }
    }

    closeStudentVideoDialog() {
        if (this.studentVideoDialog) {
            this.studentVideoDialog.close();
        }
    }

    sendStudentSessionInfo(connectionId: string, streamId: string, token: string, cameraOptions: PublisherProperties, from: Connection) {
        var data = {
            connectionId: connectionId,
            streamId: streamId,
            token: token,
            cameraOptions: cameraOptions
        };
        this.sendMessage(this.CMD_ACK_START_VIDEO, JSON.stringify(data), [ from ]);
    }

    connectTeacherWithStudent(data: StudentStreamData) {
        this.receivingStudentStreamEvent.emit(data);
    }

    closeStudentFeedback() {
        this.sendMessage(this.CMD_ACK_CLOSE_VIDEO, this.PARAM_YES, [ this.teacherFrom ]);
    }

    studentLeft() {
        this.connectionStatus = 0;
        this.teacherConnectedWith = null;
        this.busy = false;
    }

    sendMessage(type: string, data: string, recipients: Connection[]) {

        if (!this.ready) {
            return;
        }

        this.session.signal({
            data: data,             // Any string (optional)
            to: recipients,         // Array of Connection objects (optional. Broadcast to everyone if empty)
            type: type              // The type of message (optional)
          })
          .then(() => {
              console.log(`Message of type '${type}' containing '${data}' successfully sent to recipients:`);
              console.log(recipients);
          })
          .catch((error: any) => {
              console.error(error);
          });
    }

    myAlert(message: string, action: string = 'Dismiss', duration: number = this.TIMEOUT_SHORT_MSG) {
        this.snackBar.open(message, action, {
            duration: duration,
        });
    }
}

export enum SignalType {
	askTempPresenter = "signal:ask-temp-presenter",
	tempPresenter = "signal:temp-presenter",
	globalChat = "signal:global-chat",
	toggleHand = "signal:toggle-hand",
	priorityUpdate = "signal:priority-update",
	directionalChat = "signal:directional-chat",
	directionalGlobalChat = "signal:directional-global-chat",
	addUserToRoom = "signal:add-user-room"
}

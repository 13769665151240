<h1 mat-dialog-title>{{ 'Please open the camera' | translate }}</h1>
<div mat-dialog-content>
    <p>Your teacher is requesting to open the camera. Please setup your video options and click on Confirm below.</p>
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
            <label>{{ 'Quality' | translate }}</label>
        </div>
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-radio-group [formControl]="qualityForm" name="quality" id="quality" required>
                <mat-radio-button value="low" title="640x480">VGA</mat-radio-button>
                <mat-radio-button value="medium" title="1280x720">HD</mat-radio-button>
                <mat-radio-button value="high" title="1920x1080">Full HD</mat-radio-button>
                <!--<mat-radio-button value="veryhigh" title="3840x2160">4K</mat-radio-button>-->
            </mat-radio-group>
        </div>
    </div>
    <br />
    <!--
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row" style="margin-top: 10px;">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="labelText">
            <label>{{ 'Streaming Options' | translate}}</label>
        </div>
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-checkbox [(ngModel)]="joinWithVideo" name="joinWithVideo" id="joinWithVideo">Video</mat-checkbox>
            <mat-checkbox [(ngModel)]="joinWithAudio" name="joinWithAudio">Audio</mat-checkbox>
        </div>
    </div>
    <br />
    -->
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field style="width: 100%">
                <mat-label>{{ 'Video Device' | translate }}</mat-label>
                <mat-select [formControl]="videoForm1" required>
                    <!--(change)="onChange($event.target.value)"-->
                    <mat-option *ngFor="let dev of videoDeviceArr" value="{{ dev.deviceId }}">
                        {{ dev.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
            <mat-form-field style="width: 100%">
                <mat-label>{{ 'Audio Device' | translate }}</mat-label>
                <mat-select [formControl]="audioForm" required>
                    <!--(change)="onChangeAudio($event.target.value)"-->
                    <mat-option *ngFor="let dev of audioDeviceArr" value="{{ dev.deviceId }}">
                        {{ dev.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close type="button" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" class="join-btn" [disabled]="!videoForm1.valid || !audioForm.valid || !qualityForm.valid" (click)="confirmDialog()">{{ 'Ok' | translate }}</button>
</div>

import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Input,} from '@angular/core';
import { Session, Connection } from 'openvidu-browser';
import { ChatService } from 'src/app/services/chat.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  messaggi = [
    { id: 'in', msg: 'ciao' },
    { id: 'out', msg: 'ciao!' },
    { id: 'in', msg: 'come va' },
    { id: 'out', msg: 'bene grazie' },
    { id: 'in', msg: 'volevo chiederti se ti ricordi....' },
    { id: 'in', msg: '... e che dopo abbiamo fatto rotondo' },
    { id: 'out', msg: 'e come no, che divertita' },
    { id: 'in', msg: 'lo facciamo anche il prossimo sabato?' },
    { id: 'out', msg: 'mmmm... aspetta che guardo...' },
    { id: 'out', msg: ' no! mi dispiace ho Judo' },
  ];

  msg_txt = '';
  msg_error = '';
  id_utente = 1;
  altro_name = 'Studente1';
  mio_nome ='io'
  onPausa: boolean;

  @Input() session: Session;
  @Input() nome: string;
  @Input() connection: Connection;
  @Input() msg: string;
  @Input() demo: boolean = false;
  @Input() tipo:string = '';

  @ViewChild('chatContainer') private myScrollContainer: ElementRef;

  constructor(private auth: AuthService,
              public chatService: ChatService) {
    //
  }
  ngOnInit() {
    console.log('EEE init componente chat');
    this.onPausa = false;
    try {
      console.log('EEE recupero sessione ');
      console.log('EEE sessione recuperata');
      if (this.demo === false) {
        console.log('EEE non sono in demo');
        this.messaggi = [];

          console.log('EEE sono teacher scrivo a studente: ' + this.nome );
          //this.id_utente = this.student.id;
          this.altro_name = this.nome;
/*         } else {
          console.log('EEE sono uno studente ricevo messaggi dal teacher');
          this.id_utente = 0;
          this.altro_name = 'Teacher';
        } */
        // primo messaggio
        if (this.msg !== '' ) {
         // richiesta chat al teacher
         if (this.tipo === this.chatService.CHAT_REQUEST ) {
          
    
          this.altro_name = this.msg

          this.msg = 'richiesta chat da: ' + this.altro_name;

       }
          console.log('EEE invio primo messaggio');
          const msg = { id: 'in', msg: this.msg };
          this.messaggi.push(msg);
          this.playAudio();
        }

        // ettore  per chat
        console.log('EEE associo messaggi alla chatta');
        this.session.on(`signal:${this.chatService.CHAT_START}`, (event: any) => {
          console.log('chat message: ' + event.data); // Message
          console.log('chat from', event.from); // Connection object of the sender
          console.log('chat type', event.type); // The type of message ("my-chat")

          if (this.connection.connectionId === event.from.connectionId){
          // ricevo il messaggio
          console.log('EEE ricevo primo messaggio');
          const msg = { id: 'in', msg: event.data };
          this.messaggi.push(msg);
          this.scrollToBottom();
          this.playAudio();
          console.log('EEE ricevuto primo messaggio');
          } 
        });
        
      } else {
      }
    } catch (e) {
      console.error('EEE errore creazione elemento chat' + e.message);
    }
    console.log('EEE componente chat inizializzato');
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../assets/sound/note.mp3';
    audio.load();
    audio.play();
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }, 200);
  }

  ngOnDestroy() {
    //
  }

  sendMessage() {
    this.msg_error = '';
    if (this.msg_txt !== '') {
      const msg = { id: 'out', msg: this.msg_txt };

      if (this.demo == false) {
        this.session
          .signal({
            data: this.msg_txt, // Any string (optional)
            to: [this.connection], // Array of Connection objects (optional. Broadcast to everyone if empty)
            type: this.chatService.CHAT_START, // The type of message (optional)
          })
          .then(() => {
            console.log('Message successfully sent');
            this.messaggi.push(msg);
            this.scrollToBottom();
            this.msg_txt = '';
          })
          .catch((error) => {
            console.error(error);
            this.msg_error = error;
          });
      } else {
        this.messaggi.push(msg);
        this.msg_txt = '';
        this.scrollToBottom();
      }
    }
  }

  sendClose(){
    const utente = this.auth.getCurrentUser();
    this.session
    .signal({
      data: utente.name + ' ' + utente.surname, // Any string (optional)
      to: [this.connection], // Array of Connection objects (optional. Broadcast to everyone if empty)
      type: this.chatService.CHAT_CLOSE, // The type of message (optional)
    })
    .then(() => {
      console.log('EEE Message close successfully sent to ' + this.connection.connectionId);
   
    })
    .catch((error) => {
      console.error(error);
      this.msg_error = error;
    });
  }

  closeChat() {
    this.sendClose();
    this.chatService.closeChat(this.connection);
  }

  pausaChat() {
    this.onPausa = !this.onPausa;
  }
}

<h1 mat-dialog-title>{{ dialogTitle | translate }}</h1>
<div mat-dialog-content fxLayout="column">
  <mat-form-field fxFlex color="primary">
    <mat-label>{{ "Title" | translate }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="titleForm"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="titleForm.hasError('required')">
      {{ "The title is" | translate }}
      <strong>{{ "Required" | translate }}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlex color="primary">
    <mat-label>{{ "Description" | translate }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="descriptionForm"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="descriptionForm.hasError('required')">
      {{ "The description is" | translate }}
      <strong>{{ "Required" | translate }}</strong>
    </mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">
    {{ "Cancel" | translate }}
  </button>
  <button
    mat-flat-button
    color="accent"
    [disabled]="okBtnDisabled()"
    (click)="postSurvey()"
  >
    Ok
  </button>
</div>

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-content-table',
  templateUrl: './content-table.component.html',
  styleUrls: ['./content-table.component.scss']
})
export class ContentTableComponent {

  private backupContents: CourseContentDTO[] = [];

  @Input()
  courseAuthorId: number = undefined;

  @Input()
  courseMode: number = undefined;

  @Input()
  contents: CourseContentDTO[] = [];

  @Output()
  onSave: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  columns: string[] = ['type', 'author', 'name', 'description', 'hashtags', 'data', 'goto'];
  reorderMode: boolean = false;
  sending: boolean = false;

  currentUser: User;

  constructor(private auth: AuthService,
              private courseService: CourseService,
              private snackBar: MatSnackBar) {

    this.currentUser = this.auth.getCurrentUser();
  }

  dropTable(event: CdkDragDrop<CourseContentDTO[]>) {
    moveItemInArray(this.contents, this.contents.findIndex(d => d === event.item.data), event.currentIndex);
  }

  reorder(enable?: boolean) {
    this.reorderMode = enable ?? !this.reorderMode;

    this.reorderMode ?
    this.backupContents = this.contents.slice() :
    this.contents = this.backupContents.slice();

    let hasEditColumn = this.columns.includes('edit');

    if (this.reorderMode && !hasEditColumn)
      this.columns.unshift('edit');

    if (!this.reorderMode && hasEditColumn)
      this.columns.shift();
  }

  save() {
    this.sending = true;

    this.courseService.reorderCourseContents(this.contents[0].idCourse, this.contents.map((c, i) => <{ index: number, courseContentId: number }>{ index: i, courseContentId: c.id }))
      .subscribe({
        next: () => {
          this.sending = false;
          this.reorder(false);

          this.onSave.emit(true);
        },
        error: err => {
          console.error(err);

          this.sending = false;
          this.reorder(false);

          this.snackBar.open(err.error.Message, '', { duration: 3000 });
        }
      });
  }

}

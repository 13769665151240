import { Component, OnInit, /* parte di Luca */ EventEmitter, Output, /* parte di Luca */ Input} from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { ClassroomService } from 'src/app/services/classroom.service';
/* parte di Luca */
import { TeacherFeedbackService } from 'src/app/services/teacher-feedback.service';
/* parte di Luca */
import { StudentsVideoStatus } from 'src/app/models/studentsVideoStatus';
import { ChatService } from 'src/app/services/chat.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-list-video',
  templateUrl: './user-list-video.component.html',
  styleUrls: ['./user-list-video.component.scss']
})

export class UserListVideoComponent implements OnInit {

  currentUser: User;
  classUsers = [];
  dataSource = [];
  isOver = false;

  @Input()
  sidenav: any;

  @Input()
  set opened(value: boolean) {
    if (value === true)
      this.fetchStudents();
  }

  @Input()
  classId: number;

  @Input()
  lessonId: number;

  /* parte di Luca */

  //studentFeedbackBtnColor: string;

  @Output()
  askedStudentToStartVideo: EventEmitter<StudentsVideoStatus> = new EventEmitter<StudentsVideoStatus>();

  @Output()
  askedStudentToCloseVideo: EventEmitter<StudentsVideoStatus> = new EventEmitter<StudentsVideoStatus>();

  @Output()
  startedChatStudent: EventEmitter<StudentsVideoStatus> = new EventEmitter<StudentsVideoStatus>();

  /* parte di Luca */

  constructor(private calendar: CalendarService, 
              private classroomService: ClassroomService,
              /* parte di Luca */ 
              public teacherFeedbackService: TeacherFeedbackService,
              public chatService: ChatService,
              private auth: AuthService,
              ) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    if (this.currentUser.isTeacher)
      this.fetchStudents();

    /*this.teacherFeedbackService.connectionStatusEvent.subscribe((connectionStatus: number) => {
      console.log(">> " + connectionStatus)
      if(connectionStatus == 0)
        this.studentFeedbackBtnColor = 'accent'; //Giallo
      if(connectionStatus == 1)
        this.studentFeedbackBtnColor = 'rgba(55, 59, 66, 1)'; //Grigio
      if(connectionStatus == 2)
        this.studentFeedbackBtnColor = 'rgba(54, 162, 235, 1)'; //Blu
    });*/
  }

  closeNav() {
    this.sidenav.close();
  }

  fetchStudents() {
    this.dataSource = null;
    this.calendar.getStudentsVideoStatus(this.classId, this.lessonId).subscribe(output => {
      this.dataSource = [];

      if(output.length == 0) {
        this.getUsersByClassroom();
      } else {
        this.dataSource = output;
      }
    });
  }
  
  getUsersByClassroom() {
    firstValueFrom(this.classroomService.getUsersPerClassroom(this.classId)).then((res) => {
        var users = res.userList;
        this.dataSource = users;

        this.dataSource.forEach(e => {
          e.actualState = 0;
        });
    });
  }

  getColor(actualState: number) {
    if(actualState == 0)
      return 'rgba(55, 59, 66, 0.5)'; //Grigio
    if(actualState == 1)
      return 'rgba(54, 162, 235, 0.5)'; //Blu
    if(actualState == 2)
      return 'rgba(255, 99, 132, 0.5)'; //Rosso
      //return 'rgba(75, 192, 192, 0.5)'; //Verde
  }

  /* parte di Luca */

  get studentFeedbackBtnColor() {
    console.log(">> " + this.teacherFeedbackService.connectionStatus)
    if(this.teacherFeedbackService.connectionStatus == 0)
      return 'accent'; //Giallo
    if(this.teacherFeedbackService.connectionStatus == 1)
      return 'rgba(55, 59, 66, 1)'; //Grigio
    if(this.teacherFeedbackService.connectionStatus == 2)
      return 'rgba(54, 162, 235, 1)'; //Blu
      //return 'rgba(75, 192, 192, 0.5)'; //Verde
  }

  askToStartVideo(student: StudentsVideoStatus) {
    this.askedStudentToStartVideo.emit(student);
    this.closeNav();
  }

  askToCloseVideo(student: StudentsVideoStatus) {
    this.askedStudentToCloseVideo.emit(student);
    this.closeNav();
  }

  /* parte di Luca */

  /* parte ettore */
  askToChat(student: StudentsVideoStatus) {
    console.log('EEE send start chat');
    // this.startedChatStudent.emit(student);
    this.chatService.askChatToStudent(student);
    this.closeNav();
  }

}

<a *ngFor="let p of previews" [style.color]="isDark() ? 'white' : 'black'" [href]="p.url" target="_blank" rel="noopener" class="link-container">
  <img *ngIf="p.image" [src]="getSanitizedImageUrl(p)" loading="lazy" class="link-img"/>
  <div class="link-text-container">
    <div *ngIf="p.title" class="link-title">
      {{ p.title }}
    </div>
    <div *ngIf="p.description" [style.color]="isDark() ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)'" class="link-description">
      {{ p.description }}
    </div>
  </div>
</a>

<mat-drawer-container hasBackdrop="false" id="conference-container">

    <mat-drawer #directionalChat
        mode="over"
        position="end"
        style="width: 400px"
        (openedChange)="closeChats($event)">

        <app-directional-chat
            *ngIf="isModerator()"
            [hidden]="!mpresentersChatOpened"
            [session]="session"
            [globalUsersChat]="true"
            [globalUsersChatEnabledRoles]="['moderator', 'presenter']"
            [forwardedMessage]="forwardedMessage"
            [hideActive]="true"
            [read]="mpresentersChatOpened"
            (unread)="newMPresentersMessages = $event"
            (onChatsChange)="presentersChats = $event"
            (onClose)="directionalChat.close()"
            enabledRole="presenter"
            title="Speakers">
        </app-directional-chat>

        <app-directional-chat
            *ngIf="isModerator()"
            [hidden]="!mparticipantsChatOpened"
            [session]="session"
            [aggregateChats]="true"
            [forwardActive]="true"
            [forwardChats]="presentersChats"
            [replyActive]="true"
            [hideActive]="true"
            [read]="mparticipantsChatOpened"
            (unread)="newMParticipantsMessages = $event"
            (onForwardedMessage)="forwardedMessage = $event"
            (onClose)="directionalChat.close()"
            enabledRole="participant"
            title="Participants">
        </app-directional-chat>

        <app-directional-chat
            *ngIf="!isModerator()"
            [hidden]="!ppChatOpened"
            [session]="session"
            [globalUsersChat]="isPresenter()"
            [globalUsersChatEnabledRoles]="['moderator', 'presenter']"
            [read]="ppChatOpened"
            (unread)="newPPMesagges = $event"
            (onClose)="directionalChat.close()"
            enabledRole="moderator"
            title="Moderator">
        </app-directional-chat>

    </mat-drawer>

    <div *ngIf="initialSelection; else streamLayout" fxLayout="column" fxLayoutAlign="center center" style="height: 100%">
        <app-source-selection
            [OV]="OV"
            [videoDevices]="getDevices('video', 'all')"
            [audioDevices]="getDevices('audio', 'all')"
            [videoDeviceEnabled]="isPresenter() || isModerator()"
            [audioDeviceEnabled]="isPresenter() || isModerator()"
            [username]="getUsername()"
            [entityName]="'webinar'"
            [lockForm]="lockJoin"
            [acceptedNetwork]="isPresenter() || isModerator() ? validStreamingQualities : undefined"
            (result)="$event == null ? leave() : joinConference($event)">
        </app-source-selection>
    </div>

    <app-conference-participants
        *ngIf="participantsListOpened"
        [conference]="conference"
        [connectedUserIds]="getConnectedParticipantsIds()"
        (onClose)="participantsListOpened = !participantsListOpened"
        dragBoundary="#conference-container"
        class="participants-list">
    </app-conference-participants>

</mat-drawer-container>

<ng-template #streamLayout>

    <button mat-fab class="bottom-button"
        [style.bottom]="toolbarOpened ? '72px' : '0'"
        [color]="toolbarOpened ? 'accent' : 'primary'"
        (click)="toolbarOpened = !toolbarOpened"
        [matBadge]="getAllNotifications()"
        [matBadgeHidden]="toolbarOpened || getAllNotifications() === 0"
        matBadgeColor="accent">
        <mat-icon>{{ toolbarOpened ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>

    <div *ngIf="conferenceRemainingMinutes() <= 10" class="messageBanner" style="top: 40px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'The conference will be closed in x minutes' | translate: { value: conferenceRemainingMinutes() },
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="highlightPresenters.length === 0 && presenters.length === 0 && participants.length > 0" class="messageBanner" style="top: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: publisherName ? (('Conference paused by' | translate) + ' ' + publisherName) : ('Conference paused' | translate),
                                            icon: 'info'
                                         }">
        </ng-container>
    </div>

    <div *ngIf="lostConnection() || streamingQualityValues.length > 0" class="messageBanner" style="bottom: 120px">
        <ng-container *ngTemplateOutlet="messageBanner;
                                         context: {
                                            message: 'No connection. Go to an area with a better connection' | translate,
                                            icon: 'warning'
                                         }">
        </ng-container>
    </div>

    <div fxLayout="column" fxLayoutGap="20px" class="layout-container">

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex>

            <div [hidden]="presenters.length === 0">

                <app-session-participants
                    [session]="session"
                    [presenters]="presenters"
                    [optionMenuBtn]="participantsOptionsBtn"
                    [read]="true"
                    [lowerAllHandsActive]="false"
                    [lowerHandEnabled]="isModerator()"
                    [overlay]="false"
                    (onLowerHand)="toggleHand($event, false)"
                    (unread)="newHandNotifications = $event"
                    (onCurrentUserHandRaised)="isHandRaised = $event"
                    (onLowerAllHands)="toggleHand(null, false)"
                    (onPriorityPosition)="priorityPosition = $event"
                    mode="webinar"
                    dragBoundary="#conference-container">
                </app-session-participants>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex>

                <div *ngIf="publisherShare" class="presenter-stream">
                    <ng-container *ngTemplateOutlet="shareLayout"></ng-container>
                </div>

                <div *ngFor="let highlight of highlightPresenters; last as isLast" class="presenter-stream" [style.width]="100 / highlightPresenters.length">
                    <app-user-video
                        [streamManager]="highlight.manager"
                        [name]="highlight.name"
                        [color]="highlight.color"
                        [btnOptionsMarginBottom]="toolbarOpened && isLast ? '50px' : '0'"
                        [displayOutline]="highlight.userId === currentUser.id"
                        [handRaised]="highlight.handRaised"
                        [lowerHandEnabled]="isModerator()"
                        (onLowerHand)="toggleHand(highlight.userId, false)"
                        visualType="cover">

                        <ng-container *ngTemplateOutlet="
                            participantsOptionsBtn;
                            context: {
                                participant: highlight,
                                compactMode: false,
                                disableOptions: false,
                                disableSetAs: false,
                                disableKickUser: false
                            }" optionBtn>
                        </ng-container>

                    </app-user-video>
                </div>

            </div>

        </div>

    </div>

    <app-session-toolbar
        [hidden]="!toolbarOpened"
        [localParticipant]="localParticipant?.manager"
        [videoDevices]="getDevices('video', 'share')"
        [isVideoOverride]="isVideoOverride()"
        [hasScreenShareCapabilities]="hasScreenShareCapabilities"
        [shareActive]="isPublishing() || isSharing() || isHighlight()"
        [shareEnabled]="isPublishing() || isSharing() || (isHighlight() && !publisherShare)"
        [shareDeviceId]="localParticipantShareVideoDeviceId"
        [isSharing]="isSharing()"
        [(fullScreen)]="isFullScreen"
        [handRaiseActive]="false"
        [(handRaise)]="isHandRaised"
        [recordingActive]="isPublisher() && conference?.lessonSession?.recodingPlanned"
        [isRecording]="isRecording"
        [recordingEnabled]="!togglingRecording"
        [httpLink]="httpLink"
        [closeActive]="isModerator()"
        (handRaiseChange)="toggleHand(currentUser.id, $event)"
        (onRecordingChange)="toggleRecording($event)"
        (onStartShare)="startShare($event)"
        (onStopShare)="stopShare()"
        (onVisualTypeChange)="visualType = $event"
        (onLeave)="leave(true)"
        (onClose)="close()"
        class="bottom-container mat-elevation-z24"> <!--[toggleAudioActive]="isPresenter() || isPublisher() || isPublishing()"-->

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex leftBtns>
            <span style="font-weight: bold">{{ publishingInfo ? publishingInfo : '' }}</span>

            <!--button mat-mini-fab
                (click)="participantsListOpened = !participantsListOpened"
                [disabled]="participants.length === 0 && presenters.length === 0"
                [color]="participantsListOpened ? 'accent' : 'primary'"
                [matTooltip]="'Participants' | translate"
                [matBadge]="newHandNotifications"
                [matBadgeHidden]="newHandNotifications === 0"
                matBadgeColor="accent">
                <mat-icon>
                    people
                </mat-icon>
            </button-->

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <div *ngIf="isRecording" class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                    <button mat-icon-button>
                        <mat-icon color="warn">
                            fiber_manual_record
                        </mat-icon>
                    </button>
                    <span>{{ 'Recording' | translate }}</span>
                </div>
    
                <div class="mat-elevation-z4" style="display: flex; align-items: center; justify-content: center; padding-left: 5px; padding-right: 10px;">
                    <button mat-icon-button (click)="openParticipantList()" [matTooltip]="'Participants' | translate">
                        <mat-icon>
                            people
                        </mat-icon>
                    </button>
                    <span>{{ participants.length }}</span>
                </div>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex rightBtns>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <button mat-mini-fab
                    *ngIf="isModerator()"
                    (click)="toggleChats('participants')"
                    [color]="mparticipantsChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Participants' | translate"
                    [matBadge]="newMParticipantsMessages"
                    [matBadgeHidden]="newMParticipantsMessages === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>
                <button mat-mini-fab
                    *ngIf="isModerator()"
                    (click)="toggleChats('presenters')"
                    [color]="mpresentersChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Speakers' | translate"
                    [matBadge]="newMPresentersMessages"
                    [matBadgeHidden]="newMPresentersMessages === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>
                <button mat-mini-fab
                    *ngIf="!isModerator()"
                    (click)="toggleChats('moderators')"
                    [color]="ppChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Moderator' | translate"
                    [matBadge]="newPPMesagges"
                    [matBadgeHidden]="newPPMesagges === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button>
                <!--button mat-mini-fab
                    (click)="globalChat.toggle()"
                    [color]="globalChatOpened ? 'accent' : 'primary'"
                    [matTooltip]="'Chat' | translate"
                    [matBadge]="newChatMessages"
                    [matBadgeHidden]="newChatMessages === 0"
                    matBadgeColor="accent">
                    <mat-icon>
                        chat
                    </mat-icon>
                </button-->
            </div>

            <span style="font-weight: bold">{{ 'Conference' | translate }} "{{ truncate(conference?.name, 'No name', 30) }}" {{ 'has started x minutes ago' | translate: { value: conferenceElapsedMinutes() } }}</span>
        </div>

    </app-session-toolbar>

</ng-template>

<ng-template #shareLayout>
    <div class="presenter-stream">
        <app-user-video *ngIf="currentUser.id !== publisherShare.userId || publisherShare.type !== 'screen'; else currentUserScreenSharing"
            [streamManager]="publisherShare.manager"
            [name]="publisherShare.name"
            [color]="publisherShare.color"
            [displayOutline]="publisherShare.userId === currentUser.id"
            [btnOptionsMarginBottom]="toolbarOpened ? '50px' : '0'"
            [visualType]="visualType"
            [displayName]="true"
            [displayAudio]="false">

            <ng-container *ngTemplateOutlet="
                participantsOptionsBtn;
                context: {
                    participant: publisherShare,
                    compactMode: false,
                    disableOptions: true,
                    disableSetAs: true,
                    disableKickUser: true
                }" optionBtn>
            </ng-container>

        </app-user-video>
    </div>
</ng-template>

<ng-template #currentUserScreenSharing>
    <div class="share-stream-screen" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-icon style="color:white">screen_share</mat-icon>
        <span style="color:white">{{ 'You are sharing your screen' | translate }}</span>
    </div>
</ng-template>

<mat-menu #streamOptionMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-userId="userId" let-name="name" let-mode="mode" let-handRaised="handRaised">
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject' && localParticipantVideoDeviceId !== false"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('video', 'subject'), type: 'video' }">
            <mat-icon>videocam</mat-icon>
            {{ 'Video Device' | translate }}
        </button>
        <button mat-menu-item
            *ngIf="userId === currentUser.id && mode === 'subject'"
            [matMenuTriggerFor]="devicesMenu"
            [matMenuTriggerData]="{ devices: getDevices('audio', 'subject'), type: 'audio' }">
            <mat-icon>mic</mat-icon>
            {{ 'Audio Device' | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #devicesMenu="matMenu" xPosition="after" yPosition="above" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-devices="devices" let-type="type">
        <button mat-menu-item
            *ngFor="let device of devices"
            (click)="changeSource(device.deviceId, type)"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === device.deviceId || localParticipantAudioDeviceId === device.deviceId">
            {{ device.label }}
        </button>
        <!--button mat-menu-item
            *ngIf="type === 'video'"
            (click)="changeSource(false, 'video')"
            [class.mat-menu-highlight]="localParticipantVideoDeviceId === false">
            {{ 'No camera' | translate }}
        </button-->
    </ng-template>
</mat-menu>

<ng-template #participantsOptionsBtn
    let-participant="participant"
    let-compactMode="compactMode"
    let-disableOptions="disableOptions"
    let-disableSetAs="disableSetAs"
    let-disableKickUser="disableKickUser">

    <div fxLayout="row" [fxLayoutGap]="compactMode ? '0px' : '5px'">

        <ng-template #compactOptionsBtn [ngIf]="currentUser.id === participant?.userId && !disableOptions">
            <button mat-icon-button
                *ngIf="compactMode; else standardOptionsBtn"
                (click)="isFullScreen = false"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardOptionsBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false"
                [matMenuTriggerFor]="streamOptionMenu"
                [matMenuTriggerData]="participant"
                color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactKickUserBtn [ngIf]="isModerator() &&
                                                currentUser.id !== participant?.userId &&
                                                !disableKickUser">
            <button mat-icon-button
                *ngIf="compactMode; else standardKickUserBtn"
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardKickUserBtn>
            <button mat-mini-fab
                (click)="isFullScreen = false; kickParticipant(participant?.manager?.stream?.connection?.connectionId)"
                [matTooltip]="'Kick user' | translate"
                color="warn">
                <mat-icon>person_remove</mat-icon>
            </button>
        </ng-template>

        <ng-template #compactSetAsPublisherBtn [ngIf]="isModerator() &&
                                                       !isModerator(participant?.userId) &&
                                                       !disableSetAs &&
                                                       !publisherShare">
            <button mat-icon-button
                *ngIf="compactMode; else standardSetAsPublisherBtn"
                (click)="isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="accent">
                <mat-icon>{{ isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

        <ng-template #standardSetAsPublisherBtn>
            <button mat-mini-fab
                (click)="isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? setUserAsPresenter(participant?.userId, false, 'multiple') : askUserToBePresenter(participant?.userId, participant?.name)"
                [disabled]="switchPublisherLock"
                [matTooltip]="(isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'Withdraw the word' : 'Give the word') | translate"
                color="primary">
                <mat-icon>{{ isPublishing(participant?.userId) || isHighlightEnabled(participant?.userId) ? 'voice_over_off' : 'record_voice_over' }}</mat-icon>
            </button>
        </ng-template>

    </div>

</ng-template>

<ng-template #messageBanner let-message="message" let-icon="icon">
    <mat-card>
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>
                <mat-icon *ngIf="icon" style="margin-right: 10px; vertical-align: middle;">{{ icon }}</mat-icon>
                {{ message }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content></mat-card-content>
    </mat-card>
</ng-template>

<div [hidden]="!master" class="component-main">
    <div fxLayout="column">
        <div fxLayout="row">
            <div fxLayoutAlign="start center" fxFlex="30" fxLayoutGap="20px" style="padding-left: 20px;">
                <button mat-stroked-button (click)="goBack()">
                    <mat-icon matListIcon>arrow_back</mat-icon>
                    {{ 'Back' | translate }}
                </button>
                <mat-form-field *ngIf="currentUser?.isAdmin" color="primary">
                    <mat-select [(ngModel)]="master.visibility"
                        (selectionChange)="changeVisibility($event)">
                        <!--mat-option [value]="0">{{ 'Private' | translate }}</mat-option-->
                        <mat-option [value]="1">{{ 'Public' | translate }}</mat-option>
                        <mat-option [value]="2">{{ 'Public to all customers' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-stroked-button style="margin-left: 10px;" [cdkCopyToClipboard]="href" *ngIf="!isOneToOne()">
                    <mat-icon matListIcon>content_copy</mat-icon>
                    {{ 'Copy link' | translate }}
                </button>
            </div>
            <h2 fxFlex="60" fxLayoutAlign="center center">{{ (isTemplate() ? 'Personal Live Digital Academy' : 'Live Digital Academy') | translate }}</h2>
            <div fxFlex="30"></div>
        </div>
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">

            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex="50" *ngIf="isTemplate()">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'A collection of live lessons only for you' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #ffb22b;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                person
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h3>{{ 'Each time a personal live digital academy is purchased a lesson calendar must be defined by the author specifically for the user' | translate }}</h3>
                    </div>
                </mat-card-content>
            </mat-card>
            
            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex>
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Agenda' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #26c6da;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                calendar_today
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h2 *ngIf="master.startDate && master.stopDate; else noDates">
                            {{ master.startDate | date: 'dd/MM/YYYY' }}
                            -
                            {{ master.stopDate | date: 'dd/MM/YYYY' }}
                        </h2>
                        <ng-template #noDates>
                            <h2>
                                {{ 'Dates defined by the author' | translate }}
                            </h2>
                        </ng-template>
                        <h4>{{ getMasterDuration() | translate }}</h4>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex *ngIf="!isTemplate()">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Live Lessons' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #ffb22b;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                school
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h2>
                            {{ master.masterContent.length }}
                        </h2>
                        <h4>
                            {{
                                lessonPlannedDuration[0] + ' ' + ('hours' | translate)
                                + ' ' + ('and' | translate)
                                + ' ' + lessonPlannedDuration[1] + ' ' + ('minutes' | translate)
                                + ' ' + ('of streaming' | translate)
                            }}
                        </h4>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex *ngIf="!isTemplate()">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Availability' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <app-spinner-container class="center-item" color="primary" [diameter]="74" [strokeWidth]="5" 
                            [value]="getAvailablePlaces()" [displayWith]="spinnerValue">
                        </app-spinner-container>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex>
                        <h2>{{ availablePlaces < 0 ? 0 : availablePlaces }}</h2>
                        <h4>{{ ('Oof' | translate) + ' ' + master.maxParticipants + ' ' + ('total places' | translate)
                            }}</h4>
                    </div>
                </mat-card-content>
            </mat-card>

            <app-price-card class="mat-elevation-z4" fxFlex
                [routeEnabled]="master.state !== getMasterState().finished &&
                                (currentUserIsTeacher || currentUserIsParticipant||
                                currentUser?.id === master.idAuthor)"
                [routeUrl]="'/master'"
                [routeId]="master.id"
                [queryParams]="{ page: 'sidenav' }"
                [routeMessage]="'Go to Live Digital Academy'"
                [subtitle]="getSubTitle()"
                [subscription]="master.subscription"
                [buyShow]="master.state === getMasterState().planned && currentUser?.isStudent && !fromPackage"
                [buyDisabled]="availablePlaces <= 0 || currentUserHavePaid || subscriptionUser != null"
                [bookShow]="master.state === getMasterState().online && !currentUserIsParticipant && currentUser?.isStudent"
                [bookDisabled]="availablePlaces <= 0"
                (buyFree)="payMasterFree()"
                (buyPay)="addToCart()"
                (book)="joinMaster()"
                [logInToBuy]="true">
            </app-price-card>
        </div>

        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            <mat-card appearance="outlined" *ngIf="master" class="mat-elevation-z4" fxLayout="column" fxFlex="20" style="min-width: 450px;">
                <img mat-card-image [src]="master.imageUrl" [alt]="master.name" loading="lazy">
                <div class="statusText mat-elevation-z3">
                    <h5 style="margin: 0%;">{{ (getMasterState().getState(master.state) | translate).toUpperCase() }}
                    </h5>
                </div>
                <mat-card-header>
                    <mat-card-title>
                        {{ master.name }}
                    </mat-card-title>
                    <mat-card-subtitle *ngIf="master.startDate && master.stopDate; else noDatesCard" style="font-size: 12px;">
                        {{ master.startDate | date: 'dd/MM/YYYY' }} -
                        {{ master.stopDate | date: 'dd/MM/YYYY' }}
                    </mat-card-subtitle>
                    <ng-template #noDatesCard>
                        <mat-card-subtitle style="font-size: 12px;">
                            {{ 'Dates defined by the author' | translate }}
                        </mat-card-subtitle>
                    </ng-template>
                </mat-card-header>
                <mat-card-content style="overflow: hidden;">
                    {{ getHelper().getTruncateShowcase(master.description, 'No description', 250) }}
                </mat-card-content>
                <div fxFlex></div>
                <mat-card-actions *ngIf="master" fxLayout="row" fxLayoutAlign="end center">
                    <!--div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                        <button mat-button [matTooltip]="'Share' | translate" (click)="share()">
                            <mat-icon>share</mat-icon>
                        </button>
                    </div-->
                </mat-card-actions>
            </mat-card>

            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex>
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Summary' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <div [innerHTML]="master.summary">
                    </div>
                </mat-card-content>
                <div fxFlex></div>
                <mat-card-actions fxLayout="row" fxLayoutAlign="start end">
                </mat-card-actions>

            </mat-card>

            <mat-card appearance="outlined" [hidden]="hideFiles()" class="mat-elevation-z4" fxLayout="column" fxFlex="25">
                <video mat-card-image disablepictureinpicture controls oncanplay="this.play(); this.muted = true;"
                    [hidden]="!master.videoPreviewUrl" class="player">
                    <source *ngIf="master.videoPreviewUrl" [src]="master.videoPreviewUrl" type="video/mp4">
                </video>
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Presentation' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxFlex>
                </mat-card-content>
                <mat-card-actions *ngIf="master" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button *ngIf="master.document1Url" mat-icon-button
                        [matTooltip]="getHelper().getFileNameFromURI(master.document1Url)"
                        (click)="downloadFile(master.document1Url)">
                        <mat-icon>file_download</mat-icon>
                    </button>

                    <button *ngIf="master.document2Url" mat-icon-button
                        [matTooltip]="getHelper().getFileNameFromURI(master.document2Url)"
                        (click)="downloadFile(master.document2Url)">
                        <mat-icon>file_download</mat-icon>
                    </button>

                    <button *ngIf="master.document3Url" mat-icon-button
                        [matTooltip]="getHelper().getFileNameFromURI(master.document3Url)"
                        (click)="downloadFile(master.document3Url)">
                        <mat-icon>file_download</mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>

        </div>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { ProvinceDTO } from 'src/app/models/dto/provinceDTO';
import { QualificationDTO } from 'src/app/models/dto/qualificationDTO';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { LANGUAGES, Language, TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  submitProcessing: boolean = false;
  showPassword: boolean = false;
  isMobile: boolean = false;

  logInUsername: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  logInPassword: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);

  name: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  surname: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  email: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required, Validators.email]);
  username: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required, Validators.pattern(Helper.getValidUsernameRegex())]);
  password: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]);
  confirmPassword: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  customerCode: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  province: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
  qualification: UntypedFormControl = new UntypedFormControl(undefined);
  phoneNumber: UntypedFormControl = new UntypedFormControl(undefined, [Validators.pattern("^[0-9]*$"), Validators.maxLength(15), Validators.minLength(9)]);
  language: UntypedFormControl = new UntypedFormControl('en', [Validators.required]);
  dlgs: UntypedFormControl = new UntypedFormControl(false, [Validators.required]);

  languages: Language[] = [];
  provinces: ProvinceDTO[];
  qualifications: QualificationDTO[] = [];

  @Input()
  codeCustomer: string = undefined;

  @Input()
  showUsername: boolean = true;

  @Input()
  showDlgs: boolean = false;

  @Output()
  result: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private userService: UserService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private deviceService: DeviceDetectorService,
              private translationService: TranslationService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.languages = LANGUAGES;
    this.language.setValue(this.translationService.currentLang);

    if (this.codeCustomer)
      this.customerCode.setValue(this.codeCustomer);

    //this.getProvinces();
    //this.getQualifications();
  }

  signUp() {
    this.submitProcessing = true;

    let user = {
      Name: this.name.value,
      Surname: this.surname.value,
      Username: this.username.value,
      Password: this.password.value,
      Email: this.email.value,
      Timezone: Helper.getTimezone(),
      Telephone: this.phoneNumber.value,
      ProvinceId: this.province.value,
      QualificationId: this.qualification.value,
      Code: this.customerCode.value
    };

    firstValueFrom(this.userService.addPublicUser(user))
      .then(async () => {

        this.clear();

        this.submitProcessing = false;

        this.snackBar.open(await firstValueFrom(this.translate.get('User added successfully')), 'Dismiss', { duration: 5000, verticalPosition: 'bottom' });

        /*
        //Per community
        this.dialog.open(GenericPopupComponent, {
            width: '500px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('User creation')),
                body: await firstValueFrom(this.translate.get('To confirm the user creation please click on the link provided to your email address')),
                hideCancelBtn: true
            }
        });
        */

        this.result.emit(true);

      }).catch(async err => {

        this.submitProcessing = false;

        this.snackBar.open(await firstValueFrom(this.translate.get(err.error ? err.error.Message : 'There was a problem adding this user')), 'Chiudi', { 
          duration: 6000, 
          verticalPosition: 'top'
        });

      });
  }

  checkPassword() {
    return this.password.valid
        && this.confirmPassword.valid
        && this.password.value === this.confirmPassword.value;
  }

  getProvinces() {
    this.userService.getProvinces().subscribe({
      next: res => this.provinces = res,
      error: err => console.log(err)
    });
  }

  getQualifications() {
    this.userService.getQualifications().subscribe({
      next: res => this.qualifications = res,
      error: err => console.log(err)
    });
  }

  openDlgs() {
    this.dialog.open(GenericPopupComponent, {
        width: '500px',
        data: <GenericPopupData>{
          title: 'Informativa privacy (D. Lgs. 196/03)',
          body: 'I dati personali saranno trattati in accordo al D. Lgs. 196/03 per registrare la Sua partecipazione e svolgere le connesse pratiche amministrative. I dati potranno essere utilizzati per l’invio di comunicazioni in merito allo svolgimento di altri eventi formativi, e previo consenso dell’interessato potranno essere comunicati a soggetti terzi. L’interessato può esercitare i diritti di cui all’art. 7 del D. Lgs 196/03 rivolgendosi a privacy@dentaltrey.it. Titolare del trattamento è DentalTrey s.r.l., via Partisani 3, 47016 Fiumana - Predappio (FC). Durante l’evento Dental Trey potrà fare registrazioni audio-video e foto ai partecipanti, le quali potranno essere diffuse (eventi pubblici, media, web, pubblicazioni a mezzo stampa, ecc.), fatto salvo l’uso in contesti che possano arrecare pregiudizio alla dignità, alla reputazione e al decoro personale dell’interessato e dei suoi prossimi congiunti. Informativa completa su www.dentaltrey.it. Acconsento alla comunicazione dei miei dati ai soggetti terzi indicati nell’informativa.',
          hideCancelBtn: true
        }
    });
  }

  changeLanguage($event: any) {
    this.language.setValue(this.translationService.setLang($event.value).code);
  }

  getBtnDisabled(): boolean {
    let check = !this.name.valid ||
                !this.surname.valid ||
                !this.email.valid ||
                !this.customerCode.valid ||
                !this.checkPassword() ||
                this.submitProcessing;

    if (this.showUsername)
      check = check ||
              !this.username.valid;

    if (this.showDlgs)
      check = check ||
              !this.dlgs.valid ||
              this.dlgs.value !== true;

    return check;
  }

  private clear() {
    this.showPassword = false;

    this.name.reset();
    this.surname.reset();
    this.email.reset();
    this.username.reset();
    this.password.reset();
    this.confirmPassword.reset();
    this.customerCode.reset();
    this.province.reset();
    this.qualification.reset();
    this.phoneNumber.reset();
    this.language.reset();
    this.dlgs.reset();

    this.name.setErrors(null);
    this.surname.setErrors(null);
    this.email.setErrors(null);
    this.username.setErrors(null);
    this.password.setErrors(null);
    this.confirmPassword.setErrors(null);
    this.customerCode.setErrors(null);
    this.province.setErrors(null);
    this.qualification.setErrors(null);
    this.phoneNumber.setErrors(null);
    this.language.setErrors(null);
    this.dlgs.setErrors(null);
  }
}

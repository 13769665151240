import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { differenceInSeconds } from 'date-fns';
import { Helper } from 'src/app/helpers/helper';
import { LessonAggregateData } from 'src/app/models/lessonAggregateData';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-aggregate-data-table',
  templateUrl: './aggregate-data-table.component.html',
  styleUrls: ['./aggregate-data-table.component.scss']
})
export class AggregateDataTableComponent implements OnInit {

  @Input()
  lessonId: number = undefined;

  @Input()
  userType: 'all' | 'presenters' | 'participants' = 'all';

  @Input()
  set refresh(value: any) {
    this.ngOnInit();
  }

  @Output()
  lessonAggregateData: EventEmitter<LessonAggregateData[]> = new EventEmitter<LessonAggregateData[]>();

  @ViewChild('participantsAggSort') participantsAggSort: MatSort;

  participantsAggSource: MatTableDataSource<LessonAggregateData> = new MatTableDataSource<LessonAggregateData>();
  
  participantsAggColumns: string[] = ['name', 'surname', 'startDate', 'stopDate', 'totalTimeInLessons', 'totalSessions', 'downlinkMin', 'downlinkAvg', 'rttMin', 'rttAvg', 'effectiveTypeMin', 'effectiveTypeAvg'];

  constructor(private calendar: CalendarService) { }

  ngOnInit(): void {
    this.calendar.getAggregateDataOfLesson(this.lessonId, this.userType === 'presenters' ? 2 : this.userType === 'participants' ? 1 : 0)
      .subscribe({
        next: output => {

          this.participantsAggSource.data = output;

          this.participantsAggSource.sortingDataAccessor = (element, property) => {
            switch (property) {
              case 'name':
                return element.user.name;
              case 'surname':
                return element.user.surname;
              default:
                return element[property];
            }
          };

          this.participantsAggSource.sort = this.participantsAggSort;

          this.lessonAggregateData.emit(output);

        },
        error: err => {
          this.participantsAggSource.data = [];
          console.error(err);
        }
      });
  }

  getPercentage(startDate: Date, endDate: Date, time: string) {
    let lessonTime = differenceInSeconds(startDate, endDate);
    let studentTime = Helper.timeFromTimeSpan(time, 'seconds');

    if (lessonTime === 0 || studentTime === 0)
      return 0;

    return (studentTime * 100) / lessonTime;
  }

}

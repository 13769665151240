<h1 mat-dialog-title>{{ title | translate }}</h1>
<div mat-dialog-content>
    <app-translations-form
        [label]="'Name' | translate"
        [disabled]="sending"
        [(translation)]="nameTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name">
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>

    </app-translations-form>
    <app-translations-form
        [label]="'Description' | translate"
        [disabled]="sending"
        [mode]="'textarea'"
        [(translation)]="descriptionTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea matInput [formControl]="description" style="height: 100px"></textarea>
        </mat-form-field>

    </app-translations-form>
    <mat-form-field *ngIf="data.mode === 1" color="primary">
        <mat-label>{{ 'Sequence' | translate }}</mat-label>
        <input matInput type="number" [formControl]="order" [min]="1">
        <mat-error *ngIf="order.hasError('required')">
            {{ 'It' | translate }} <strong>{{ 'is mandatory' | translate }}</strong> {{ 'to select a value' | translate }}
        </mat-error>
    </mat-form-field>
    <!--mat-form-field *ngIf="data.mode === 0" color="primary">
        <mat-label>{{ 'Priority' | translate }}</mat-label>
        <mat-select [formControl]="order">
            <mat-option [value]="contentToEdit.orderIndex" *ngIf="customOrder">{{ 'Custom priority' | translate }}</mat-option>
            <mat-option [value]="1">{{ 'Very High' | translate }}</mat-option>
            <mat-option [value]="2">{{ 'High' | translate }}</mat-option>
            <mat-option [value]="3">{{ 'Medium' | translate }}</mat-option>
            <mat-option [value]="4">{{ 'Low' | translate }}</mat-option>
            <mat-option [value]="5">{{ 'Very low' | translate }}</mat-option>
        </mat-select>
        <mat-error *ngIf="order.hasError('required')">
            {{ 'It' | translate }} <strong>{{ 'is mandatory' | translate }}</strong> {{ 'to select a value' | translate }}
        </mat-error>
    </mat-form-field-->
    <div *ngIf="type !== 'folder'" fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 20px">
        <div fxFlex>
            <mat-checkbox [formControl]="download">{{ 'Downloadable' | translate }}</mat-checkbox>
        </div>
        <!--div *ngIf="currentType === 1" fxFlex> SCOMMENTARE PER ABILITAZIONE SOTTOTITOLI
            <mat-checkbox [(ngModel)]="subtitles">{{ 'Generate subtitles' | translate }}</mat-checkbox>
        </div-->
    </div>
    <div *ngIf="subtitles" fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Media language' | translate }}</mat-label>
            <mat-select [formControl]="mediaLanguage">
                <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.label }}</mat-option>
            </mat-select>
        </mat-form-field>
        <!--mat-form-field color="primary">
            <mat-label>{{ 'Available media subtitles' | translate }}</mat-label>
            <mat-select [formControl]="subtitlesLanguages">
                <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.label | translate }}</mat-option>
            </mat-select>
        </mat-form-field-->
    </div>
    <div>
        <mat-label *ngIf="type !== 'folder'">{{ type === 'scorm' ? 'Scorm' : 'Media' }}</mat-label>
        <app-file-input *ngIf="type !== 'folder'"
            [control]="mediaSrc"
            [maxSize]="5000000"
            [acceptedTypes]="getAcceptedFiles()"
            [rejectedErrorMessage]="('The file type must be a video and not exceed' | translate) + ' 5GB'"
            [deletedErrorMessage]="'Please select a file' | translate"
            (acceptedFile)="mediaAccept()"
            (deletedFile)="mediaDelete()"
            style="width: 100%">
        </app-file-input>
        <mat-label style="margin-top: 20px">{{ 'Thumbnail' | translate }}</mat-label>
        <app-file-input
            [control]="thumbnailSrc"
            [maxSize]="2000"
            [acceptedTypes]="'image/*'"
            [aspectRatio]="'8:5'"
            [maxHeight]="2000"
            [maxWidth]="2000"
            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
            [deletedErrorMessage]="'Please select a file' | translate"
            style="width: 100%">
        </app-file-input>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()" [disabled]="sending">{{ 'Cancel' | translate }}</button>
  <button mat-flat-button color="accent" (click)="saveContent()" [disabled]="getBtnDisabled()">Ok</button>
</div>

<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

<ngx-extended-pdf-viewer *ngIf="currentType === 2"
    [src]="this.mediaSrc.value"
    hidden="true">
</ngx-extended-pdf-viewer>

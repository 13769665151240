import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-master-profile',
  templateUrl: './master-profile.component.html',
  styleUrls: ['./master-profile.component.scss']
})
export class MasterProfileComponent implements OnInit {

  id: number;
  currentUser: User;
  master: MasterDTO;
  receiving: boolean = true;
  classroom: ClassroomDTO;
  currentUserIsParticipant: boolean = false;
  currentUserIsTeacher: boolean = false;
  currentUserHavePaid: boolean = false;
  availablePlaces: number = 0;
  plannedDuration: number[] = [0, 0];
  choiceMaster: string = "showcase";

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private masterService: MasterService) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();

    if (this.route.snapshot.queryParamMap.has('page'))
      this.choiceMaster = this.route.snapshot.queryParamMap.get('page');

    this.getMaster();
  }

  getMaster() {
    this.master = null;

    this.masterService.getMaster(this.id, this.auth.isAuthenticated())
      .subscribe(output => {
        this.master = output;

        let duration = 0;
        output.masterContent.forEach(content => {
          duration += this.getTime(content.lesson.startPlanned, content.lesson.endPlanned);
        });

        this.plannedDuration = Helper.convertToTime(duration / 1000);

        if (output.subscription && output.subscription.subscriptionUser && this.auth.isAuthenticated())
          this.currentUserHavePaid = Helper.getLatestSubscriptionUser(output.subscription.subscriptionUser, this.currentUser.id) != null;
          //this.currentUserHavePaid = output.subscription.subscriptionUser.findIndex(u => u.idUser === this.currentUser.id
          //                                                                            && u.dateFrom < this.master.stopDate
          //                                                                            && u.dateTo > this.master.startDate) !== -1;
        
        this.getParticipants();
      });
  }

  getParticipants() {
    this.masterService.getParticipantsOfMaster(this.id)
      .subscribe({
        next: output => {
          this.classroom = output;

          if (this.master.subscription != null && this.master.subscription.subscriptionUser != null) {

          let paidUsers = this.classroom.classroomContent.filter(cc => this.master.subscription.subscriptionUser.findIndex(su => su.idUser === cc.userId) !== -1);
          this.availablePlaces = this.master.maxParticipants - paidUsers.length;
          
          //Subscriptions valide per il master corrente, metodo non corrento per il calcolo dei posti rimanenti
          //dato che un utente puo avere piu SubscriptioUser con validita differente nel tempo
          //let validSubs = this.master.subscription.subscriptionUser.filter(s => s.dateFrom < this.master.stopDate && s.dateTo > this.master.startDate);
          //let clearedSubs = [];

            //from(validSubs)
            //  .pipe(distinct(s => s.idUser))
            //  .subscribe(s => clearedSubs.push(s))
            //  .add(() => this.availablePlaces = this.master.maxParticipants - clearedSubs.length);
          } else {
            this.availablePlaces = this.master.maxParticipants;
          }

          this.currentUserIsTeacher = output.teacherClassroom.findIndex(t => t.userId === this.currentUser?.id) !== -1;
          this.currentUserIsParticipant = output.classroomContent.findIndex(s => s.userId === this.currentUser?.id) !== -1;

          this.receiving = false;
        },
        error: err => {
          console.log(err);
          this.receiving = false;
        }
      });
  }

  getTime(startDate: Date | string, endDate: Date | string) {
    let start = new Date(startDate);
    let end = new Date(endDate);

    if(end.getTime() - start.getTime() < 1000)
        return 0;

    return end.getTime() - start.getTime();
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { CustomerUpdateEntity } from 'src/app/models/dto/customerUpdateEntity';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.scss']
})
export class DashboardPopupComponent implements OnInit {

  ActiveOrNot: string;
  text_form: UntypedFormControl = new UntypedFormControl (null);
  save_checkBox: boolean = false;

  constructor(public dialogRef: MatDialogRef<DashboardPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private calendar: CalendarService) { }

  ngOnInit() {
    this.ActiveControll();
  }

  onSave() {
    let put: CustomerUpdateEntity = new CustomerUpdateEntity();
    
    if (this.data.type == "Demo") {
      if(this.data.customer.isDemo == 0)
        this.data.customer.isDemo = 1;
      else this.data.customer.isDemo = 0;
      this.postCustomer(put);
    }

    if (this.data.type === "Billing period") {
      if(this.data.customer.invoicePerMonth === 1)
        this.data.customer.invoicePerMonth = 2
      else
      this.data.customer.invoicePerMonth = 1
      this.postCustomer(put)
    }

    if (this.data.type === "Billing") {
      if(this.data.customer.invoicePerMonth === 0)
        this.data.customer.invoicePerMonth = 1
      else
      this.data.customer.invoicePerMonth = 0
      this.postCustomer(put)
    }

    if (this.data.type == "Courses") {
      if(this.data.customer.coursesEnabled == 0)
        this.data.customer.coursesEnabled = 1;
      else this.data.customer.coursesEnabled = 0;
      this.postCustomer(put);
    }
    if (this.data.type == "Authorized") {
      if(this.data.customer.authorized == 0)
        this.data.customer.authorized = 1;
      else this.data.customer.authorized = 0;
      this.postCustomer(put);
    }

    if (this.data.type == "Masters") {
      if(this.data.customer.mastersEnabled == 0)
        this.data.customer.mastersEnabled = 1;
      else this.data.customer.mastersEnabled = 0;
      this.postCustomer(put);
    }

    if (this.data.type == "Smily") {
      if(this.data.customer.smilyEnabled == 0)
        this.data.customer.smilyEnabled = 1;
      else this.data.customer.smilyEnabled = 0;
      this.postCustomer(put);
    }

    if (this.data.type == "Streaming") {
      if(this.data.customer.streamingEnabled == 0)
        this.data.customer.streamingEnabled = 1;
      else this.data.customer.streamingEnabled = 0;
      this.postCustomer(put);
    }

    if (this.data.type == "Low resolution") {
      if(this.data.customer.lowResEnabled == 0)
        this.data.customer.lowResEnabled = 1;
      else this.data.customer.lowResEnabled = 0;
      this.postCustomer(put);
    }

    if (this.data.type == "Write in overlay") {
      this.data.customer.logoText = this.text_form.value;
      this.postCustomer(put);
    }

    if (this.data.type == "Font size") {
      this.data.customer.fontSize = this.text_form.value;
      this.postCustomer(put);
    }

    if (this.data.type == "Max active users") {
      this.data.customer.maxActiveUsers = this.text_form.value;
      this.postCustomer(put);
    }

    if (this.data.type == "Max passive users") {
      this.data.customer.maxPassiveUsers = this.text_form.value;
      this.postCustomer(put);
    }

    if (this.data.type == "Storage size") {
      this.data.customer.maxCourseSize = this.text_form.value;
      this.postCustomer(put);
    }

    if (this.data.type == "Customer code") {
      this.data.customer.iButtonUserName = this.text_form.value;
      this.postCustomer(put);
    }

    if (this.data.type == "Font name") {
      this.data.customer.fontName = this.text_form.value;
      this.postCustomer(put);
    }

    firstValueFrom(this.calendar.putCustomer(put, this.data.customer.id))
    .then(success => console.log("OK"))
    .catch(error => console.log(error));

    this.data.refresh = true;
    this.dialogRef.close(true); 
  }

  onClose() {
    this.data.refresh = false;
    this.dialogRef.close(true);
  }

  postCustomer(post: CustomerUpdateEntity) {
    post.Name = this.data.customer.name;
    post.Description = this.data.customer.description;
    post.IsDemo = this.data.customer.isDemo;
    post.Authorized = this.data.customer.authorized;
    post.DemoDuration = this.data.customer.demoDuration;
    post.CoursesEnabled = this.data.customer.coursesEnabled;
    post.MaxActiveUsers = this.data.customer.maxActiveUsers;
    post.MaxCourseSize = this.data.customer.maxCourseSize;
    post.MaxPassiveUsers = this.data.customer.maxPassiveUsers;
    post.MastersEnabled = this.data.customer.mastersEnabled;
    post.SmilyEnabled = this.data.customer.smilyEnabled;
    post.LogoText = this.data.customer.logoText;
    post.StreamingEnabled = this.data.customer.streamingEnabled;
    post.LowRes = this.data.customer.lowResEnabled;
    post.FontSize = this.data.customer.fontSize;
    post.CustomerCode = this.data.customer.iButtonUserName;
    post.FontName = this.data.customer.fontName;
    post.invoicePerMonth = this.data.customer.invoicePerMonth;
  }

  ActiveControll(){
    switch (this.data.type) {
      case "Demo":
        if (this.data.customer.isDemo == 1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      case "Courses":
        if (this.data.customer.coursesEnabled == 1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      case "Authorized":
        if (this.data.customer.authorized == 1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      case "Masters":
        if (this.data.customer.mastersEnabled == 1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      case "Smily":
        if (this.data.customer.smilyEnabled == 1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      case "Streaming":
        if (this.data.customer.streamingEnabled ==1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      case "Low resolution":
        if (this.data.customer.lowResEnabled ==1) {
          this.ActiveOrNot = "disabled";
        } else {
          this.ActiveOrNot = "enabled";
        }
      break;
      
      case "Write in overlay":
        this.text_form.setValidators([Validators.maxLength(24)]);
        if (this.data.customer.logoText == null)
            this.text_form.disable();
        else
            this.text_form.setValue(this.data.customer.logoText);
        console.log(this.text_form);
        break;


      case "Customer code":
        this.text_form.setValidators([Validators.minLength(3),Validators.maxLength(50)]);
        this.text_form.setValue(this.data.customer.iButtonUserName);
        break;

      case "Font size":
        this.text_form.setValidators([Validators.min(8),Validators.max(24)]);
        this.text_form.setValue(this.data.customer.fontSize);
        if (this.text_form.value == null)
        this.text_form.setValue(12);
        break;

      case "Max active users":
        this.text_form.setValidators([Validators.min(0),Validators.max(100)]);
        this.text_form.setValue(this.data.customer.maxActiveUsers);
        if (this.text_form.value == null)
        this.text_form.setValue(50);
        break;

      case "Max passive users":
        this.text_form.setValidators([Validators.min(0),Validators.max(1000)]);
        this.text_form.setValue(this.data.customer.maxPassiveUsers);
        if (this.text_form.value == null)
        this.text_form.setValue(100);
        break;
      
      case "Storage size":
        this.text_form.setValidators([Validators.min(0),Validators.max(100)]);
        this.text_form.setValue(this.data.customer.maxCourseSize);
        if (this.text_form.value == null)
        this.text_form.setValue(10);
        break;
     
      case "Font name":
        //this.text_form.setValidators([Validators.minLength(0),Validators.maxLength(15)]);
        this.text_form.setValue(this.data.customer.fontName);
        if (Helper.isNullOrEmpty(this.text_form.value))
          this.text_form.setValue("Sans");
        break;

      case "Billing period":
        if (this.data.customer.invoicePerMonth === 1) {
          this.ActiveOrNot = "quarterly";
        } else {
          this.ActiveOrNot = "monthly";
        }
        break;

      case "Billing":
        if (this.data.customer.invoicePerMonth === 0) {
          this.ActiveOrNot = "enabled";
        } else {
          this.ActiveOrNot = "disabled";
        }
        break;
        
      default:
        break;
    }
  }

  checkEnable($event){
     if ($event.checked) {
      this.text_form.setValue(null);
      this.text_form.disable();
      console.log(this.text_form);
    } else
      this.text_form.enable();
  }
  
  disableBtn() {
     if (this.data.type == 'Write in overlay')
      return (!this.text_form.valid && this.text_form.enabled) ||
             (this.text_form.disabled && this.save_checkBox)    //casi text_form è abilitata e non è valida primo caso   secondo caso  text_form disabilitata e checkBox è falso 
             
      return (this.data.type =='Customer code' || 
              this.data.type =='Font name' ||
              this.data.type =='Font size' || 
              this.data.type =='Storage size' ||
              this.data.type =='Max passive users' || 
              this.data.type =='Max active users') &&
              !this.text_form.valid;
  }
}

<h2 mat-dialog-title>Reply to Comment</h2>
<mat-dialog-content class="mat-typography custom-dialog">
  <mat-form-field appearance="outline" style="width: 100%">
    <textarea matInput rows="5" [(ngModel)]="subComment"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="subComment" cdkFocusInitial>
    Submit
  </button>
</mat-dialog-actions>

<div fxLayoutAlign="center center" *ngIf="imageUrl">

    <div fxLayout="column" fxLayoutGap="10px" style="width: 80vw">

        <div style="font-size: 16px; text-align: start;">
            <div fxLayout="column" fxLayoutAlign="center start" style="color: #5092CF;" fxFlex>
                {{ getHashTags() }}
            </div>
            <div fxLayout="column" fxLayoutAlign="center end" style="color: grey;" fxFlex>
                {{ creationDate | date: 'dd/MM/YYYY' }}
            </div>
        </div>

        <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex>
            <img mat-card-image [src]="imageUrl" [alt]="title" loading="lazy">
            <div class="priceText">{{ price }}</div>
            <mat-card-header>
                <mat-card-title>
                    {{ title }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutGap="10px" style="overflow: hidden;">
                <span *ngIf="header" style="font-size: 14px; font-weight: bold;">{{ header }}</span>
                <div [innerHtml]="description"></div>
                <!--div [innerHTML]="showcaseSummary"></div-->
                <div [innerHtml]="footer"></div>
            </mat-card-content>
        </mat-card>

    </div>

</div>

<!--div class="center-item" style="width: 80%;" *ngIf="imageUrl">
    <div style="font-size: 16px; text-align: start;">
        <div fxLayout="column" fxLayoutAlign="center start" style="color: #5092CF;" fxFlex>
            {{ getHashTags() }}
        </div>
        <div fxLayout="column" fxLayoutAlign="center end" style="color: grey;" fxFlex>
            {{ creationDate | date: 'dd/MM/YYYY' }}
        </div>
    </div>
    <div style="position: relative; margin-top: 10px;">
        <img class="mat-elevation-z8 header" id="headerImg" (load)="setImageBrightness($event, 'headerImg')" crossOrigin="anonymous" [src]="imageUrl" loading="lazy">
        <h4 class="headerText" style="top: 15%;" [style.color]="textColor">{{ header }}</h4>
        <h2 class="headerText" style="top: 45%;" [style.color]="textColor">{{ title }}</h2>
        <h4 class="headerText" style="bottom: 10%;" [style.color]="textColor">{{ footer }}</h4>
        <div class="priceText">{{ price }}</div>
    </div>
    <div [innerHtml]="description" fxLayoutAlign="center center" style="text-align: justify; margin-top: 20px; max-width: fit-content; line-height: 1.6;">
    </div>
</div-->

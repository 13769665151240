import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, NgZone, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, take } from 'rxjs/operators';
import { SocialWallBlogDTO } from '../models/dto/SocialWallBlogDTO';
import { SocialWallContentDTO } from '../models/dto/SocialWallContentDTO';
import { SocialWallDTO } from '../models/dto/SocialWallDTO';
import { SocialWallLikeDTO } from '../models/dto/SocialWallLikeDTO';
import { AzureStorageService } from '../services/storage.service';
import { DialogaddlinkComponent } from './extensioncomponent/dialogaddlink.component';
import { DialogcommentComponent } from './extensioncomponent/dialogcomment.component';
import { DialogdispuserlikeComponent } from './extensioncomponent/dialogdispuserlike.component';
import { WallService } from './service/wall.service';
import { User } from '../models/user';
import linkifyHtml from 'linkify-html';
import { ClassroomService } from '../services/classroom.service';
import { Classroom } from '../models/classroom';
import { CustomerDTO } from '../models/dto/customerDTO';
import { SocialWallAdditionalCustomerDTO } from '../models/dto/SocialWallAdditionalCustomerDTO';
import { SocialWallClassroomDTO } from '../models/dto/SocialWallClasroomDTO';
import { DarkThemeService } from '../services/dark-theme.service';
import { AuthService } from '../services/auth.service';
import { SelectionMode, SelectionService } from '../services/selection.service';
import { firstValueFrom, Observable } from 'rxjs';
import { Helper } from '../helpers/helper';
import { GenericPopupComponent, GenericPopupData } from '../popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WallPopupComponent } from './wall-popup/wall-popup.component';
import { WallBlogPopupComponent } from './wall-blog-popup/wall-blog-popup.component';
import { ClassroomDTO } from '../models/dto/classroomDTO';

export class FileInfo {
  name: string
  url: string
}

@Component({
  selector: "app-wall",
  templateUrl: "./wall.component.html",
  styleUrls: ["./wall.component.scss"],
})
export class WallComponent implements OnInit {

  @Input() classroomId: number = -1;

  socialWallArr: SocialWallDTO[] = [];

  visibility: UntypedFormControl = new UntypedFormControl('');
  postTitle: UntypedFormControl = new FormControl('', [Validators.maxLength(256)]);
  postText: UntypedFormControl = new UntypedFormControl('');
  txtComment: UntypedFormControl = new UntypedFormControl('');
  txtSubComment: string;

  selectCustomer: UntypedFormControl = new UntypedFormControl([]);
  selectClassroom: UntypedFormControl = new UntypedFormControl([]);

  fileRejectedPicture: boolean = false;
  reader = new FileReader();
  filePicture: any[] = [];
  fileVideo: any[] = [];
  fileInfo: FileInfo[] = [];
  fileUrlStr: string = "";

  attachLink: SocialWallContentDTO[] = [];
  attachEtcLink: SocialWallContentDTO[] = [];

  currentUser: User;
  profilePicture: string = null;

  classrooms: ClassroomDTO[] = [];
  customersArr: CustomerDTO[] = [];
  socialWallAdditionalCustomerArrPost: SocialWallAdditionalCustomerDTO[] = [];
  socialWallClassroomArrPost: SocialWallClassroomDTO[] = [];

  lastSocialWallId: number;

  hashTags: string[] = [];
  hashTag: UntypedFormControl = new UntypedFormControl('');
  filteredHashTags: Observable<string[]>;

  public get userInputLinks () {
    return "futurepost:" + this.postTitle.value + " : " + this.postText.value;
  }

  socialWallPostToEdit: SocialWallDTO;
  commentContent: UntypedFormControl = new UntypedFormControl([]);

  constructor(private _ngZone: NgZone,
              private auth: AuthService,
              private wallService: WallService,
              private azureService: AzureStorageService,
              public dialog: MatDialog,
              private selectionService: SelectionService,
              private classService: ClassroomService,
              private router: Router,
              private route: ActivatedRoute,
              public translate: TranslateService,
              public snackBar: MatSnackBar,
              private socialWall: WallService,
              public darkService: DarkThemeService) { }

  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  triggerResize () {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit (): void {
    this.currentUser = this.auth.getCurrentUser();

    //Necessario per evitare che sia inviato l'url della foto (es. https://qualcosa.com) al backend
    this.profilePicture = this.currentUser.profilePictureUrl;

    this.getSocialWall();
    
    if (this.route.snapshot.queryParamMap.has('selection') && Boolean(this.route.snapshot.queryParamMap.get('selection')) === true) {
      this.getCourseLinks();
    }
      
    var defaultVisibility = "0";

    var prevTitle = sessionStorage.getItem('prevSocialWallTitle');
    var prevText = sessionStorage.getItem('prevSocialWallText');
    var prevVisibility = sessionStorage.getItem('prevSocialWallVisibility');

    if (prevTitle) {
      this.postTitle.setValue(prevTitle);
      sessionStorage.removeItem('prevSocialWallTitle');
    }

    if (prevText) {
      this.postText.setValue(prevText);
      sessionStorage.removeItem('prevSocialWallText');
    }

    if (prevVisibility) {
      defaultVisibility = "" + prevVisibility;
      sessionStorage.removeItem('prevSocialWallVisibility');
    }

    this.visibility.setValue(defaultVisibility);

    this.selectCustomer.statusChanges.subscribe(
      result => console.log(result)
    );

    this.getClassRoom();

    if (this.currentUser.isAdmin || this.currentUser.isCustomerAdmin || this.currentUser.isAuthor)
      this.getCustomer();

    this.getHashTags();
    this.filteredHashTags = this.hashTag.valueChanges.pipe(
      startWith(''),
      map(value => this._filterHashTags(value))
    );
  }

  getSocialWall () {
    this.wallService.getSocialWallAllChild(this.classroomId).subscribe((output) => {

      output.forEach((ele) => {
        this.processSocialWallPost(ele);

        //set last socialwallid
        this.lastSocialWallId = ele.id;
      });

      this.loadMorePostsRec();
    });
  }

  loadMorePostsRec () {
    this.wallService.getSocialWallAllChildLoadMore(this.classroomId, this.lastSocialWallId).subscribe((output) => {

      output.forEach((ele) => {
        this.processSocialWallPost(ele);

        //set last socialwallid
        this.lastSocialWallId = ele.id;
      });

      if (this.socialWallArr.length < 12 && output.length > 0)
        this.loadMorePostsRec();

    });
  }

  processSocialWallPost (ele: SocialWallDTO) {
    var socialWallLikesArr = [];
    var socialWallBlogsArr = [];
    var socialWallBlogsChildArr = [];
    var socialWallContentsArr = [];

    ele.isLikeUser = 0;
    ele.likeid = 0;

    //social wall likes
    ele.socialWallLikes.forEach(async (elem) => {
      if (elem.iduser == this.currentUser.id) {
        elem.iduserNavigation.name =  await firstValueFrom(this.translate.get("You"));
        ele.isLikeUser = 1;
        ele.likeid = elem.id;
        socialWallLikesArr.push(elem);
      }
    });

    ele.socialWallLikes.forEach((elem) => {
      if (elem.iduser != this.currentUser.id) {
        socialWallLikesArr.push(elem);
      }
    });

    ele.socialWallLikes = socialWallLikesArr;

    //social wall blogs
    ele.socialWallBlogs.forEach((elem) => {

      if (elem.idparent == 1) {
        ele.socialWallBlogs.push(elem);
        socialWallBlogsArr.push(elem);
      } else {
        socialWallBlogsChildArr.push(elem);
      }
    })

    ele.socialWallBlogs = socialWallBlogsArr;
    ele.SocialWallBlogChild = socialWallBlogsChildArr;

    //social wall contents
    ele.socialWallContents.forEach((elem) => {
      socialWallContentsArr.push(elem);
    })

    ele.socialWallContents = socialWallContentsArr;

    this.socialWallArr.push(ele);
  }

  async postSocialWall () {
    var socialWallDTO = new SocialWallDTO();

    socialWallDTO.idauthor = this.currentUser.id;

    let inputText = this.postText.value;

    function replaceH3WithH4(text) {
      // Utilizza una RegEx per cercare <h3>...</h3> all'inizio del testo
      let regex = /^<h3>(.*?)<\/h3>/;
    
      let newText = text.replace(regex, (match, capturedText) => {
        if (match === `<h3>${capturedText}</h3>`) {
          return `<h4>${capturedText}</h4>`;
        } else {
          // Mantieni il testo invariato
          return match;
        }
      });
    
      return newText;
    }

    let cleanText = replaceH3WithH4(inputText);

    socialWallDTO.title = linkifyHtml(this.postTitle.value, {
      defaultProtocol: 'https',
      target: "_blank"
    });

    socialWallDTO.postText = linkifyHtml(cleanText, {
      defaultProtocol: 'https',
      target: "_blank"
    });

    socialWallDTO.visibility = this.visibility.value;
    socialWallDTO.socialWallBlogs = null;
    socialWallDTO.socialWallLikes = null;

    this.wallService.postSocialWal(socialWallDTO).subscribe(async (output) => {
      this.socialWallArr.unshift(output);
      var idSocialWallRef = output.id;

      this.socialWallArr.forEach(async (ele) => {

        if (ele.id != output.id) return;

        ele.SocialWallBlogChild = [];
        ele.socialWallLikes = [];
        ele.socialWallBlogs = [];
        ele.likeid = 0;
        ele.isLikeUser = 0;

        this.filePicture.forEach(async (elem) => {

          var res = await this.azureService.uploadFileFromSocialWall(elem);

          var socialWallContent = new SocialWallContentDTO();
          socialWallContent.idsocialWall = ele.id;
          socialWallContent.contentLink = res;
          socialWallContent.type = 1;

          this.wallService.postSocialWallContent(socialWallContent).subscribe(async (output) => {

            this.socialWallArr.forEach((ele) => {
              if (ele.id != output.idsocialWall) return;

              ele.socialWallContents.push(output);
            });
          });
        });

        this.fileVideo.forEach(async (elem) => {

          var res = await this.azureService.uploadFileFromSocialWall(elem);

          var socialWallContent = new SocialWallContentDTO();
          socialWallContent.idsocialWall = ele.id;
          socialWallContent.contentLink = res;
          socialWallContent.type = 2;

          this.wallService.postSocialWallContent(socialWallContent).subscribe(async (output) => {

            this.socialWallArr.forEach((ele) => {
              if (ele.id != output.idsocialWall) return;

              ele.socialWallContents.push(output);
            });
          });
        });

        this.attachLink.forEach((elem) => {
          var socialWallContent = new SocialWallContentDTO();
          socialWallContent.idsocialWall = ele.id;
          socialWallContent.contentLink = elem.contentLink;
          socialWallContent.description = linkifyHtml(elem.description, {
            defaultProtocol: 'https',
            target: "_blank"
          });
          socialWallContent.type = 0;

          this.wallService.postSocialWallContent(socialWallContent).subscribe((output) => {

            this.socialWallArr.forEach((ele) => {
              if (ele.id != output.idsocialWall) return;

              ele.socialWallContents.push(output);
            });
          });
        });

        this.attachEtcLink.forEach((elem) => {
          var socialWallContent = new SocialWallContentDTO();
          socialWallContent.idsocialWall = ele.id;
          socialWallContent.contentLink = elem.contentLink;
          socialWallContent.description = linkifyHtml(elem.description, {
            defaultProtocol: 'https',
            target: "_blank"
          });
          socialWallContent.type = 3;

          this.wallService.postSocialWallContent(socialWallContent).subscribe((output) => {

            this.socialWallArr.forEach((ele) => {
              if (ele.id != output.idsocialWall) return;

              ele.socialWallContents.push(output);
            });
          });
        });

        //clear attachments
        this.filePicture = [];
        this.fileVideo = [];
        this.attachLink = [];
        this.attachEtcLink = [];

        this.selectionService.clearBackupElements();
      });

      //post to socialwalladditionalcustomer 
      this.socialWallAdditionalCustomerArrPost = [];
      this.socialWallAdditionalCustomerArrPost = this.selectCustomer.value;
      this.socialWallAdditionalCustomerArrPost.forEach(async (elem) => {
        var socialWallAdditionalCustomer = new SocialWallAdditionalCustomerDTO();
        socialWallAdditionalCustomer.idCustomer = Number(elem);
        socialWallAdditionalCustomer.idSocialWall = idSocialWallRef;

        await firstValueFrom(this.wallService.postSocialWallAdditionalCustomer(socialWallAdditionalCustomer));
      });

      if (this.classroomId <= 0) {
        //post to socialwallclassroom
        this.socialWallClassroomArrPost = this.selectClassroom.value;
        this.socialWallClassroomArrPost.forEach(async (elem) => {
          var socialWallClassroom = new SocialWallClassroomDTO();
          socialWallClassroom.idClassroom = Number(elem);
          socialWallClassroom.idSocialWall = idSocialWallRef;

          await firstValueFrom(this.wallService.postSocialWallClassroom(socialWallClassroom));
        });
      } else {
        //post to socialwallclassroom
        var socialWallClassroom = new SocialWallClassroomDTO();
        socialWallClassroom.idClassroom = this.classroomId;
        socialWallClassroom.idSocialWall = idSocialWallRef;

        await firstValueFrom(this.wallService.postSocialWallClassroom(socialWallClassroom));
      }

      this.postTitle.setValue("");
      this.postText.setValue("");
    });
  }

  sortSocialWallArrByDesc (prop: string) {
    return this.socialWallArr.sort((a, b) =>
      a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  writeAComment (socialWallId: number) {
    if (this.txtComment.value) {
      var socialWallBlogDTO = new SocialWallBlogDTO();

      socialWallBlogDTO.idauthor = this.currentUser.id;
      socialWallBlogDTO.idsocialWall = socialWallId;
      socialWallBlogDTO.idparent = 1;
      socialWallBlogDTO.postContent = this.txtComment.value;
      socialWallBlogDTO.postContent = linkifyHtml(socialWallBlogDTO.postContent, {
        defaultProtocol: 'https',
        target: "_blank"
      });

      this.wallService
        .postSocialWallComment(socialWallBlogDTO)
        .subscribe(() => {
          this.txtComment.setValue("");

          this.socialWallArr.forEach((ele) => {
            if (ele.id == socialWallId) {
              ele.socialWallBlogs = [];
              ele.SocialWallBlogChild = [];

              this.wallService
                .getSocialWallBlogBySocialWallId(ele.id)
                .subscribe((output) => {
                  output.forEach((element) => {
                    if (element.idparent == 1) {
                      ele.socialWallBlogs.push(element);
                    } else {
                      ele.SocialWallBlogChild.push(element);
                    }
                  });
                });
            }
          });
        });
    }
  }

  replyComment (socialWallBlogId: number, socialWallId: number) {
    const dialogRef = this.dialog.open(DialogcommentComponent);

    dialogRef.disableClose = true;//disable default close operation
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var socialWallBlogDTO = new SocialWallBlogDTO();

        socialWallBlogDTO.idauthor = this.currentUser.id;
        socialWallBlogDTO.idsocialWall = socialWallId;
        socialWallBlogDTO.idparent = socialWallBlogId;
        socialWallBlogDTO.postContent = result;

        socialWallBlogDTO.postContent = linkifyHtml(socialWallBlogDTO.postContent, {
          defaultProtocol: 'https',
          target: "_blank"
        });

        this.wallService.postSocialWallComment(socialWallBlogDTO).subscribe(() => {
          this.txtComment.setValue('');

          this.socialWallArr.forEach((ele) => {
            if (ele.id == socialWallId) {

              ele.socialWallBlogs = [];
              ele.SocialWallBlogChild = [];

              this.wallService.getSocialWallBlogBySocialWallId(ele.id).subscribe(output => {
                output.forEach((element) => {
                  if (element.idparent == 1) {
                    ele.socialWallBlogs.push(element);
                  } else {
                    ele.SocialWallBlogChild.push(element);
                  }
                });
              });
            }
          });
        });
      }
    });
  }

  likePost (socialWallId: number) {
    var socialWallLikeDTO = new SocialWallLikeDTO();
    socialWallLikeDTO.idsocialWall = socialWallId;

    this.wallService.postSocialWallLike(socialWallLikeDTO).subscribe(() => {

      this.socialWallArr.forEach((ele) => {

        //dont refresh all post to update like
        if (ele.id != socialWallId) return;

        ele.socialWallLikes = [];
        ele.likeid = 0;
        ele.isLikeUser = 0;

        this.wallService.getSocialWallLikeBySocialWallId(ele.id).subscribe((output) => {
          var socialWallLikesArr = [];

          output.forEach(async (elem) => {
            if (elem.iduser == this.currentUser.id) {
              elem.iduserNavigation.name = await firstValueFrom(this.translate.get("You"));
              ele.isLikeUser = 1;
              ele.likeid = elem.id;
              socialWallLikesArr.push(elem);
            }
          });

          output.forEach((elem) => {
            if (elem.iduser != this.currentUser.id) {
              socialWallLikesArr.push(elem);
            }
          });

          ele.socialWallLikes = socialWallLikesArr;
        });
      });
    });
  }

  unlikePost (socialWallLikeId: number, socialWallId: number) {

    this.wallService.deleteSocialWallLike(socialWallLikeId).subscribe(() => {
      this.socialWallArr.forEach((ele) => {

        //dont refresh all post to update like
        if (ele.id != socialWallId) return;

        ele.socialWallLikes = [];
        ele.likeid = 0;
        ele.isLikeUser = 0;

        this.wallService.getSocialWallLikeBySocialWallId(ele.id).subscribe((output) => {
          var socialWallLikesArr = [];

          output.forEach(async (elem) => {
            if (elem.iduser == this.currentUser.id) {
              elem.iduserNavigation.name =  await firstValueFrom(this.translate.get("You"));
              ele.isLikeUser = 1;
              ele.likeid = elem.id;
              socialWallLikesArr.push(elem);
            }

          });

          output.forEach((elem) => {
            if (elem.iduser != this.currentUser.id) {
              socialWallLikesArr.push(elem);
            }
          });

          ele.socialWallLikes = socialWallLikesArr;
        });
      });
    });
  }

  showWhoLike (socialWallId: number) {
    const dialogRef = this.dialog.open(DialogdispuserlikeComponent, {
      data: { socialWallId: socialWallId }
    });
  }

  async onFileChange (event) {
    const fileInfoVar = {
      name: "",
      url: ""
    }

    this.reader = new FileReader();

    const [file] = event.target.files;
    this.reader.readAsDataURL(file);

    this.reader.onload = () => {
      fileInfoVar.name = event.target.files[0].name
      fileInfoVar.url = this.reader.result as string
    }

    if (file.type.match('image.*')) {
      this.filePicture.push(event.target.files[0]);
    } else if (file.type.match('video.*')) {
      this.fileVideo.push(event.target.files[0]);
    }

    this.fileInfo.push(fileInfoVar);
  }

  removePic (item) {
    const index = this.filePicture.indexOf(item);
    // const index = this.fileInfo.indexOf(item);
    if (index !== -1) {
      // this.fileInfo.splice(index, 1);
      this.filePicture.splice(index, 1);
    }

  }

  removeVideo (item) {
    const index = this.fileVideo.indexOf(item);
    if (index !== -1) {
      this.fileVideo.splice(index, 1);
    }
  }

  addLink () {
    const dialogRef = this.dialog.open(DialogaddlinkComponent, { panelClass: 'custom-modalbox' });

    dialogRef.disableClose = true;//disable default close operation
    dialogRef.afterClosed().subscribe((result: SocialWallContentDTO) => {
      if (result.contentLink && result.description) {
        var attachLink = new SocialWallContentDTO();
        attachLink.contentLink = result.contentLink;
        attachLink.description = result.description;
        if (!(attachLink.contentLink.includes("https://") || !attachLink.contentLink.includes("http://"))) {
          attachLink.contentLink = "https://" + attachLink.contentLink;
        }
        attachLink.type = 3;
        this.attachLink.push(attachLink);
      }
    });

  }

  addCourseLink () {
    sessionStorage.setItem('prevSocialWallTitle', this.postTitle.value);
    sessionStorage.setItem('prevSocialWallText', this.postText.value);
    sessionStorage.setItem('prevSocialWallVisibility', this.visibility.value);
    sessionStorage.setItem('prevSocialWallClassrooms', JSON.stringify(this.selectClassroom.value));
    sessionStorage.setItem('prevSocialWallCustomers', JSON.stringify(this.selectCustomer.value));

    this.selectionService.startSelection(null, SelectionMode.socialWallContents);
  }

  getCourseLinks () {
    this.attachEtcLink = [];
    this.selectionService.backupElements.forEach((item) => {
      var attachEtcLink = new SocialWallContentDTO();
      attachEtcLink.contentLink = "" + item.originEntity.id;
      //attachEtcLink.contentLink = item.headerImageUrl + "@@" + item.mediaUrl;
      attachEtcLink.description = item.originEntity.name;
      attachEtcLink.idstringDescription = item.originEntity.idStringName;
      attachEtcLink.type = 3;
      this.attachEtcLink.push(attachEtcLink);
    });
  }

  removeLink (item) {
    const index = this.attachLink.indexOf(item);
    if (index !== -1) {
      this.attachLink.splice(index, 1);
    }
  }

  removeEtcLink (item) {
    const index = this.attachEtcLink.indexOf(item);
    if (index !== -1) {
      this.attachEtcLink.splice(index, 1);
    }
  }

  needCarousel (item: SocialWallDTO) {
    //return item.socialWallContents.find(test => test.type == 1 || test.type == 2) != undefined;
    return this.countCarousel(item) > 0;
  }

  countCarousel (item: SocialWallDTO) {
    return item.socialWallContents.filter(test => test.type == 1 || test.type == 2).length;
  }

  getCarouselSlides (item: SocialWallDTO, type: number) {
    return item.socialWallContents.filter(test => test.type == type);
  }

  getClassRoom () {
    // always selected
    this.classrooms = [];

    this.classService.getClassrooms().subscribe((output) => {
      this.classrooms = output as ClassroomDTO[];

      let prevClassrooms = sessionStorage.getItem('prevSocialWallClassrooms');
      
      if (prevClassrooms) {
        this.selectClassroom.setValue(JSON.parse(prevClassrooms));
        sessionStorage.removeItem('prevSocialWallClassrooms');
      }
    });
  }

  goToEtcContent (url: string) {
    if (url.includes("/coursevideo/")) {
      var id = parseInt(url.replace("/coursevideo/", ""));
      this.goToVideo(id);
    }
    if (url.includes("/course/")) {
      var id = parseInt(url.replace("/course/", ""));
      this.goToCourse(id);
    }
    if (url.includes("/masterview/")) {
      var id = parseInt(url.replace("/masterview/", ""));
      this.goToMaster(id);
    }
  }

  goToVideo (id: number) {
    this.router.navigate(['/coursevideo/' + id]);
  }

  goToCourse (id: number) {
    this.router.navigate(['/course/' + id]);
  }

  goToMaster (id: number) {
    this.router.navigate(['/master/' + id]);
  }

  getCustomer () {
    this.wallService.getAllCustomer().subscribe((output) => {
      this.customersArr = output
      var prevCustomers = sessionStorage.getItem('prevSocialWallCustomers');
      if (prevCustomers) {
        this.selectCustomer.setValue(JSON.parse(prevCustomers));
        sessionStorage.removeItem('prevSocialWallCustomers');
      } else {
        var currentArray = this.customersArr.filter(item => item.id == this.currentUser.idCustomer);
        var selection = currentArray.map(item => item.id);
        this.selectCustomer.setValue(selection);
      }
    });
  }

  loadMore () {
    this.wallService.getSocialWallAllChildLoadMore(this.classroomId, this.lastSocialWallId).subscribe((output) => {
      output.forEach((ele) => {

        var socialWallLikesArr = [];
        var socialWallBlogsArr = [];
        var socialWallBlogsChildArr = [];
        var socialWallContentsArr = [];

        ele.isLikeUser = 0;
        ele.likeid = 0;

        //social wall likes
        ele.socialWallLikes.forEach(async (elem) => {
          if (elem.iduser == this.currentUser.id) {
            elem.iduserNavigation.name =  await firstValueFrom(this.translate.get("You"));
            ele.isLikeUser = 1;
            ele.likeid = elem.id;
            socialWallLikesArr.push(elem);
          }

        })
        ele.socialWallLikes.forEach((elem) => {
          if (elem.iduser != this.currentUser.id) {
            socialWallLikesArr.push(elem);
          }
        })

        ele.socialWallLikes = socialWallLikesArr;

        //social wall blogs
        ele.socialWallBlogs.forEach((elem) => {

          if (elem.idparent == 1) {
            ele.socialWallBlogs.push(elem);
            socialWallBlogsArr.push(elem);
          } else {
            socialWallBlogsChildArr.push(elem);
          }
        });

        ele.socialWallBlogs = socialWallBlogsArr;
        ele.SocialWallBlogChild = socialWallBlogsChildArr;

        //social wall contents
        ele.socialWallContents.forEach((elem) => {
          socialWallContentsArr.push(elem);
        });

        ele.socialWallContents = socialWallContentsArr;

        this.socialWallArr.push(ele);
        //set last socialwallid 
        this.lastSocialWallId = ele.id;
      })
    })
  }

  postBtnDisabled(): boolean {
    var check: boolean = this.postText.value.length === 0 || this.postTitle.value.length === 0 || !this.postTitle.valid;

    if (this.visibility.value == 0)
      return check || this.selectClassroom.value == "";

    if (this.visibility.value == 1)
      return check || this.selectCustomer.value == "";

    return check;
  }

  getHashTags() {
    this.wallService.getHastags()
      .subscribe(output => this.hashTags = output);
  }

  private _filterHashTags(value: string): string[] {
    var filterValue = value.toLowerCase();

    return this.hashTags.filter(hashTag => hashTag.toLowerCase().includes(filterValue));
  }

  getHtml(text: string) {
    if (Helper.isNullOrEmpty(text))
      return text;

    let header = text.substring(0, text.indexOf("</h3>"));
    let headerWithoutHtml = header.replace(/(<([^>]+)>)/ig, "");

    if (Helper.isNullOrEmpty(headerWithoutHtml))
      return headerWithoutHtml;

    return Helper.clean(headerWithoutHtml + ' -');
  }

  getDescription(text: string) {
    if (Helper.isNullOrEmpty(text))
      return text;
    let indexOfH3 = text.indexOf('</h3>');
    if (indexOfH3 !== -1) {
      return Helper.clean(text.substring(indexOfH3 + 5));
    } else {
      return Helper.clean(text);
    }
  }

  isCustomerAdmin () {
    return this.auth.isCustomerAdmin();
  }

  async editPost(socialWallPost: SocialWallDTO) {
    const dialogRef = this.dialog.open( WallPopupComponent, {
      width: '400px',
      data: socialWallPost
    });
  }


  async deletePost(postId: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete Post')),
        body:  await firstValueFrom(this.translate.get(`Are you sure you want to delete this post?`))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.wallService.deleteSocialWallPost(postId)
          .subscribe({
            next: async () => {
              this.snackBar.open(await firstValueFrom(this.translate.get('Post deleted')), undefined, {duration: 3000});
              this.socialWallArr = this.socialWallArr.filter(s => s.id !== postId);
              this.getSocialWall();

            },
            error: err => {
              this.snackBar.open('Error deleting post', undefined, {duration: 3000})
              console.log(err);
            }
          });
      }
    });
  }

  async deleteBlog(idBlog: number) {
    const dialogRef = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: await firstValueFrom(this.translate.get('Delete comment')),
          body: await firstValueFrom(this.translate.get('Are you sure you want to delete this comment?'))
        }
      });
  
      dialogRef.afterClosed().subscribe(async res => {
        if (!res)
          return;
  
        this.socialWall.deleteBlog(idBlog)
          .subscribe({
            next: async () => {
              this.snackBar.open(await firstValueFrom(this.translate.get('Comment deleted')),undefined, { duration: 3000 });
              this.socialWallArr.forEach((ele) => {
               {
                  ele.socialWallBlogs = [];
                  ele.SocialWallBlogChild = [];
                  this.wallService.getSocialWallBlogBySocialWallId(ele.id).subscribe(output => {
                    output.forEach((element) => {
                      if (element.idparent == 1) {
                        ele.socialWallBlogs.push(element);
                      } else {
                        ele.SocialWallBlogChild.push(element);
                      }
                    });
                  });
                }
              });
            },
            error: err => {
              this.snackBar.open(err.Message);
              console.log(err);
            }
          });
      });
  }


  editMode(blog: SocialWallBlogDTO) {
    const dialogRef = this.dialog.open( WallBlogPopupComponent, {
      width: '400px',
      data: blog
    });
  }

  extractLinksFromText(inputText: string) {
    if (Helper.isNullOrEmpty(inputText))
      return null;

    let matches = inputText.match(/href="([^"]*)/g);

    if (!matches)
      return null;

    return matches.join(' ');
  }

  isDark() {
    return this.darkService.isSetDark;
  }
}


@Pipe({ name: 'hashTagsFilter' })
export class HashTagsPipe implements PipeTransform {
  transform(items: any[], text: string): any[] {

    if (!items) return []; console.log(text);

    if (!text || text.length === 0) return items;

    var hashTag = text.toLowerCase();

    return items.filter(elem => {
        var res = elem.hashTags != null ? elem.hashTags.toLowerCase().includes(hashTag) : false;

        if (elem.socialWallContents)
          elem.socialWallContents.filter((content: SocialWallContentDTO) => {
            res = res || (content.hashTags != null ? content.hashTags.toLowerCase().includes(hashTag) : false);
          });

        if (elem.socialWallBlogs)
          elem.socialWallBlogs.filter((blog: SocialWallBlogDTO) => {
            res = res || (blog.hashTags != null ? blog.hashTags.toLowerCase().includes(hashTag) : false);
          });

        return res;
    });
  }
}

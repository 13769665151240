import { UserDTO } from "./dto/userDTO";

export class User {
    public id: number;
    public idCustomer: number;
    public customerName: string;
    public customerDesc: string;
    public coursesEnabled: boolean;
    public mastersEnabled: boolean;
    public smilyEnabled: boolean;
    public importEnabled: boolean;
    public streamingEnabled: boolean;
    public isGlobal: boolean;
    public eduPlanEnabled: boolean;
    public maxActiveUsers: number;
    public lowRes: number;
    public username: string;
    public name: string;
    public surname: string;
    public email: string;
    public isAdmin: boolean;
    public isCustomerAdmin: boolean
    public isTutor: boolean;
    public isTeacher: boolean;
    public isSpeaker: boolean;
    public isStudent: boolean;
    public isAuthor: boolean;
    public state: number;
    public firstPassword: number;
    public changeStateDesc: string;
    public profilePictureUrl: string;
    public userSummary: string;
    public streamInfo: StreamInfo;
    public timeStamp: Date;
    public timezone: number;
    public token: string;
    public signalRToken: string;
    public azureToken: string;
    public ownStripeAccount : boolean;
    public provider: string;
    public googleEnabled: boolean;
    public googleEmail: string;
    public platformName: string;
    public platformLogoUrl: string;
    public frontEndType: number;
    public isDemo: boolean;
    public province: string;
    public telephone: string;
    public qualification: string;

    //Non presenti nell'oggetto del backend
    password?: string;
}

export class StreamInfo {
    public logo: number;
    public logoText: string;
    public fontName: string;
    public fontSize: number;
    public fontColor: string;
    public imageLogoLink: string;
}

import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { AuthService } from 'src/app/services/auth.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-mobile-app-link',
  templateUrl: './mobile-app-link.component.html',
  styleUrls: ['./mobile-app-link.component.scss']
})
export class MobileAppLinkComponent {

  private os: 'ios' | 'android' | 'unknown' = 'unknown';

  background: string = `background-image: url(${EnvironmentHelper.getConfig(undefined, 'backgroundImage')})`;
  playStoreUrl: string = EnvironmentHelper.getConfig((this.isDev() || this.isDT()) ? 'dt' : undefined, 'playstore');
  appStoreUrl: string = EnvironmentHelper.getConfig((this.isDev() || this.isDT()) ? 'dt' : undefined, 'appstore');

  constructor(private navBar: NavBarService,
              private auth: AuthService,
              private device: DeviceDetectorService){}

  ngOnInit() {
    this.navBar.hide('all');

    this.os = this.device.os?.toLocaleLowerCase().includes('android')
            ? 'android'
            : this.device.os?.toLocaleLowerCase().includes('ios')
            ? 'ios'
            : 'unknown';
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  isAndroid() {
    return this.os === 'android';
  }

  isIos() {
    return this.os === 'ios';
  }

  isUnknown() {
    return this.os === 'unknown';
  }

  isDT() {
    return this.auth.isDT();
  }

  isDev() {
    return EnvironmentHelper.isDev();
  }
}

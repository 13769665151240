import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Helper } from "src/app/helpers/helper";
import { PackageContentDTO } from "src/app/models/dto/packageContentDTO";
import { PackageDTO } from "src/app/models/dto/packageDTO";
import { SubscriptionDTO } from "src/app/models/dto/subscriptionDTO";
import { PackageEdit } from "src/app/models/packageEdit";
import { TranslationService } from "./translation.service";

@Injectable({
    providedIn: 'root'
})
export class PackageService {
    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlSubscriptions = `${this.bckEndUrlBaseUrl}/subscription`;
    private bckEndUrlPackages = `${this.bckEndUrlBaseUrl}/packages`;
    private bckEndUrlPackage = `${this.bckEndUrlPackages}/package`;

    constructor(private http: HttpClient,
                private translationService: TranslationService) { }

    public getPackages (userAuthenticated: boolean): Observable<PackageDTO[]> {
        return this.http.get<PackageDTO[]>(`${this.bckEndUrlPackages}/all/${this.translationService.getLanguage()}`, { headers: userAuthenticated ? Helper.getAuthHeader() : null });
    }

    public getAuthorPackages (): Observable<PackageDTO[]> {
        return this.http.get<PackageDTO[]>(`${this.bckEndUrlPackages}/author/${this.translationService.getLanguage()}`, { headers: Helper.getAuthHeader() });
    }

    public getPackage (idPackage: number, userAuthenticated: boolean): Observable<PackageDTO> {
        return this.http.get<PackageDTO>(`${this.bckEndUrlPackage}/${idPackage}/${this.translationService.getLanguage()}`, { headers: userAuthenticated ? Helper.getAuthHeader() : null });
    }

    public postPackage (newPackage: PackageEdit) {
        newPackage.Language = this.translationService.getLanguage();

        return this.http.post(`${this.bckEndUrlPackage}`, newPackage, { headers: Helper.getAuthHeader() });
    }

    public addSubscription (idPackage: number, subscription: SubscriptionDTO) {
        return this.http.post(`${this.bckEndUrlSubscriptions}/package/${idPackage}`, subscription, { headers: Helper.getAuthHeader() });
    }

    public putSubscription (idPackage: number, subscription: SubscriptionDTO) {
        return this.http.put(`${this.bckEndUrlSubscriptions}/package/${idPackage}`, subscription, { headers: Helper.getAuthHeader() });
    }

    public addEntities (idPackage: number, entities: PackageContentDTO[]) {
        return this.http.post(`${this.bckEndUrlPackages}/packagecontent/${idPackage}`, entities, { headers: Helper.getAuthHeader() });
    }

    public buyPackage (subId: number, userId?: number) {
        let options: any = { headers: Helper.getAuthHeader() };

        if (userId != null)
            options.params = new HttpParams()
                                .set('targetUser', String(userId));

        return this.http.post(`${this.bckEndUrlSubscriptions}/paid/package/${subId}`, {}, options);
    }

    public setPackageState(state: number, packageId: number) {
        return this.http.put(`${this.bckEndUrlPackage}/${packageId}/state/${state}`, {}, { headers: Helper.getAuthHeader() });
    }

    public userAlreadyHasSomeEntities(packageId: number) {
        return this.http.get(`${this.bckEndUrlPackage}/${packageId}/checkEntities`, { headers: Helper.getAuthHeader() });
    }

    public updatePackage(packageId: number, newPackage: PackageEdit) {
        newPackage.Language = this.translationService.getLanguage();
        
        return this.http.put(`${this.bckEndUrlPackage}/${packageId}`, newPackage, { headers: Helper.getAuthHeader() });
    }

    public deletePackage(packageId: number) {
        return this.http.delete(`${this.bckEndUrlPackage}/${packageId}`, { headers: Helper.getAuthHeader() });
    }
    
    public updatePackageVisibility(packageId: number, visibility: number) {
        return this.http.get(`${this.bckEndUrlPackage}/${packageId}/visibility/${visibility}`, { headers: Helper.getAuthHeader() });
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationExtras } from '@angular/router';
import { addDays, differenceInMilliseconds, isToday } from 'date-fns';
import { PublisherProperties } from 'openvidu-browser';
import { firstValueFrom, from, Observable } from 'rxjs';
import { first, groupBy, mergeMap, toArray } from 'rxjs/operators';
import { LessonSessionDTO, SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { LessonService } from 'src/app/services/lessons.service';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { JoinSessionDialogComponent } from './join-session-dialog/join-session-dialog.component';
import { AppLessonPopUpComponent } from './app-lesson-pop-up/app-lesson-pop-up.component';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { EmergencyService } from 'src/app/services/emergency.service';
import { LessonNotePopupComponent } from './lesson-note-popup/lesson-note-popup.component';
import { User } from 'src/app/models/user';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AddSurveyPopUpComponent } from 'src/app/survey/add-survey-pop-up/add-survey-pop-up.component';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { ResDTO } from 'src/app/models/dto/resDTO';
import { Helper } from 'src/app/helpers/helper';
import { PageEvent } from '@angular/material/paginator';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { MasterType } from 'src/app/models/dto/masterDTO';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { StreamingClosePopupComponent } from '../video-session/streaming-close-popup/streaming-close-popup.component';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
    selector: 'app-lessons',
    templateUrl: './lessons.component.html',
    styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit {
    name:string;
    duration:number;
    selectedCustomer: number = -1;
    value: string = "";
    backupLessons: any[];
    attachment: CourseContentDTO[];
    userWithSimlyRec: UserDTO[];
    lessons: any[];
    scheduledLessons: LessonSessionDTO[] = [];
    currentUser: User;
    classrooms: ClassroomDTO[];
    customers: CustomerDTO[];
    customer: number;
    //addingLesson: false;
    lessonTitle: string;
    lessonDescription: string;
    classSelected: string;
    quickLessonInterval;
    //submitNewLesson: boolean;
    record;
    gjari: any[] = [];
    quickLessons;
    rooms;

    //Per mat-paginator
    pageSize: number = 10;
    pageIndex: number = 0;
    intervalStreaming: any;
    LessonActiveStreaming: LessonSessionDTO[] = [];
    
    constructor(private lesson: LessonService,
                private calendar: CalendarService,
                private classroomService: ClassroomService,
                private router: Router,
                private auth: AuthService,
                public snackBar: MatSnackBar,
                public dialog: MatDialog,
                private videoSessionService: VideoSessionService,
                private emergency: EmergencyService,
                public darkService: DarkThemeService,
                private surveyService: SurveyService,
                private translate: TranslateService) { }

    async ngOnInit() {
        localStorage.removeItem('quickLesson');
        localStorage.removeItem('lessonActive');
        localStorage.setItem('easyLesson', 'false');

        this.currentUser = this.auth.getCurrentUser();

        this.fetchClassRoom();
        this.fetchCustomer();
        this.fetchRooms();
        this.getScheduledLessons();

        if (this.isStudent()) {

            this.clearQuickLessonInterval();

            this.quickLessonInterval = setInterval(() => {

                this.lesson
                    .getLessonsQuick()
                    .pipe(first())
                    .subscribe(lesson => {

                        if (!lesson || lesson.length === 0)
                            return;

                        this.clearQuickLessonInterval();

                        localStorage.setItem('easyLesson', 'true');
                        
                        this.router.navigate(['/lessons', lesson[0].id], { queryParams: { lessonStartDate: lesson[0].startDate } });
                        
                    });

            }, 10000);

        }

        this.clearStreamingInterval();

        this.intervalStreaming = setInterval(() => {

            this.lesson
                .getActiveStreamingLessons()
                .pipe(first())
                .subscribe(lessons => this.LessonActiveStreaming = lessons);

        }, 2000);
    }

    ngOnDestroy() {
        this.clearQuickLessonInterval();
        this.clearStreamingInterval();
    }

    private clearQuickLessonInterval() {
        if (!this.quickLessonInterval)
            return;

        clearInterval(this.quickLessonInterval);
        this.quickLessonInterval = undefined;
    }

    private clearStreamingInterval() {
        if (!this.intervalStreaming)
            return;

        clearInterval(this.intervalStreaming);
        this.intervalStreaming = undefined;
    }

    getLessons() {
        this.lesson.getLessons()
            .subscribe({
                next: lesson => {
                    console.log(lesson);
                    this.backupLessons = lesson;

                    this.resetLessons();
                },
                error: err => {
                    console.log(err);
                    this.backupLessons = [];
                    this.lessons = [];
                }
            });
    }

    getLessonContents(lesson: any) {
        this.attachment = [];

        if (!lesson.hasCourseContents)
            return;

            this.calendar.getLessonContents(lesson.id)
                .subscribe(calendar => {
                    this.attachment = calendar;
            });
        }

    dateDiff(start: Date, end: Date) {
        return Helper.convertToHMS(differenceInMilliseconds(new Date(end), new Date(start)));
    }

    isTeacher() {
        return this.currentUser.isTeacher;
    }

    isStudent() {
        return this.currentUser.isStudent;
    }
    
    isAdmin() {
        return this.currentUser.isCustomerAdmin;
    }

    openAddDialog(): void {
        let eventData: EventDialogData = {
            classId: this.classrooms[0].id,
            classrooms: this.classrooms,
            rooms: this.rooms,
            type: 1
          };
          
        const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
            width: '500px',
            data: { eventData, page: "lesson" }
          });
    
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.newLesson(result.title, result.description, result.classId, result.roomId, result.recordPlan, result.private, result.smilyRec);
          }
        });
    }

    newLesson(titolo: string, descrizione: string, classId: number, roomId: number, record: boolean, privateR: boolean, smilyRec: boolean) {
        localStorage.setItem('Recording', record.toString());
        //this.submitNewLesson = true;
        return this.lesson.createLesson(titolo, descrizione, classId, roomId, record, privateR, smilyRec)
            .pipe(first())
            .subscribe({
                next: lesson => {
                    console.log('New lesson added: ');
                    //this.submitNewLesson = false;
                    this.snackBar.open('Lesson added!', undefined, { duration: 3000 });
                    //this.addingLesson = false;
                    this.getLessons();
                    return lesson;
                },
                error: err => {
                    console.error(err);
                    //this.submitNewLesson = false;
                    this.snackBar.open(Helper.getLessonErrorMessage(err.status), undefined, { duration: 5000 });
                }
            });
    }

    /*
    getClassrooms() {
        return this.classService.getClassrooms()
            .pipe(first())
            .subscribe(classrooms => {
                this.classrooms = classrooms;
            });
    }*/

    async goToLesson(id, lesson) {
        localStorage.setItem('chosenClass', lesson.idClassroom);
        localStorage.setItem('easyLesson', 'false');
        localStorage.setItem('lessonActive', JSON.stringify(lesson));
        if (this.isTeacher()) {
            await this.videoSessionService.createSession(lesson.id);
            let dialogRef: MatDialogRef<JoinSessionDialogComponent>;
            dialogRef = this.dialog.open(JoinSessionDialogComponent);
            dialogRef.componentInstance.dialogRef = dialogRef;
            dialogRef.afterClosed().subscribe((cameraOptions: PublisherProperties[]) => {
                if (!!cameraOptions) {
                    console.log('Joining session with options:');
                    console.log(cameraOptions);
                    this.videoSessionService.scenario = Number(localStorage.getItem('scenario'));
                    this.videoSessionService.cameraOptions = cameraOptions;
                    
                    localStorage.removeItem('scenario');
                    this.router.navigate(['/lessons/' + id]);
                }
            });
        } else {
            console.log(lesson);
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    lessonStartDate: lesson.startDate
                }
              };
            this.router.navigate(['/lessons/' + id], navigationExtras);
        }
    }

    async closeLesson(lesson: any) {
        const dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Close lesson')),
                body: await firstValueFrom(this.translate.get(`Are you sure you want to close lesson?`, { name: lesson.name }))
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;

            if (this.isTeacher()) {
                const dialogRef = this.dialog.open(LessonNotePopupComponent, {
                    width: '400px',
                    data: { description: lesson.description }
                });

                dialogRef.afterClosed().subscribe(async result => {
                    if(result != null)
                        await this.lesson.addLessonNote(lesson.id, result);
    
                    this.lesson.closeLesson(lesson.id)
                        .then(() => this.ngOnInit());
                });
            } else {
                this.lesson.closeLesson(lesson.id)
                    .then(() => this.ngOnInit());
            }
        });
    }

    async deleteLesson(id: number, name: string, record?: boolean) {

        let msg = record
                ? `Are you sure you want to delete lesson? All associated records will be removed`
                : `Are you sure you want to delete lesson?`;

        const dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Delete lesson')),
                body: await firstValueFrom(this.translate.get(msg, { name: name }))
            }
        });
      
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;

            await this.lesson.deleteLesson(id);
            await this.ngOnInit();
        });
    }

    lessonClosed(name) {
        this.snackBar.open(`Lesson: ${name} is closed!`, 'Dismiss', {
            duration: 2000,
            verticalPosition: 'top'
        });
    }

    lessonNotClosed(name) {
        this.snackBar.open(`Lesson: ${name} has not started yet!`, 'Dismiss', {
            duration: 2000,
            verticalPosition: 'top'
        });
    }

    /*
    getPreview(record) {
        if (record) {
            if (record.url) {
                return `${record.url.substring(0, record.url.length - 3)}jpg`;
            }
        }

    }

    download(record) {
        if (record.url) {
            this.document.location.href = record.url;
            console.log(record)
        }
    }*/

    goToVideo(lesson){
        let navigationExtras: NavigationExtras = {
            queryParams: {
                lessonId: lesson.id,
                title: `${lesson.name} - ${lesson.teacherName} ${lesson.teacherSurname}`
            }
        };

        this.router.navigate(['/lessonvideo'], navigationExtras);
    }

    goToAttachment(attachment: CourseContentDTO){
        this.router.navigate(['/coursevideo/', attachment.id]);
    }

    async easyLesson() {
        localStorage.setItem('easyLesson', 'true'); 
        let dialogRef: MatDialogRef<JoinSessionDialogComponent>;
        dialogRef = this.dialog.open(JoinSessionDialogComponent);
        dialogRef.componentInstance.dialogRef = dialogRef;
        // localStorage.removeItem('easyLesson')
        dialogRef.afterClosed().subscribe((cameraOptions: PublisherProperties) => {
            if (!!cameraOptions) {
                console.log('Joining session with options:');
                console.log(cameraOptions);
                this.videoSessionService.cameraOptions[0] = cameraOptions;
                let classChosen;
                classChosen = localStorage.getItem('chosenClass');
                this.lesson.createQuickLesson('Quick Lesson', 'Easy Lesson', classChosen)
                    .subscribe({
                        next: (lesson: any) => {
                            localStorage.setItem('quickLesson', JSON.stringify(lesson))
                            this.videoSessionService.scenario = 1;
                            this.router.navigateByUrl(['/lessons/'] + lesson.id)
                        },
                        error: error => {
                            console.error(error);
                            this.snackBar.open(Helper.getLessonErrorMessage(error.status), undefined, { duration: 5000 });
                        }
                    });
            }
        });
    }

    groupByClients() {

        const source = from(this.backupLessons);
        const example = source.pipe(
            groupBy(person => person.customerName),
            mergeMap(group => group.pipe(toArray())));
        const subscribe = example.subscribe(val => {
            this.gjari.push(val)
        })

    }

    getScheduledLessons() {
        if (!this.isTeacher() && !this.isStudent())
            return;

        let today: Date = new Date();
        today.setHours(0, 0, 0, 0);
    
        let upperLimit: Date = new Date(today);
        upperLimit = addDays(upperLimit, 16);

        if (this.currentUser.isTutor || this.currentUser.isTeacher || this.currentUser.isSpeaker || this.currentUser.isStudent)
            this.calendar.getLessons(
                today,
                upperLimit,
                undefined,
                undefined,
                undefined,
                2,
                this.currentUser.isCustomerAdmin || this.currentUser.isTeacher || this.currentUser.isTutor ? this.currentUser.idCustomer : undefined
            ).subscribe({
                next: lessons => this.scheduledLessons = lessons.filter(value => value.typeId != 6),
                error: err => {
                    console.log(err);
                    this.scheduledLessons = [];
                }
            });
    }

    activateSheduledLesson(lesson: LessonSessionDTO) {
        this.calendar.activateLesson(lesson.id)
            .subscribe({
                next: () => {
                    this.getLessons();
                    this.getScheduledLessons();
                },
                error: err => {
                    console.error(err);
                    this.snackBar.open(Helper.getLessonErrorMessage(err.status), undefined, { duration: 5000 });
                }
            });
    }

    isTodayLesson(lesson: LessonSessionDTO): boolean {
        return isToday(new Date(lesson.startPlanned));
    }

    activateLessonDisabled(lesson: LessonSessionDTO): boolean {
        if (this.currentUser.id !== lesson.teacherId)
            return true;

        let startDate = new Date(lesson.startPlanned);
        let now = new Date();

        if (!isToday(startDate))
            return true;

        if (lesson.typeId === 4)
            return now < startDate
                || now > new Date(lesson.endPlanned);

        return false;
    }

    getHours(startDate: Date, endDate: Date){
        let start = new Date(startDate);
        let end = new Date(endDate);
        let minuti = "";
        let ore = "";

        if (end.getMinutes() - start.getMinutes() > 0)
            minuti = end.getMinutes() - start.getMinutes() + " Minutes";

        if (end.getHours() - start.getHours() == 1) 
            ore =  end.getHours() - start.getHours() + " Hour";
        else
            ore =  end.getHours() - start.getHours() + " Hours";

        if (ore == "0 Hours")
            return minuti;
        else if (minuti == "")
            return ore;
        
        return ore + " and " + minuti;
    }

    activateClickDisableLesson() {
        this.snackBar.open("You can only activate the Scheduled Lessons scheduled for today.", 'Dismiss', {
            duration: 2000,
            verticalPosition: 'top'
        });
    }

    openReport(lesson) {
        this.router.navigate(['/calendar', lesson.id]);
    }

    fetchClassRoom() {
        this.classroomService.getClassrooms()
            .subscribe(output => { 
                this.classrooms = output as ClassroomDTO[];
                this.getLessons();
            });
    }

    fetchCustomer() {
        var result: Observable<CustomerDTO[]>;
        this.customer = undefined;
        this.customers = undefined;

        if (this.currentUser.isAdmin) {
        result = this.calendar.getCustomerForAdmin();
        } else if (this.currentUser.isCustomerAdmin) {
        result = this.calendar.getCustumerForAdmin(this.currentUser.idCustomer);
        }

        if (result) {
        result.subscribe(output => (this.customers = output));
        }
    }

    fetchRooms() {
        var result: Observable<RoomDTO[]>;

        result = this.calendar.getRoomsForAll();

        if (result)
            result.subscribe(output => (this.rooms = output));
    }

    teacherStreamStuck() {
        const dialogRef = this.dialog.open(StreamingClosePopupComponent);

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                firstValueFrom(this.emergency.UnlockLessosOnTeacher(this.currentUser.id))
                    .then(success => {
                    this.snackBar.open('Session closed successfully', '', { duration: 3000 });
                    }).catch(err => {
                    this.snackBar.open('Error closing session', '', { duration: 3000 });
                    }).finally(() => {
                        this.ngOnInit();
                    });
            }
        });
    }

    goToSurvey(lesson:LessonSessionDTO, preview: boolean = false) {
        this.router.navigate(['/survey', lesson.idSurvey], preview ? { queryParams: { view: 'preview' } } : { queryParams: { idLesson: lesson.id } });
    }

    goToSurveyDisabled(lesson: LessonSessionDTO) {
        return lesson.stopDate !== null
            || new Date() < new Date(lesson.startPlanned)
            || !this.isStudent()
            || lesson.answered;
    }

    goToSurveyResult(lesson: LessonSessionDTO) {
        this.router.navigate(['/survey-result', lesson.id], { queryParams: { idSurvey: lesson.idSurvey } });
    }

    showResultSurvey(lesson) {
        this.router.navigate(['/survey-results', lesson.idSurvey], { queryParams: { idLesson: lesson.id, idClassroom: lesson.idClassroom } } );
    }

    addSurvey(): void {
        let eventData: EventDialogData = {
            dialogTitle : 'Add new survey',
            classId: this.classrooms[0].id,
            rooms: this.rooms,
            classrooms: this.classrooms,
            teacherId: this.currentUser.id,
            teachers: [],
            type: 4
        };

        const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
            width: '500px',
            data: { eventData, page: "master" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            var surveyLesson: SurveyLessonDTO = new SurveyLessonDTO();
            if (!result.survey) {
                surveyLesson.teacherId = result.teacherId;
                surveyLesson.startPlanned = result.startData;
                surveyLesson.endPlanned = result.endData;
                surveyLesson.name = result.title;
                surveyLesson.description = result.description;
                surveyLesson.classId = result.classId,
                surveyLesson.typeId = 4;
                surveyLesson.survey = new SurveyDTO();
                surveyLesson.survey.surveyJSON = '';
            } else {
                surveyLesson.teacherId = result.teacherId;
                surveyLesson.startPlanned = result.startData;
                surveyLesson.endPlanned = result.endData;
                surveyLesson.name = result.survey.name;
                surveyLesson.description = result.survey.description;
                surveyLesson.classId = result.classId,
                surveyLesson.typeId = 4;
                surveyLesson.survey = new SurveyDTO();
                surveyLesson.survey.surveyJSON = result.survey.surveyJSON;
            }

            this.surveyService.AddSurveyToLesson(surveyLesson)
                .subscribe({
                    next: (res:ResDTO) => {

                        if (res.Message)
                            this.router.navigate(['/survey-creator', res.Message]);

                    },
                    error: err => {
                        this.snackBar.open(err.message, '', { duration: 3000 });
                    }
                });   
            }
        });
    }

    getTitleColor(lessonType: number) {
        if (lessonType === 4) {
            return this.darkService.isSetDark ? '#00acc1' : '#004d90';
        }

        return '';
    }

    //Per mat-paginator
    changePage(event?: PageEvent) {
        let minIndex = 0; 
        let maxIndex = this.pageSize;
        
        if (event) {
            this.pageIndex = event.pageIndex;
            this.pageSize = event.pageSize;

            minIndex = event.pageIndex * event.pageSize;
            maxIndex = (event.pageIndex + 1) * event.pageSize;
        }
        
        this.lessons = this.getFilteredLesson(this.value).filter((l, i) => i >= minIndex && i < maxIndex);
    }

 

    resetLessons(resetSearch: boolean = true) {
        if (resetSearch)
            this.value = null;

        this.pageIndex = 0;
        this.pageSize = 10;

        this.changePage(null);
    }

    getFilteredLesson(text: string) {
        let lessons = this.backupLessons;

        if (this.selectedCustomer !== -1)
            lessons = lessons.filter(lesson => lesson.idCustomer === this.selectedCustomer);

        if (Helper.isNullOrEmpty(text))
            return lessons;

        return lessons.filter(lesson => lesson.name?.toLowerCase().includes(text) || lesson.description?.toLowerCase().includes(text));
    }

    isOneToOneLesson(lesson: any) {
        var masterType = lesson instanceof LessonSessionDTO
                    ? lesson.classroom.master.type
                    : lesson.masterType;

        return masterType === MasterType.oneToOne;
    }

    getTruncateString(text: string, maxLength: number) {
        return Helper.getTruncateShowcase(text, 'No title', maxLength);
    }

    getLessonDescription(description: string) {
        if (!description) return description;

        var splittedDescription = description.split('Notes:');
        if (splittedDescription.length > 1) {
            var notes = splittedDescription.splice(splittedDescription.length - 1, 1);
            return `${splittedDescription.join('')}\n\nNotes:${notes}`;
        }

        return description;
    }

    getLessonSmilyRecordings(lesson: any): void {
        this.userWithSimlyRec = [];

        if (!this.isTeacher || !lesson.smilyRecording)
            return;

        this.calendar.getLessonUsersSmilyRecording(lesson.id)
            .subscribe(res => this.userWithSimlyRec = res);
    }

    verifyLessonActiveStreaming(idLesson: number){
        return this.LessonActiveStreaming.find(lesson => lesson.id === idLesson) != null;
    }
    
}

<p>{{data.type | translate}}</p>
<mat-dialog-content>
    <div *ngIf="data.type != 'Max passive users' && 
    data.type !='Font size' && 
    data.type !='Write in overlay' && 
    data.type !='Max active users' && 
    data.type !='Storage size' && 
    data.type !='Font name' && 
    data.type !='Customer code' "> 
        {{"Are you sure to edit the section" | translate}} {{data.type | translate}} in {{ActiveOrNot | translate}}?
    </div>
    <div *ngIf="data.type == 'Write in overlay'">
            <mat-form-field>
              <mat-label style="color: white;"></mat-label>
              <input matInput type="text" [formControl]="text_form">
            </mat-form-field>
            <mat-checkbox (change)="checkEnable($event)" [checked]="text_form.value === null" [(value)]="save_checkBox">{{'Disable' | translate}}</mat-checkbox>
       </div>
    <div *ngIf="data.type == 'Font size' || data.type == 'Max active users' || data.type == 'Max passive users' || data.type == 'Storage size'">
            <mat-form-field>
              <input matInput style="color: white;" type="number" [formControl]="text_form">
            </mat-form-field>
       </div>
    <div *ngIf="data.type == 'Font name'">
        <mat-form-field>
          <mat-select style="color: white;" [formControl]="text_form">
            <mat-option value="Sans">Sans</mat-option>
            <mat-option value="Tahoma">Tahoma</mat-option>
            <mat-option value="Verdana">Verdana</mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="data.type == 'Customer code'">
            <mat-form-field>
              <mat-label style="color: white;"></mat-label>
              <input matInput type="text" [formControl]="text_form" style="color: white;">
            </mat-form-field>
        </div>
    </mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button  type="text" color="accent" (click)="onSave()" [disabled]="disableBtn()">Ok</button> 
    <button mat-stroked-button (click)="onClose()">{{"Cancel" | translate}}</button> 
</mat-dialog-actions>

import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Device, Publisher } from 'openvidu-browser';

const VISUALTYPE_CONFIG_KEY: string = "visualTypeConfig";
const VIDEO_OVERRIDE_TIMEOUT: number = 2000; //ms
const TOGGLE_FULLSCREEN_TIMEOUT: number = 500; //ms

@Component({
  selector: 'app-session-toolbar',
  templateUrl: './session-toolbar.component.html',
  styleUrls: ['./session-toolbar.component.scss']
})
export class SessionToolbarComponent implements OnInit, OnDestroy {

	private fromInputFullscreen: boolean = false;

	@Input()
	localParticipant: Publisher;

	@Input()
	videoDevices: Device[] = [];

	@Input()
	hasScreenShareCapabilities: boolean = false;

	@Input()
	toggleVideoActive: boolean = true;

	@Input()
	toggleAudioActive: boolean = true;

	@Input()
	get isVideoOverride(): boolean { return this.videoOverride; }
	set isVideoOverride(value: boolean) {
		this.videoOverride = value;

		setTimeout(() => {

			if (this.videoOverride) {

				this.videoOverrideActive = this.isVideoActive();
	
				if (this.videoOverrideActive)
					this.toggleVideo();
	
			} else {
	
				if (this.videoOverrideActive)
					this.toggleVideo();
	
				this.videoOverrideActive = false;
	
			}

		}, VIDEO_OVERRIDE_TIMEOUT);
	}

	@Input()
	shareActive: boolean = true;

	@Input()
	shareEnabled: boolean = true;

	@Input()
	shareDeviceId: string = null;

	@Input()
	isSharing: boolean = false;

	@Input()
	fullScreenActive: boolean = true;

	@Input()
	visualTypeActive: boolean = true;

	@Input()
	get fullScreen(): boolean { return this.isFullScreen(); }
	set fullScreen(value: boolean) {

		this.fromInputFullscreen = true;

		setTimeout(() => {

			this.toggleFullScreen(value);
			this.fromInputFullscreen = false;

		}, TOGGLE_FULLSCREEN_TIMEOUT);

	}

	@Input()
	recordingActive: boolean = false;

	@Input()
	recordingEnabled: boolean = true;

	@Input()
	isRecording: boolean = false;

	@Input()
	handRaiseActive: boolean = true;

	@Input()
	handRaise: boolean = false;

	@Input()
	httpLink: string = null;

	@Input()
	closeActive: boolean = false;

	@Output()
	fullScreenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	onStartShare: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	onStopShare: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	onVisualTypeChange: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	handRaiseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	onRecordingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	onLeave: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

	visualType: 'contain' | 'cover' | 'fill' = 'cover';

	videoOverride: boolean = false;
	videoOverrideActive: boolean = false;

	constructor(public snackBar: MatSnackBar) { }

	ngOnInit(): void {
		this.setVisualType(localStorage.getItem(VISUALTYPE_CONFIG_KEY) as 'contain' | 'cover' | 'fill');
		this.ngOnDestroy();

		window.document?.addEventListener('fullscreenchange', () => this.fullScreenHandler());
	}

	ngOnDestroy(): void {
		window.document?.removeEventListener('fullscreenchange', () => this.fullScreenHandler());
	}

	isAudioActive() {
		return this.localParticipant?.stream.audioActive ?? false;
	}

	isVideoActive() {
		return this.localParticipant?.stream.videoActive ?? false;
	}

	isToggleAudioActive() {
		return (this.localParticipant?.stream.hasAudio ?? false) && this.toggleAudioActive;
	}

	isToggleVideoActive() {
		return (this.localParticipant?.stream.hasVideo ?? false) && this.toggleVideoActive;
	}

  	toggleAudio() {
		this.localParticipant?.publishAudio(!this.localParticipant.stream.audioActive);
	}

	toggleVideo() {
		this.localParticipant?.publishVideo(!this.localParticipant.stream.videoActive);
	}

  	async toggleFullScreen(value: boolean = !this.isFullScreen()) {
		const fs = window.document.getElementById('conference-container');

		try {

			value ? await fs.requestFullscreen() : await window.document.exitFullscreen();

		} catch (e) { }
	}

  	setVisualType(type: 'contain' | 'cover' | 'fill') {
		if (!type)
			return;

		localStorage.setItem(VISUALTYPE_CONFIG_KEY, type);
		this.visualType = type;

		this.onVisualTypeChange.emit(type);
	}

	toggleHandRaise() {
		this.handRaise = !this.handRaise;
		this.handRaiseChange.emit(this.handRaise);
	}

	startShare(deviceId: string) {
		this.onStartShare.emit(deviceId);
	}

	stopShare() {
		this.onStopShare.emit(true);
	}

	copyUrl() {
		this.snackBar.open('Conference link copied!', 'Dismiss', {
			duration: 3000,
			verticalPosition: 'top'
		});
	}

	private isFullScreen() {
		return window.document.fullscreenElement != null;
	}

	private fullScreenHandler() {
		if (this.fromInputFullscreen)
			return;

		this.fullScreenChange.emit(this.isFullScreen());
	}

}

<mat-card appearance="outlined" class="mat-elevation-z4" style="width: 100%; height: 100%;">
    <mat-card-header>
        <mat-card-title>
            {{ 'Contents' | translate }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table #contentsTable
            mat-table
            cdkDropList
            [cdkDropListData]="contents"
            (cdkDropListDropped)="dropTable($event); contentsTable.renderRows();"
            [dataSource]="contents"
            style="width: 100%;">

            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>{{ 'Edit' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matTooltip]="'Move' | translate" cdkDragHandle style="cursor: move">
                        <mat-icon>drag_handle</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>{{ 'Type' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon [matTooltip]="element.contentHelper.typeTitle()">{{ element.contentHelper.typeIcon() }}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="author">
                <th mat-header-cell *matHeaderCellDef>{{ 'Author' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.author.name + ' ' + element.author.surname }}
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">
                        <div>
                            <app-profile-picture [url]="element.contentHelper.headerImg()" size="65px"></app-profile-picture>
                        </div>
                        <div fxLayoutAlign="center start">
                            {{ element.name?.length > 20 ? (element.name | slice:0:17) + '...' : element.name }}
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>{{ 'Description' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description?.length > 50 ? (element.description | slice:0:47) + '...' : element.description }}
                </td>
            </ng-container>

            <ng-container matColumnDef="hashtags">
                <th mat-header-cell *matHeaderCellDef>{{ 'Hashtags' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.hashTag }}
                </td>
            </ng-container>

            <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef>{{ 'Created' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.created | date: 'dd/MM/YYYY' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="goto">
                <th mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <app-content-action-buttons
                            [authorId]="courseAuthorId"
                            [content]="element"
                            [contentHelper]="element.contentHelper"
                            [compact]="true"
                            (onDelete)="onDelete.emit(true); reorder(false);">
                        </app-content-action-buttons>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"
                cdkDrag
                [cdkDragData]="row"
                [cdkDragDisabled]="!reorderMode || sending">
            </tr>
        </table>
    </mat-card-content>
    <mat-card-actions *ngIf="courseAuthorId === currentUser.id && courseMode === 0">
        <button mat-raised-button color="primary" *ngIf="!reorderMode" (click)="reorder(true)">
            <mat-icon>reorder</mat-icon>
            {{ 'Reorder contents' | translate }}
        </button>
        <button mat-stroked-button *ngIf="reorderMode" (click)="reorder(false)" [disabled]="sending" style="margin-right: 10px">
            {{ 'Cancel' | translate }}
        </button>
        <button mat-raised-button color="accent" *ngIf="reorderMode" (click)="save()" [disabled]="sending || contents.length === 0">
            <mat-icon>check</mat-icon>
            {{ 'Save' | translate }}
        </button>
    </mat-card-actions>
</mat-card>

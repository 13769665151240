<div fxLayout="column" fxLayoutGap="20px" class="component-main">

    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon matListIcon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
    </div>
    <div fxLayoutAlign="center">
        <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="center center" style="width: 400px;">
            <mat-card-header>
                <mat-card-title>
                    {{ 'Total views' | translate }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content style="font-size: 40px;">
                {{totViewAll}}
            </mat-card-content>
        </mat-card>
    </div>
    <mat-tab-group>
        <mat-tab [label]="'Yearly Statistics' | translate">
            <div fxLayoutAlign="end center" fxFlex="100" style="font-size: 1.5rem; margin-top:10px" fxLayoutGap="20">
                <button mat-fab color="primary" *ngIf="!isStudent()"
                    (click)="exportTotalViewsToCsv()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
                <h2>
                    {{ 'Views' | translate }}
                </h2>
                <div class="btn-group">
                    <mat-form-field color="primary">
                        <mat-label>{{ 'Year' | translate }}</mat-label>
                        <mat-select name="name" [formControl]="year" (selectionChange)="yearChange()">
                            <mat-option [value]="0">{{ 'All years' | translate }}</mat-option>
                            <mat-option *ngFor="let year of years" [value]="year">
                                {{year}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="graph">
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels"
                        [options]="barChartOptions" [plugins]="barChartPlugins" [type]="barChartType">
                    </canvas>
                </div>
            </div>
        </mat-tab>
        <mat-tab [label]="'Students data' | role | translate">
            <div fxLayoutAlign="end center" fxFlex="100" style="font-size: 1.5rem; margin-top: 10px;" fxLayoutGap="20">
                <button mat-fab color="primary" *ngIf="!isStudent()"
                    (click)="exportTotalViewsStudentsToCsv()"
                    [matTooltip]="'Save csv' | translate">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" style="margin-bottom: 20px; margin-top: 10px;">              
                <mat-card appearance="outlined" class="mat-elevation-z4" fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            {{ 'Students' | role | translate }}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayoutGap="10px">
                            <mat-form-field fxFlex="80" color="primary">
                                <mat-label>{{ 'Search' | translate }}</mat-label>
                                <input matInput type="text" (keyup)="doFilter($event)">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div fxFlex="20">
                                <button mat-stroked-button (click)="viewForAll()">
                                    {{ 'All data' | translate }}
                                    <mat-icon matListIcon>arrow_forward</mat-icon>
                                </button>
                            </div>
                        </div>

                        <table mat-table>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Student' | role | translate }}</th>
                                <td mat-cell *matCellDef="let row" fxLayout="row" fxLayoutAlign="start center"
                                    fxLayoutGap="10px" style="padding: 5px">
                                    <div *ngIf="row">
                                        <app-profile-picture [url]="row.profilePictureUrl"></app-profile-picture>
                                    </div>

                                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
                                        *ngIf="row">
                                        <span style="font-weight: bold;">{{ row.name + " " + row.surname }}</span>
                                        <span style="color: gray; font-size: 10px;" *ngIf="row.userSummary">
                                            {{ row.userSummary }}
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="data">
                                <th mat-header-cell *matHeaderCellDef>{{ 'Data' | translate }}</th>
                                <td mat-cell *matCellDef="let row">
                                    <button mat-stroked-button (click)="viewForStudent(row.id)">
                                        {{ 'Student data' | role | translate }}
                                        <mat-icon>arrow_forward</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <mat-paginator #paginator [length]="dataSrc?.data.length" [pageIndex]="0" [pageSize]="10" 
                            color="primary" [pageSizeOptions]="[5, 10, 20]">
                        </mat-paginator>

                    </mat-card-content>
                </mat-card>

                <div fxFlex="50%" fxLayout="column" fxLayoutGap="10px">

                    <mat-card appearance="outlined" class="mat-elevation-z4">
                        <mat-card-header>
                            <mat-card-title>
                                {{ 'Views' | translate }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="graph">
                                <canvas baseChart [datasets]="barChartData3" [labels]="barChartLabels3"
                                    [options]="barChartOptions" [plugins]="barChartPlugins" [type]="barChartType">
                                </canvas>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

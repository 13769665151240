import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialWallLikeDTO } from 'src/app/models/dto/SocialWallLikeDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { AuthService } from 'src/app/services/auth.service';
import { WallService } from '../service/wall.service';

export class DialogData {
  socialWallId: number;
}

@Component({
  selector: 'app-dialogdispuserlike',
  templateUrl: './dialogdispuserlike.component.html',
  styleUrls: ['./dialogdispuserlike.component.scss']
})
export class DialogdispuserlikeComponent implements OnInit {

  user : UserDTO = new UserDTO();
  dataContent : DialogData;
  userArr: UserDTO[] = [];
  sortarrLike : SocialWallLikeDTO[] = [];
  sortarrLikeNotCurUser : SocialWallLikeDTO[] = [];

  sortarrLikeUser : UserDTO[] = [];
  sortarrNotUser : UserDTO[] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
        private wallService : WallService,
        private auth: AuthService) {

      this.dataContent = data;
   }

  ngOnInit(): void {
    this.user.id = this.auth.getCurrentUser().id;

    
    this.wallService.getSocialWallLikeBySocialWallId(this.dataContent.socialWallId).subscribe( (output) => {

      output.forEach((ele,i) => {
        var userTrans = new UserDTO();
        userTrans.socialwallLike = new SocialWallLikeDTO();

        userTrans.id = ele.iduser;
        userTrans.created = ele.created;
        userTrans.socialwallLike.id = ele.id;
        this.userArr.push(userTrans);
      })

       this.wallService.getUserWhoLike(this.userArr).subscribe((output) => {
         this.userArr = [];
              
          output.forEach((element,i) => {
            var iduser = element.id;
            var name = "";
            var userTrans = new UserDTO();
             if (iduser == this.user.id) {
              name = " You"
            }else {
              name = element.name + " " + element.surname;
            }
              userTrans.name = name;
            
           this.userArr.push(userTrans);

          }) 



      })

    })
  }

   sortSocialWallArrByCreated() {
      return this.sortarrLikeNotCurUser.sort((a, b) => {
      return <any>new Date(b.created) - <any>new Date(a.created);
    });
  }

 

}

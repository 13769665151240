import { JoinedLessonDTO } from './joinedLessonDTO';
import { UserDTO } from './userDTO';
import { ClassroomDTO } from './classroomDTO';
import { RoomDTO } from './roomDTO';
import { AttendanceDTO } from './attendanceDTO';
import { SurveyDTO } from './surveyDTO';
import { LessonContentDTO } from './lessonContentDTO';
import { SurveyAnswerDTO } from './surveyAnswerDTO';
import { LessonVirtualRoomDTO } from './lessonVirtualRoomDTO';
import { ConferenceDTO } from './conferenceDTO';

export class LessonSessionDTO {

    public id?: number;
    public idParentLesson?: number;
    public idVirtualRoom?: number;
    public classId: number;
    public typeId: number;
    public roomId?: number;
    public teacherId: number;
    public name: string;
    public description: string;

    public startPlanned?: Date | string;
    public endPlanned?: Date | string;
    public recodingPlanned: boolean;
    public privateRecording: boolean;
    public smilyRecording: boolean;
    public recordingUrl: string;
    public startDate?: Date;
    public stopDate?: Date;

    public state: number;
    public created: Date;
    public scenario?: number;
    public idSurvey?: number;
    public idGoogleEvent: string;
    public recordingElaborating: number;
    public recordingElaborationQueue: number;
    public recordingElaborationError: number;
    public recordingElaborationSucceeded: number;
    public recordingTotal: number;

    public joinedLessons: JoinedLessonDTO[];
    public teacher: UserDTO;
    public classroom: ClassroomDTO;
    public room: RoomDTO;
    public attendance: AttendanceDTO;
    public survey?: SurveyDTO;
    public lessonContent: LessonContentDTO[];
    public surveyAnswer: SurveyAnswerDTO[];
    public lessonVirtualRoom: LessonVirtualRoomDTO[];
    public conference: ConferenceDTO;

    //Esterne a LessonSessionDTO
    public score: number;
    public answered: boolean;
}

export class LessonContentEdit {
    public idConnection: string;
    public playMode: number;
    public idContent: number;
}

export class SurveyLessonDTO extends LessonSessionDTO {
    public typeId: number = 4;
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { User } from 'src/app/models/user';
import { UserRole } from 'src/app/models/userRole';
import { AuthService } from 'src/app/services/auth.service';
import { AzureStorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  //timezones = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  roles: { role: number, label: string }[] = [{ role: UserRole.Student, label: 'Student' }];

  currentUser: User = null;
  sendingUser: boolean = false;

  userId: number = null;
  customerName: string = null;
  customerId: number = null;
  mode: 'standard' | 'customerAdmin' | 'level' = 'standard';

  subscriptionLevelId: number = null;

  username: FormControl = new FormControl('', [Validators.required]);
  name: FormControl = new FormControl('', [Validators.required]);
  surname: FormControl = new FormControl('', [Validators.required]);
  email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  role: FormControl = new FormControl(UserRole.Student, [Validators.required]);
  //timezone: UntypedFormControl = new UntypedFormControl(1, [Validators.required]);
  state: FormControl = new FormControl(1, [Validators.required]);
  password: FormControl = new FormControl('', [Validators.required, Validators.pattern(Helper.getValidPasswordRegex())]);
  passwordRepeat: FormControl = new FormControl('', [Validators.required]);
  profilePicture: FormControl = new FormControl(undefined);
  author: FormControl = new FormControl(1);
  signalRAccount: FormControl = new FormControl(0);

  timezone: number = Helper.getTimezone();

  constructor(private dialogRef: MatDialogRef<AddUserComponent>,
              @Inject(MAT_DIALOG_DATA) private data,
              private auth: AuthService,
              private userService: UserService,
              private translate: TranslateService,
              private azureService: AzureStorageService,
              private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.userId = this.data?.userId;
    this.customerId = this.data?.customerId;
    this.customerName = this.data?.customerName;

    this.mode = this.data?.mode ?? 'standard';

    if (this.isCustomerAdminMode()) {

      this.roles = [{ role: UserRole.CustomerAdmin, label: 'Customer admin' }];
      this.role.setValue(UserRole.CustomerAdmin);

    } else {

      if (this.currentUser.isAdmin || this.currentUser.isCustomerAdmin) {

        if (EnvironmentHelper.isAixp() || this.auth.isDT())
          this.roles.push({ role: UserRole.Tutor, label: 'Tutor' });

        if (EnvironmentHelper.isSaratoga())
          this.roles.push({ role: UserRole.Teacher, label: 'Teacher' });

      }

      if (this.currentUser.isAdmin || this.currentUser.isCustomerAdmin || this.currentUser.isTutor) {

        if (EnvironmentHelper.isAixp() || this.auth.isDT())
          this.roles.push({ role: UserRole.Speaker, label: 'Speaker' });

      }

    }

    this.roles.sort((a, b) => a.role - b.role);

    if (this.isLevelMode())
      this.subscriptionLevelId = this.data?.levelId;

    if (this.userId == null)
      this.username.setValidators([Validators.required, Validators.pattern(Helper.getValidUsernameRegex())]);
    else
      this.getUser();
  }

  getUser() {
    this.userService.get(this.userId).subscribe(res => {
      this.username.setValue(res.username);
      this.name.setValue(res.name);
      this.surname.setValue(res.surname);
      this.email.setValue(res.email);
      this.state.setValue(res.state);
      //this.timezone.setValue(res.timezone);
      this.role.setValue(UserRole.code(res));
      this.author.setValue(res.isAuthor ? 1 : 0);

      this.timezone = res.timezone;

      // Solo per compatibilita' con vecchi ruoli non piu' utilizzati in Aixp (Teacher)
      if (!this.roles.some(r => r.role === this.role.value))
        this.roles.push({ role: UserRole.code(res), label: UserRole.role(res) });
    });

    if (this.currentUser.isAdmin || this.currentUser.isCustomerAdmin)
      this.userService.hasSignalr(Number(this.userId))
        .subscribe({
          next: () => this.signalRAccount.setValue(1),
          error: () => this.signalRAccount.setValue(0)
        });
  }

  async save() {
    this.lockEdit(true);

    let user = new User();

    user.username = this.username.value;
    user.name = this.name.value;
    user.surname = this.surname.value;
    user.email = this.email.value;
    user.state = this.state.value;
    user.timezone = this.timezone;
    user.isAdmin = this.isAdminRole();
    user.isCustomerAdmin = this.isCustomerAdminRole();
    user.isTutor = this.isTutorRole();
    user.isTeacher = this.isTeacherRole();
    user.isSpeaker = this.isSpeakerRole();
    user.isStudent = this.isStudentRole();
    user.isAuthor = this.author.value == 1;
    user.password = this.password.value;
    user.profilePictureUrl = await this.azureService.uploadFile(this.profilePicture.value);
    user.idCustomer = this.currentUser.isAdmin ? this.customerId : null;

    // Rimuovere creando oggetto per add ed update
    user['CreateSignalRAccount'] = this.signalRAccount.value;

    let operation = this.isLevelMode()
                  ? this.userService.addPublicUserLevel(this.subscriptionLevelId, user)
                  : this.userId
                  ? this.userService.updateUser(user, this.userId)
                  : this.userService.addUser(user);

    await firstValueFrom(operation)
      .then(res => {
        console.log(res);
        this.dialogRef.close(true);
      })
      .catch(async err => {
        this.snackbar.open(await firstValueFrom(this.translate.get(err.error.Message)), 'Dismiss', { duration: 4000 });
        console.error(err);

        this.lockEdit(false);
      });
  }

  btnDisabled() {
    let check = !this.name.valid ||
                !this.surname.valid ||
                !this.email.valid ||
                !this.role.valid ||
                !this.username.valid ||
                !this.state.valid ||
                //!this.timezone.valid ||
                this.sendingUser;

    if (!this.userId)
      return check ||
             !this.password.valid ||
             !this.passwordRepeat.valid ||
             !this.checkPassword();

    return check;
  }

  lockEdit(lock: boolean) {
    if(lock) {
      this.username.disable();
      this.name.disable();
      this.surname.disable();
      this.email.disable();
      this.state.disable();
      //this.timezone.disable();
      this.role.disable();
      this.password.disable();
      this.passwordRepeat.disable();
      this.profilePicture.disable();
      this.author.disable();
      this.signalRAccount.disable();
    } else {
      this.username.enable();
      this.name.enable();
      this.surname.enable();
      this.email.enable();
      this.state.enable();
      //this.timezone.enable();
      this.role.enable();
      this.password.enable();
      this.passwordRepeat.enable();
      this.profilePicture.enable();
      this.author.enable();
      this.signalRAccount.enable();
    }

    this.sendingUser = lock;
  }

  checkPassword() {
    return this.password.value === this.passwordRepeat.value;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  isCustomerAdminMode() {
    return this.mode === 'customerAdmin';
  }

  isLevelMode() {
    return this.mode === 'level';
  }

  isAdminRole() {
    return this.role.value === UserRole.Admin;
  }

  isCustomerAdminRole() {
    return this.role.value === UserRole.CustomerAdmin;
  }

  isTutorRole() {
    return this.role.value === UserRole.Tutor;
  }

  isTeacherRole() {
    return this.role.value === UserRole.Teacher;
  }

  isSpeakerRole() {
    return this.role.value === UserRole.Speaker;
  }

  isStudentRole() {
    return this.role.value === UserRole.Student;
  }

}

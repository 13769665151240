<div fxLayout="column" fxLayoutGap="20px" class="component-main center-content">
    <h2>{{ 'Conference Report' | translate }}</h2>
    <span>
        {{ 'Conference' | translate }}<b> {{ conference?.name }} </b> {{ 'of' | translate }} <a class="red-text">
            {{ conference?.lessonSession?.startPlanned | date: 'dd MMMM YYYY' }}</a>
        {{ 'took place' | translate}} {{ 'from' | translate }} {{ conference?.lessonSession?.startDate | date: 'HH:mm' }}
        {{ 'to' | translate }} {{ conference?.lessonSession.stopDate | date: 'HH:mm' }}
        ({{ 'planned from' | translate}} {{ conference?.lessonSession?.startPlanned | date: 'HH:mm'}} {{'to' | translate }}
        {{ conference?.lessonSession?.endPlanned | date: 'HH:mm' }}) {{ 'by' | translate }}
        <a class="red-text">{{ conference?.lessonSession.teacher.name + ' ' + conference?.lessonSession.teacher.surname }}</a>
    </span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-stroked-button class="card-button" (click)="goBack()" >
            <mat-icon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
        <button mat-fab color="primary" (click)="refresh = !refresh" 
            [matTooltip]="'Refresh' | translate">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
   
    <div fxLayout="row" fxLayoutGap="30px" style="width: 100%">

        <div fxFlex="70" fxLayout="column" fxLayoutAlign="start center">

            <h2 fxLayoutAlign="center center" style="margin-top: 0px">
                {{ (isAuthor() || isPresenter() ? 'Users attended the conference' : 'Your presence time in the conference') | translate }}
            </h2>

            <mat-tab-group *ngIf="conference" style="width: 100%">
                
                <mat-tab *ngIf="(isAuthor() ||
                                 isPresenter() ||
                                 currentUser.isAdmin ||
                                 (currentUser.isCustomerAdmin && currentUser.idCustomer === conference.lessonSession.teacher.customerId))"
                    [label]="'Presenters' | translate">
                    <app-aggregate-data-table
                        [lessonId]="conference.idLesson"
                        [userType]="'presenters'"
                        [refresh]="refresh">
                    </app-aggregate-data-table>
                </mat-tab>
                <mat-tab *ngIf="(isAuthor() ||
                                 isPresenter() ||
                                 currentUser.isAdmin ||
                                 (currentUser.isCustomerAdmin && currentUser.idCustomer === conference.lessonSession.teacher.customerId))"
                    [label]="'Participants' | translate">
                    <app-aggregate-data-table
                        [lessonId]="conference.idLesson"
                        [userType]="'participants'"
                        [refresh]="refresh">
                    </app-aggregate-data-table>
                </mat-tab>
                <mat-tab [label]="'Sessions' | translate">
                    <app-joinedlesson-table
                        [lessonId]="conference.idLesson"
                        [hashTagsToExport]="conference.hashTag"
                        [refresh]="refresh"
                        (joinedLessons)="joinedLessons = $event">
                    </app-joinedlesson-table>
                </mat-tab>
        
            </mat-tab-group>
            
        </div>

        <div fxFlex fxLayout="column" fxLayoutAlign="start center" style="gap: 20px">

            <h2 fxLayoutAlign="center center" style="margin: 0px">
                {{ 'Conference statistics' | translate }}
            </h2>

            <mat-card appearance="outlined" class="right-card">
                <mat-card-header style="text-align: center">
                    {{ 'The conference lasted' | translate }} {{ conferenceDuration }}
                    {{ 'of the' | translate }} {{ conferencePlannedDuration }}
                    {{ 'planned' | translate }}
                </mat-card-header>
                <mat-card-content class="center-content">
                     <app-spinner-container
                        [diameter]="75"
                        [strokeWidth]="5"
                        [value]="conferenceDurationPercentage | number: '1.0-2'"
                        [displayWith]="spinnerValue">
                    </app-spinner-container> 
                </mat-card-content>
            </mat-card>

            <mat-card *ngIf="isAuthor() || isPresenter()" appearance="outlined" class="right-card">
                <mat-card-header style="text-align: center">
                    {{ 'The average attendance to the conference is' | translate }} {{ getAvgPercentage() | number: '1.0-2' }}%
                </mat-card-header>
                <mat-card-content class="center-content">
                    <app-spinner-container
                        [diameter]="75"
                        [strokeWidth]="5"
                        [value]="getAvgPercentage() | number: '1.0-2'"
                        [displayWith]="spinnerValue">
                    </app-spinner-container> 
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="right-card">
                <mat-card-header class="center-content" style="flex-direction: column; gap: 10px;">
                    <ng-container [ngTemplateOutlet]="conferenceReachedEnd ? conferenceReachedEndMsg : conferenceHasnotReachedEndMsg"></ng-container>
                </mat-card-header>
                <mat-card-content></mat-card-content>
            </mat-card>

        </div> 
    </div>
    
</div>

<ng-template #conferenceReachedEndMsg>
    <span>{{ 'Conference is over due to maximum time reached' | translate }}</span>
    <mat-icon>access_time</mat-icon>
</ng-template>

<ng-template #conferenceHasnotReachedEndMsg>
    <span>{{ 'Conference closed by the organizer: ' | translate }}</span>
    <a class="red-text">{{ conference?.lessonSession.teacher.name + ' ' +  conference?.lessonSession.teacher.surname }}</a>
</ng-template>

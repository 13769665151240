import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { ClassroomService } from 'src/app/services/classroom.service';
import { differenceInMilliseconds, differenceInSeconds } from 'date-fns';
import { CONFERENCE_DURATION_MINUTES } from 'src/app/models/conference-session/conferenceConstants';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { JoinedLessonDTO } from 'src/app/models/dto/joinedLessonDTO';

@Component({
  selector: 'app-conference-report',
  templateUrl: './conference-report.component.html',
  styleUrls: ['./conference-report.component.scss']
})
export class ConferenceReportComponent implements OnInit{

  currentUser: User;
  
  lessonId: number;

  conference: ConferenceDTO;
  joinedLessons: JoinedLessonDTO[] = [];

  conferenceDuration: string;
  conferencePlannedDuration: string;
  conferenceDurationPercentage: number;
  conferenceAttendancePercentage: number;
  conferenceReachedEnd: boolean = false;
  totalUsers: number = 0;

  refresh: boolean = false;

  constructor(
    private auth: AuthService,
    private location: Location,
    private router: Router,
    private conferenceService: ConferenceService,
    private classroomService: ClassroomService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.lessonId = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
      
    this.getConference();
  }

  getConference() {
    this.conferenceService.getConferenceByLessonId(this.lessonId)
      .subscribe(output => {
        this.conference = output;

        let conferenceDuration = differenceInMilliseconds(this.conference.lessonSession.stopDate, this.conference.lessonSession.startDate);
        let conferencePlannedDuration = differenceInMilliseconds(this.conference.lessonSession.endPlanned, this.conference.lessonSession.startPlanned);

        this.conferenceDuration = Helper.convertToHMS(conferenceDuration);
        this.conferencePlannedDuration = Helper.convertToHMS(conferencePlannedDuration);
        this.conferenceDurationPercentage = conferenceDuration === 0 || conferencePlannedDuration === 0 ? 0 : Math.round((conferenceDuration / conferencePlannedDuration) * 100);
        this.conferenceReachedEnd = conferenceDuration === 0 ? false : ((conferenceDuration / 1000) / 60) >= CONFERENCE_DURATION_MINUTES;
        
        if (this.isAuthor() || this.isPresenter())
          this.getUsersByClassroom();

      });
  }

  getUsersByClassroom() {
    this.classroomService.getUsersPerClassroom(this.conference.lessonSession.classId)
      .subscribe(res => this.totalUsers = res?.userList?.filter(u => !this.isAuthor(u.id))?.length ?? 0);
  }

  getAvgPercentage() {
    let totalTime = this.joinedLessons
      .map(d => Helper.timeFromTimeSpan(d.timeInLessons, 'seconds'))
      .reduce((total, time) => total + time, 0);

    if (totalTime === 0 || this.totalUsers === 0 || !this.conference)
      return 0;

    return Math.round(((totalTime / this.totalUsers) / differenceInSeconds(this.conference.lessonSession.stopDate, this.conference.lessonSession.startDate)) * 100);
  }

  isAuthor(userId: number = this.currentUser.id) {
    return this.conference?.lessonSession.teacherId === userId;
  }

  isPresenter(userId: number = this.currentUser.id) {
    return this.conference?.conferencePresenter.some(p => p.idPresenter === userId) ?? false;
  }

  spinnerValue(value: number) {
    return `${value}%`;
  }

  goBack() {
    (this.location.getState() as any)?.page == "calendar" ?
    this.location.back() :
    this.router.navigate(['/conferences']);
  }
  
}

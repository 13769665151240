<div fxLayout="column">
    <div id="header-div" fxLayout="row" fxLayoutAlign="center center" class="mat-elevation-z6">
        <div fxFlex="40" fxLayoutAlign="start center" style="margin-left: 5px; color: whitesmoke;">
            <button mat-icon-button id="back-btn" *ngIf="(isStudent() && this.easyLesson == 'true') || (this.easyLesson == 'false')"
                (click)="leaveLesson()" [matTooltip]="'Back to lessons' | translate">
                <mat-icon>
                    keyboard_arrow_left
                </mat-icon>
            </button>
            <span class="small" *ngIf="this.easyLesson == 'true'">
                Easy Stream
            </span>
            <span class="small" *ngIf="this.easyLesson !== 'true'">
                {{ this.lessonActive?.name }}
            </span>
        </div>
        <div fxFlex="20" fxLayoutAlign="center center" style="color: whitesmoke;">
            <!--
            <button mat-icon-button *ngIf="studentFeedbackPipAvailable" (click)="studentFeedbackPip()" matTooltip="Picture in picture">
                <mat-icon>
                    picture_in_picture_alt
                </mat-icon>
            </button>
            -->
            <button mat-icon-button *ngIf="scenario > 1 && pipToggle" (click)="pip()" matTooltip="Picture in picture">
                <mat-icon>
                    picture_in_picture_alt
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isTeacher()" color="accent"
                [matMenuTriggerFor]="switchVideoTrack1" [matTooltip]="'Switch video mode' | translate">
                <mat-icon>
                    switch_video
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isTeacher() && scenario > 1" color="primary"
                [matMenuTriggerFor]="switchVideoTrack2" [matTooltip]="'Switch video mode second player' | translate">
                <mat-icon>
                    switch_video
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isTeacher() && scenario > 2"
                [matMenuTriggerFor]="switchVideoTrack3" [matTooltip]="'Switch video mode third player' | translate">
                <mat-icon style="color: #5092CF">
                    switch_video
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="studentFeedbackPipAvailable && !isTeacher()" (click)="toggleStudentAudio()" [matTooltip]="'Toggle audio' | translate">
                <mat-icon>
                    {{studentAudioIcon}}
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isTeacher()" (click)="toggleLocalAudio()" [matTooltip]="'Toggle audio' | translate">
                <mat-icon>
                    {{audioIcon}}
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isTeacher()" (click)="toggleLocalVideo()" [matTooltip]="'Toggle video' | translate">
                <mat-icon>
                    {{videoIcon}}
                </mat-icon>
            </button>
            <button mat-icon-button *ngIf="isTeacher() && scenario == 1 && contents.length > 0" [matMenuTriggerFor]="lessonContent" [matTooltip]="'Choose content to display' | translate">
                <mat-icon>
                    queue_play_next
                </mat-icon>
            </button>
            <button mat-icon-button color="warn" *ngIf="isTeacher() && currentContent" (click)="setLessonContent(0, currentContent.id)" [matTooltip]="'Remove the content displayed' | translate">
                <mat-icon>
                    remove_from_queue
                </mat-icon>
            </button>
            <button mat-icon-button
                [matMenuTriggerFor]="visualTypePlayer" [matTooltip]="'Change video appearance' | translate">
                <mat-icon>
                    settings_overscan
                </mat-icon>
            </button>
            <button mat-icon-button color="warn" *ngIf="studentFeedbackPipAvailable && !isTeacher()" (click)="closeStudentFeedback()" [matTooltip]="'Close Teacher Feedback' | translate">
                <mat-icon>
                    power_settings_new
                </mat-icon>
            </button>
            <button mat-icon-button color="warn" *ngIf="(isTeacher() && this.easyLesson == 'true')" (click)="closeLesson()" [matTooltip]="'Close' | translate">
                <mat-icon>
                    power_settings_new
                </mat-icon>
            </button>
            <mat-icon class="icon" style="color: red;" [matTooltip]="'Recording' | translate"
                *ngIf="isTeacher() && this.recording">
                fiber_manual_record
            </mat-icon>
        </div>
        <div fxFlex="40" fxLayoutAlign="end center" style="margin-right: 5px;">
            <button mat-icon-button style="margin-right: 10px;"
                *ngIf="isStudent()"
                (click)="askChatToTeacher()"
                [disabled]="disableStudentAskBtn"
                [matTooltip]="'Ask teacher to chat' | translate">
                <mat-icon>
                    chat
                </mat-icon>
            </button>
            <button mat-icon-button style="color: white; margin-right: 10px;"
                *ngIf="isTeacher() && chatService.getAskChat() != 0"
                [matTooltip]="'Chat requests' | translate"
                [matMenuTriggerFor]="menu"
                #menuBtn>
                <mat-icon
                    [matBadge]="chatService.getAskChat()" 
                    [matBadgeHidden]="chatService.getAskChat() == 0"
                    matBadgeColor="warn">
                    chat
                </mat-icon>
            </button>
            <span class="small">
                {{resolutionWidth}}x{{resolutionHeight}}
            </span>
            <span class="small" *ngIf="isStudent()">
                - {{lessonStartDate | date: 'HH:mm'}}
            </span>
            <span class="small" *ngIf="isTeacher()">
                - {{lessonOpened}} {{ 'Users' | translate }}
            </span>
            <span class="small" *ngIf="isTeacher()">
                - {{countDown | async | formatTime}}
            </span>
        </div>
    </div>
    
    <mat-drawer-container [hasBackdrop]="false" style="height: 100%;">
    
        <mat-drawer #studentsDrawer mode="over"
            style="width: 300px; margin-top: 50px; background: rgba(0, 0, 0, 0.5);">
            <app-user-list-video
                [sidenav]="studentsDrawer"
                [opened]="studentsDrawer.opened"
                [classId]="classId"
                [lessonId]="getNumberId()"
                (askedStudentToStartVideo)="askedStudentToStartVideo($event)"
                (askedStudentToCloseVideo)="askedStudentToCloseVideo($event)">
            </app-user-list-video>
        </mat-drawer>
    
        <div fxLayout="row" style="background-color: black; margin-top: 50px; height: calc(100vh - 50px);">

            <div [style.display]="contentShow ? 'none' : 'block'" fxFlex>
                <div #player1Container
                    [fxFlex]="player1Width"
                    [ngClass]="{'showPip': p1CssPip}"
                    mwlResizable
                    mwlResizeHandle
                    [enableGhostResize]="true"
                    [resizeEdges]="{ right: true }"
                    (resizeEnd)="onResizeEnd1($event)">
                    <video #videoplayer1
                        class="player"
                        [style.display]="p1Show"
                        [style.object-fit]="visualType">
                    </video>
                    <div [style.display]="p1ShowSS" class="screenSharing">
                        <app-error-message
                            [errorTitle]="'You are sharing your screen' | translate"
                            [bold]="true">
                        </app-error-message>
                    </div>
                </div>

                <div #player2Container
                    fxFlex
                    [style.display]="scenario < 2 ? 'none' : 'block'"
                    style="min-width: 15%;">
                    <video #videoplayer2
                        disablepictureinpicture
                        class="player"
                        [style.display]="p2Show"
                        [style.object-fit]="visualType">
                    </video>
                    <div [style.display]="p2ShowSS" class="screenSharing">
                        <app-error-message
                            [errorTitle]="'You are sharing your screen' | translate"
                            [bold]="true">
                        </app-error-message>
                    </div>
                </div>

                <div #player3Container
                    [fxFlex]="player3Width"
                    [style.display]="scenario < 3 ? 'none' : 'block'"
                    mwlResizable
                    mwlResizeHandle
                    [enableGhostResize]="true"
                    [resizeEdges]="{ left: true }"
                    (resizeEnd)="onResizeEnd3($event)">
                    <video #videoplayer3
                        disablepictureinpicture
                        class="player"
                        [style.display]="p3Show"
                        [style.object-fit]="visualType">
                    </video>
                    <div [style.display]="p3ShowSS" class="screenSharing">
                        <app-error-message
                            [errorTitle]="'You are sharing your screen' | translate"
                            [bold]="true">
                        </app-error-message>
                    </div>
                </div>

                <!-- parte di Luca -->
                <div #player4Container [fxFlex]="player4Width" [style.display]="p4Show" [ngClass]="{'showPip': p4CssPip}" style="height: 100%;">
                    <video #videoplayer4 class="player" [style.object-fit]="visualType"></video>
                </div>
            </div>

            <div #contentsContainer [style.display]="contentShow ? 'block' : 'none'" fxFlex>
                <app-player-events *ngIf="currentContent && currentContent.type === 1"
                    [media]="currentContent.mediaUrl"
                    [enableControls]="isTeacher()"
                    [(startTime)]="contentMarker"
                    [pause]="contentPause"
                    [disableSeekCheck]="isTeacher() ? false : true"
                    (time)="updateTime($event)"
                    (paused)="updateContent($event, true)"
                    (played)="updateContent($event, false)"
                    (firstPlayed)="startTracking()"
                    (seek)="addTracker($event)"
                    height="100%"
                    width="100%">
                </app-player-events>
                <ngx-extended-pdf-viewer *ngIf="currentContent && currentContent.type === 2"
                    [useBrowserLocale]="true"
                    [src]="currentContent.mediaUrl"
                    [textLayer]="true"
                    [showBorders]="false"
                    [showOpenFileButton]="false"
                    [showBookmarkButton]="false"
                    [showPresentationModeButton]="true"
                    [filenameForDownload]="currentContent.name"
                    [enablePrint]="false"
                    [showDownloadButton]="false"
                    [contextMenuAllowed]="false"
                    (pdfLoaded)="startTracking()"
                    (pageChange)="updateContent($event)"
                    [(page)]="contentMarker"
                    [theme]="darkService.isSetDark ? 'dark' : 'light'"
                    height="100%"
                    width="100%">
                </ngx-extended-pdf-viewer>
            </div>

        </div>

        <div class="teacher-chat-bar">
            <app-chat *ngFor="let item of chatService.chatList"  
            [session]="item.session"
            [nome]="item.nome"
            [connection]="item.connection"
            [tipo]="item.tipo"
            [msg]="item.msg">
            </app-chat>
        </div>
    
    </mat-drawer-container>   
</div>

<div style="z-index:5; position: fixed; display: flex; 
            align-self: flex-end; bottom : 5%; margin-left: -28px;" *ngIf="isTeacher() && !studentsDrawer.opened">
    <button mat-fab color="accent" [class.mat-elevation-z2]="!isOver" [class.mat-elevation-z8]="isOver"
            (mouseover)="isOver = true" (mouseleave)="isOver = false" style="height: 56px; width: 56px;"
            (click)="studentsDrawer.toggle()" [matTooltip]="'Open' | translate" matTooltipPosition="after">
        <mat-icon style="margin-right: -25px; color: black;">{{ studentsDrawer.opened ? 'arrow_back_ios' : 'arrow_forward_ios' }}</mat-icon>
    </button>
</div>

<!-- Menu -->
<mat-menu #switchVideoTrack1="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="switchTo(device, 1)"
            *ngFor="let device of videoDeviceArr">{{ device.label }}</button>
    </ng-template>
</mat-menu>

<mat-menu #switchVideoTrack2="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="switchTo(device, 2)"
            *ngFor="let device of videoDeviceArr">{{ device.label }}</button>
    </ng-template>
</mat-menu>

<mat-menu #switchVideoTrack3="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="switchTo(device, 3)"
            *ngFor="let device of videoDeviceArr">{{ device.label }}</button>
    </ng-template>
</mat-menu>

<mat-menu #lessonContent="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item *ngFor="let content of contents" fxLayoutAlign="start center" (click)="chooseLessonContent(content.id)" style="height: auto; padding: 16px;">
            <img [src]="content.headerImageUrl" style="height: 50px; width: 50px; object-fit: cover; border-radius: 4px; margin-right: 10px;">
            <span>{{ content.name == null ? 'No title' : content.name.length>20 ? (content.name | slice:0:20) + '..' : (content.name) }}</span>
        </button>
    </ng-template>
</mat-menu>

<mat-menu #visualTypePlayer="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="setVisualType('fill')" [class.mat-menu-highlight]="visualType === 'fill'">{{ 'Fill' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('cover')" [class.mat-menu-highlight]="visualType === 'cover'">{{ 'Cover' | translate }}</button>
        <button mat-menu-item (click)="setVisualType('contain')" [class.mat-menu-highlight]="visualType === 'contain'">{{ 'Contain' | translate }}</button>
    </ng-template>
</mat-menu>

<mat-menu #menu="matMenu" xPosition="before" yPosition="below" backdropClass="mat-menu-font">
    <div mat-menu-item *ngFor="let item of chatService.askChatList">
        <mat-icon>person</mat-icon>
        <span style="margin-right:10px; font-size: small;">{{item.nome}}</span>
        <mat-icon (click)="chatService.okStudentChat(item)" 
                style="color: green;"
                [matTooltip]="'Accept Chat' | translate">
                speaker_notes
        </mat-icon> 
        <mat-icon (click)="chatService.noStudentChat(item)" 
                style="color: red;"
                [matTooltip]="'Refuse Chat' | translate">
                speaker_notes_off
        </mat-icon>
    </div>
</mat-menu>

<!-- <div *ngIf="isStudent()" class="teacher-chat-bar">
<app-chat 
    [session]="null"
    [student]="null"
    [connection]="null"
></app-chat>
</div> -->

import { Component, OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, Validators } from "@angular/forms";
import { OpenVidu } from "openvidu-browser";
import { StudentVideoDialogData } from 'src/app/services/teacher-feedback.service';

@Component({
  selector: "start-student-video-dialog",
  templateUrl: "./start-student-video-dialog.component.html",
  styleUrls: ["./start-student-video-dialog.component.scss"],
})
export class StartStudentVideoDialogComponent implements OnInit {
  qualityForm: UntypedFormControl = new UntypedFormControl("medium", [Validators.required]);
  videoForm1: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  audioForm: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  videoDeviceArr: Array<any> = [];
  audioDeviceArr: Array<any> = [];
  joinWithVideo = true;
  joinWithAudio = true;

  OV: OpenVidu;

  constructor(
    public dialogRef: MatDialogRef<StartStudentVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StudentVideoDialogData
  ) {
    dialogRef.disableClose = true;
    if (!this.data)
      this.data = { accepted: false, cameraOptions: null, from: null };
  }

  async ngOnInit() {
    this.OV = new OpenVidu();

    this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined })
      .then(async mediaStream => {

        mediaStream?.getAudioTracks().forEach((track) => track.stop());
        mediaStream?.getVideoTracks().forEach((track) => track.stop());

        let devices = await this.OV.getDevices();

        this.videoDeviceArr = devices.filter(d => d.kind === 'videoinput');
        this.audioDeviceArr = this.audioDeviceArr.concat(devices.filter(d => d.kind === 'audioinput'));

        this.videoForm1.setValue(this.videoDeviceArr[0].deviceId);
        this.audioForm.setValue(this.audioDeviceArr[0].deviceId);
      })
      .catch(err => console.error(err));
  }

  onNoClick(): void {
    this.data.accepted = false;
    this.dialogRef.close(this.data);
  }

  confirmDialog(): void {
    this.data.accepted = true;
    this.data.cameraOptions = {
        publishAudio: !this.joinWithAudio ? false : true,
        publishVideo: !this.joinWithVideo ? false : true,
        resolution: this.getResolution(),
        videoSource: this.videoForm1.value,
        audioSource: this.audioForm.value
    };

    this.dialogRef.close(this.data);
  }

  getResolution(): string {
    let resolution;
    switch (this.qualityForm.value) {
        case 'low':
            resolution = '640x480';
            break;
        case 'medium':
            resolution = '1280x720';
            break;
        case 'high':
            resolution = '1920x1080';
            break;
        case 'veryhigh':
            resolution = '3840x2160';
            break;
        //case 'screenSharing':
        //resolution = 'screenSharing';
        //break;
        default:
            resolution = '1920x1080';
    }
    return resolution;
  }
}

import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { UntypedFormControl, Validators } from '@angular/forms';
import { addHours, addMinutes, format, isBefore, subMinutes } from 'date-fns';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { DatePipe } from '@angular/common';
import { AzureStorageService } from 'src/app/services/storage.service';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/helpers/helper';

@Component({
    selector: 'app-app-lesson-pop-up',
    templateUrl: './app-lesson-pop-up.component.html',
    styleUrls: ['./app-lesson-pop-up.component.scss']
})
export class AppLessonPopUpComponent implements OnInit {
    currentUser: User;
    sending: boolean = false;

    dateSubTitle: string;
    eventData: EventDialogData;
    lessonOpenInClass: LessonSessionDTO = null;
    page: string;

    //Mostra l'ora da cambiare oppure il calendario (input type 'time' oppure 'date')
    showDateOrTime: boolean = false; //default 'time' (false)

    startDateForm: UntypedFormControl = new UntypedFormControl(null, [Validators.required, this.dateValidator]);
    endDateForm: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    nameForm: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
    teacherForm: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
    roomForm: UntypedFormControl = new UntypedFormControl({ value: 0, disabled: false });

    descriptionForm: UntypedFormControl = new UntypedFormControl('');
    recordForm: UntypedFormControl = new UntypedFormControl(false);
    privateForm: UntypedFormControl = new UntypedFormControl(true);
    smilyRecForm: UntypedFormControl = new UntypedFormControl(false);

    classroomForm: UntypedFormControl = new UntypedFormControl(undefined);

    constructor(private auth: AuthService,
                private dialogRef: MatDialogRef<AppLessonPopUpComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private calendar: CalendarService,
                private datePipe: DatePipe,
                private cdr: ChangeDetectorRef,
                private azureService: AzureStorageService) { }

    ngOnInit() { //Da collegare record alla chiamata di salvataggio della lezione
        this.currentUser = this.auth.getCurrentUser();

        if (!this.currentUser.smilyEnabled)
            this.smilyRecForm.disable();

        this.page = this.data.page;
        this.eventData = this.data.eventData;
        this.showDateOrTime = this.data?.dateOrTime ?? false;

        let startDate = this.eventData.startData ? new Date(this.eventData.startData) : addMinutes(new Date(), 5);
        let endDate = this.eventData.endData ? new Date(this.eventData.endData) : addHours(startDate, 2);

        if (endDate < startDate)
            endDate = addHours(startDate, 2);

        this.eventData.startData = startDate;

        this.startDateForm.setValue(this.datePipe.transform(startDate, this.showDateOrTime ? 'yyyy-MM-ddTHH:mm' : 'HH:mm'));
        this.endDateForm.setValue(this.datePipe.transform(endDate, 'HH:mm'));

        this.updateTitle();

        if (this.eventData.classId != null) {
            this.classroomForm.setValue(this.eventData.classId, { emitEvent: true });
            this.checkClassLesson();
        }

        if (this.eventData.title != null)
            this.nameForm.setValue(this.eventData.title);

        if (this.eventData.description != null)
            this.descriptionForm.setValue(this.eventData.description);

        if (this.eventData.recordPlan != null)
            this.recordForm.setValue(this.eventData.recordPlan);

        if (this.eventData.private != null)
            this.privateForm.setValue(this.eventData.private);

        if (this.eventData.smilyRec != null)
            this.smilyRecForm.setValue(this.eventData.smilyRec);

        if (this.eventData.roomId != null)
            this.roomForm.setValue(this.eventData.roomId, { emitEvent: true });

        if (this.eventData.teacherId != null)
            this.teacherForm.setValue(this.eventData.teacherId, { emitEvent: true });

        this.azureService.updateCustomerStorage()
            .then(storage => {
                if (storage && ((storage.currentSize / storage.maxSize) * 100) >= 100) {
                    this.recordForm.setValue(0);
                    this.recordForm.disable();
                }
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    updateTitle() {
        this.dateSubTitle = format(this.getStartDate(), 'PP');
    }

    negativeSpan(): boolean {
        let startDate = this.getStartDate();
        let endDate = this.getEndDate();
    
        if (endDate <= startDate) {
           this.endDateForm.setErrors({ negativeSpan: this.endDateForm.value });
           this.cdr.detectChanges();
           return true;
        }
    
        return false;
    }

    dateValidator(control: UntypedFormControl): { [s: string]: boolean } {
        if (!control.value)
          return null;
    
        if (isBefore(new Date(control.value), new Date()))
            return { 'invalidDate': true };
        
        return null;
    }

    async getResult() {
        this.sending = true;

        this.eventData.startData = this.getStartDate().toUTCString();
        this.eventData.endData = this.getEndDate().toUTCString();
        this.eventData.title = this.nameForm.value;
        this.eventData.description = this.descriptionForm.value;
        this.eventData.recordPlan = this.recordForm.value;
        this.eventData.private = this.privateForm.value;
        this.eventData.smilyRec = this.smilyRecForm.value;
        this.eventData.classId = this.classroomForm.value;
        this.eventData.roomId = this.roomForm.value === 0 ? null : this.roomForm.value;
        this.eventData.teacherId = this.teacherForm.value;

        this.dialogRef.close(this.eventData);
    }

    checkClassLesson() {
        if(this.isLessonPage()) {
            this.lessonOpenInClass = null;

            this.calendar.getOpenLessonInClass(this.classroomForm.value)
                .subscribe(output => {
                    if(output != null)
                        this.lessonOpenInClass = output;
                })
        }
    }

    isStandardLesson() {
        return this.eventData.type === 1;
    }

    isMasterLesson() {
        return this.eventData.type === 3;
    }

    isSurvey() {
        return this.eventData.type === 4;
    }

    isLessonPage() {
        return this.page === "lesson";
    }

    okBtnDisabled() {
        let check = !this.startDateForm.valid ||
                    !this.endDateForm.valid ||
                    !this.nameForm.valid ||
                    this.sending;

        if (this.isLessonPage())
            return check ||
                   this.lessonOpenInClass != null;

        if (this.isStandardLesson())
            return check ||
                   this.negativeSpan();

        if (this.isMasterLesson())
            return check ||
                   !this.teacherForm.valid ||
                   this.negativeSpan();
    }

    private getStartDate() {
        if (this.showDateOrTime)
          return new Date(this.startDateForm.value);
    
        let startDate = new Date(this.eventData.startData);
        let startTime = Helper.convertTimeToHMS(this.startDateForm.value);
    
        startDate.setHours(startTime[0]);
        startDate.setMinutes(startTime[1]);
        startDate.setSeconds(startTime[2]);
    
        return startDate;
    }

    private getEndDate() {
        let endTime = Helper.convertTimeToHMS(this.endDateForm.value);
        let endDate = this.getStartDate();
    
        endDate.setHours(endTime[0]);
        endDate.setMinutes(endTime[1]);
        endDate.setSeconds(endTime[2]);
    
        return endDate;
    }
}

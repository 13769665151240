import { Component, OnInit } from '@angular/core';
import { AuthorProfileService } from 'src/app/services/author.service';
import { AuthorProfileDTO } from 'src/app/models/dto/authorProfileDTO';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthorContact } from 'src/app/models/authorProfileEdit';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { AuthService } from 'src/app/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-authorprofile',
  templateUrl: './authorprofile.component.html',
  styleUrls: ['./authorprofile.component.scss']
})
export class AuthorprofileComponent implements OnInit {

  id: number = null;
  authorProfile: AuthorProfileDTO = null;
  textColor: string = 'white';
  currentUser;

  senderName: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  senderSurname: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  senderEmail: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  subject: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  message: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  sendingForm: boolean = false;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private profileService: AuthorProfileService,
              private location: Location,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();

    this.getProfile();
  }

  getProfile() {
    this.profileService.getAuthorProfileOfUser(this.id)
      .subscribe(output => {
        this.authorProfile = output;
      });
  }

  goBack() {
    this.location.back();
  }

  sendEmail() {
    this.toggleForm(false);

    let form = new AuthorContact();

    form.IdAuthor = this.id;
    form.SenderName = this.senderName.value;
    form.SenderSurname = this.senderSurname.value;
    form.SenderEmail = this.senderEmail.value;
    form.Subject = this.subject.value;
    form.Message = this.message.value;

    firstValueFrom(this.profileService.contactAuthor(form))
      .then(success => {
        this.snackBar.open('Author contacted successfully', 'Dismiss', { duration: 3000 });
        this.resetForm();
      }).catch(err => {
        this.snackBar.open('Error contacting author', 'Dismiss', { duration: 3000 });
      }).finally(() => {
        this.toggleForm(true);
      });
  }

  toggleForm(toggle: boolean) {
    if(toggle) {
      this.senderName.enable();
      this.senderSurname.enable();
      this.senderEmail.enable();
      this.subject.enable();
      this.message.enable();
    } else {
      this.senderName.disable();
      this.senderSurname.disable();
      this.senderEmail.disable();
      this.subject.disable();
      this.message.disable();
    }

    this.sendingForm = toggle;
  }

  resetForm() {
    this.senderName.reset();
    this.senderSurname.reset();
    this.senderEmail.reset();
    this.subject.reset();
    this.message.reset();

    this.senderName.setErrors(null);
    this.senderSurname.setErrors(null);
    this.senderEmail.setErrors(null);
    this.subject.setErrors(null);
    this.message.setErrors(null);
  }

  setImageBrightness($event, elementName: string) {
    this.textColor = Helper.getImageBrightness($event, elementName);
  }
}

import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { EnvironmentHelper } from '../models/environmentVariables';

const AIXP_DARK_THEME_CLASS: string = "darkModeAixp";
const SARATOGA_DARK_THEME_CLASS: string = "darkModeSaratoga";

const LIGHT_THEME_KEY: string = "Light";
const DARK_THEME_KEY: string = "Dark";

@Injectable({
  providedIn: 'root'
})
export class DarkThemeService {

  public get isSetDark(): boolean { return this._isDarkTheme; }
  public get isSetDarkClass(): string { return this._isDarkTheme ? this._darkThemeClass : ''; }
  
  private _darkThemeClass: string = EnvironmentHelper.isSaratoga() ? SARATOGA_DARK_THEME_CLASS : AIXP_DARK_THEME_CLASS;
  private _isDarkTheme: boolean = false;
 
  constructor(private overlay: OverlayContainer) { }

  public onLogin() {
    this._isDarkTheme = localStorage.getItem('theme') === LIGHT_THEME_KEY ? false : true;
    this.storeThemeSelection(false);
  }

  public onLogOut() {
    this._isDarkTheme = false;
    this.storeThemeSelection(false);
  }

  public storeThemeSelection(toggle: boolean = true) {

    if (toggle) {
      this._isDarkTheme = !this._isDarkTheme;
      localStorage.setItem('theme', this._isDarkTheme ? DARK_THEME_KEY : LIGHT_THEME_KEY);
    }

    this._isDarkTheme ?
    this.overlay.getContainerElement().classList.add(this._darkThemeClass) :
    this.overlay.getContainerElement().classList.remove(this._darkThemeClass);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { addDays } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { MasterDTO } from 'src/app/models/dto/masterDTO';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { MasterEdit } from 'src/app/models/masterEdit';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { MasterService } from 'src/app/services/master.service';
import { SideNavService } from 'src/app/services/sidenav.service';
import { AzureStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-master-add',
  templateUrl: './master-add.component.html',
  styleUrls: ['./master-add.component.scss']
})
export class MasterAddComponent implements OnInit {

  currentUser: User = null;
  isTemplate: boolean = false;
  masterToEdit: MasterDTO = null;
  classrooms: ClassroomDTO[] = [];
  sending: boolean = false;
  minDate: Date = new Date();

  selectedClassrooms: number[] = [];
  imageSrc: FormControl = new FormControl(undefined, [Validators.required]);
  name: FormControl = new FormControl('', [Validators.required, Validators.minLength(4)]);
  description: FormControl = new FormControl('');
  header: FormControl = new FormControl('');
  footer: FormControl = new FormControl('');
  summary: FormControl = new FormControl('');
  rangeDate: FormGroup = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required])
    },
    [Validators.required]);
  participants: FormControl = new FormControl(1);
  duration: FormControl = new FormControl(7, [Validators.required, Validators.min(1)]);
  visibility: FormControl = new FormControl(false, [Validators.required]);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;
  headerTranslation: TranslationDTO = undefined;
  footerTranslation: TranslationDTO = undefined;
  summaryTranslation: TranslationDTO = undefined;

  constructor(private auth: AuthService,
    public sideNav: SideNavService,
    public dialogRef: MatDialogRef<MasterAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private masterService: MasterService,
    private snackBar: MatSnackBar,
    private azureService: AzureStorageService,
    private router: Router) {
    this.rangeDate.patchValue({
      start: new Date(),
      end: addDays(new Date(), 7)
    });
  }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    if (this.data != null) {

      if (this.data.isTemplate)
        this.isTemplate = this.data.isTemplate;

      if (this.data.master) { 
        this.masterToEdit = this.data.master;

        this.name.setValue(this.masterToEdit.name);
        this.description.setValue(this.masterToEdit.description);
        this.header.setValue(this.masterToEdit.header);
        this.footer.setValue(this.masterToEdit.footer);
        this.summary.setValue(this.masterToEdit.summary);
        this.participants.setValue(this.masterToEdit.maxParticipants);
        this.visibility.setValue(this.masterToEdit.visibility === 2 ? true : false);
        this.duration.setValue(this.masterToEdit.durationInDays);
        this.rangeDate.patchValue({
          start: new Date(this.masterToEdit.startDate),
          end: new Date(this.masterToEdit.stopDate)
        });

        this.nameTranslation = this.masterToEdit.nameTranslation;
        this.descriptionTranslation = this.masterToEdit.descriptionTranslation;
        this.headerTranslation = this.masterToEdit.headerTranslation;
        this.footerTranslation = this.masterToEdit.footerTranslation;
        this.summaryTranslation = this.masterToEdit.summaryTranslation;
      }
    }

    if (!this.masterToEdit)
      this.participants.setValue(this.currentUser.maxActiveUsers);

    if (this.isTemplate)
      this.participants.setValue(1);

    this.participants.setValidators([Validators.required, Validators.min(1), Validators.max(this.currentUser.maxActiveUsers)]);
  }

  async saveMaster() {
    this.toggleDisable(true);
    
    let master = new MasterEdit();

    master.IdAuthor = this.currentUser.id;
    master.Name = this.name.value;
    master.Description = this.description.value;
    master.Header = this.header.value;
    master.Footer = this.footer.value;
    master.Summary = this.summary.value;
    master.MaxParticipants = this.participants.value;
    master.Visibility = this.visibility.value === true ? 2 : 1;
    master.NameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    master.DescriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);
    master.HeaderTranslation = TranslationEdit.fromDTO(this.headerTranslation);
    master.FooterTranslation = TranslationEdit.fromDTO(this.footerTranslation);
    master.SummaryTranslation = TranslationEdit.fromDTO(this.summaryTranslation);

    if (!this.isTemplate) {
      master.StartDate = new Date(this.rangeDate.controls.start.value);
      master.StopDate = new Date(this.rangeDate.controls.end.value);
    } else {
      master.DurationInDays = this.duration.value;
    }

    master.ImageUrl = await this.azureService.uploadFile(this.imageSrc.value);

    if(this.masterToEdit == null) {

      if (this.isTemplate) {
        firstValueFrom(this.masterService.postMasterTemplate(master))
          .then(success => {
            this.apiSucc('Master added successfully');
            this.router.navigate(['/master', (success as any).Message], { queryParams: { page: 'sidenav' } });
          }).catch(err => this.apiErr(err));
      } else {
        firstValueFrom(this.masterService.postMaster(master))
          .then(success => {
            this.apiSucc('Master added successfully');
            this.router.navigate(['/master', (success as any).Message], { queryParams: { page: 'sidenav' } });
          }).catch(err => this.apiErr(err));
      }
        
    } else {
      master.State == this.masterToEdit.state;

      firstValueFrom(this.masterService.putMaster(this.masterToEdit.id, master))
        .then(() => this.apiSucc('Master edited successfully'))
        .catch(err => this.apiErr(err));
    }
  }

  apiSucc(msg: string) {
    this.snackBar.open(msg, 'Dismiss', { duration: 3000 });
    this.toggleDisable(false);
    this.dialogRef.close(true);
  }

  apiErr(err: any) {
    console.log(err);

    this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
    this.toggleDisable(false);
    this.onNoClick();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  toggleDisable(toggle: boolean) {
    this.sending = toggle;

    if (toggle) {
      this.name.disable();
      this.description.disable();
      this.header.disable();
      this.footer.disable();
      this.summary.disable();
      this.imageSrc.disable();
      this.rangeDate.disable();
      this.visibility.disable();
    } else {
      this.name.enable();
      this.description.enable();
      this.header.enable();
      this.footer.enable();
      this.summary.enable();
      this.imageSrc.enable();
      this.rangeDate.enable();
      this.visibility.enable();
    }
  }

  disabledOkBtn() {
    var check = !this.name.valid ||
                !this.participants.valid ||
                !this.visibility.valid;

    if (!this.masterToEdit) {
      check = check || !this.imageSrc.valid;

      return this.isTemplate ? (check || !this.duration.valid) : (check || !this.rangeDate.valid);
    } else {
      return this.isTemplate? (check || !this.duration.valid) : (check || !this.rangeDate.valid);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Device, OpenVidu, PublisherProperties } from 'openvidu-browser';
import { joinLessonConfig } from 'src/app/models/joinLessonConfig';
import { CalendarService } from 'src/app/services/calendar.service';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClassroomService } from 'src/app/services/classroom.service';

const NO_AUDIO_DEVICE: string = "noaudio";

@Component({
    selector: 'app-join-session-dialog',
    templateUrl: './join-session-dialog.component.html',
    styleUrls: ['join-session-dialog.component.scss']
})
export class JoinSessionDialogComponent implements OnInit {
    isLowRes: boolean = false;
    joinWithVideo = true;
    joinWithAudio = true;
    screenSharing = false;

    OV: OpenVidu;
    videoDeviceArr: Device[] = [];
    audioDeviceArr: Device[] = [{ kind: 'audioinput', deviceId: NO_AUDIO_DEVICE, label: 'No audio device' }];
    classrooms: ClassroomDTO[];
    easyLesson: any;
    lessonActive: any;
    tabGlobalIndex: number = 0;
    tabScenarioIndex: number = 0;
    localConfig2: string = 'webcam';
    localConfig3: string = 'webcam';
    lessonOpenInClass: LessonSessionDTO = null;

    classroomForm: UntypedFormControl = new UntypedFormControl(undefined, [Validators.required]);
    videoForm1: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    videoForm2: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    videoForm3: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    audioForm: UntypedFormControl = new UntypedFormControl(null);
    qualityForm: UntypedFormControl = new UntypedFormControl('medium', [Validators.required]);
    sourceForm: UntypedFormControl = new UntypedFormControl('webcam', [Validators.required]);
    scenarioForm: UntypedFormControl = new UntypedFormControl(2, [Validators.required]);

    constructor(private auth: AuthService,
                private calendar: CalendarService,
                private classroomService: ClassroomService,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<JoinSessionDialogComponent>) { }

    async ngOnInit(): Promise<void> {

        if (localStorage.getItem('easyLesson')) {
            this.easyLesson = localStorage.getItem('easyLesson');
            this.getClassrooms();
        }

        if (localStorage.getItem('lessonActive'))
            this.lessonActive = JSON.parse(localStorage.getItem('lessonActive'));

        if (this.auth.getCurrentUser().lowRes === 1) {
            this.qualityForm.setValue('low');
            this.isLowRes = true;
        }

        this.OV = new OpenVidu();

        try {
	
            await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });

            let devices = await this.OV.getDevices();

            this.videoDeviceArr = devices.filter(d => d.kind === 'videoinput');
            this.audioDeviceArr = this.audioDeviceArr.concat(devices.filter(d => d.kind === 'audioinput'));

            this.videoForm1.setValue(this.videoDeviceArr[0].deviceId);
            this.audioForm.setValue(this.audioDeviceArr[0].deviceId);

        } catch (e) {
            console.error(e);

            this.snackBar.open('please connect a device and allow camera and microphone access to continue', 'Dismiss', {
                duration: 5000,
                verticalPosition: 'top'
            });
        }
    }

    joinSession() {
        if(this.easyLesson == 'true')
            localStorage.setItem('chosenClass', this.classroomForm.value);
            
        if(this.tabGlobalIndex == 0) {
            this.scenarioForm.setValue(1);
        }
        
        localStorage.setItem('scenario', this.scenarioForm.value);

        var cameraOptions: PublisherProperties[] = [];
        //if (this.getResolution() === 'screenSharing') {
        //cameraOptions = {
        //videoSource: 'screen'
        //};
        //} else {
        cameraOptions[0] = {
            publishAudio: !this.joinWithAudio ? false : true,
            publishVideo: !this.joinWithVideo ? false : true,
            resolution: this.getResolution(),
            videoSource: (this.sourceForm.value == 'screenSharing') ? 'screen' : this.videoForm1.value,
            audioSource: this.audioForm.value === NO_AUDIO_DEVICE ? null : this.audioForm.value
        };

        if(this.scenarioForm.value > 1 && this.tabGlobalIndex == 1) {
            this.localConfig2 = 'webcam';

            cameraOptions[1] = {
                publishAudio: false,
                publishVideo: !this.joinWithVideo ? false : true,
                resolution: this.getResolution(),
                videoSource: this.videoForm2.value, //(this.localConfig2 == 'screenSharing') ? 'screen' : this.videoForm2.value
                audioSource: null
            };

            if(this.scenarioForm.value > 2 && this.tabGlobalIndex == 1) {
                this.localConfig3 = 'webcam';
                
                cameraOptions[2] = {
                    publishAudio: false,
                    publishVideo: !this.joinWithVideo ? false : true,
                    resolution: this.getResolution(),
                    videoSource: this.videoForm3.value, //(this.localConfig3 == 'screenSharing') ? 'screen' : this.videoForm3.value
                    audioSource: null
                };
            }
        }

        if(this.scenarioForm.value > 1) {
            var config: joinLessonConfig = new joinLessonConfig();

            config.sourceNumber = this.scenarioForm.value;
            config.quality = this.qualityForm.value;
            config.audioEnabled = this.joinWithAudio;
            config.videoEnabled = this.joinWithVideo;
            config.audioDevice = this.audioDeviceArr.find(d => d.deviceId == this.audioForm.value).label;
            config.source1 = this.sourceForm.value;
            config.video1 = this.videoDeviceArr.find(d => d.deviceId == this.videoForm1.value).label;
            config.source2 = this.localConfig2;
            config.video2 = this.videoDeviceArr.find(d => d.deviceId == this.videoForm2.value).label;
            config.source3 = null;
            config.video3 = null;

            if(this.videoForm3.value != '' && this.scenarioForm.value > 2) {
                config.source3 = this.localConfig3;
                config.video3 = this.videoDeviceArr.find(d => d.deviceId == this.videoForm3.value).label;
                localStorage.setItem('joinLessonConfig3', JSON.stringify(config));
            } else
                localStorage.setItem('joinLessonConfig2', JSON.stringify(config));
        }

        //}
        this.dialogRef.close(cameraOptions);
    }

    getClassrooms() {
        this.classroomService.getClassrooms()
            .subscribe(output => { 
                this.classrooms = output as ClassroomDTO[];
                this.classroomForm.setValue(output[0].id);

                if(this.easyLesson == 'true')
                    this.checkClassLesson();
            });
    }

    getResolution(): string {
        let resolution;
        switch (this.qualityForm.value) {
            case 'low':
                resolution = '640x480';
                break;
            case 'medium':
                resolution = '1280x720';
                break;
            case 'high':
                resolution = '1920x1080';
                break;
            case 'veryhigh':
                resolution = '3840x2160';
                break;
            //case 'screenSharing':
            //resolution = 'screenSharing';
            //break;
            default:
                resolution = '1920x1080';
        }
        return resolution;
    }

    tabChangePage($event) {
        this.tabGlobalIndex = $event;

        if($event == 1)
            this.setJoinLessonConfig(this.scenarioForm.value);
        else
            this.defaultConfigValues();
    }

    setJoinLessonConfig(scenario: number) {
        var config: joinLessonConfig;

        if(scenario == 2) {
            if(localStorage.getItem('joinLessonConfig2'))
                config = JSON.parse(localStorage.getItem('joinLessonConfig2'));
        } else {
            if(localStorage.getItem('joinLessonConfig3'))
                config = JSON.parse(localStorage.getItem('joinLessonConfig3'));
        }

        if(config != null) {

            if (this.auth.getCurrentUser().lowRes === 1) {
                this.qualityForm.setValue('low');
                this.isLowRes = true;
            } else {
                this.qualityForm.setValue(config.quality);
            }

            this.scenarioForm.setValue(config.sourceNumber);
            
            this.joinWithAudio = config.audioEnabled;
            this.joinWithVideo = config.videoEnabled;
        
            var devAudio1 = this.audioDeviceArr.find(d => d.label === config.audioDevice);
            var devVideo1 = this.videoDeviceArr.find(d => d.label === config.video1);
            var devVideo2 = this.videoDeviceArr.find(d => d.label === config.video2);
            var devVideo3 = this.videoDeviceArr.find(d => d.label === config.video3);
            
            if(devAudio1 != null)
                this.audioForm.setValue(devAudio1.deviceId);
                    
            this.sourceForm.setValue(config.source1);
        
            if(devVideo1 != null)
                this.videoForm1.setValue(devVideo1.deviceId);
        
            if(devVideo2 != null) {
                this.videoForm2.setValue(devVideo2.deviceId);
                this.localConfig2 = config.source2;
            }
        
            if(scenario != 2 && devVideo3 != null) {
                this.videoForm3.setValue(devVideo3.deviceId);
                this.localConfig3 = config.source3;
            } 
        }   
    }

    defaultConfigValues() {
        this.audioForm.setValue(this.audioDeviceArr[0].deviceId);
        this.videoForm1.setValue(this.videoDeviceArr[0].deviceId);
        this.videoForm2.setValue(null);
        this.videoForm3.setValue(null);
        this.sourceForm.setValue('webcam');
        this.joinWithAudio = true;
        this.joinWithVideo = true;
        this.scenarioForm.setValue(2);

        if (this.auth.getCurrentUser().lowRes === 1) {
            this.qualityForm.setValue('low');
            this.isLowRes = true;
        } else {
            this.qualityForm.setValue('medium');
        }
    }

    checkClassLesson() {
        this.lessonOpenInClass = null;

        this.calendar.getOpenLessonInClass(this.classroomForm.value)
            .subscribe(output => {
                if(output != null)
                    this.lessonOpenInClass = output;
            })
    }

    onNoClick(): void {
        localStorage.setItem('easyLesson', 'false');
        this.OV = null;
        //location.reload();
        this.dialogRef.close();
    }
}

<div fxLayout="column" class="component-main">

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <button mat-stroked-button (click)="goBack()">
            <mat-icon matListIcon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <h1 style="align-self: center; font-size: 40px;">{{ contentTitle }}</h1>
    </div>
    <mat-tab-group>

        <mat-tab [label]="'Students data' | role | translate">
            <div fxLayout="column" fxLayoutAlign="center center">
                <h2>
                    {{ 'Views' | translate }}
                </h2>
                <div class="graph">
                    <canvas baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [type]="barChartType">
                    </canvas>
                </div>
            </div>
        </mat-tab>
        
        <mat-tab [label]="'Students' | role | translate">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start start" style="margin-bottom: 20px; margin-top: 10px;">
                <mat-card appearance="outlined" class="mat-elevation-z4" fxFlex="50">
                    <mat-card-header>
                        <mat-card-title>
                            {{ 'Students' | role | translate }}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxLayoutGap="10px">
                            <mat-form-field fxFlex="80" color="primary">
                            <mat-label>{{'search' | translate}}</mat-label>
                            <input matInput type="text" (keyup)="doFilter($event)">
                            <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                            <div fxFlex="20">
                            <button mat-stroked-button (click)="viewMost()">
                                {{ 'All data' | translate }}
                                <mat-icon matListIcon>arrow_forward</mat-icon>
                            </button>
                        </div>
                        </div>
                        <table mat-table class="full-width-table" matSort aria-label="Elements">
                            <!-- Id Column -->
                            <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                            <td mat-cell *matCellDef="let row">{{row.id}}</td>
                            </ng-container>
                        
                            <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Student' | role | translate }}</th>
                            <td mat-cell *matCellDef="let row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">
                                <div *ngIf="row">
                                    <app-profile-picture [url]="row.profilePictureUrl"></app-profile-picture>
                                </div>
                        
                                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="row">
                                    <span style="font-weight: bold;">{{ row.surname + ' ' + row.name }}</span>
                                    <span style="color: gray; font-size: 10px;" *ngIf="row.userSummary">{{ row.userSummary }}</span>
                                    <!-- <span>{{row.suveyAnwser.length}}</span> -->
                                </div>
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="survey">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Data' | translate }}</th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-stroked-button (click)="viewForStudent(row.id)">
                                    {{ 'Student data' | role | translate }}
                                    <mat-icon>arrow_forward</mat-icon>
                                </button>
                            </td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        
                        <mat-paginator #paginator
                            [length]="dataSrc?.data.length"
                            [pageIndex]="0"
                            [pageSize]="10"
                            [pageSizeOptions]="[1, 4, 10, 20]"
                            color="primary">
                        </mat-paginator>
                    </mat-card-content>
                </mat-card>

                <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">

                    <mat-card appearance="outlined" class="mat-elevation-z4">
                        <mat-card-header>
                            <mat-card-title>
                                {{ studSelected ?? ('Students data' | role | translate) }}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <div class="graph">
                                    <canvas baseChart
                                      [datasets]="barChartData3"
                                      [labels]="barChartLabels3"
                                      [options]="barChartOptions"
                                      [plugins]="barChartPlugins"
                                      [type]="barChartType">
                                    </canvas>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>
            </div>

        </mat-tab>
        <mat-tab [label]="'Yearly Statistics' | translate">
            <div fxLayout="column" fxLayoutAlign="center center">
                <h2>
                    {{ 'Views' | translate }}
                </h2>
                <div class="btn-group">
                      <mat-form-field color="primary">
                        <mat-label>{{ 'Year' | translate }}</mat-label>
                        <mat-select name="name" [formControl]="year" (selectionChange)="yearChange()">
                            <mat-option [value]="0">{{ 'All years' | translate }}</mat-option>
                            <mat-option *ngFor="let year of years" [value]="year">
                                {{year}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="graph">
                    <canvas baseChart
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [type]="barChartType">
                    </canvas>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { User } from '../../models/user';
import { GenericPopupComponent, GenericPopupData } from '../generic-popup/generic-popup.component';
import { AuthService } from '../../services/auth.service';
import { CourseService } from '../../services/course.service';
import { MasterService } from '../../services/master.service';
import { PackageService } from '../../services/package.service';
import { UserService } from '../../services/user.service';
import { ConferenceService } from 'src/app/services/conference.service';
import { UserRole } from 'src/app/models/userRole';

@Component({
  selector: 'app-add-user-list-popup',
  templateUrl: './add-user-list-popup.component.html',
  styleUrls: ['./add-user-list-popup.component.scss']
})
export class AddUserListPopupComponent implements OnInit {

  currentUser: User = null;
  subscriptionFee : number;
  training: string;
  type: 'course' | 'webinar' | 'master' | 'package' = null;
  subscriptionId: number = null;
  alreadyPaidUsers: number[] = [];
  subscriptionFree: boolean;
  datasource: MatTableDataSource<User>;
  displayedColumns: string[] = ['thumbnail', 'username', 'surname', 'name', 'actions'];
  paginatorDefault: number = 25;
  paginatorPages: number[] = [10, 25, 50];
  isFree: boolean;
  translatedType: string;

  @ViewChild('usersSort') usersSort: MatSort;
  @ViewChild('usersPaginator') usersPaginator: MatPaginator;

  constructor(private dialogRef: MatDialogRef<AddUserListPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private auth: AuthService,
              private userService: UserService,
              private courseService: CourseService,
              private masterService: MasterService,
              private packageService: PackageService,
              private conferenceService: ConferenceService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();
    this.getUsers();
    this.subscriptionFee = this.data.subscription.subscriptionFee;
    this.subscriptionFree = this.data.subscriptionType
    this.checkPrice();
    this.training= this.data.training;
    this.type = this.data.type;
    this.checkType();
    this.translateType();
    this.subscriptionId = this.data.subscriptionId;
    this.alreadyPaidUsers = this.data.alreadyPaidUsers;
  }

  checkType() : void {
    if (this.training == undefined)
      this.training = this.type;
  }

  checkPrice() :void {
    if (this.subscriptionFree) {
      this.subscriptionFee = 0;
      this.isFree = true;
    }
    else
      this.subscriptionFee = this.data.subscription.subscriptionFee.euro;
  }

  translateType() :void {
    if (this.type == 'package')
      this.translatedType = this.translate.instant('package');

    if (this.type == 'course')
      this.translatedType = this.translate.instant('course');
  }

  getUsers(): void {
    this.userService.getAllUsers([UserRole.Student], undefined, undefined, true)
      .subscribe(res => {

          let users = res as User[];

          if (!this.datasource) {
            this.datasource = new MatTableDataSource();

            this.datasource.sort = this.usersSort;
            this.datasource.paginator = this.usersPaginator;

            this.datasource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'actions':
                  return this.hasAlreadyPaid(item.id);
                default:
                  return item[property];
              };
            }
          }

          users.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());

          this.datasource.data = users;

      });
  }

  async addUserAsPaid(user: any) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Set as paid')),
        body: await firstValueFrom(this.translate.get('Are you sure to add {{surname}} {{name}} as a paid user?', { surname: user.surname, name: user.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;

        let action: Observable<ArrayBuffer> = null;

        if (this.type === 'course')
          action = this.courseService.buyCourse(this.subscriptionId, user.id);
        else if (this.type === 'master')
          action = this.masterService.buyMaster(this.subscriptionId, user.id);
        else if (this.type === 'package')
          action = this.packageService.buyPackage(this.subscriptionId, user.id);
        else if (this.type === 'webinar')
          action = this.conferenceService.buyConference(this.subscriptionId, user.id);
    
        action.subscribe({
          next: async () => {
            this.alreadyPaidUsers.push(user.id);
            this.snackBar.open (await firstValueFrom(this.translate.get(`User added to ${this.type}`)), '', { duration: 3000 });
          },
          error: err => this.snackBar.open(err.error.Message, '', { duration: 3000 })
        });
    });
  }

  hasAlreadyPaid(userId: number) {
    return this.alreadyPaidUsers.findIndex(u => u === userId) !== -1;
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  applyFilter (event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.datasource.filter = value.trim().toLowerCase();
  }

}

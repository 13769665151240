<div class="component-main" *ngIf="content">
    <div>
        <div fxLayout="row" fxLayoutAlign="center start">
            <h2>{{ content.name == null ? 'No title' : content.name }}</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px;">
            <button mat-stroked-button (click)="goBack()">
              <mat-icon>arrow_back</mat-icon>
              {{ 'Back' | translate }}
            </button>
            <!--button mat-stroked-button style="margin-left: 10px;" [cdkCopyToClipboard]="href"> 
                <mat-icon>content_copy</mat-icon>
                {{ 'Copy link' | translate }}
            </button-->
            <button mat-fab *ngIf="content.type === 100" (click)="contentsMode = (contentsMode === 'cards' ? 'table' : 'cards')" [matTooltip]="'Change contents view' | translate" 
                color="primary">
                <mat-icon>view_list</mat-icon>
            </button>
            <button mat-fab color="primary" (click)="updateContent()" [matTooltip]="'Edit' | translate"
                *ngIf="(currentUser.id === content.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId)) && currentUser.coursesEnabled"
                [disabled]="isVideoCut">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-fab [color]="isVideoCut ? 'warn' : 'accent'"
                (click)="isVideoCut = !isVideoCut"
                [disabled]="sending"
                [matTooltip]="(isVideoCut ? 'Cancel' : 'Cut video') | translate"
                *ngIf="(currentUser.id === content.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId)) && currentUser.coursesEnabled && content.type === 1">
                <mat-icon>{{ isVideoCut ? 'close' : 'content_cut' }}</mat-icon>
            </button>
            <button mat-fab color="primary"
                *ngIf="(currentUser.id === content.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId)) && currentUser.coursesEnabled && content.type === 100"
                (click)="startSelection()"
                [disabled]="selectionShowing()"
                [matTooltip]="'Contents' | translate">
                <mat-icon>playlist_add</mat-icon>
            </button>
            <button mat-fab color="warn" (click)="deleteContent()" [matTooltip]="'Delete' | translate"
                *ngIf="(currentUser.id === content.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId)) && currentUser.coursesEnabled"
                [disabled]="isVideoCut">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-fab color="primary" (click)="goToTracker()" [matTooltip]="'Go to report' | translate"
                *ngIf="(currentUser.id === content.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId)) && content.type !== 100">
                <mat-icon>bar_chart</mat-icon>
            </button>
            <button mat-fab color="accent" (click)="editSubtitles()" [matTooltip]="(subtitleStatus === 'available' ? 'Subtitles available' : 'Subtitles generation requested') | translate"
                *ngIf="(currentUser.id === content.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId)) && subtitleStatus != undefined">
                <mat-icon>{{ subtitleStatus === 'available' ? 'subtitles' : 'subtitles_off' }}</mat-icon>
            </button>
        </div>
    </div>
    <div style="font-size: 16px; color: #5092CF; text-align: start;">
        {{ getHashTags() }}
    </div>
    <div>
        <div fxLayout="column" fxFlex="start center" style="align-items: center" fxFlex="80">
            
            <div style="width: 100%;">

                <div *ngIf="content.type === 1">

                    <app-player-events 
                        [shadow]="true"
                        [media]="content.mediaUrl"
                        [caption]="content.subtitles ? { label: content.subtitles[0].language, url: content.subtitles[0].url } : undefined"
                        [controls]="download"
                        [enableControls]="true"
                        [(startTime)]="trackerStartPosition"
                        (duration)="videoDuration = $event"
                        (firstPlayed)="startTracking()"
                        (seek)="addTracker($event)"
                        (close)="addTracker([$event, 0])"
                        height="50vh"
                        class="player">
                    </app-player-events>

                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isVideoCut">

                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="90">

                            <span fxFlex>{{ sliderLabel(startTime) }}</span>

                            <mat-slider
                                [min]="0"
                                [max]="videoDuration"
                                [discrete]="true"
                                [displayWith]="sliderLabel"
                                [disabled]="sending"
                                fxFlex="80">

                                <input [(ngModel)]="startTime" matSliderStartThumb>
                                <input [(ngModel)]="endTime" matSliderEndThumb>

                            </mat-slider>

                            <span fxFlex>{{ sliderLabel(endTime) }}</span>

                        </div>

                        <div fxFlex>
                            <button mat-icon-button
                                (click)="cutVideo()"
                                [disabled]="startTime >= endTime || startTime < 0 || endTime > videoDuration || sending"
                                [matTooltip]="'Cut video' | translate">
                                <mat-icon>done</mat-icon>
                            </button>
                        </div>
                        
                    </div>
                    
                </div>

                <div *ngIf="content.type === 2" class="mat-elevation-z8" style="margin-top: 10px; margin-bottom: 10px;">
                    <ngx-extended-pdf-viewer *ngIf="!editing"
                        [src]="content.mediaUrl"
                        [useBrowserLocale]="true"
                        [textLayer]="true"
                        [showBorders]="false"
                        [showOpenFileButton]="false"
                        [showBookmarkButton]="false"
                        [showPresentationModeButton]="true"
                        [filenameForDownload]="content.name"
                        [enablePrint]="content.downloadable == 1"
                        [showDownloadButton]="content.downloadable == 1"
                        [contextMenuAllowed]="content.downloadable == 1"
                        (pdfLoaded)="startTracking()"
                        (pageChange)="pageChanged($event)"
                        [page]="trackerStartPosition"
                        [theme]="darkService.isSetDark ? 'dark' : 'light'"
                        height="60vh">
                    </ngx-extended-pdf-viewer>
                </div>

                <div *ngIf="content.type === 5" fxLayout="column" fxLayoutGap="10px">
                    <app-picture-preview
                        [src]="content.headerImageUrl"
                        height="50vh"
                        width="100%"
                        fit="cover"
                        style="background-color: black">
                    </app-picture-preview>
                    <app-audio-player-events 
                        [shadow]="true"
                        [media]="content.mediaUrl"
                        [controls]="download"
                        [enableControls]="true"
                        [(startTime)]="trackerStartPosition"
                        (duration)="videoDuration = $event"
                        (firstPlayed)="startTracking()"
                        (seek)="addTracker($event)"
                        (close)="addTracker([$event, 0])">
                    </app-audio-player-events>
                </div>

                <div *ngIf="content.type === 100" style="margin-bottom: 20px">

                    <div *ngIf="contents.length === 0; else contentsContainer" fxLayoutAlign="center center">
                        <app-error-message customClass="warning"
                            [errorTitle]="'There are no contents' | translate"
                            [errorContent]="'In this folder there are no contents, try adding some using the purple button' | translate">
                        </app-error-message>
                    </div>

                    <ng-template #contentsContainer>

                        <div *ngIf="contentsMode === 'cards'" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">

                            <app-content-card *ngFor="let children of contents"
                                [courseAuthorId]="content.course.idAuthor"
                                [courseMode]="content.course.mode"
                                [courseState]="content.course.state"
                                [contents]="contents"
                                [content]="children"
                                [forceCustomOrder]="customOrder"
                                (onDelete)="getContentById()">
                            </app-content-card>
                
                        </div>

                        <app-content-table *ngIf="contentsMode === 'table'"
                            [courseAuthorId]="content.course.idAuthor"
                            [courseMode]="content.course.mode"
                            [contents]="contents"
                            (onSave)="getContentById()"
                            (onDelete)="getContentById()">
                        </app-content-table>

                    </ng-template>

                </div>

                <mat-card appearance="outlined" *ngIf="content.type === 4" fxLayoutAlign="center center" style="height: 50vh;" class="mat-elevation-z8">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!scormUrl; else scormFrame">
                        <button mat-fab color="accent" (click)="playScorm()" [disable]="scormUrl != null">
                            <mat-icon>play_arrow</mat-icon>
                        </button>
                        <span style="font-weight: bold">{{ (isPreviewUser() ? 'See content preview' : 'Start content') | translate }}</span>
                    </div>

                    <ng-template #scormFrame>
                        <iframe [src]="scormUrl | safeUrl" frameBorder="0"></iframe>
                    </ng-template>
                </mat-card>
                
                <div style="font-size: 16px; margin-top: 10px;" *ngIf="content.author && content.type !== 100">
                    
                    <div fxLayout="column" fxLayoutAlign="center start" style="color: grey;" fxFlex>
                        {{ content.author.name + ' ' + content.author.surname }}
                    </div>

                    <div fxLayout="column" fxLayoutAlign="center end" fxFlex="7">
                        <button mat-button [matTooltip]="getLastLikes()" matTooltipClass="multiline-tooltip" (click)="userLike ? deleteLike() : postLike()"
                            style="font-weight: bold; color: grey; font-size: 20px;">
                            <mat-icon [style.color]="userLike ? '#5092CF' : 'grey'" style="margin-right: 5px;">thumb_up</mat-icon>
                            {{ likes.length }}
                        </button>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="center end" fxFlex="4">
                        <button mat-icon-button [matTooltip]="(userFav ? 'Remove from favourites' : 'Add as favourite') | translate" (click)="userFav ? removeFavourite() : addFavourite()">
                            <mat-icon [style.color]="userFav ? 'red' : 'grey'">favorite</mat-icon>
                        </button>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="center end" style="color: grey;" fxFlex="14">
                        {{ content.created | date: 'HH:mm - dd/MM/YYYY' }}
                    </div>

                </div>

                <div [innerHtml]="content.description" class="textBlock">
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex="start center" style="align-items: center;" fxFlex="40" *ngIf="content.author">
            <app-author-info *ngIf="!isDT()" [profile]="content.author"></app-author-info>
            <mat-card appearance="outlined" style="width: 80%;" class="mat-elevation-z8">
                <button mat-mini-fab color="warn" class="buttonFloating" (click)="showBlog(0)" *ngIf="showBlogForm">
                    <mat-icon>clear</mat-icon>
                </button>
                <button mat-mini-fab color="accent" class="buttonFloating" (click)="showBlog(1)" *ngIf="!showBlogForm">
                    <mat-icon>add_comment</mat-icon>
                </button>
                <mat-card-header>
                    <mat-card-title style="margin-bottom: 20px;">{{ 'Comments' | translate }}</mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column" fxLayoutGap="10px">
                    <div *ngIf="showBlogForm">
                        <mat-form-field appearance="outline" style="width: 70%;" color="primary">
                            <mat-label>
                                {{ (selectedBlog != null && showBlogForm == 1) ? ('Reply to' | translate) + ' ' + selectedBlog.author.name + ' ' + selectedBlog.author.surname : showBlogForm == 2 ? ('Edit' | translate) : ('Comment' | translate) }}
                            </mat-label>
                            <textarea matInput [formControl]="postContent" style="height: 100px;"></textarea>
                        </mat-form-field>
                        <button mat-mini-fab color="accent" (click)="editBlog(showBlogForm == 1 ? true : false)" [disabled]="!postContent.valid" style="margin-left: 10px;">
                            <mat-icon>done</mat-icon>
                        </button>
                    </div>
                    <ng-container *ngFor="let blog of blogs" [ngTemplateOutlet]="blogContainer" [ngTemplateOutletContext]="{blog:blog}"></ng-container>
                    <div *ngIf="blogs == null || blogs.length == 0">
                        {{ 'No comments for this content, be the first one' | translate }}
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>

<ng-template #blogContainer let-blog="blog">
    <div fxLayout="row" fxLayoutAlign="start start" class="mat-elevation-z2" style="background-color: gainsboro; border-radius: 20px;" [style.color]="darkService.isSetDark ? 'black !important': ''" >
        <div fxLayout="column" fxLayoutAlign="start center">
            <img *ngIf="blog.author.profilePictureUrl" mat-card-avatar [src]="blog.author.profilePictureUrl" style="margin: 10px;" loading="lazy">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex>
            <div fxLayoutAlign="start start" style="margin: 10px; font-weight: bold; font-size: 14px;">
                {{ blog.author.name + ' ' + blog.author.surname }}
            </div>
            <div fxLayoutAlign="start start" [innerHTML]="blog.postContent" style="margin: 10px; margin-top: 0px; line-height: 1.6; text-align: justify;">
            </div>
        </div>
        <div fxLayout="column wrap">
            <div fxLayoutAlign="end center" style="margin: 10px; color: gray; font-size: small;">
                {{ blog.created | date: 'HH:mm - dd/MM/YYYY' }}
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-mini-fab color="primary" *ngIf="blog.idAuthor === currentUser.id" (click)="showBlog(2, blog)" style="margin: 10px;">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-mini-fab color="accent" (click)="showBlog(1, blog)" style="margin: 10px;">
                    <mat-icon>reply</mat-icon>
                </button>
                <button mat-mini-fab color="warn"
                    *ngIf="currentUser.id === blog.idAuthor || (currentUser.isCustomerAdmin && currentUser.idCustomer === content.author.customerId) || currentUser.isAdmin"
                    (click)="deleteBlog(blog.id)"
                    style="margin: 10px;">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div style="padding-left: 10%; margin-top: 10px;" *ngFor="let blog of blog.blogs">
        <ng-container [ngTemplateOutlet]="blogContainer" [ngTemplateOutletContext]="{blog:blog}"></ng-container>
    </div>
</ng-template>

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SocialWallBlogDTO } from 'src/app/models/dto/SocialWallBlogDTO';
import { WallService } from '../service/wall.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-wall-blog-popup',
  templateUrl: './wall-blog-popup.component.html',
  styleUrls: ['./wall-blog-popup.component.scss']
})
export class WallBlogPopupComponent implements OnInit {
  content: UntypedFormControl = new UntypedFormControl([]);
  blog: SocialWallBlogDTO  = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
               private socialWall: WallService,
               public snackBar: MatSnackBar,
               public translate: TranslateService,
               public dialogRef: MatDialogRef<WallBlogPopupComponent>) { }

  ngOnInit(): void {
    this.blog = this.data;
    this.content.setValue(this.blog.postContent)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  hasErrors() {
    if (!this.content.valid)
      return true
    else
      return false
  }

  saveEdit() {

  this.blog.postContent = this.content.value;
    
  this.socialWall.putBlog(this.blog.id, this.blog)
    .subscribe({
      next: async () => {
        this.dialogRef.close();
        this.snackBar.open(await firstValueFrom(this.translate.get('Comment edited')), undefined, {duration: 3000});
      },
      error: async err => {
        this.snackBar.open(await firstValueFrom(this.translate.get('Error editing comment')), undefined, {duration: 3000});
        console.log(err);
      }
    });
  }

}

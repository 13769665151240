<h2 mat-dialog-title>Add Link</h2>
<mat-dialog-content class="mat-typography custom-dialog">
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Link</mat-label>
    <input matInput [(ngModel)]="socialWallContent.contentLink" />
  </mat-form-field>
  <br />
  <mat-form-field appearance="outline" style="min-width: 100%">
    <mat-label>Description</mat-label>
    <textarea matInput [(ngModel)]="socialWallContent.description"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="socialWallContent" cdkFocusInitial>
    Submit
  </button>
</mat-dialog-actions>

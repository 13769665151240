import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentsVideoStatus } from 'src/app/models/studentsVideoStatus';
import { Session, SignalEvent, Connection } from 'openvidu-browser';
import { TeacherFeedbackService} from 'src/app/services/teacher-feedback.service';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public chatList: any[];
  public askChatList: any[];
  private session: Session;
  curUser: User;
  readonly TIMEOUT_SHORT_MSG = 5000;
  readonly TIMEOUT_LONG_MSG = 10000;
  readonly CHAT_START = 'my-chat';
  readonly CHAT_CLOSE = 'chat-close';
  readonly CHAT_REQUEST = 'r-chat';
  readonly CHAT_REFUSE = 'chat-refuse';
  readonly DISCONNECTED = 'disconnected';

  constructor(private snackBar: MatSnackBar, 
              private auth: AuthService,
              public teacherFeedbackService: TeacherFeedbackService) { 
    this.chatList = [];
    this.askChatList = [];
  }

setupService(session: Session) {
  this.curUser = this.auth.getCurrentUser();
  
  this.session = session;

  if (this.curUser.isTeacher) {

  // teacher
  // ettore richiesta di chat con teacher
  this.session.on(`signal:${this.CHAT_REQUEST}`, (event: SignalEvent) => {
    console.log(event.data); // Message
    console.log(event.from); // Connection object of the sender
    console.log(event.type); // The type of message 
    // this.myAlert(`richiesta di chatta`);
    // controllo che la richiesta non sia già stata inviata
    let item = undefined;
    item = this.askChatList.find(con => con.connection.connectionId === event.from.connectionId )
    // controllo che la chat non sia già attiva
    if (!item) {
      item = this.chatList.find(con => con.connection.connectionId === event.from.connectionId )
    }
    if (!item) {
       this.askChatList.push({session: this.session, connection: event.from, nome: event.data , msg: '', tipo: this.CHAT_REQUEST});
       this.playAudio();
    }
  });
  // messaggio chiusura chat
  this.session.on(`signal:${this.CHAT_CLOSE}`, (event: any) => {
  console.log('chat-close message: ' + event.data); // Message
  console.log('chat-close from', event.from); // Connection object of the sender
  console.log('chat-close type', event.type); // The type of message 
  this.myAlert(`${event.data} closed chat`);
  this.closeChat(event.from);
  });

   // disconnessione

  this.session.on(`signal:${this.DISCONNECTED}`, (event: any) => {
    console.log('disconnected message: ' + event.data); // Message
    console.log('disconnected from', event.from); // Connection object of the sender
    console.log('disconnected type', event.type); // The type of message 
    this.myAlert(`${event.data} disconnect stream video`);
    this.closeChat(event.from);
    });


} else if (this.curUser.isStudent) {
  // student

  /// per init chat
  this.session.on(`signal:${this.CHAT_START}`, (event: any) => {
  // console.log("chat message: " + event.data); // Message
  // console.log("chat from", event.from); // Connection object of the sender
  //  console.log("chat type", event.type); // The type of message 
  this.creaChatConTeacher(event.from, event.data);
  });

  this.session.on(`signal:${this.CHAT_CLOSE}`, (event: any) => {
  console.log('chat-close message: ' + event.data); // Message
  console.log('chat-close from', event.from); // Connection object of the sender
  console.log('chat-close type', event.type); // The type of message 
  this.myAlert(`Teacher closed chat`);
  this.closeChat(event.from);
  });
  
  
   this.session.on(`signal:${this.CHAT_REFUSE}`, (event: any) => {
    console.log('chat refuse message: ' + event.data); // Message
    console.log('chat refuse from', event.from); // Connection object of the sender
    console.log('chat refuse type', event.type); // The type of message 
    this.myAlert(`${event.data} refused chat`);
    this.closeChat(event.from);
    });


  } // else if (this.auth.isStudent){

}

chatFindConnection(connectionId: string): Connection {
  return this.chatList.find(con => con.connectionId === connectionId )
}

askChatToStudent(student: StudentsVideoStatus){
  const studente =student.name + ' ' + student.surname;
  const insegnante = this.curUser.name + ' ' + this.curUser.surname;
  console.log('EEE askToChat ' + student.connectionId);
  const connection = this.teacherFeedbackService.connectionList.find(con => con.connectionId === student.connectionId);
  if (connection){
      // Ettore
      const item = this.chatList.find(cl => cl.connection.connectionId === student.connectionId);
      if (!item){
       console.log('EEE studente NON presente');
       this.chatList.push({session: this.session, connection, nome: studente, msg: '', tipo: 'chat' });
       // invio messaggio e apro la chat dallo studente
       this.session
      .signal({
          data: insegnante, // Any string (optional)
          to: [connection], // Array of Connection objects (optional. Broadcast to everyone if empty)
          type: this.CHAT_START, // The type of message (optional)
      })
      .then(() => {
          console.log('First  Message successfully sent to student');
      })
      .catch((error) => {
          console.error(error);
      });
      } else {
          console.log('EEE studente già presente');
      }
      console.log('EEE trovata connessione ');
  }
}

askChatToTeacher() {
  // Ettore la connessione con il teacher
  // this.teacherFrom = this.session.remoteConnections
  const key = Object.keys(this.session.remoteConnections)[0];
  const teacherConnection = this.session.remoteConnections[key];
  // const teacherConnection = this.allCon.find(con=>con.connectionId == id)
  //if (teacherConnection){
     this.session
      .signal({
          data: this.curUser.name + ' ' + this.curUser.surname, // Any string (optional)
          to: [], // mando messaggio a tutti ma riceve solo utente teacher
          type: this.CHAT_REQUEST, // The type of message (optional)
      })
      .then(() => {
        this.snackBar.open('Request sent to the teacher successfully', null, { duration: 5000 });
      })
      .catch((error) => {
          this.snackBar.open('Error requesting teacher to chat', null, { duration: 5000 });
          console.error(error);
      });
  //}
}

creaChatConTeacher(connection: Connection, nome_teacher: string) {
  if (connection && this.chatList.length === 0){
      this.chatList.push({session: this.session, connection, nome: nome_teacher, msg: '', tipo: 'chat'});
  }
}
closeChat(connection: Connection){
  const idx = this.chatList.findIndex(ch=>ch.connection == connection )
  if (idx > -1) {
      this.chatList.splice(idx, 1);
  }
}

getAskChat(): number {
  return this.askChatList.length;
}

myAlert(message: string, action: string = 'Dismiss', duration: number = this.TIMEOUT_SHORT_MSG) {
  this.snackBar.open(message, action, {duration});
}

playAudio() {
  const audio = new Audio();
  audio.src = "../assets/sound/note.mp3";
  audio.load();
  audio.play();
}

okStudentChat(item){
  let idx = this.askChatList.findIndex(el => el === item);
  this.askChatList.splice(idx,1);
  item.tipo = 'chat';
  this.chatList.push(item);
  const insegnante = this.curUser.name + ' ' + this.curUser.surname;
  // invio messaggio e apro la chat dallo studente
  this.session
  .signal({
      data: insegnante, // Any string (optional)
      to: [item.connection], // Array of Connection objects (optional. Broadcast to everyone if empty)
      type: this.CHAT_START, // The type of message (optional)
  })
  .then(() => {
      console.log('First  Message successfully sent to student');
  })
  .catch((error) => {
      console.error(error);
  });
}


noStudentChat(item){
  const insegnante = this.curUser.name + ' ' + this.curUser.surname;
  this.session
  .signal({
      data: insegnante, // Any string (optional)
      to: [item.connection], // Array of Connection objects (optional. Broadcast to everyone if empty)
      type: this.CHAT_REFUSE, // The type of message (optional)
  })
  .then(() => {
      console.log('Teacher refuse chat');
  })
  .catch((error) => {
      console.error(error);
  });

  let idx = this.askChatList.findIndex(el=> el===item);
  this.askChatList.splice(idx,1);
}

sendDisconected (connection:Connection){
   let el = this.chatList.find(ch=>ch.connection == connection )
   
   if (!el) {
     el = this.askChatList.find(ch=>ch.connection == connection )
   }

   if (el){
     this.myAlert('Student ' + el.nome + ' disconnected')
     let idxChatList = this.chatList.findIndex(ch=>ch.connection == connection )
     this.chatList.splice(idxChatList, 1);
     let idxAskChatList = this.askChatList.findIndex(ch=>ch.connection == connection )
     this.askChatList.splice(idxAskChatList, 1);
    }
}

}

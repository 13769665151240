import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContentHelper } from 'src/app/helpers/contentHelper';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { SelectionElement, SelectionMode, SelectionService } from 'src/app/services/selection.service';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent {

  private _content: CourseContentDTO = undefined;

  @Input()
  courseAuthorId: number = undefined;

  @Input()
  courseMode: number = undefined;

  @Input()
  courseState: number = 0;

  @Input()
  contents: CourseContentDTO[] = [];

  @Input()
  get content(): CourseContentDTO { return this._content; }
  set content(value: CourseContentDTO) {
    this._content = value;
    
    this.author = `${this.content.author.name} ${this.content.author.surname}`;
    this.contentHelper = this.content?.contentHelper ?? new ContentHelper(this.router,
                                                                          this.dialog,
                                                                          this.courseService,
                                                                          this.auth.getCurrentUser(),
                                                                          this.content,
                                                                          this.contents,
                                                                          this.courseAuthorId,
                                                                          this.courseMode);
  }

  @Input()
  forceCustomOrder: boolean = false;

  @Output()
  onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentUser: User = undefined;
  contentHelper: ContentHelper = undefined;
  author: string = undefined;

  constructor(private router: Router,
              private dialog: MatDialog,
              private auth: AuthService,
              private courseService: CourseService,
              private selectionService: SelectionService,
              public darkService: DarkThemeService) {

    this.currentUser = this.auth.getCurrentUser();

  }

  typeTitle() {
    return this.contentHelper.typeTitle();
  }

  typeIcon() {
    return this.contentHelper.typeIcon();
  }

  thumbnail() {
    return this.contentHelper.headerImg();
  }

  goTo() {
    if (this.forceCustomOrder)
      this.contentHelper.forceCustomOrder();

    this.contentHelper.goTo();
  }

  goToProfile(): void {
    this.router.navigate(['/authorprofile', this.content.idAuthor]);
  }

  goToSurvey() {
    this.contentHelper.goToSurvey();
  }

  goToSurveyResults() {
    this.contentHelper.goToSurveyResultsForAuthor();
  }

  selectionToggle(event: any) {
    if(event.checked && !this.selectionService.isFull())
      this.selectionService.addElement(new SelectionElement(this.content.id, this.content.name, this.thumbnail(), this.content));
    else
      this.selectionService.removeElement(this.content.id);
  }

  selectionShow() {
    if (!this.selectionEnabled())
      return false;

    if (this.selectionService.mode === SelectionMode.folder)
      return this.content.type !== 100;

    if (this.selectionService.mode === SelectionMode.lessonContents ||
        this.selectionService.mode === SelectionMode.socialWallContents ||
        this.selectionService.mode === SelectionMode.masterContents)
        return this.content.type === 1 || this.content.type === 2;

    return false;
  }

  selectionEnabled() {
    return this.selectionService.showSelection;
  }

  selectionDisabled() {
    let check = this.selectionService.isFull();

    if (this.selectionService.mode === SelectionMode.folder)
      return check;

    return check || this.courseState === 0;
  }

  selectionChecked() {
    return this.selectionService.elementExist(this.content.id);
  }

}

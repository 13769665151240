import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { EduPlanContentDTO } from 'src/app/models/dto/eduPlanContentDTO';
import { EduPlanDTO } from 'src/app/models/dto/eduPlanDTO';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { EduPlanService } from 'src/app/services/eduplan.service';

@Component({
  selector: 'app-eduplan-showcase',
  templateUrl: './eduplan-showcase.component.html',
  styleUrls: ['./eduplan-showcase.component.scss']
})
export class EduplanShowcaseComponent implements OnInit {
  appName: string = null;
  currentUser: User = null;

  id: number = null;
  eduPlans: EduPlanShowcase[];
  
  tableColumns: string[] = ['title', 'state', 'expirationDate', 'action'];

  chartType: ChartType = 'pie';
  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right'
      }
    }
  }

  data = [{ data: [75, 25], label: '' }];
  labels = ['Done', 'Not done'];

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private eduPlanService: EduPlanService) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.appName = this.currentUser?.platformName ?? EnvironmentHelper.getConfigAuto(this.auth, 'title');

    this.getPlans();
  }

  getPlans() {
    this.eduPlanService.getLatestEduPlansOfUser()
      .subscribe(res => {
        this.eduPlans = res;

        this.eduPlans.forEach(plan => {
          plan.eduContents = new MatTableDataSource(plan.educationalPlanContent.sort((a, b) => a.sequenceIndex - b.sequenceIndex));
          plan.chartLabels = ['Done', 'Partially done', 'Not done'];

          let done = plan.educationalPlanStatus?.filter(ps => ps.completionStatus === 100).length;
          let partiallyDone = plan.educationalPlanStatus?.filter(ps => ps.completionStatus > 0 && ps.completionStatus < 100).length;
          let notDone = plan.educationalPlanStatus?.filter(ps => ps.completionStatus === 0).length +
                        plan.educationalPlanContent?.filter(pc => plan.educationalPlanStatus?.findIndex(ps => ps.idContent === pc.id) === -1).length;
                        
          plan.donePerc =  Math.round((done / plan.educationalPlanContent.length) * 10000) / 100;
          plan.chartData = [{
            data: [done, partiallyDone, notDone],
            label: '',
            backgroundColor: [this.getColor('green', 0.8), this.getColor('yellow', 0.8), this.getColor('red', 0.8)],
            hoverBackgroundColor: [this.getColor('green', 1), this.getColor('yellow', 1), this.getColor('red', 1)],
            borderColor: [this.getColor('green', 1), this.getColor('yellow', 1), this.getColor('red', 1)],
            hoverBorderColor: [this.getColor('green', 1), this.getColor('yellow', 1), this.getColor('red', 1)]
          }];
        });
      });
  }

  getTitle(content: EduPlanContentDTO) {
    if (content.idCourse != null)
      return content.course.name;
    if (content.idMaster != null)
      return content.master.name;

    return 'No title';
  }

  getStatus(content: EduPlanContentDTO) {
    let val = this.eduPlans
        .find(p => p.id === content.idEducationalPlan)?.educationalPlanStatus?.find(s => s.idContent === content.id)?.completionStatus;

    return val === 0 || val == null ? 'red' : val === 100 ? 'yellowgreen' : 'yellow';
  }

  getActionBtn(content: EduPlanContentDTO) {
    if (content.idCourse != null)
      return 'Go to course';
    if (content.idMaster != null)
      return 'Go to master';
  }

  goTo(content: EduPlanContentDTO) {
    if (content.idCourse != null)
      return this.router.navigate(['/course', content.idCourse], { queryParams: { page: 'sidenav' } });
    if (content.idMaster != null)
      return this.router.navigate(['/master', content.idMaster], { queryParams: { page: 'sidenav' } });
  }

  getColor(color: 'green' | 'yellow' | 'red', opacity: number = 1) {
    if (color === 'green')
      return `rgb(50, 205, 50, ${opacity})`;

    if (color === 'yellow')
      return `rgb(255, 205, 86, ${opacity})`;

    if (color === 'red')
      return `rgb(220, 20, 60, ${opacity})`;

    return null;
  }

}

class EduPlanShowcase extends EduPlanDTO {
  eduContents?: MatTableDataSource<EduPlanContentDTO>;
  chartData?: ChartDataset[];
  chartLabels?: string[];
  donePerc?: number;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Classroom } from 'src/app/models/classroom';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomDTO } from '../models/dto/classroomDTO';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  bckEndUrlSessions: string = `${environment.apiUrl}/api/classrooms/`;

  constructor(private http: HttpClient) { }

  getClassrooms(
    authorId?: number,
    type?: number,
    customerId?: number,
    oldObj?: boolean
    //userId?: number
  ): Observable<ClassroomDTO[] | Classroom[]> {
    let params = new HttpParams();

    if (authorId != undefined)
      params = params.append('authorId', authorId);

    if (type != undefined)
      params = params.append('type', type);

    if (customerId != undefined)
      params = params.append('customerId', customerId);

    if (oldObj != undefined)
      params = params.append('oldObj', oldObj);

    return this.http.get<ClassroomDTO[] | Classroom[]>(`${this.bckEndUrlSessions}`, { headers: Helper.getAuthHeader(), params: params });
  }

  deleteClassroom(id: number) {
    return this.http.delete(`${this.bckEndUrlSessions}${id}`, { headers: Helper.getAuthHeader() })
      .pipe(map(() => {
        console.log('deleted class');
      }))
  }

  updateClassroom(classroom: Classroom, id: number): Observable<ClassroomDTO> {
    return this.http.put<ClassroomDTO>
      (
        `${this.bckEndUrlSessions}${id}`,
        {
          Name: classroom.name,
          Description: classroom.description
        },
        {
          headers: Helper.getAuthHeader()
        }
      );
  }

  createClassroom(classroom: Classroom, idCustomer?: number, googleCaledar?: boolean): Observable<ClassroomDTO> {
    let params = new HttpParams();

    if (googleCaledar != undefined)
      params = params.append('calendar', googleCaledar);

    return this.http.post<ClassroomDTO>
      (
        `${this.bckEndUrlSessions}`,
        {
          name: classroom.name,
          description: classroom.description,
          idCustomer: idCustomer
        },
        {
          headers: Helper.getAuthHeader(),
          params: params
        }
      );
  }

  getUsersPerClassroom(id: number) {
    return this.http.get<any>(
      `${this.bckEndUrlSessions}${id}/users?UserFilterType=0&PageLimit=10000&PageIndex=1`,
      { headers: Helper.getAuthHeader() })
  }

  /* Non utilizzata
  public getUsersPerClass(id): Observable<Users> {
    this.setHeader();
    return this.http.get<Users>(`${this.bckEndUrlSessions}${id}/usersofclassroom?UserFilterType=0&PageLimit=10000&PageIndex=1`, { headers: Helper.getAuthHeader() })
  }*/

  removeUserFromClass(id: number, classId: number) {
    return this.http.delete<User[]>(`${this.bckEndUrlSessions}${classId}/user/${id}`, { headers: Helper.getAuthHeader() });
  }

  addUserToClass(id: number, classId: number) {
    return this.http.post(`${this.bckEndUrlSessions}${classId}/user/${id}`, undefined, { headers: Helper.getAuthHeader() });
  }
}

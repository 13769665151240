<h1 mat-dialog-title>{{ 'Edit Comment' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" style="margin-top:10px;" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Content' | translate }}</mat-label>
            <textarea matInput [formControl]="content" style="height: 100px;" required></textarea>
            <mat-error *ngIf="content.hasError('required')">
                {{ 'Comment required' | translate }}
              </mat-error>
        </mat-form-field>
    </div>
    
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" 
        [disabled]="hasErrors()"
        (click)="saveEdit()">Ok
    </button>
</div>


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.scss']
})
export class GenericPopupComponent implements OnInit {

  popupSettings: GenericPopupData = null;
  position:string;
  

  constructor(public dialogRef: MatDialogRef<GenericPopupComponent>,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.popupSettings = this.data;
  }

  closePopUp() {
    this.dialogRef.close(true);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  goToPage(page:string){
    this.router.navigate([`/${page}`]);
    this.dialogRef.close(true);
  }

}

export class GenericPopupData {
  public title: string;
  public body: string;
  public page: string;
  public btnAlign: string;
  public btnMarginTop:string;
  public titleMarginTop:string;
  public noButtons: boolean = false;
  public hideOkBtn: boolean = false;
  public hideCancelBtn: boolean = false;
  public showGoBtn: boolean = false;
}

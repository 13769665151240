import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";

export class EnvironmentVariables {
    apiUrl: string;
    production: boolean;
    mode: 'aixp' | 'saratoga';
    appVersion: string;
    appDate: string;
    supportsBidirectional: boolean;
    useNativePip4Student: boolean;
    loginBackgroundPicture: string;
    applicationName: string;
    applicationNameShort: string;
    applicationMotto: string;
    applicationFavicon: string;
    applicationCompanyName: string;
    applicationCompanyUrl: string;
    applicationCompanyPhone: string;
    applicationCompanyLogo: string;
    applicationCompanyLogoWhite: string;
    applicationPlayStoreUrl: string;
    applicationAppStoreUrl: string;
    applicationPrivacyPolicy: string;
    applicationCookiePolicy: string;
    mainPage: string;
    surveyJsKey: string;
    facebookAppId: string;
    googleClientId: string;
    configs: EnvironmentCustomerConfig[] = [];    
}

export class EnvironmentCustomerConfig {
    id: 'gd' | 'dt' | 'vr' | 'sl' | 'sc360' | 'fitxp' | 'hro';
    title?: string = undefined;
    url?: string = undefined;
    name?: string = undefined;
    phone?: string = undefined;
    icon?: string = undefined;
    logo?: string = undefined;
    logoDark?: string = undefined;
    logoLight?: string = undefined;
    backgroundImage?: string = undefined;
    backgroundImageMobile?: string = undefined;
    privacyPolicy?: string = undefined;
    cookiePolicy?: string = undefined;
    playStore?: string = undefined;
    appStore?: string = undefined;
}

export class EnvironmentHelper {

    public static isGD() {
        return window.location.origin.startsWith('https://gammadonna.aixp.io');
    }

    public static isDT() {
        return window.location.origin.startsWith('https://dentaltreyelearning.aixp.io');
    }

    public static isVR() {
        return window.location.origin.startsWith('https://viqirobot.aixp.io');
    }

    public static isSL() {
        return window.location.origin.startsWith('https://sunlightacademy.aixp.io');
    }

    public static isSC360() {
        return window.location.origin.startsWith('https://smartcabinet360.aixp.io');
    }

    public static isFitxp() {
        return window.location.origin.startsWith('https://fitxp.io');
    }

    public static isHRO() {
        return window.location.origin.startsWith('https://hroconsulting.aixp.io');
    }

    public static isDev() {
        return (window.location.origin.startsWith('http://dev.s-teach.com') || window.location.origin.startsWith('https://dev2.s-teach.com'));
    }

    public static getConfig(code: 'gd' | 'dt' | 'vr' | 'sl' | 'sc360' | 'fitxp' | 'hro',
                            param: 'title' | 'url' | 'name' | 'phone' | 'icon' | 'logo' | 'logoDark' | 'logoLight' | 'backgroundImage' | 'backgroundImageMobile' | 'privacy' | 'cookie' | 'playstore' | 'appstore') {

        let config = environment.configs.find(c => c.id === code);

        if (param === 'title')
            return config?.title ?? environment.applicationName;

        if (param === 'url')
            return config?.url ?? environment.applicationCompanyUrl;

        if (param === 'name')
            return config?.name ?? environment.applicationCompanyName;

        if (param === 'phone')
            return config?.phone ?? environment.applicationCompanyPhone;

        if (param === 'icon')
            return config?.icon ?? environment.applicationFavicon;

        if (param === 'logo')
            return config?.logo ?? environment.applicationCompanyLogo;

        if (param === 'logoDark')
            return config?.logoDark ?? environment.applicationCompanyLogo;

        if (param === 'logoLight')
            return config?.logoLight ?? environment.applicationCompanyLogoWhite;

        if (param === 'backgroundImage')
            return config?.backgroundImage ?? environment.loginBackgroundPicture;
        
        if (param === 'backgroundImageMobile')
            return config?.backgroundImageMobile ?? environment.loginBackgroundPicture;

        if (param === 'privacy')
            return config?.privacyPolicy ?? environment.applicationPrivacyPolicy;

        if (param === 'cookie')
            return config?.cookiePolicy ?? environment.applicationCookiePolicy;

        if (param === 'playstore')
            return config?.playStore ?? environment.applicationPlayStoreUrl;

        if (param === 'appstore')
            return config?.appStore ?? environment.applicationAppStoreUrl;

        return undefined;

    }

    public static getConfigAuto(auth: AuthService,
                                param: 'title' | 'url' | 'name' | 'phone' | 'icon' | 'logo' | 'logoDark' | 'logoLight' | 'backgroundImage' | 'backgroundImageMobile' | 'privacy' | 'cookie' | 'playstore' | 'appstore') {

        let code: 'gd' | 'dt' | 'vr' | 'sl' | 'sc360' | 'fitxp' | 'hro' = auth.isGD()
                                                                        ? 'gd'
                                                                        : auth.isDT()
                                                                        ? 'dt'
                                                                        : auth.isVR()
                                                                        ? 'vr'
                                                                        : auth.isSL()
                                                                        ? 'sl'
                                                                        : auth.isSC360()
                                                                        ? 'sc360'
                                                                        : auth.isFitxp()
                                                                        ? 'fitxp'
                                                                        : auth.isHRO()
                                                                        ? 'hro'
                                                                        : undefined;

        return EnvironmentHelper.getConfig(code, param);

    }

    public static isAixp() {
        return environment.mode === 'aixp';
    }

    public static isSaratoga() {
        return environment.mode === 'saratoga';
    }

}

<h1 mat-dialog-title>{{ (!isLessonPage() ? 'Schedule a new Lesson' : 'Perform a new Lesson') | translate }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="10px">
    <h2>{{ dateSubTitle }}</h2>
    <mat-form-field fxFlex color="primary">
        <mat-label>{{ 'Title' | translate }}</mat-label>
        <input type="text" matInput [formControl]="nameForm">
        <mat-error *ngIf="nameForm.hasError('required')">
            {{ 'The title is' | translate}} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" *ngIf="!isLessonPage()">
        <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'Start' | translate }}</mat-label>
            <input matInput [type]="showDateOrTime ? 'datetime-local' : 'time'" [formControl]="startDateForm">
            <mat-error *ngIf="startDateForm.hasError('required')">
                {{ 'The date is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex color="primary">
            <mat-label>{{ 'End' | translate }}</mat-label>
            <input type="time" matInput [formControl]="endDateForm" (input)="negativeSpan()">
            <mat-error *ngIf="endDateForm.hasError('required')">
                {{ 'The date is' | translate }} <strong>{{ 'Required' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="endDateForm.hasError('negativeSpan')">
                {{ 'The difference in dates must' | translate }} <strong>{{ 'not be negative' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
        <div fxLayout="column" fxFlex *ngIf="!isMasterLesson()">
            <mat-form-field color="primary">
                <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
                <mat-select matInput [formControl]="classroomForm" (selectionChange)="checkClassLesson()">
                    <mat-option *ngFor="let item of eventData.classrooms" [value]="item?.id">
                        {{item?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="lessonOpenInClass != null" style="font-size: 12px; margin-bottom: 10px;">
                {{ 'Please select another classroom' | translate }},<br>
                {{ 'there is a lesson already open by' | translate }}
                {{ lessonOpenInClass.teacher.surname + ' ' + lessonOpenInClass.teacher.name }}
                {{ 'in this classroom' | translate }}
            </mat-error>
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex>
            <mat-checkbox [formControl]="recordForm" name="record" id="record">
                {{ 'Record' | translate }}
            </mat-checkbox>
            <mat-checkbox *ngIf="recordForm.value" [formControl]="privateForm" name="private" id="private"
                [matTooltip]="'This setting will make the recording accessible only to the teacher' | translate">
                {{ 'Private recording' | translate }}
            </mat-checkbox>
            <mat-error *ngIf="recordForm.disabled" style="margin-top: 10px; font-size: 75%">
                {{ "Cloud storage is full, it's not possible to enable lesson recording" | translate }}
            </mat-error>
            <mat-checkbox *ngIf="isStandardLesson() && currentUser.smilyEnabled" [formControl]="smilyRecForm" name="smilyRecord" id="smilyRecord">
                {{ 'Smily record' | translate }}
            </mat-checkbox>
            <!--mat-error *ngIf="recordForm.value" style="margin-top: 10px; font-size: 75%">
                {{ 'The Scenario feature will be' | translate }} <strong>{{ 'disabled' | translate }}</strong>
            </mat-error-->
        </div>
    </div>
    <mat-form-field fxFlex color="primary">
        <mat-label>{{ 'Room' | translate }}</mat-label>
        <mat-select matInput [formControl]="roomForm">
            <mat-option [value]="0">{{ 'Virtual room' | translate }}</mat-option>
            <mat-option *ngFor="let item of eventData.rooms" [value]="item?.id">
                {{item?.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex *ngIf="isMasterLesson()" color="primary">
        <mat-label>{{ 'Teacher' | translate }}</mat-label>
        <mat-select matInput [formControl]="teacherForm">
            <mat-option *ngFor="let item of eventData.teachers" [value]="item?.id">
                {{item?.surname + ' ' + item?.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="teacherForm.hasError('required')">
            {{ 'The author is' | translate }} <strong>{{ 'Required' | translate }}</strong>
        </mat-error>
    </mat-form-field>
    
    <mat-form-field fxFlex color="primary">
        <mat-label>{{ 'Description' | translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize [formControl]="descriptionForm"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent"
        [disabled]="okBtnDisabled()"
        (click)="getResult()">Ok</button>
</div>

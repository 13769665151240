import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SocialWallContentDTO } from 'src/app/models/dto/SocialWallContentDTO';

@Component({
  selector: 'app-dialogaddlink',
  templateUrl: './dialogaddlink.component.html',
  styleUrls: ['./dialogaddlink.component.scss']
})
export class DialogaddlinkComponent implements OnInit {
  
  socialWallContent : SocialWallContentDTO = new SocialWallContentDTO();
  link = new UntypedFormControl('');
  desc = new UntypedFormControl('');
 
  constructor(public dialogRef: MatDialogRef<DialogaddlinkComponent>) { }

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

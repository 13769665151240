export class CustomerUpdateEntity {
    public Name: string;
    public Description: string;
    public Email: string;
    public IsDemo: number;
    public Authorized: number;
    public DemoDuration: number;
    public CoursesEnabled: number;
    public MastersEnabled: number;
    public SmilyEnabled: number;
    public MaxActiveUsers: number;
    public MaxPassiveUsers: number;
    public MaxCourseSize: number;
    public LowRes: number;
    public LogoText: string;
    public FontSize: string;
    public FontName: string;
    public StreamingEnabled: number;
    public CustomerCode: string;
    public StoragePath:string;
    public invoicePerMonth: number;
}

<!-- class="mat-elevation-z8" -->
<div >
    <div>
      <mat-form-field fxFlex color="primary">
        <mat-label>{{'search' | translate}}</mat-label>
        <input matInput type="text" (keyup)="doFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <table mat-table class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
        <td mat-cell *matCellDef="let row">{{row.id}}</td>
      </ng-container>
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'Student' | role | translate }}</th>
        <td mat-cell *matCellDef="let row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" style="padding: 5px">
            <div *ngIf="row">
                <app-profile-picture [url]="row.profilePictureUrl">
                </app-profile-picture>
            </div>
  
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="row">
                <span style="font-weight: bold;">{{ row.surname + ' ' + row.name }}</span>
              <span style="color: gray; font-size: 10px;" *ngIf="row.userSummary">{{ row.userSummary }}</span>
              <!-- <span>{{row.suveyAnwser.length}}</span> -->
            </div>
        </td>
    </ng-container>
  
      <!-- Name Column -->
  <!--     <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container> -->
  
      <!-- survey Column -->
      <ng-container matColumnDef="survey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Survey</th>
        <td mat-cell *matCellDef="let row">
          <button mat-stroked-button  *ngIf="row.surveyAnswer.length" (click)="showAnswer(row)" >
            {{ 'Show answer' | translate }}
            <mat-icon matListIcon>arrow_forward</mat-icon>
  
        </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator #paginator
        [length]="dataSrc?.data.length"
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[1, 4, 10, 20]"
        color="primary">
    </mat-paginator>
</div>

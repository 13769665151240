<h2 mat-dialog-title>{{ 'People who liked this' | translate }}</h2>
<mat-dialog-content class="mat-typography custom-dialog" style="display:flex;flex-direction: column;">
  <mat-list role="list" *ngFor="let item of userArr" style="margin-bottom:-20px;">
    <mat-list-item role="listitem" style="margin:0px;">
      {{ item.name | translate }}
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'Close' | translate }}</button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-streaming-close-popup',
  templateUrl: './streaming-close-popup.component.html',
  styleUrls: ['./streaming-close-popup.component.scss']
})
export class StreamingClosePopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StreamingClosePopupComponent>) { }

  ngOnInit() {
  }

  closePopUp() {
    this.dialogRef.close(true);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
}

export class joinLessonConfig {
    public sourceNumber: number;
    public quality: string;
    public videoEnabled: boolean;
    public audioEnabled: boolean;
    public audioDevice: string;
    public source1: string;
    public video1: string;
    public source2: string;
    public video2: string;
    public source3?: string;
    public video3?: string;
}

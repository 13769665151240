<div style="margin: 10px;">
  <mat-chip-listbox class="mat-chip-list-stacked" aria-orientation="vertical" *ngIf="dataSource" style="max-width: 300px;">
    <mat-chip-option *ngFor="let student of dataSource" [selectable]="false" [style.backgroundColor]="getColor(student.actualState)" style="width: 280px; max-width: 280px;">
      {{ student.name }} {{ student.surname }}

      <mat-icon class="warning"
        *ngIf="student.station != null && !student.station.workingState"
        [matTooltip]="('Problems on Smily' | translate) + ' ' + student.station.name"
        class="icon"
        matChipTrailingIcon>
        error
      </mat-icon>

      <button mat-icon-button
        *ngIf="student.actualState === 2 && teacherFeedbackService.connectionStatus === 0"
        (click)="askToChat(student)"
        [matTooltip]="'Start Chat' | translate"
        matChipTrailingIcon>
        <mat-icon class="icon">
          chat
        </mat-icon>
      </button>
      <button mat-icon-button
        *ngIf="student.actualState === 2 && !teacherFeedbackService.busy"
        [matTooltip]="'Start Video' | translate"
        (click)="askToStartVideo(student)"
        [disabled]="teacherFeedbackService.connectionStatus === 1"
        matChipTrailingIcon>
        <mat-icon class="icon">
          videocam
        </mat-icon>
      </button>
      <button mat-icon-button
        *ngIf="student.actualState === 2 && teacherFeedbackService.teacherConnectedWith == student.connectionId"
        (click)="askToCloseVideo(student)"
        [matTooltip]="'Close Video' | translate"
        matChipTrailingIcon>
        <mat-icon [ngStyle]="{'color': 'rgba(54, 162, 235, 1)'}" class="icon">
          videocam_off
        </mat-icon>
      </button>

      <mat-icon
        *ngIf="student.actualState != 0 && student.station != null"
        [matTooltip]="'Smily: ' + student.station.name + ' ' + ('Room' | translate) + ': ' + student.room.name"
        class="icon"
        matChipTrailingIcon>
        desktop_windows
      </mat-icon>
      <mat-icon
        *ngIf="student.actualState != 0 && student.station == null"
        [matTooltip]="'Remote Connection' | translate"
        class="icon"
        matChipTrailingIcon>
        settings_ethernet
      </mat-icon>

    </mat-chip-option>
  </mat-chip-listbox>
</div>
<div style="z-index: -1; position : fixed; display: flex; 
  align-self: flex-end; bottom : 5%; background: #ffd740; left: 0px; height: 56px; width: 300px;"
  (mouseover)="isOver = true"
  (mouseleave)="isOver = false">
</div>
<div style="z-index: -2; position : fixed; display: flex; 
  align-self: flex-end; bottom : 5%; left: 270px">
        <button mat-fab color="accent"
            class="menu-button"
            [class.mat-elevation-z2]="!isOver"
            [class.mat-elevation-z8]="isOver"
            (mouseover)="isOver = true"
            (mouseleave)="isOver = false"
            style="height: 56px; width: 56px;"
            (click)="closeNav()"
            [matTooltip]="'Close' | translate" matTooltipPosition="after">
            <mat-icon style="margin-right: -38px; color: black;">arrow_back_ios</mat-icon>
        </button>
    </div>

<!-- margin: 0; padding: 0; [style.left]="0px"
[style.top]="0px"-->

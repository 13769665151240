import { HttpHeaders } from "@angular/common/http";
import { SubscriptionUserDTO } from "src/app/models/dto/subscriptionUserDTO";
import { DEVICE_CONFIG_KEY } from "../app.component";
import { DeviceCloudSettings } from "../models/deviceCloudSettings";

export class Helper {

    public static isNullOrEmpty(value: string): boolean {
        return value == null || value.length === 0 || !value.trim() ? true : false;
    }

    public static clean(value: string): string {
        return this.isNullOrEmpty(value) ? null : value.trim();
    }

    public static getTruncateShowcase(value: string, nullValue: string, maxLength: number): string {
        return this.isNullOrEmpty(value) ? nullValue : value.length > maxLength ? (value.slice(0, maxLength - 3) + '...') : value;
    }

    public static getUserToken(): string {
        let token: string = null;

        try {
            token = sessionStorage.getItem('token');
        } catch(e) {
            //console.log('Cannot get user');
        }

        return token;
    }

    public static getAuthHeader(authToken: string = null): HttpHeaders {
        let headers = new HttpHeaders();
        let token = this.isNullOrEmpty(authToken) ? this.getUserToken() : authToken;

        if (!this.isNullOrEmpty(token))
            headers = headers.set('Authorization', `Bearer ${token}`);

        return headers;
    }

    public static isValidEnumValue(enumerator: any, value: any): boolean {
        return Object.values(enumerator).indexOf(value) !== -1;
    }

    public static getUrl(): string {
        return `${window.location.origin}${window.location.pathname}`;
    }

    public static getFileNameFromURI(uri: string): string {
        return decodeURIComponent(uri).split('?')[0].split('/').pop();
    }

    public static getFileExtension(filename: string): string {
        return filename.split('.').pop();
    }

    public static convertToTime(seconds: number): number[] {
        if (seconds != null) {
            let hours = seconds / 3600;
            let minutes = (seconds % 3600) / 60;
      
            return [Math.floor(hours), Math.floor(minutes)];
        }
        
        return [0, 0];
    }

    public static convertToHMS(milliseconds: number): string {
        let seconds = milliseconds / 1000;

        let hours = seconds / 3600;
        seconds = seconds % 3600;

        let minutes = seconds / 60;
        seconds = seconds % 60;

        hours = isNaN(hours) ? 0 : Math.floor(hours);
        minutes = isNaN(minutes) ? 0 : Math.floor(minutes);
        seconds = isNaN(seconds) ? 0 : Math.floor(seconds);
        
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    public static convertTimeToHMS(time: string): number[] {
        let res: number[] = [0, 0, 0];

        let splitted = time.split(':');
        for (var i = 0; i < splitted.length; i++) {
          if (i > 2)
            break;
    
          res[i] = parseInt(splitted[i], 10);
        }
        
        return res;
    }

    public static timeFromTimeSpan(timespan: string, unit: "milliseconds" | "seconds" | "minutes" | "hours" | "days" = "seconds"): number {
        if (Helper.isNullOrEmpty(timespan) || !unit)
            return 0;

        let splittedTime = timespan.split(':').map(t => Number(t)); // [0] ore, [1] minuti, [2] secondi
        if (splittedTime.length !== 3)
            return 0;

        let totalSeconds = splittedTime[2];

        totalSeconds += splittedTime[1] * 60;
        totalSeconds += splittedTime[0] * 60 * 60;

        if (totalSeconds <= 0)
            return 0;

        if (unit === 'milliseconds')
            return Math.round(totalSeconds * 1000);

        if (unit === 'seconds')
            return Math.round(totalSeconds);

        totalSeconds = totalSeconds / 60;

        if (unit === 'minutes')
            return totalSeconds;

        totalSeconds = totalSeconds / 60;

        if (unit === 'hours')
            return Math.round(totalSeconds);

        totalSeconds = totalSeconds / 24;

        if (unit === 'days')
            return Math.round(totalSeconds);

        return 0;
    }
    
    public static getImageBrightness($event: any, elementName: string): string {
        let img: any = document.getElementById(elementName);
        let colorSum = 0;
    
        // create canvas
        let canvas = document.createElement("canvas");
        let path = $event.composedPath();
    
        canvas.width = path[0].naturalWidth;
        canvas.height = path[0].naturalHeight;
    
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
    
        let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let data = imageData.data;
        let r, g, b, avg;
    
        for(let x = 0, len = data.length; x < len; x+=4) {
            if(data[x+3] == 0) { //Se è trasparente rgba
                r = 255;
                g = 255;
                b = 255;
            } else {
                r = data[x];
                g = data[x+1];
                b = data[x+2];
            }
        
            avg = Math.floor((r + g + b) / 3);
            colorSum += avg;
        }
    
        let brightness = Math.floor(colorSum / (canvas.width * canvas.height));
    
        return brightness > 170 ? 'black' : 'white';
    }

    public static formatBytes(bytes: number, decimals: number): string {

        //formatBytes(1024);       // 1 KB
        //formatBytes('1024');     // 1 KB
        //formatBytes(1234);       // 1.21 KB
        //formatBytes(1234, 3);    // 1.205 KB

        if(0 == bytes)
            return "0 Bytes";
        
        let c = 1024,
            d = decimals || 2,
            e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            f = Math.floor(Math.log(bytes) / Math.log(c));
    
        return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    }

    public static getDomain(value: string): string {
        let split = value.split('.');

        if (split.length > 2)
            split.shift();

        return split
            .join('.')
            .replace('https://', '')
            .replace('http://', '');
    }

    public static getRandomColor(): string {
        return `#${((1 << 24) * Math.random() | 0).toString(16)}`;
    }

    public static getColorBrightness(color: string) {
        if (!color)
            return 0;

        color = color.substring(1); // strip #

        let rgb = parseInt(color, 16); // convert rrggbb to decimal
        let r = (rgb >> 16) & 0xff; // extract red
        let g = (rgb >> 8) & 0xff; // extract green
        let b = (rgb >> 0) & 0xff; // extract blue

        return 0.2126 * r + 0.7152 * g + 0.0722 * b; // MIN 0 (dark) MAX 255 (bright). > 128 considered bright
    }

    public static sleep(ms: number): Promise<void> {
        return new Promise(r => setTimeout(r, ms));
    }

    public static getValidUsernameRegex(): RegExp {
        return /^[A-Za-z0-9-_.]{6,200}$/;
    }

    public static getValidPasswordRegex(): RegExp {
        return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.* ).{8,30}$/;
    }

    public static isJson(value: string): boolean {
        let res = null;

        try {
            res = JSON.parse(value);
        } catch (e) {
            return false;
        }

        return typeof res === 'object' && res != null;
    }

    public static getTimezone(date?: string | Date) {
        let d = new Date();

        if (date)
            d = typeof date === "string" ? new Date(date) : date;

        return Math.floor(-d.getTimezoneOffset() / 60);
    }

    //Funzioni di classi specifiche

    public static getLatestSubscriptionUser(subscriptionUsers: SubscriptionUserDTO[], userId: number): SubscriptionUserDTO {
        let userSubscriptions = subscriptionUsers.filter(p => p.idUser === userId);
        return userSubscriptions && userSubscriptions.length > 0 ? userSubscriptions.reduce((a, b) => new Date(a.stateLastChangedDate) > new Date(b.stateLastChangedDate) ? a : b) : null;
    }

    public static getDeviceConfig(): DeviceCloudSettings {
        try {

            let config = JSON.parse(localStorage.getItem(DEVICE_CONFIG_KEY));

            return config;

        } catch (e) {
            console.error(e);
        }

        return null;
    }

    public static getLessonErrorMessage(code: number): string {
        if (code === 403)
            return 'This user doesn\'t own this classroom';
        if (code === 409)
            return 'Current classroom has active lessons, please close them to continue further';
        if (code === 422)
            return 'Current teacher has active lessons, please close them to continue further';
        if (code === 503)
            return 'Media server error';
        if (code === 406)
            return 'Max active users limit reached for this customer, terminate a lesson to continue';
        if (code === 405)
            return 'Streamings are disabled for this customer';

        return 'Internal server error';
    }

}

<h1 mat-dialog-title>{{ 'Add Training Course' | translate }}</h1>
<div mat-dialog-content>
    <app-translations-form
        [label]="'Name' | translate"
        [disabled]="sending"
        [(translation)]="nameTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input matInput type="text" [formControl]="name">
            <mat-error *ngIf="name.hasError('required')">
                {{ 'Name is required' | translate }}
            </mat-error>
            <mat-error *ngIf="name.hasError('minlength')">
                {{ 'Name should be at least 4 characters' | translate }}
            </mat-error>
        </mat-form-field>

    </app-translations-form>
    <app-translations-form
        [label]="'Description' | translate"
        [disabled]="sending"
        [mode]="'textarea'"
        [(translation)]="descriptionTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea matInput [formControl]="description" style="height: 100px"></textarea>
        </mat-form-field>

    </app-translations-form>
    <app-translations-form
        [label]="'Header' | translate"
        [disabled]="sending"
        [(translation)]="headerTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Header' | translate }}</mat-label>
            <input matInput type="text" [formControl]="header">
        </mat-form-field>

    </app-translations-form>
    <app-translations-form
        [label]="'Footer' | translate"
        [disabled]="sending"
        [(translation)]="footerTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Footer' | translate }}</mat-label>
            <input matInput type="text" [formControl]="footer">
        </mat-form-field>

    </app-translations-form>
    <app-translations-form
        [label]="'Showcase Summary' | translate"
        [disabled]="sending"
        [mode]="'textarea'"
        [(translation)]="summaryTranslation">

        <mat-form-field color="primary" currentLanguageForm>
            <mat-label>{{ 'Showcase Summary' | translate }}</mat-label>
            <textarea matInput [formControl]="summary" style="height: 100px"></textarea>
        </mat-form-field>

    </app-translations-form>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Mode' | translate }}</mat-label>
            <mat-select matInput [formControl]="modalita">
                <mat-option [value]="0">
                    {{ 'Priority' | translate }}
                </mat-option>
                <mat-option [value]="1">
                    {{ 'Sequence' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!public.value && !everyone.value">
        <mat-selection-list #classroom [(ngModel)]="selectedClassrooms" [disabled]="sending" style="max-height: 200px;">
            <mat-list-option *ngFor="let class of classrooms" [value]="class.id">
                {{ class.name }}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div style="margin-bottom: 20px; margin-top: 20px;">
        <div>
            <mat-checkbox [formControl]="public" (change)="publicChanged(false)">{{ 'Public' | translate }}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="everyone" (change)="publicChanged(true)" *ngIf="currentUser.isAdmin">{{ 'Public to all customers' | translate }}</mat-checkbox>
        </div>
        <div>
            <mat-checkbox [formControl]="notifications">{{ 'Enable automatic notifications' | translate }}</mat-checkbox>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex>
            <mat-label>{{ 'Media' | translate }}</mat-label>
            <app-file-input
                [control]="imageSrc"
                [maxSize]="2000"
                [acceptedTypes]="'image/*'"
                [aspectRatio]="'8:5'"
                [maxHeight]="2000"
                [maxWidth]="2000"
                [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <app-picture-preview *ngIf="courseToEdit"
            [src]="courseToEdit.imageUrl"
            [tooltip]="'Current image' | translate"
            fxFlex="50">
        </app-picture-preview>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()" [disabled]="sending">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button *ngIf="!public.value && !everyone.value && !courseToEdit" color="accent"
        (click)="saveCourse()" [disabled]="!name.valid ||
                                        selectedClassrooms.length == 0 ||
                                        !imageSrc.valid ||
                                        sending">Ok</button>
    <button mat-flat-button *ngIf="(public.value || everyone.value) && !courseToEdit" color="accent"
        (click)="saveCourse()" [disabled]="!name.valid ||
                                        !imageSrc.valid ||
                                        sending">Ok</button>
    <button mat-flat-button *ngIf="!public.value && !everyone.value && courseToEdit" color="accent"
        (click)="saveCourse()" [disabled]="!name.valid ||
                                        selectedClassrooms.length == 0 ||
                                        sending">Ok</button>
    <button mat-flat-button *ngIf="(public.value || everyone.value) && courseToEdit" color="accent"
        (click)="saveCourse()" [disabled]="!name.valid ||
                                        sending">Ok</button>
</div>

<div *ngIf="sending" class="cssload-container" style="top: 50%">
    <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
</div>

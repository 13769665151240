import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { SocialWallAdditionalCustomerDTO } from 'src/app/models/dto/SocialWallAdditionalCustomerDTO';
import { SocialWallBlogDTO } from 'src/app/models/dto/SocialWallBlogDTO';
import { SocialWallClassroomDTO } from 'src/app/models/dto/SocialWallClasroomDTO';
import { SocialWallContentDTO } from 'src/app/models/dto/SocialWallContentDTO';
import { SocialWallDTO } from 'src/app/models/dto/SocialWallDTO';
import { SocialWallLikeDTO } from 'src/app/models/dto/SocialWallLikeDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { Helper } from '../../helpers/helper';

@Injectable({
  providedIn: 'root'
})
export class WallService {
  bckEndUrlSocialWall = `${environment.apiUrl}/api/v2/SocialWall`;
  bckEndUrlSocialWallBlog = `${environment.apiUrl}/api/v2/SocialWallBlog`;
  bckEndUrlSocialWallLike = `${environment.apiUrl}/api/v2/SocialWallLike`;
  bckEndUrlSocialWallContent = `${environment.apiUrl}/api/v2/SocialWallContent`;
  bckEndUrlSocialWallAdditionalCustomer = `${environment.apiUrl}/api/v2/SocialWallAdditionalCustomer`;
  bckEndUrlSocialWallClassroom = `${environment.apiUrl}/api/v2/SocialWallClassroom`;

  constructor(private http: HttpClient, private translationService: TranslationService) { }

  getSocialWallAllChild (classroomId: number): Observable<SocialWallDTO[]> {
    let lang = this.translationService.getLanguage();
    return this.http.get<SocialWallDTO[]>(`${this.bckEndUrlSocialWall}/getSocialWallAllChild/${classroomId}/${lang}`,
      { headers: Helper.getAuthHeader() });
  }

  getSocialWallAllChildLoadMore (classroomId: number, id: number): Observable<SocialWallDTO[]> {
    let lang = this.translationService.getLanguage();
    return this.http.get<SocialWallDTO[]>(`${this.bckEndUrlSocialWall}/getSocialWallAllChildLoadMore/${classroomId}/${id}/${lang}`,
      { headers: Helper.getAuthHeader() });
  }

  /*
  getSocialWall() : Observable<SocialWallDTO[]> {
    let lang = this.translationService.getLanguage();
    return this.http.get<SocialWallDTO[]>(`${this.bckEndUrlSocialWall}/${lang}`);
  }
  */

  getSocialWallBlogBySocialWallId (socialWallId: number): Observable<SocialWallBlogDTO[]> {
    let lang = this.translationService.getLanguage();
    return this.http.get<SocialWallBlogDTO[]>(`${this.bckEndUrlSocialWallBlog}/BySocialWall/${lang}/${socialWallId}`,
      { headers: Helper.getAuthHeader() });
  }

  getSocialWallLikeBySocialWallId (socialWallId: number): Observable<SocialWallLikeDTO[]> {
    return this.http.get<SocialWallLikeDTO[]>(`${this.bckEndUrlSocialWallLike}/BySocialWall/${socialWallId}`,
      { headers: Helper.getAuthHeader() });
  }

  getUserWhoLike (body: UserDTO[]): Observable<UserDTO[]> {
    return this.http.post(`${this.bckEndUrlSocialWallLike}/getuserwsholike`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  getAllCustomer () {
    return this.http.get<CustomerDTO[]>(`${this.bckEndUrlSocialWall}/getAllCustomer`,
      { headers: Helper.getAuthHeader() });
  }

  postSocialWal (body: SocialWallDTO) {
    let lang = this.translationService.getLanguage();
    return this.http.post(`${this.bckEndUrlSocialWall}/${lang}/`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  postSocialWallComment (body: SocialWallBlogDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallBlog}`, body, { headers: Helper.getAuthHeader() })
  }

  postSocialWallLike (body: SocialWallLikeDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallLike}`, body, { headers: Helper.getAuthHeader() })
  }

  postSocialWallContent (body: SocialWallContentDTO) {
    let lang = this.translationService.getLanguage();
    return this.http.post(`${this.bckEndUrlSocialWallContent}/${lang}/`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  postSocialWallAdditionalCustomer (body: SocialWallAdditionalCustomerDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallAdditionalCustomer}`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  postSocialWallClassroom (body: SocialWallClassroomDTO) {
    return this.http.post(`${this.bckEndUrlSocialWallClassroom}`, body, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }

  deleteSocialWallLike (socialWallLikeId: number) {
    return this.http.delete(`${this.bckEndUrlSocialWallLike}/${socialWallLikeId}`, { headers: Helper.getAuthHeader() });
  }

  getHastags(): Observable<string[]> {
    return this.http.get<string[]>(`${this.bckEndUrlSocialWall}/hashTags`, { headers: Helper.getAuthHeader() });
  }

  deleteSocialWallPost(socialWallPostId: number) {
    return this.http.delete(`${this.bckEndUrlSocialWall}/${socialWallPostId}`, { headers: Helper.getAuthHeader() });
  }

  editSocialWallPost(id: number, socialWallPost: SocialWallDTO) {
    return this.http.put(`${this.bckEndUrlSocialWall}/${id}`, socialWallPost, {headers: Helper.getAuthHeader() });
  }

  deleteBlog(id: number) {
    return this.http.delete(`${this.bckEndUrlSocialWallBlog}/blog/${id}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }
  
  putBlog(id: number, blog: SocialWallBlogDTO) {
    return this.http.put(`${this.bckEndUrlSocialWallBlog}/blog/${id}`, blog, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
}
}

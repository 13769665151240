import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/helpers/myErrorStateMatcher';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyService } from 'src/app/services/survey.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-survey-template-dialog',
  templateUrl: './survey-template-dialog.component.html',
  styleUrls: ['./survey-template-dialog.component.scss']
})
export class SurveyTemplateDialogComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  dialogTitle:string;
  titleForm: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  descriptionForm: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  

  constructor(public dialogRef: MatDialogRef<SurveyTemplateDialogComponent >, @Inject(MAT_DIALOG_DATA) public data,
              private auth: AuthService,
              private surveyService:SurveyService) { }

  ngOnInit(): void {
    this.dialogTitle="Add survey template";
  }

  onNoClick(): void {
    this.dialogRef.close(); 
  }

  postSurvey(): void{
    const currentUser = this.auth.getCurrentUser();
    const survey= new SurveyDTO();
    survey.idAuthor=currentUser.id;
    survey.name=this.titleForm.value;
    survey.description=this.descriptionForm.value;
    survey.isTemplate = 1;
    survey.surveyJSON = '{}';


    this.surveyService.postSurvey(survey)
    .subscribe({
      next: res => {
        console.log(res)
        this.dialogRef.close(res); 
      },
      error: err =>{
        console.error(err)
        this.dialogRef.close(); 
      }
    });
  }

  okBtnDisabled() {
    return !this.titleForm.valid ||
           !this.descriptionForm.valid;
  }
}

import { Injectable } from '@angular/core';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Helper } from 'src/app/helpers/helper';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { environment } from 'src/environments/environment';
import { SurveyAnswerDTO } from 'src/app/models/dto/surveyAnswerDTO';
import { SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
  private bckEndUrlSurvey = `${this.bckEndUrlBaseUrl }/survey`;
  currentSurveyLesson: SurveyLessonDTO;
  teachers?: UserDTO[];
  masterId: number;

  constructor(private http: HttpClient) { }

  public getSurveys(): Observable<SurveyDTO[]> {
    return this.http.get<SurveyDTO[]>(`${this.bckEndUrlSurvey}/all`, { headers: Helper.getAuthHeader() });
  }

  public getSurveysTeacher(userId: number): Observable<SurveyDTO[]> {
    let param = new HttpParams()
    .set('idAuthor', String(userId))
    return this.http.get<SurveyDTO[]>(`${this.bckEndUrlSurvey}/all`, { headers: Helper.getAuthHeader(),params: param });
  }

  public getSurvey(id): Observable<SurveyDTO> {
    return this.http.get<SurveyDTO>(`${this.bckEndUrlSurvey}/${id}`, { headers: Helper.getAuthHeader() });
  }

  public putSurvey(idSurvey: number,survey: SurveyDTO) {
    return this.http.put(`${this.bckEndUrlSurvey}/${idSurvey}`, survey, { headers: Helper.getAuthHeader() });
  }

  public postSurvey(survey: SurveyDTO) {
    return this.http.post(`${this.bckEndUrlSurvey}`, survey, { headers: Helper.getAuthHeader() });
  }

  public postSurveyAnswer(idLesson:number, surveyAnswerDTO: SurveyAnswerDTO ){
    // POST {backendURl}/api/v2/survey/answer/{lessonId} => Passare un oggetto SurveyAnswerDTO 
    //  con lo stato (completato 1, non completato 0), e il surveyJSON di risposta
    return this.http.post(`${this.bckEndUrlSurvey}/answer/${idLesson}`,surveyAnswerDTO, { headers: Helper.getAuthHeader() } )
  }

  public getSurveyAnswers(idLesson?:number, idUser?:number){
    // GET {backendURl}/api/v2/survey/answers => Questa API richiede i queryParameters idLesson e idUser. 
    // Possono essere sia combinati che non, almeno uno dei due però è richiesto 
    let param = new HttpParams()
    if (idLesson){
        param.set('idLesson', String(idLesson))
      }
    if (idUser){
        param.set('idUser', String(idUser));
    }

    return this.http.get(`${this.bckEndUrlSurvey}/answers`, { headers: Helper.getAuthHeader(), params: param } );
 }

 public getSurveyAnswersClassroom(idClassroom: number, idLesson: number){
    // Ho dovuto eseguire un cambiamento ad un API esistente, quella che ti dava le risposte di un survey.
    // Invece di essere GET answers/master/{idMaster}/{idLesson} è GET answers/{idClassroom}/{idLesson}
    // mi da tutti gli studenti e tutte le risposte
    return this.http.get<UserDTO[]>(`${this.bckEndUrlSurvey}/answers/${idClassroom}/${idLesson}`, { headers: Helper.getAuthHeader()} );
  }

  public putSurveyScore(idAnswer: number, myScore: number, myScoreNote: string ){
    // PUT {backendURl}/api/v2/survey/answer/{idAnswer} 
    // => Richiede solo lo Score(int) e ScoreNote(string). Bisogna essere teacher della lezione per poter fare la put
    const score ={score: myScore, scoreNote: myScoreNote};
    return this.http.put(`${this.bckEndUrlSurvey}/answer/${idAnswer}`, score , { headers: Helper.getAuthHeader() })
  }

  public getSurveyScore(idLesson: number){
    // GET {backendURl}/api/v2/survey/answer/{idLesson} 
    // => Questa viene eseguita dall'utente interessato(da lì ricavo il suo idUser) e gli restituisce la risposta che ha dato per quella lezione
    return this.http.get<any>(`${this.bckEndUrlSurvey}/answer/${idLesson}`, { headers: Helper.getAuthHeader()} );
  }

  public getStudentSurvey(idLesson: number){
    // Ok allora con questa (GET {backendURl}/api/v2/survey/answer/{idLesson}
    // => Questa viene eseguita dall'utente interessato(da lì ricavo il suo idUser)) ti do anche l'oggetto survey dentro
    return this.http.get<any>(`${this.bckEndUrlSurvey}/answer/${idLesson}`, { headers: Helper.getAuthHeader()} );
  }


  public AddSurveyToLesson(lessonSurvey: SurveyLessonDTO){
  /* 
  Ho fatto l'API per aggiungere una lezione survey al momento:
  POST {backendUrl}/api/v2/lessons/teacher/now => passare un oggetto LessonSessionDTO con almeno name, classId, teacherId, startPlanned, endPlanned e il surveyDTO
  (startPlanned e endPlanned vengono rimpiazzate dal backend, servono solo per evitare errori del backend)
  */

    return this.http.post(`${this.bckEndUrlBaseUrl}/lessons/teacher`,lessonSurvey, { headers: Helper.getAuthHeader() } )
  }

}

<h1 mat-dialog-title>{{ (isOneToOne() ? 'Add documents' : 'Add preview') | translate }}</h1>
<div mat-dialog-content fxLayout="column" fxLayputGap="20px" style="width: 100%;">
    <div fxLayout="row" *ngIf="!isOneToOne()">
        <div fxFlex>
            <mat-label>{{ 'Video' | translate }}</mat-label>
            <app-file-input
                [control]="videoSrc"
                [maxSize]="5000000"
                [acceptedTypes]="'video/*,.mkv'"
                [rejectedErrorMessage]="('The file type must be a video and not exceed' | translate) + ' 5GB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <div *ngIf="master.videoPreviewUrl" fxLayoutAlign="center center" fxFlex>
            <button mat-flat-button color="warn" (click)="deletePreview(0)">
                {{ getHelper().getTruncateShowcase(videoName, 'No name', 20) }}
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex>
            <mat-label>{{ 'Document' | translate }} 1</mat-label>
            <app-file-input
                [control]="document1Src"
                [maxSize]="15000"
                [acceptedTypes]="'.pdf'"
                [rejectedErrorMessage]="('The file type must be a pdf and not exceed' | translate) + ' 15MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <div fxLayout="column" *ngIf="master.document1Url" fxLayoutAlign="center center" fxFlex>
            <button mat-flat-button color="warn" (click)="deletePreview(1)">
                {{ getHelper().getTruncateShowcase(document1Name, 'No name', 20) }}
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex>
            <mat-label>{{ 'Document' | translate }} 2</mat-label>
            <app-file-input
                [control]="document2Src"
                [maxSize]="15000"
                [acceptedTypes]="'.pdf'"
                [rejectedErrorMessage]="('The file type must be a pdf and not exceed' | translate) + ' 15MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <div fxLayout="column" *ngIf="master.document2Url" fxLayoutAlign="center center" fxFlex>
            <button mat-flat-button color="warn" (click)="deletePreview(2)">
                {{ getHelper().getTruncateShowcase(document2Name, 'No name', 20) }}
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex>
            <mat-label>{{ 'Document' | translate }} 3</mat-label>
            <app-file-input
                [control]="document3Src"
                [maxSize]="15000"
                [acceptedTypes]="'.pdf'"
                [rejectedErrorMessage]="('The file type must be a pdf and not exceed' | translate) + ' 15MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <div fxLayout="column" *ngIf="master.document3Url" fxLayoutAlign="center center" fxFlex>
            <button mat-flat-button color="warn" (click)="deletePreview(3)">
                {{ getHelper().getTruncateShowcase(document3Name, 'No name', 20) }}
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" *ngIf="isOneToOne()">
        <div fxFlex>
            <mat-label>{{ 'Document' | translate }} 4</mat-label>
            <app-file-input
                [control]="document4Src"
                [maxSize]="15000"
                [acceptedTypes]="'.pdf'"
                [rejectedErrorMessage]="('The file type must be a pdf and not exceed' | translate) + ' 15MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <div fxLayout="column" *ngIf="master.document4Url" fxLayoutAlign="center center" fxFlex>
            <button mat-flat-button color="warn" (click)="deletePreview(4)">
                {{ getHelper().getTruncateShowcase(document4Name, 'No name', 20) }}
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" *ngIf="isOneToOne()">
        <div fxFlex>
            <mat-label>{{ 'Document' | translate }} 5</mat-label>
            <app-file-input
                [control]="document5Src"
                [maxSize]="15000"
                [acceptedTypes]="'.pdf'"
                [rejectedErrorMessage]="('The file type must be a pdf and not exceed' | translate) + ' 15MB'"
                [deletedErrorMessage]="'Please select a file' | translate"
                style="width: 100%">
            </app-file-input>
        </div>
        <div fxLayout="column" *ngIf="master.document5Url" fxLayoutAlign="center center" fxFlex>
            <button mat-flat-button color="warn" (click)="deletePreview(5)">
                {{ getHelper().getTruncateShowcase(document5Name, 'No name', 20) }}
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="savePreview()">
        Ok
    </button>
</div>

<mat-card appearance="outlined" class="chat-card" cdkDrag>
    <mat-card-header>
        <mat-card-title>
            <span (click)="scrollToBottom()" style="margin-right: 10px;">{{altro_name}}</span> 
            <div class="ics">
                <button mat-icon-button (click)="pausaChat()">
                    <mat-icon>remove</mat-icon>
                </button>
                <button mat-icon-button (click)="closeChat()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-card-title>  
    </mat-card-header>
   <!--  <mat-card-subtitle>dove si vedono le parole</mat-card-subtitle> -->
    <mat-card-content *ngIf="!onPausa" >
        <div #chatContainer class="chat-container">
            <div class="chat-msg" *ngFor="let msg of messaggi"[ngClass]="{'chat-out':msg.id=='out','chat-in':msg.id=='in'}">
                {{msg.msg}}
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions *ngIf="!onPausa">
        <mat-form-field fxFlex="80">
            <mat-label>Leave a comment</mat-label>
            <textarea matInput 
            [(ngModel)]="msg_txt">
            </textarea>
          <mat-hint color="red">{{msg_error}}</mat-hint>
        </mat-form-field>
        <button mat-icon-button
            (click)="sendMessage()"
            [disabled]="msg_txt.length === 0 || !msg_txt.trim()"
            style="margin-left: 10px;">
            <mat-icon>chat</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>

<div class="component-main">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h2 *ngIf="!isStudent() && !isTeacher()" style="align-self: center;">{{ 'Lessons' | translate }}</h2>
        <mat-form-field color="primary" *ngIf="currentUser.isAdmin">
            <mat-label>{{ 'Customer' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedCustomer" (selectionChange)="resetLessons()">
               <mat-option [value]="-1">{{ 'All Customers' | translate }}</mat-option>
               <mat-option *ngFor="let item of customers" [value]="item.id">{{ item.name }}</mat-option>
           </mat-select>
        </mat-form-field>
        <h2 *ngIf="isStudent() || isTeacher()" style="align-self: center;">{{ 'My Lessons' | translate }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        
        <div fxFlex="70" fxLayout="row">
            <!-- <h3 fxLayoutAlign="start center" *ngIf="isStudent() || isTeacher()" style="margin-left: 10px;" fxFlex>{{'My Lessons' | translate}}</h3> -->
            <mat-form-field color="primary" style="margin-left: 10px">
                <mat-label>{{ 'Search' | translate }}...</mat-label>
                <input matInput [(ngModel)]="value" (ngModelChange)="resetLessons(false)">
                <button matSuffix mat-icon-button [disabled]="!value" [matTooltip]="'Search' | translate">
                    <mat-icon>search</mat-icon>
                </button>
                <button matSuffix mat-icon-button [disabled]="!value" [matTooltip]="'Clear' | translate" (click)="resetLessons()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>

            <div *ngIf="!isStudent()" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" fxFlex style="margin-right: 10px">
                <button mat-fab extended
                    (click)="easyLesson()"
                    [disabled]="!isTeacher() || !currentUser.streamingEnabled"
                    [style.background-color]="isTeacher() && currentUser.streamingEnabled ? '#5092CF' : ''"
                    [style.color]="isTeacher() && currentUser.streamingEnabled ? 'white' : ''"
                    style="width: 150px"
                    matTooltip="Easy Stream"
                    matTooltipPosition="below">
                    Easy Stream!
                </button>      
                <button mat-fab
                    (click)="getLessons()"
                    [matTooltip]="'Refresh' | translate"
                    matTooltipPosition="below"
                    color="primary">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button mat-fab
                    *ngIf="isTeacher()"
                    (click)="teacherStreamStuck()"
                    [matTooltip]="'Teacher Stuck' | translate"
                    matTooltipPosition="below"
                    color="warn">
                    <mat-icon>videocam_off</mat-icon>
                </button>
                <button mat-fab extended
                    (click)="openAddDialog()"
                    [disabled]="!isTeacher() || !currentUser.streamingEnabled"
                    [matTooltip]="'Add Live Lesson' | translate"
                    matTooltipPosition="below"
                    color="accent">
                    <mat-icon>add</mat-icon>
                    {{ 'Lesson' | translate }}
                </button>
                <button mat-fab extended
                    (click)="addSurvey()"
                    [disabled]="!isTeacher()"
                    [matTooltip]="'Add survey' | translate"
                    matTooltipPosition="below"
                    color="primary">
                    <mat-icon>add</mat-icon>
                    {{ 'Survey' | translate }}
                </button>
            </div>

        </div>
        <div *ngIf="scheduledLessons && (isStudent() || isTeacher())" fxFlex="30" fxLayoutAlign="start center" style="padding-left:3%;">
            <h3>{{'On the agenda' | translate}}</h3>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px">
        <!-- First Column -->
        <!--Admin and Customer Admin first column-->
        <div *ngIf="backupLessons" fxFlex="70">
            <div *ngIf="!isStudent() && !isTeacher(); else studentTeacherLessons" style="width: 100%;">
                <!-- Lista di lezioni -->
                <mat-accordion class="my-headers-align">
                    <mat-expansion-panel *ngFor="let lesson of lessons"  style="margin: 10px;" 
                        [style.background-color]="darkService.isSetDark ? '' : '#eef5f9'" 
                        [style.color]="darkService.isSetDark ? 'white !important' : ''">

                        <mat-expansion-panel-header>
                            <div fxLayout="column" fxFlex>
                                <mat-panel-title fxLayout="row" [style.color]="getTitleColor(lesson.type)">
                                    <span class="titolo-lezione" fxFlex>
                                        {{ lesson.name }}
                                    </span>
                                    <span *ngIf="lesson.masterType != null" fxLayoutAlign="end center" fxFlex style="font-size: 13px;">
                                        {{ (isOneToOneLesson(lesson) ? 'Personal Live Digital Academy' : 'Live Digital Academy') | translate }}
                                    </span>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <div class="textFont" fxFlex="20">
                                        {{ lesson.teacherName }} {{ lesson.teacherSurname }}
                                    </div>
    
                                    <div class="textFont" fxFlex>
                                        {{ (lesson.typeId === 4 ? lesson.startPlanned : lesson.startDate) | date: 'dd/MM/YYYY HH:mm'}} -
                                        {{ lesson.typeId === 4 ? (lesson.stopPlanned | date: 'HH:mm') : (lesson.stopDate ? (lesson.stopDate | date: 'HH:mm') : '??:??') }}
                                    </div>
                                    <div class="textFont" fxFlex>
                                        {{ lesson.classRoom }}
                                    </div>

                                    <div fxLayoutAlign="end center" fxFlex="30" [ngSwitch]="lesson.typeId">
                                        <button mat-icon-button *ngSwitchDefault (click)="goToLesson(lesson.id, lesson)" [disabled]="lesson.stopDate" [matTooltip]="'Go to lesson!' | translate">
                                            <mat-icon>
                                                play_circle_filled
                                            </mat-icon>
                                        </button>
        
                                        <ng-container *ngSwitchCase="4">
                                            <button mat-icon-button *ngIf="isStudent()" (click)="goToSurvey(lesson)" [disabled]="goToSurveyDisabled(lesson)" [matTooltip]="'Go to survey!' | translate">
                                                <mat-icon>
                                                    play_lesson
                                                </mat-icon>
                                            </button>
    
                                            <button mat-icon-button *ngIf="isStudent()" [disabled]="!lesson.score" (click)="goToSurveyResult(lesson)"  [matTooltip]="'View survey results' | translate">
                                                <mat-icon>
                                                    content_paste
                                                </mat-icon>
                                            </button>
                                        </ng-container>
        
                                        <!--ng-container *ngIf="!isStudent()"-->
                                        <ng-container>
                                            <button mat-icon-button (click)="closeLesson(lesson)" [disabled]="lesson.stopDate" [matTooltip]="'Close' | translate">
                                                <mat-icon>
                                                    close
                                                </mat-icon>
                                            </button>
        
                                            <button mat-icon-button color="warn" (click)="deleteLesson(lesson.id, lesson.name, lesson.recordingPlanned)" [disabled]="!lesson.stopDate" [matTooltip]="'Delete' | translate">
                                                <mat-icon>
                                                    delete
                                                </mat-icon>
                                            </button>
        
                                            <button mat-icon-button *ngIf="lesson.typeId !== 4; else surveyAnswers" (click)="openReport(lesson)" [disabled]="!lesson.stopDate || !lesson.classStudents" [matTooltip]="'Go to report' | translate">
                                                <mat-icon>
                                                    assessment
                                                </mat-icon>
                                            </button>
                                            <ng-template #surveyAnswers>
                                                <button mat-icon-button
                                                    [matTooltip]="'Show survey answer' | translate"
                                                    (click)="showResultSurvey(lesson)">
                                                    <mat-icon
                                                        [matBadgeHidden]="lesson.totalAnswers === 0" 
                                                        [matBadge]="lesson.totalAnswers">
                                                        people
                                                    </mat-icon>
                                                </button>
                                            </ng-template>
                                        </ng-container>
                                        <div *ngIf="lesson.recordingPlanned && !lesson.privateRecording" fxLayoutAlign="start start" fxFlex="20">
                                            <button mat-icon-button
                                                (click)="goToVideo(lesson)"
                                                [disabled]="!lesson.stopDate"
                                                [matTooltip]="(lesson.stopDate ? 'Recording available' : 'Recording planned') | translate">
                                                <!-- [style.color]="lesson.stopDate ? 'blue' : 'grey'" style="vertical-align: middle;" -->
                                                <mat-icon>
                                                    video_library
                                                </mat-icon>
                                            </button>
                                        </div>

                                        <!-- <button mat-icon-button *ngIf="lesson.recordingPlanned && ((!lesson.privateRecording && isStudent()) || !isStudent()); else fillRec"
                                            [matTooltip]="(lesson.stopDate ? 'Recording available' : 'Recording planned') | translate"
                                            [style.color]="lesson.stopDate ? 'red' : 'grey'" style="vertical-align: middle;">
                                            <mat-icon>
                                                fiber_manual_record
                                            </mat-icon>
                                        </button> -->
        
                                        <ng-template #fillRec>
                                            <div style="min-width: 40px;">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </mat-panel-description>
                            </div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px">
                                <div style="text-align: start; text-align: justify; white-space: pre-line;" fxFlex>
                                    {{ lesson.description }}
                                </div>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-paginator
                    *ngIf="getFilteredLesson(value).length > 0"
                    [length]="getFilteredLesson(value).length"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="changePage($event)"
                    class="mat-elevation-z3 lesson-paginator"
                    color="primary">
                </mat-paginator>
            </div>
            <!-- Student and Teacher first column --> <!-- 0 lezione, 1 lezione master, 2 survey -->
            <ng-template #studentTeacherLessons>
                <mat-accordion class="my-headers-align">
                    <mat-expansion-panel *ngFor="let lesson of lessons" style="margin: 10px;" 
                        [style.background-color]="darkService.isSetDark ? '' : '#eef5f9'" 
                        [style.color]="darkService.isSetDark ? 'white !important' : ''" 
                        (opened)="getLessonContents(lesson); getLessonSmilyRecordings(lesson)" >

                        <mat-expansion-panel-header>
                            <div fxLayout="column" fxFlex>
                                <mat-panel-title fxLayout="row" [style.color]="getTitleColor(lesson.typeId)">
                                    <span class="titolo-lezione" fxFlex>
                                        {{ lesson.name }}
                                    </span>
                                    <span *ngIf="lesson.masterType != null" fxLayoutAlign="end center" fxFlex style="font-size: 13px;">
                                        {{ (isOneToOneLesson(lesson) ? 'Personal Live Digital Academy' : 'Live Digital Academy') | translate }}
                                    </span>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <div class="textFont" fxFlex="20">
                                        {{ lesson.teacherName }} {{ lesson.teacherSurname }}
                                    </div>
    
                                    <div class="textFont" fxFlex>
                                        {{ (lesson.typeId === 4 ? lesson.startPlanned : lesson.startDate) | date: 'dd/MM/YYYY HH:mm' }} -
                                        {{ lesson.typeId === 4 ? (lesson.stopPlanned | date: 'HH:mm') : (lesson.stopDate ? (lesson.stopDate | date: 'HH:mm') : '??:??') }}
                                    </div>
                                    <div class="textFont" fxFlex>
                                        {{ lesson.classRoom }}
                                    </div>

                                    <div fxLayoutAlign="end center" fxFlex="30" [ngSwitch]="lesson.typeId">
                                        <button mat-icon-button *ngSwitchDefault (click)="goToLesson(lesson.id, lesson)" [disabled]="lesson.stopDate" [matTooltip]="'Go to lesson!' | translate">
                                            <mat-icon [class.play-btn]="verifyLessonActiveStreaming(lesson.id)">
                                                play_circle_filled
                                            </mat-icon>
                                        </button>
        
                                        <ng-container *ngSwitchCase="4">
                                            <button mat-icon-button *ngIf="isStudent()" (click)="goToSurvey(lesson)" [disabled]="goToSurveyDisabled(lesson)" [matTooltip]="'Go to survey!' | translate">
                                                <mat-icon>
                                                    play_lesson
                                                </mat-icon>
                                            </button>
    
                                            <button mat-icon-button *ngIf="isStudent()" [disabled]="!lesson.score" (click)="goToSurveyResult(lesson)"  [matTooltip]="'View survey results' | translate">
                                                <mat-icon>
                                                    content_paste
                                                </mat-icon>
                                            </button>
                                        </ng-container>
        
                                        <ng-container *ngIf="!isStudent()">
                                            <button mat-icon-button (click)="closeLesson(lesson)" [disabled]="lesson.stopDate" [matTooltip]="'Close' | translate">
                                                <mat-icon>
                                                    close
                                                </mat-icon>
                                            </button>
        
                                            <button mat-icon-button color="warn" (click)="deleteLesson(lesson.id, lesson.name, lesson.recordingPlanned)" [disabled]="!lesson.stopDate" [matTooltip]="'Delete' | translate">
                                                <mat-icon>
                                                    delete
                                                </mat-icon>
                                            </button>
        
                                            <button mat-icon-button *ngIf="lesson.typeId !== 4; else surveyAnswers" (click)="openReport(lesson)" [disabled]="!lesson.stopDate || !lesson.classStudents" [matTooltip]="'Go to report' | translate">
                                                <mat-icon>
                                                    assessment
                                                </mat-icon>
                                            </button>
                                            <ng-template #surveyAnswers>
                                                <button mat-icon-button
                                                    [matTooltip]="'Show survey answer' | translate"
                                                    (click)="showResultSurvey(lesson)">
                                                    <mat-icon
                                                        [matBadgeHidden]="lesson.totalAnswers === 0" 
                                                        [matBadge]="lesson.totalAnswers">
                                                        people
                                                    </mat-icon>
                                                </button>
                                            </ng-template>
                                        </ng-container>
        
                                        <button mat-icon-button *ngIf="lesson.recordingPlanned && ((!lesson.privateRecording && isStudent()) || !isStudent()); else fillRec"
                                            [matTooltip]="(lesson.stopDate ? 'Recording available' : 'Recording planned') | translate"
                                            [style.color]="lesson.stopDate ? 'red' : 'grey'" style="vertical-align: middle;">
                                            <mat-icon>
                                                fiber_manual_record
                                            </mat-icon>
                                        </button>
        
                                        <ng-template #fillRec>
                                            <div style="min-width: 40px;">&nbsp;</div>
                                        </ng-template>
                                    </div>
                                </mat-panel-description>
                            </div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">

                                <!-- Descrizione e Durata della lezione -->
                                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">

                                    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" [fxFlex]="lesson.stopDate != null ? 65 : ''"
                                        *ngIf="lesson.description">
                                        <span class="lessonInfoTitle">{{ 'Description' | translate }}</span>
                                        <div [innerText]="getLessonDescription(lesson.description)"></div>
                                    </div>

                                    <mat-divider [vertical]="true" *ngIf="lesson.description && lesson.stopDate != null"></mat-divider>

                                    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex
                                        *ngIf="lesson.stopDate != null">
                                        <span class="lessonInfoTitle">{{ 'Duration' | translate }}</span>
                                        <p>
                                            {{ 'The lesson lasted' | translate }} {{ dateDiff(lesson.startDate, lesson.stopDate) }}
                                            {{ 'of the' | translate }} {{ dateDiff(lesson.startPlanned, lesson.stopPlanned) }}
                                            {{ 'planned' | translate }}
                                        </p>
                                    </div>

                                </div>

                                <!-- Contenuti e bottone per visualizzare eventuali registrazioni -->
                                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">

                                    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex
                                        *ngIf="lesson.hasCourseContents">
                                        <span class="lessonInfoTitle">{{ 'Contents' | translate }}</span>
                                        <div fxLayout="row wrap">
                                            <mat-card appearance="outlined" *ngFor="let content of attachment"
                                                fxLayout="column"
                                                style="width: 200px; height: 220px; cursor: pointer; margin-top: 10px; margin-right: 10px;"
                                                class="mat-elevation-z2">
                                                <img mat-card-image [src]="content.headerImageUrl" (click)="goToAttachment(content)" loading="lazy" style="height: 100px; min-height: 100px; object-fit: cover;">
                                                <mat-card-header>
                                                    <mat-card-title class="truncate-text" style="max-width: 168px; font-size: 14px;">
                                                        {{ content.name }}
                                                    </mat-card-title>
                                                </mat-card-header>
                                                <div fxFlex></div>
                                                <mat-card-actions>
                                                    <button mat-raised-button color="accent"
                                                        (click)="goToAttachment(content)">
                                                        <mat-icon>launch</mat-icon>
                                                        <div class="truncate-text" style="max-width: 130px">{{ 'Go to content' | translate }}</div>
                                                    </button>
                                                </mat-card-actions>
                                            </mat-card>
                                        </div>
                                    </div>

                                    <mat-divider [vertical]="true"
                                        *ngIf="lesson.hasCourseContents && lesson.recordingPlanned && lesson.stopDate && ((!lesson.privateRecording && isStudent()) || !isStudent())"></mat-divider>

                                    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" [fxFlex]="lesson.hasCourseContents ? 35 : 100"
                                        *ngIf="lesson.recordingPlanned && lesson.stopDate && ((!lesson.privateRecording && isStudent()) || !isStudent())">
                                        <span class="lessonInfoTitle">{{ 'Recordings' | translate }}</span>
                                        <button mat-raised-button color="primary" (click)="goToVideo(lesson)">
                                            <mat-icon>launch</mat-icon> 
                                            {{ 'Go to recording' | translate }}
                                        </button>
                                    </div>

                                </div>

                                <!-- Elenco utenti con registrazioni provenienti dallo Smily -->
                                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px"
                                    *ngIf="userWithSimlyRec.length > 0 && isTeacher()">
                                    <span class="lessonInfoTitle">Smily</span>
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                        <app-profile-picture *ngFor="let usr of userWithSimlyRec"
                                            [url]="usr.profilePictureUrl"
                                            [text]="usr.surname + ' ' + usr.name"
                                            [hover]="true"
                                            [routerLink]="['/smily-recordings', lesson.id]"
                                            [queryParams]="{ userId: usr.id }">
                                        </app-profile-picture>
                                    </div>
                                </div>

                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-paginator
                    *ngIf="getFilteredLesson(value).length > 0"
                    [length]="getFilteredLesson(value).length"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[5, 10, 20]"
                    (page)="changePage($event)"
                    class="mat-elevation-z3 lesson-paginator"
                    color="primary">
                </mat-paginator>


                <div *ngIf="backupLessons.length === 0 && isStudent()">
                    <app-error-message customClass="warning" fxFlex="85" fxLayoutAlign="end center"
                        [errorTitle]="'You do not have any lessons' | translate"
                        [errorContent]="'There are no active lessons available for you' | translate"
                        [center]="false">
                    </app-error-message>
                </div>
    
                <div *ngIf="backupLessons.length === 0 && isTeacher()">
                    <app-error-message customClass="warning" fxFlex="90" fxLayoutAlign="end center"
                        [errorTitle]="'You do not have any lessons' | translate"
                        [errorContent]="'You can add one by clicking on the icon above' | translate"
                        [center]="false">
                    </app-error-message>
                </div>
            </ng-template>
        </div>
    
        <!---Second Column-->
        <!--Student and Teacher second column-->
        <div *ngIf="scheduledLessons && (isStudent() || isTeacher())" fxFlex="30">
            <div style="width: 90%;">
                <mat-accordion class="my-headers-align">
                    <mat-expansion-panel *ngFor="let lesson of scheduledLessons" style="margin-top: 10px;margin-left:5.5%; ">
                        <mat-expansion-panel-header style="height: 63px !important;">

                            <div fxLayout="column" fxFlex>
                                <mat-panel-title fxLayout="row">
                                    <div>
                                        <button mat-icon-button *ngIf="isTeacher()" 
                                           (click)="activateSheduledLesson(lesson)" 
                                           [disabled]="activateLessonDisabled(lesson)" [matTooltip]="'Activate the lesson!'| translate">
                                           <mat-icon>
                                           {{ lesson.typeId !== 4 ? 'play_circle_filled' : 'play_lesson' }}
                                           </mat-icon>
                                        </button>
                                    </div>
                                    <span fxLayoutAlign="start center" fxFlex>
                                        {{ lesson.name }}
                                    </span>
                                     <span fxLayoutAlign="end center" fxFlex style="font-size: 13px;">
                                        {{ lesson.classroom.name }}
                                    </span>
                                </mat-panel-title>
                                <mat-panel-description class="textFont">
                                    <span *ngIf="lesson.classroom.master != null" fxLayoutAlign="start" fxFlex>
                                        {{ (isOneToOneLesson(lesson) ? 'Personal Live Digital Academy' : 'Live Digital Academy') | translate }} - {{lesson.classroom.name}}
                                    </span>
                                    <span [style.color]="isTodayLesson(lesson) ? 'red' : '#979191'" fxLayoutAlign="end" fxFlex>
                                        {{lesson.startPlanned | date: 'dd MMMM YYYY'}}
                                    </span> 
                                </mat-panel-description>
                            </div>

                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>

                            <mat-grid-list cols="4" rowHeight="3em">
                                <mat-grid-tile [colspan]="1">
                                    <div class="divGrid" style="text-align: start;">
                                        <strong [style.color]="isTodayLesson(lesson) ? 'red' : ''">
                                            {{lesson.startPlanned | date: 'HH:mm'}} - {{lesson.endPlanned | date: 'HH:mm'}}
                                        </strong>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="2">
                                    <div class="divGrid" style="text-align: center;">
                                        <strong [style.color]="isTodayLesson(lesson) ? 'red' : ''">
                                            {{getHours(lesson.startPlanned, lesson.endPlanned)}}
                                        </strong>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1">
                                    <div class="divGrid" style="font-size: 14px; text-align: right; color: #5092CF">
                                        {{lesson.teacher.name}} {{lesson.teacher.surname}}
                                    </div>
                                </mat-grid-tile>
 
                                <!-- <mat-grid-tile [colspan]="3">
                                    <div class="divGrid" style=" text-align: start; font-weight: bold; font-size: 20px">
                                        {{(lesson.name.length>75)? (lesson.name | slice:0:75)+'...':(lesson.name) }}
                                    </div>
                                </mat-grid-tile> -->
                                <mat-grid-tile [colspan]="3">
                                    <div style="width: 100%; text-align: start; color: grey; font-size: 13px;  white-space: pre-line;">
                                        <span>{{lesson.description ? (lesson.description.length > 110 ? (lesson.description | slice:0:110) + '...': lesson.description) : null}}</span>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
    
    
                <div *ngIf="scheduledLessons.length == 0 && isTeacher()">
                    <app-error-message customClass="warning"
                        [errorTitle]="'You do not have any scheduled lessons' | translate"
                        [errorContent]="'You can add one by clicking on Calendar in the navigation bar' | translate"
                        [center]="false">
                    </app-error-message>
                </div>
    
                <div *ngIf="scheduledLessons.length == 0 && isStudent()">
                    <app-error-message customClass="warning"
                        [errorTitle]="'You do not have any scheduled lessons' | translate"
                        [errorContent]="'You can check the calendar for weeks to come' | translate" 
                        [center]="false">
                    </app-error-message>
                </div>
            </div>
        </div>
    </div>
</div>

<mat-card appearance="outlined" cdkDrag
    [cdkDragBoundary]="dragBoundary"
    [cdkDragDisabled]="!dragBoundary"
    (cdkDragStarted)="isDragging = true"
    (cdkDragEnded)="isDragging = false"
    style="min-width: 500px">

    <mat-card-header fxLayoutAlign="space-between center">
        <mat-card-title>
            {{ 'Participants' | translate }}
        </mat-card-title>
        <button mat-icon-button cdkDragHandle
            *ngIf="dragBoundary"
            [matTooltip]="'Move' | translate"
            [matTooltipDisabled]="isDragging">
            <mat-icon>open_with</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content>
        <table mat-table [dataSource]="participantsSource" matSort #matSort="matSort">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.name }} </td>
            </ng-container>
            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ getRole(element) | translate }}</td>
            </ng-container>
            <ng-container matColumnDef="province">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'City' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.province ?? '-' }}</td>
            </ng-container>
            <ng-container matColumnDef="qualification">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Qualification' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.qualification ?? '-' }}</td>
            </ng-container>
            <ng-container matColumnDef="telephone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Telephone' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.telephone ?? '-' }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="participantsSourceColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: participantsSourceColumns"></tr>
        </table>
        <mat-paginator #paginator [pageSize]="10" color="primary"></mat-paginator>
    </mat-card-content>

    <mat-card-actions>
        <button mat-stroked-button (click)="onClose.emit(true)">
            {{ 'Close' | translate }}
        </button>
    </mat-card-actions>

</mat-card>

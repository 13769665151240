import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { Helper } from 'src/app/helpers/helper';
import { TranslateService } from '@ngx-translate/core';

const LANGSTORAGE: string = 'language';

export const IT: string = 'it';
export const EN: string = 'en';
export const ES: string = 'es';
export const FR: string = 'fr';
export const DE: string = 'de';
export const RU: string = 'ru';
export const PT: string = 'pt';

export const LANGUAGES: Language[] = [
    { code: EN, description: 'English' },
    { code: IT, description: 'Italiano' },
    { code: ES, description: 'Español' },
    { code: FR, description: 'Français' },
    { code: RU, description: 'Pусский' },
    { code: PT, description: 'Português' }
];

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private _currentLang: string = EN; //Modificare solo tramite setLang()
    public get currentLang(): string { return this._currentLang; }

    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlTranslation = `${this.bckEndUrlBaseUrl}/translation`;
    
    constructor(private http: HttpClient, private translate: TranslateService) {
        translate.addLangs(LANGUAGES.map(l => l.code));
        translate.setDefaultLang(EN);

        this.setLang();
    }

    public isValidLanguage(lang: string): boolean {
        return lang === IT ||
               lang === EN ||
               lang === ES ||
               lang === FR ||
               lang === DE ||
               lang === RU ||
               lang === PT;
    }

    public setLang(lang?: string): Language {
        if (!lang)
            lang = localStorage.getItem(LANGSTORAGE);
        
        lang = this.isValidLanguage(lang) ? lang : EN;

        localStorage.setItem(LANGSTORAGE, lang);
        this.translate.use(lang);
        this._currentLang = lang;

        return LANGUAGES.find(l => l.code === lang);
    }

    public getLanguage(lang: string = this.currentLang): string {
        if (lang === IT)
            return 'it_IT';
        if (lang === ES)
            return 'es_ES';
        if (lang === FR)
            return 'fr_FR';
        if (lang === DE)
            return 'de_DE';
        if (lang === RU)
            return 'ru_RU';
        if (lang === PT)
            return 'pt_PT';
    
        return 'en_US';
    }

    // ---- API ---- //

    public getTranslationsOfUser(userId: number): Observable<TranslationDTO[]> {
        return this.http.get<TranslationDTO[]>(`${this.bckEndUrlTranslation}/user/${userId}`, { headers: Helper.getAuthHeader() });
    }

    public editTranslation(translationId: number, translation: TranslationEdit) {
        return this.http.put(`${this.bckEndUrlTranslation}/${translationId}`, translation, { headers: Helper.getAuthHeader() });
    }
}

export class Language {
    public code: string;
    public description: string;
}

import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-fitxp-login',
  templateUrl: './fitxp-login.component.html',
  styleUrls: ['./fitxp-login.component.scss']
})
export class FitxpLoginComponent {

  mode: 'login' | 'signup' = 'login';
  isMobile: boolean = false;
  completeLogo: string = EnvironmentHelper.getConfig('fitxp', 'logo');
  logo: string = EnvironmentHelper.getConfig('fitxp', this.themeService.isSetDark ? 'logoLight' : 'logoDark');
  backgroundImageUrl: string = `background-image: url(${EnvironmentHelper.getConfig('fitxp', 'backgroundImage')})`;

  constructor(private themeService: DarkThemeService,
              private deviceService: DeviceDetectorService,
              private navBar: NavBarService)
  {
    this.isMobile = this.deviceService.isMobile();
    this.navBar.hide('topbar');
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }
}

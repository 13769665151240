import { Component } from '@angular/core';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { IT, TranslationService } from 'src/app/services/translation.service';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';

@Component({
  selector: 'app-dentaltrey-login',
  templateUrl: './dentaltrey-login.component.html',
  styleUrls: ['./dentaltrey-login.component.scss']
})
export class DentaltreyLoginComponent {

    registerBtnText: string = 'Iscriviti a\nDentalTrey E-Learning';
    registeredText: string = 'Grazie per esserti iscritto a "DentalTrey E-Learning".\n\nTra pochi istanti riceverai un\'email di conferma all\'indirizzo di posta da te indicatoci.\nTi preghiamo di accedere a "DentalTrey E-Learning" dal tuo PC seguendo le istruzioni contenute nell\'email.\n\nA presto! Il team di DentalTrey';

    mode: 'login' | 'signup' = 'login';
    isMobile: boolean = false;
    logo: string = EnvironmentHelper.getConfig('dt', this.themeService.isSetDark ? 'logoLight' : 'logoDark');
    backgroundImage: string = EnvironmentHelper.getConfig('dt', 'backgroundImage');
    playStoreUrl: string = EnvironmentHelper.getConfig('dt', 'playstore');
    appStoreUrl: string = EnvironmentHelper.getConfig('dt', 'appstore');

    idCustomer: number = 39;
    customerCode: string = 'dentaltrey2022';
   
    constructor(
        private themeService: DarkThemeService,
        private translationService: TranslationService,
        private navBar: NavBarService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
        this.translationService.setLang(IT);
        this.themeService.onLogOut();
        this.navBar.hide('topbar');
    }

    changeMode(newMode: 'login' | 'signup') {
        this.mode = newMode;
    }

    isLoginMode() {
        return this.mode === 'login';
    }

    isSignUpMode() {
        return this.mode === 'signup';
    }

}

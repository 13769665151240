import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { SocialWallDTO } from 'src/app/models/dto/SocialWallDTO';
import { WallService } from '../service/wall.service';
import { Helper } from 'src/app/helpers/helper';
import linkifyHtml from 'linkify-html';

@Component({
  selector: 'app-wall-popup',
  templateUrl: './wall-popup.component.html',
  styleUrls: ['./wall-popup.component.scss']
})
export class WallPopupComponent implements OnInit {

  title: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(4)]);
  description: UntypedFormControl = new UntypedFormControl(Validators.required);
  header: UntypedFormControl = new UntypedFormControl(null);
  socialWallPostEdited: SocialWallDTO = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public socialWall: WallService,
              public snackBar: MatSnackBar,
              public translate: TranslateService,
              public dialogRef: MatDialogRef<WallPopupComponent>)
   { }

  ngOnInit(): void {

  this.socialWallPostEdited = this.data;
  //this.title.setValue(this.socialWallPostEdited.title);
  this.description.setValue(this.stripHtmlTagsDescription(this.socialWallPostEdited.postText));
  this.title.setValue(this.stripHtmlTags(this.socialWallPostEdited.title));
  //this.description.setValue(this.stripHtmlTags(description))
  this.header.setValue(this.getHtml(this.socialWallPostEdited.postText));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getDescription(text: string) {
    if (Helper.isNullOrEmpty(text))
      return text;
    let indexOfH3 = text.indexOf('</h3>');
    if (indexOfH3 !== -1) {
      return Helper.clean(text.substring(indexOfH3 + 5));
    } else {
      return Helper.clean(text);
    }
  }

  stripHtmlTagsDescription(html: string): string {
    let description = this.getDescription(html);
    let div = document.createElement("div");
    div.innerHTML = description;
    let textWithoutHtml = div.textContent || div.innerText || "";
    return textWithoutHtml;
  }

  stripHtmlTags(html: string): string {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  showHtml() {
    this.description.setValue(this.getDescription(this.socialWallPostEdited.postText));
  }

  getHtml(text:string) {
    let header = text.substring(0, text.indexOf("</h3>"));
    let headerWithoutHtml = header.replace(/(<([^>]+)>)/ig,"");
    return headerWithoutHtml;
  }

  hasErrors() {
    if (!this.title.valid || !this.description.valid)
      return true
    else
      return false
  }

  saveEdit() {

    let linkifyTitle = linkifyHtml(this.title.value, {
      defaultProtocol: 'https',
      target: "_blank"
    });

    this.socialWallPostEdited.title = linkifyTitle;
    
    let linkifyText = linkifyHtml(this.description.value, {
      defaultProtocol: 'https',
      target: "_blank"
    });
    
    this.socialWallPostEdited.postText = '<h3>' + this.header.value + '</h3>' + linkifyText;

    this.socialWall.editSocialWallPost(this.data.id,this.socialWallPostEdited)
      .subscribe( {next: async () => {
        this.dialogRef.close();
        this.snackBar.open(await firstValueFrom(this.translate.get('Post edited')), undefined, {duration: 3000});
      },
      error: err => {
        this.snackBar.open('Error editing post', undefined, {duration: 3000})
        console.log(err);
      }
    });
  }

}


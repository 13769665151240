import { LocationStrategy } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { AuthService } from 'src/app/services/auth.service';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { NavBarService } from 'src/app/services/nav-bar.service';

@Component({
  selector: 'app-mobile-warning',
  templateUrl: './mobile-warning.component.html',
  styleUrls: ['./mobile-warning.component.scss']
})
export class MobileWarningComponent implements OnInit, OnDestroy {

  private mode: 'login' | 'signup' = 'login';
  private os: 'ios' | 'android' | 'unknown' = 'unknown';

  msg: string | null = null;
  isFromApp: boolean = false;

  // access-form attributes
  @Input()
  idCustomer: number = undefined;

  // register-form attributes
  @Input()
  codeCustomer: string = undefined;

  @Input()
  showUsername: boolean = true;

  @Input()
  showDlgs: boolean = false;

  @Input()
  showPhone: boolean = false;

  // mobile-warning attributes
  @Input()
  showMobileApps: boolean = true;

  @Input()
  logo: string = EnvironmentHelper.getConfig(undefined, this.darkService.isSetDark ? 'logoLight' : 'logoDark');

  @Input()
  background: string = `background-image: url(${EnvironmentHelper.getConfig(undefined, 'backgroundImage')})`;

  @Input()
  registerBtnText: string = undefined;

  @Input()
  registeredText: string = 'Thank you for signing up. You can now login to the platform using using a laptop, desktop computer or tablet from: ' + window.origin;

  @Input()
  playStoreUrl: string = EnvironmentHelper.getConfig(undefined, 'playstore');

  @Input()
  appStoreUrl: string = EnvironmentHelper.getConfig(undefined, 'appstore');

  constructor(private auth: AuthService,
              private darkService: DarkThemeService,
              private navBar: NavBarService,
              private location: LocationStrategy,
              private device: DeviceDetectorService) {

    history.pushState(null, null, window.location.href);

    // check if back or forward button is pressed.
    this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
    });

  }

  ngOnInit(): void {
    this.navBar.hide('all');
    this.isFromApp = this.auth.fromApp;
  
    this.os = this.device.os?.toLocaleLowerCase().includes('android')
            ? 'android'
            : this.device.os?.toLocaleLowerCase().includes('ios')
            ? 'ios'
            : 'unknown';

    if (!this.registerBtnText)
      this.registerBtnText = this.codeCustomer ? 'Sign Up' : 'Sign Up existing organization';
  }

  ngOnDestroy(): void {
    this.navBar.show('topbar');
    this.navBar.show('bottombar');
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }

  onUserRegistered() {
    this.msg = this.registeredText;
  }

  requestPathHasValue() {
    return this.auth.requestPathHasValue();
  }

  isAndroid() {
    return this.os === 'android';
  }

  isIos() {
    return this.os === 'ios';
  }

  isUnknown() {
    return this.os === 'unknown';
  }

  isDev() {
    return EnvironmentHelper.isDev();
  }
}

<div class="component-main container">
    <div fxLayout="row" fxLayoutAlign="center start">
        <h2>{{ 'Active masters' | translate }}</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isAuthenticated()">
        
        <mat-form-field style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Search' | translate }}...</mat-label>
            <input matInput [(ngModel)]="search">
            <button matSuffix mat-icon-button [disabled]="!search" [matTooltip]="'Clear' | translate" (click)="search = null">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field style="margin-left: 30px; margin-top: 15px;" color="primary">
            <mat-label>{{ 'Digital Content Supplier' | translate }}</mat-label>
            <mat-select [formControl]="customer" (selectionChange)="onCustomerChange()">
                <mat-option [value]="0">{{ 'All Digital Content Supplier' | translate }}</mat-option>
                <mat-option *ngFor="let item of customers" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
       <!--  <mat-form-field appearance="legacy" style="margin-left: 30px; margin-top: 15px;">
            <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
            <mat-select [formControl]="classroom" (selectionChange)="onClassroomChange()">
                <mat-option [value]="0">{{ 'All Classroom' | translate }}</mat-option>
                <mat-option *ngFor="let item of classrooms" [value]="item?.id">
                    {{item?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>

    <div *ngIf="masters.length > 0" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">
        <app-showcase-card *ngFor="let master of masters | search: search: ['name', 'description', 'header', 'footer', 'summary']"
            [routeUrl]="'/master'"
            [routeId]="master.id"
            [queryParams]="{ page: 'showcase' }"
            [routeMessage]="'Go to master'"
            [imgUrl]="master.imageUrl"
            [topLeft]="getMasterState().getState(master.state)"
            [title]="master.name"
            [subtitle_extra]="master.header"
            [subtitle]="master.startDate && master.stopDate ? ((master.startDate | date: 'dd/MM/YYYY') +  ' - ' + (master.stopDate | date: 'dd/MM/YYYY')) : ''"
            [description]="master.description"
            [subscription]="master.subscription"
            [enableSelectionOn]="getSelectionEnabledMode()"
            [selectionEntity]="getSelectionEntity(master)"
            [compact]="compactMode"
            [enableHighlight]="compactMode">
        
            <app-showcase-card-author-info bodyBottom
                [author]="master.author"
                mode="author"
                padding="0 16px">
            </app-showcase-card-author-info>
        
        </app-showcase-card>
    </div>

    <div *ngIf="masters.length == 0">
        <app-error-message customClass="warning"
            [errorTitle]="'There are no masters' | translate"
            [errorContent]="'Currently there are none to show' | translate">
        </app-error-message>
    </div>
</div>

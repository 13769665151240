import { Component, OnInit } from '@angular/core';
import { CustomerDTO, CustomerStorage } from 'src/app/models/dto/customerDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { MatDialog } from '@angular/material/dialog';
import { DashboardPopupComponent } from './dashboard-popup/dashboard-popup.component';
import { ChartDataset, ChartOptions } from 'chart.js';
import { customerUsage } from 'src/app/models/customerUsage';
import { firstBy } from 'thenby';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { UntypedFormControl } from '@angular/forms';
import { CsvExporterService, StreamingTime } from 'src/app/services/csv-exporter.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point: {
        radius: 1,
        hitRadius: 1
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          color: "#6f6f6f"
        },
        ticks: {
          color: "#868585"
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          color: "#6f6f6f"
        },
        ticks: {
          color: "#868585"
        }
      }
    },
    plugins: {
      datalabels: { // This code is used to display data values
        display: false,
      },
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      }
    }
  };
  
  public lineChartType = 'line';
  public lineChartData: ChartDataset[][] = [[{ data: [0], label: '%' }], [{ data: [0], label: '%' }], [{ data: [0], label: '%' }]];
  public lineChartLabels: string[][] = [];

  currentUser: User = null;

  types: string[] = ['line','bar']
  nullGraph: boolean[] = [true, true, true];
  typeTime: string[] = ["", "", ""];
  activeUsersPerc: number = 0;
  remainingDays: number = 0;
  remainingDaysPerc: number = 0;
  expirationDate: Date;

  customerData: CustomerStorage = null;
  customersStorage: CustomerStorage[] = [];
  customers: CustomerDTO[] = [];
  chartTypeStreaming: UntypedFormControl  = new UntypedFormControl('');
  selectedType:string = 'line';

  constructor(private auth: AuthService,
    private calendar: CalendarService,
    private dialog: MatDialog,
    private exporter: CsvExporterService,
    private snackBar: MatSnackBar,
    public darkService: DarkThemeService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();
    
    this.getCustomers();
  }
 
  getCustomers() {
    this.calendar.getCustomerForAdmin()
      .subscribe(output => {
        this.customers = [];
        this.customers = output;

        this.getCustomersStorage();
      });
  } 

  getCustomersStorage() {
    this.calendar.getCurrentAzureStates()
      .subscribe(output => {
        this.customersStorage = [];
        this.customersStorage = output;
      });
  }

  getStorageValue(data: CustomerStorage) {
    return (data.currentSize / data.maxSize) * 100;
  }

  spinnerValue(value: number) {
    if (value.toFixed(1).split('.')[1] == "00")
      return value + "%";
    else
      return value.toFixed(1) + "%";
  }

  refresh() {
    this.getCustomers();
  }

  popupOpen(data: CustomerDTO, type: string) {
    if (!this.currentUser.isAdmin)
      return;

    const dialogRef = this.dialog.open(DashboardPopupComponent, {
      data: { customer: data, type: type}, width: '400px'
    });
  }

  expansionHeader(data: CustomerDTO) {
    this.setTime(data);
    this.getData(data.id);

    this.customerData = this.customersStorage.find(s => s.customer.id === data.id);
    this.activeUsersPerc = Math.round((data.activeUsers / data.maxPassiveUsers * 10000) / 100);
  }

  resetData(index: number) {
    this.nullGraph[index] = true;
    this.lineChartLabels[index] = [];
    this.lineChartData[index] = [{ data: [0], label: '%' }];
  }

  getData(id: number) {
    this.calendar.getCustomerStreaming(id)
      .subscribe({
        next: output => this.setChartData(output, 0),
        error: () => this.resetData(0)
      });

    this.calendar.getCustomerAccess(id)
      .subscribe({
        next: output => this.setChartData(output, 1),
        error: () => this.resetData(1)
      });

    this.calendar.getCustomerTotStreaming(id)
      .subscribe({
        next: output => this.setChartData(output, 2),
        error: () => this.resetData(2)
      });
  }

  setTime(data: CustomerDTO) {
    this.expirationDate = this.addDays(data.created, data.demoDuration);
    this.remainingDays = 0;
    this.remainingDaysPerc = 0;

    let diff = this.expirationDate.valueOf() - new Date().valueOf();
    if (diff > 0) {
      let timeSpent = data.demoDuration - (diff / (1000 * 60 * 60 * 24));

      this.remainingDays = Math.round(data.demoDuration - timeSpent);
      this.remainingDaysPerc = Math.round((this.remainingDays / data.demoDuration) * 100);
    }
  }

  setChartData(data: customerUsage[], index: number) {
    if (!data || data.length === 0) {
      this.resetData(index);
      return;
    }

    this.nullGraph[index] = false;

    data.sort(firstBy((d: customerUsage) => d.year, 1).thenBy((d: customerUsage) => d.month, 1));

    let yValues = [];
    let xValues = [];

    if (data.length === 1) {
      yValues.push(0);
      xValues.push(`${data[0].month === 1 ? 12 : data[0].month - 1}/${data[0].month === 1 ? data[0].year - 1 : data[0].year}`);
    }

    let minuteValues = data.map(d => d.totalSeconds / 60);
    let hoursValues = minuteValues.map(d => d / 60);

    if (hoursValues.filter(d => d <= 1).length >= data.length / 3) {
      this.typeTime[index] = "min";
      yValues = yValues.concat(minuteValues);
    } else {
      this.typeTime[index] = "hours";
      yValues = yValues.concat(hoursValues);
    }

    this.lineChartLabels[index] = xValues.concat(data.map(d => `${d.month}/${d.year}`));
    this.lineChartData[index] = [
      {
        data: yValues,
        label: this.typeTime[index],
        borderColor: 'rgba(103, 58, 183, 1)',
        backgroundColor: 'rgba(103, 58, 183, 0.5)'
      }
    ];
  }

  addDays(date: Date, days: number): Date {
    let temp = new Date(date);
    temp.setDate(temp.getDate() + days);

    return temp;
  }

  exportStreamingTimeToCsv(id:number,name:string){
    this.streamingTimeTransmitted(id,name);
    this.totalStreamingTime(id,name);
  }

  totalStreamingTime(id:number,name:string) {

    let data: StreamingTime[] = [];

    this.calendar.getCustomerTotStreaming(id)
      .subscribe({
        next: output => {
        if (output.length != 0) {
          output.forEach(u => {
            data.push({
              month: u.month,
              year: u.year,
              minutes: u.totalSeconds / 60,
              seconds: u.totalSeconds
            });
          });
        }

        this.exporter.exportDataToCsv(
          data,
          ["Year", "Month", "Minutes", "Seconds"],
          `Total streaming time of ${name}`
        );
      },
      error: err => {
        console.log(err);
        this.snackBar.open(err.status, undefined, { duration: 3000 });
      }
    })
  }

  streamingTimeTransmitted(id:number,name:string){
    let data: StreamingTime[] = [];

    this.calendar.getCustomerStreaming(id)
      .subscribe({
        next: output => {
        if (output.length != 0) {
          output.forEach(u => {
            data.push({
              month: u.month,
              year: u.year,
              minutes: u.totalSeconds / 60,
              seconds: u.totalSeconds
            });
          });
        }

        this.exporter.exportDataToCsv(
          data,
          ["Year", "Month", "Minutes", "Seconds"],
          `Streaming time trasmitted of ${name}`
        );
      },
      error: err => {
        console.log(err);
        this.snackBar.open(err.status, undefined, { duration: 3000 });
      }
    })
  }
  
}

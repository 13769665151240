import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  cookiePolicy: string;
  learnMore: string = 'https://www.garanteprivacy.it/faq/cookie';

  constructor(private auth: AuthService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.cookiePolicy = EnvironmentHelper.getConfigAuto(this.auth, 'cookie');
  }

  closeBanner() {
    localStorage.setItem('cookie_policy', 'true');
    this.snackBar.dismiss();
  }
}

<div fxLayout="column" fxLayoutGap="20px" class="component-main" *ngIf="dataSource">
    <h2 class="page-title">{{ 'Students attended the lesson' | role | translate }}</h2>
    <p class="lesson-name">
        {{ 'Lesson' | translate }}<b> {{ lesson.name }} </b> {{ 'of' | translate }} <a style="color: red">
            {{ lesson.startPlanned | date: 'dd MMMM YYYY' }}</a>
        {{ 'took place' | translate}} {{ 'from' | translate }} {{ lesson.startDate | date: 'HH:mm' }}
        {{ 'to' | translate }} {{ lesson.stopDate | date: 'HH:mm' }}
        ({{ 'planned from' | translate}} {{ lesson.startPlanned | date: 'HH:mm'}} {{'to' | translate }}
        {{ lesson.endPlanned | date: 'HH:mm' }}) {{ 'by' | translate }}
        <a style="color: red">{{ lesson.teacher.name + ' ' + lesson.teacher.surname }}</a>
        <!--Controllo timezone da rivedere-->
    </p>
    <div class="btn-group">
        <button mat-stroked-button class="card-button" (click)="goBack()" style="margin-right: 20px;">
            <mat-icon matListIcon>arrow_back</mat-icon>
            {{ 'Back' | translate }}
        </button>
        <button mat-fab color="primary" (click)="updatePage()"
            [matTooltip]="'Refresh' | translate" style="margin-right: 20px;">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-fab color="primary" (click)="lessonExportToCsv()"
            [matTooltip]="'Save csv' | translate">
            <mat-icon>save</mat-icon>
        </button>
    </div>

    <div fxLayout="row"  fxLayoutGap="20px">

        <div fxFlex="73" fxFlex.md="73" fxFlex.lg="73" fxFlex.xl="73" fxLayout="column" _fxLayoutAlign="start center" style="height: 70vh;">

            <div class="table-container mat-elevation-z8">
                <table mat-table [dataSource]="dataSource"matSort>
                    
                    <ng-container matColumnDef="surname">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
                        <td mat-cell *matCellDef="let element"> {{ element.user.surname }} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
                        <td mat-cell *matCellDef="let element"> {{ element.user.name }} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Start' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.startDate | date: 'HH:mm'}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'End' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.endDate | date: 'HH:mm'}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="timeInLessons">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Presence Time' | translate }} hh:mm:ss</th>
                        <td mat-cell *matCellDef="let element"> {{ element.timeInLessons | slice:0:8 }}
                            <mat-progress-bar mode="determinate"
                                [value]="getPercentage(lesson.startDate, lesson.stopDate, element.startDate, element.endDate)">
                            </mat-progress-bar>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="room">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Room' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.station?.room.name || 'Remote Connection' | translate }} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="station">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Station' | translate }}</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.station?.name || 'Remote Connection' | translate}} </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                </table>
            </div>
        </div>

        <div fxFlex="27" fxFlex.md="27" fxFlex.lg="27" fxFlex.xl="27"fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px" style="vertical-align: top;">
            <mat-card appearance="outlined" style="width: 93%;" class="mat-elevation-z8">
                <mat-card-title style="text-align: center; font-size: 16px;">
                    {{ 'The lesson lasted' | translate }} {{ lessonDuration }}
                    {{ 'of the' | translate }} {{ lessonPlannedDuration }}
                    {{ 'planned' | translate }}
                </mat-card-title>
                <mat-card-content>
                    <app-spinner-container class="center-item" color="primary" [diameter]="75" [strokeWidth]="5"
                        [value]="getPercentage(lesson.startPlanned, lesson.endPlanned, lesson.startDate, lesson.stopDate)"
                        [displayWith]="spinnerValue">
                    </app-spinner-container>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" style="width: 93%" class="mat-elevation-z8">
                <mat-card-title style="text-align: center; font-size: 16px;">
                    {{'The average attendance to the lesson is' | translate}}
                    {{ getAvgPercentage() }}%
                </mat-card-title>
                <mat-card-content>
                    <app-spinner-container class="center-item" color="primary" [diameter]="75" [strokeWidth]="5"
                        [value]="getAvgPercentage()" [displayWith]="spinnerValue">
                    </app-spinner-container>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { Connection, ConnectionEvent, Device, OpenVidu, Publisher, Session, SessionDisconnectedEvent, SignalEvent, StreamEvent, StreamManager } from 'openvidu-browser';
import { ConferenceService } from 'src/app/services/conference.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LessonService } from 'src/app/services/lessons.service';
import { SourceSelection } from './source-selection/source-selection.component';
import { ClientStream } from 'src/app/models/conference-session/clientStream';
import { ClientData } from 'src/app/models/conference-session/clientData';
import { SignalType } from 'src/app/models/conference-session/clientSignalType';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { Helper } from 'src/app/helpers/helper';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { CalendarService } from 'src/app/services/calendar.service';
import { SignalPriorityUpdate } from 'src/app/models/conference-session/signalPriorityUpdate';
import { SignalTempPresenter } from 'src/app/models/conference-session/signalTempPresenter';
import { SignalAskTempPresenter } from 'src/app/models/conference-session/signalAskTempPresenter';
import { SignalRaiseHand } from 'src/app/models/conference-session/signalRaiseHand';
import { CONFERENCE_DURATION_MINUTES, CONFERENCE_START_CHECK_INTERVAL, DEVICES_CONNECTED_CHECK_INTERVAL, MAX_OPERATION_LOCK_TIME, MIN_OPERATION_LOCK_TIME, PARTICIPANT_RESOLUTION, PRESENTER_RESOLUTION, PRIORITY_ADD_VALUE, PRIORITY_CHECK_TIMEOUT, PRIORITY_MAX_VALUE, PRIORITY_MIN_VALUE, PRIORITY_QUEUE_LENGTH, PRIORITY_REMOVE_VALUE, STREAMING_QUALITY_CHECK_INTERVAL } from 'src/app/models/conference-session/conferenceConstants';
import { Platform } from '@angular/cdk/platform';
import { VirtualRoomDTO } from 'src/app/models/dto/virtualRoomDTO';
import { SignalUserRoom } from 'src/app/models/conference-session/signalUserRoom';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { NetworkInformation } from 'src/app/helpers/networkInformation';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';

const ROOM_JOIN_CONFIG_KEY: string = 'roomJoinConfig';
const CHECK_PUBLISHER_CONNECTED_TIMEOUT: number = 2000; //ms

@Component({
  selector: 'app-conference-session',
  templateUrl: './conference-session.component.html',
  styleUrls: ['./conference-session.component.scss']
})
export class ConferenceSessionComponent implements OnInit, OnDestroy {

	id: number;
	currentUser: User;
	conference: ConferenceDTO;
	conferenceStartDate: Date;
	conferenceEndDate: Date;
	httpLink: string;

	OV: OpenVidu;
	session: Session;
	token: string;
	videoDevices: Device[] = [];
	audioDevices: Device[] = [];
	localParticipant: ClientStream;
	publisher: ClientStream;
	presenters: ClientStream[] = [];
	participants: ClientStream[] = [];
	localParticipantVideoDeviceId: string | false = null;
	localParticipantAudioDeviceId: string | false = null;
	conferenceInterval: any = null;
	checkConferenceStopDateInterval: any = null;
	priorityCheckTimeout: any = null;
	leaving: boolean = false;

	streamingQualityInterval: any = undefined;
  	streamingQualityValues: string[] = [];
	validStreamingQualities: string[] = ['3g', '4g'];

	OVShare: OpenVidu;
	sessionShare: Session;
	localParticipantShare: ClientStream;
	publisherShare: ClientStream;
	localParticipantShareVideoDeviceId: string = null;
	priorityPosition: number = 0;

	visualType: string;
	isFullScreen: boolean = true;
	hasScreenShareCapabilities: boolean = false;
	isRecording: boolean = false;
	togglingRecording: boolean = false;

	initialSelection: boolean = true;
	lockJoin: boolean = true;
	switchPublisherLock: boolean = false;
	publishingInfo: string = null;
	publisherName: string = null;
	publisherAlreadyPresent: boolean = true;

	newChatMessages: number = 0;
	newHandNotifications: number = 0;

	toolbarOpened: boolean = false;
	globalChatOpened: boolean = false;
	participantsListOpened: boolean = false;

	private _isSpeaking: boolean = false;
	private _isHandRaised: boolean = false;

	get isHandRaised(): boolean { return this._isHandRaised; }
	set isHandRaised(value: boolean) {

		if (value && this._isHandRaised !== value)
			this.updatePriority("add");

		this._isHandRaised = value;

	}

	virtualRooms: VirtualRoomDTO[] = [];

	virtualRoomId: number = undefined;
	room: LessonSessionDTO = undefined;
	
	selectedRoomData: VirtualRoomDTO = undefined;
	selectedUsers: number[] = [];
	connectedUsers: number[] = [];
	toolbarRoomsOpened: boolean = false;
	
	constructor(private routeActive: ActivatedRoute,
				private router: Router,
				private auth: AuthService,
				private conferenceService: ConferenceService,
				private videoSessionService: VideoSessionService,
				private lessonService: LessonService,
				private snackBar: MatSnackBar,
				private dialog: MatDialog,
				private translate: TranslateService,
				private navBar: NavBarService,
				private calendar: CalendarService,
				private platform: Platform) { }

	async ngOnInit(): Promise<void> {
		this.navBar.hide('all');
		this.auth.stopInactivityCheck();

		this.currentUser = this.auth.getCurrentUser();

		this.id = Number(this.routeActive.snapshot.paramMap.get('id'));

		if (this.routeActive.snapshot.queryParamMap.has('virtualRoomId'))
			this.virtualRoomId = Number(this.routeActive.snapshot.queryParamMap.get('virtualRoomId'));

		let joinConfigString = sessionStorage.getItem(ROOM_JOIN_CONFIG_KEY);
		let joinConfig = undefined;

		if (joinConfigString) {

			sessionStorage.removeItem(ROOM_JOIN_CONFIG_KEY);

			joinConfig = <SourceSelection>JSON.parse(joinConfigString);
			this.initialSelection = false;

		}

		this.OV = new OpenVidu();

		try {

			this.conference = await firstValueFrom(this.conferenceService.getConference(this.id));
	  
			this.conferenceStartDate = new Date(this.conference.lessonSession.startDate);
			this.conferenceEndDate = addMinutes(this.conferenceStartDate, CONFERENCE_DURATION_MINUTES);
		
			this.httpLink = `${window.location.origin}/login/?conference=${this.conference.httpLink}`;

			this.virtualRooms = this.conference.lessonSession.lessonVirtualRoom?.map(l => l.virtualRoom);

			if (!this.virtualRoomId) {
				
				if (this.isConferencePublisher() || this.isConferenceSubstitute())
					await this.videoSessionService.createSession(this.conference.idLesson);
		  
				//if (this.isPresenter())
				//	this.auth.stopInactivityCheck();
		  
				this.token = (await this.getToken())?.token;
				
				this.lockJoin = false;
		  
				this.checkConferenceIsFinished();

			} else {

				this.room = await firstValueFrom(this.conferenceService.getConferenceRoom(this.id, this.virtualRoomId));

				await this.videoSessionService.createSession(this.room.id);

				this.token = (await this.getToken())?.token;

				this.lockJoin = false;

				this.conferenceService.getConference(this.id).subscribe(res => {
					this.virtualRooms = res.lessonSession.lessonVirtualRoom?.map(l => l.virtualRoom);
				});

			}

		} catch (e) {
			console.error(e);
		  
			this.snackBar.open('conference not found', 'Dismiss', {
				duration: 5000,
				verticalPosition: 'top'
			});
		
			this.leave();
		}

		let userMedia: MediaStream = null;
		let tries: number = 0;
	
		while (!userMedia) {
	
			// Senza questo controllo il loop continua anche con pagina distrutta
			if (!this.OV)
				return;
	
			console.log(`read devices, try ${++tries}`);
	
			try {
	
				userMedia = await this.OV.getUserMedia({ audioSource: undefined, videoSource: undefined });

				userMedia.getTracks().forEach(t => t.stop());
	
			} catch (e) {
				console.error(e);
	
				this.snackBar.open('please connect a device and allow camera and microphone access to continue', 'Dismiss', {
					duration: DEVICES_CONNECTED_CHECK_INTERVAL,
					verticalPosition: 'top'
				});
		
				await Helper.sleep(DEVICES_CONNECTED_CHECK_INTERVAL);
			}
	
		}
	
		let devices = await this.OV.getDevices();
	
		this.videoDevices = devices.filter(d => d.kind === "videoinput");
		this.audioDevices = devices.filter(d => d.kind === "audioinput");

		window.addEventListener('resize', () => document.documentElement.style.setProperty('--vh', `${(window.innerHeight - 40) * 0.01}px`));

		if (joinConfig)
			this.joinConference(joinConfig);
	}

	async ngOnDestroy(): Promise<void> {
		if (this.isFullScreen)
			this.isFullScreen = false;

		if (this.conferenceInterval != null) {
			clearInterval(this.conferenceInterval);
			this.conferenceInterval = null;
		}

		if (this.checkConferenceStopDateInterval != null) {
			clearInterval(this.checkConferenceStopDateInterval);
			this.checkConferenceStopDateInterval = null;
		}

		if (this.priorityCheckTimeout != null) {
			clearTimeout(this.priorityCheckTimeout);
			this.priorityCheckTimeout = null;
		}

		await this.disposePublisher(this.localParticipantShare?.manager as Publisher);
		this.localParticipantShare = null;

		await this.disposePublisher(this.localParticipant?.manager as Publisher);
		this.localParticipant = null;

		this.sessionShare?.disconnect();
		this.session?.disconnect();

		this.presenters = [];
		this.participants = [];

		this.localParticipantVideoDeviceId = null;
		this.localParticipantAudioDeviceId = null;
		this.localParticipantShareVideoDeviceId = null;

		delete this.localParticipantShare;
		delete this.localParticipant;
		delete this.publisherShare;
		delete this.publisher;
		delete this.sessionShare;
		delete this.session;
		delete this.OVShare;
		delete this.OV;

		this.stopStreamingQualityCheck();
		this.auth.startInactivityCheck();
		this.auth.lostConnection = false;

		this.navBar.show('topbar');
		this.navBar.show('bottombar');
		this.dialog.closeAll();
	}

	async leave(fromUser: boolean = false, route: any[] = ['/conferences'], params?: any) {
		if (fromUser) {

			let isLeavingRoom: boolean = this.virtualRoomId && route[0] === '/conference';

			const dialogRef = this.dialog.open(GenericPopupComponent,
			{
				width: '400px',
				data: <GenericPopupData>{
					title: await firstValueFrom(this.translate.get(isLeavingRoom ? 'Leave room' : 'Leave conference')),
					body: await firstValueFrom(
						this.translate.get(
							isLeavingRoom ? 'Are you sure you want to leave room name?' : 'Are you sure you want to leave conference name?',
							{ value: (isLeavingRoom ? this.room?.name : this.conference?.name) ?? ''  }
						)
					)
				}
			});

			if (!(await firstValueFrom(dialogRef.afterClosed())))
				return;

		}

		if (route && route[0] === '/conference')
			sessionStorage.setItem(ROOM_JOIN_CONFIG_KEY, JSON.stringify(<SourceSelection>{
				videoDeviceId: this.localParticipantVideoDeviceId,
				audioDeviceId: this.localParticipantAudioDeviceId,
				videoEnabled: this.localParticipant?.manager?.stream?.videoActive ?? false,
				audioEnabled: this.localParticipant?.manager?.stream?.audioActive ?? false
			}));

		await this.leaveConferenceStream();
		this.router.navigate(route, { queryParams: params });
	}

	isExclusivePresenter() {
		return this.conference?.idMode === 1;
	}

	isSharedPresenters() {
		return this.conference?.idMode === 2;
	}

	isPublisher(clientData: ClientData = this.localParticipant) {
		return clientData?.role === 'publisher';
	}

	isPresenter(clientData: ClientData = this.localParticipant) {
		return clientData?.role === 'presenter';
	}

	isPublishing(userId: number = this.currentUser.id) {
		return this.publisher?.userId === userId;
	}

	isSharing(userId: number = this.currentUser.id) {
		return this.publisherShare?.userId === userId;
	}

	isPublisherNotPublishing() {
		return this.publisher != null && !this.isPublisher(this.publisher);
	}

	async startShare(deviceId: string) {
		if (!this.OVShare)
			this.OVShare = new OpenVidu();
		
		let prevStreamId: string = this.localParticipantShare
								 ? this.localParticipantShare.manager.stream.streamId
								 : null;

		await this.stopShare();

		this.sessionShare = this.OVShare.initSession();

		let res = await this.getToken();

		let clientData = new ClientData(this.currentUser.id,
										this.getUsername(),
										this.currentUser.profilePictureUrl,
										this.getRole(this.publisherAlreadyPresent),
										'share',
										deviceId === 'screen' ? 'screen' : 'camera');

		let videoSource: string | boolean | MediaStreamTrack = deviceId;
		let audioSource: string | boolean | MediaStreamTrack = false;

		try {

			if (deviceId === 'screen') {

				let screen = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
		
				videoSource = screen.getVideoTracks()[0];

				if (screen.getAudioTracks()?.length > 0)
					audioSource = screen.getAudioTracks()[0];
			}

			let localParticipantShare = this.OVShare.initPublisher(undefined, {
				audioSource: audioSource, // The source of audio. If undefined default microphone
				videoSource: videoSource, // The source of video. If undefined default webcam
				publishAudio: audioSource != null && audioSource != false, // Whether you want to start publishing with your audio unmuted or not
				publishVideo: true, // Whether you want to start publishing with your video enabled or not
				resolution: PRESENTER_RESOLUTION, // The resolution of your video
				frameRate: 25, // The frame rate of your video
				mirror: false
			});

			this.localParticipantShare = new ClientStream(localParticipantShare, clientData);
			this.localParticipantShareVideoDeviceId = deviceId;

			if (deviceId === 'screen') {

				localParticipantShare.once('accessDenied', async () => {
					console.log('Screen share access denied');
					
					await this.stopShare();
				});

				localParticipantShare.once('accessAllowed', async () => {
					console.log('Screen share access allowed');
			
					localParticipantShare.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', async () => {
						console.log('Screen share stop');
			
						await this.stopShare();
					});

					try {

						await this.sessionShare.connect(res.token, clientData);

						await this.sessionShare.publish(localParticipantShare);
				
						this.joinConferenceStream(this.sessionShare.connection.connectionId,
												  this.sessionShare.connection?.stream?.streamId,
												  2,
												  prevStreamId);

					} catch (e) {
						console.error(e);

						await this.stopShare();
					}
		
				});

			} else {

				await this.sessionShare.connect(res.token, clientData);
		
				await this.sessionShare.publish(localParticipantShare);
		
				this.joinConferenceStream(this.sessionShare.connection.connectionId,
										  this.sessionShare.connection?.stream?.streamId,
										  2,
										  prevStreamId);
		
			}

		} catch (e) {
			console.error(e);

			await this.stopShare();
		}

	}

	async stopShare() {
		if (!this.isSharing())
			return;

		if (this.localParticipantShare?.manager?.stream?.isLocalStreamPublished ?? false) {
			let connectionId: string = this.localParticipantShare.manager.stream.connection.connectionId;
			let streamId: string = this.localParticipantShare.manager.stream.streamId;

			//await this.sessionShare?.unpublish(<Publisher>this.localParticipantShare.manager);

			this.leaveConferenceStream(connectionId, streamId);
		}

		this.sessionShare?.disconnect();
		this.sessionShare = null;

		await this.disposePublisher(this.localParticipantShare?.manager as Publisher);

		this.localParticipantShare = null;
		this.localParticipantShareVideoDeviceId = null;

		this.publisherShare = null;
	}

	getUsername() {
		let value = `${this.currentUser.name} ${this.currentUser.surname}`;

		if (this.virtualRoomId)
			value += ` - in "${this.room?.name}"`;

		return value;
	}

	async joinConference(streamConfig: SourceSelection) {

		this.lockJoin = true;

		if (this.conference?.lessonSession.state !== 1 && (this.isConferencePublisher() || this.isConferenceSubstitute())) {

			try {

				await firstValueFrom(this.calendar.activateLesson(this.conference.idLesson));
				await this.ngOnInit();
				
			} catch (e) {
				console.error(e);

				this.snackBar.open(Helper.getLessonErrorMessage(e.status), 'Dismiss', {
					duration: 5000,
					verticalPosition: 'top'
				});

				this.lockJoin = false;

				return;
			}

			this.snackBar.open('Conference started', 'Dismiss', {
				duration: 3000,
				verticalPosition: 'top'
			});

		}

		this.InitOpenviduSession();

		this.publisherAlreadyPresent = await this.checkForPublisherAlreadyConnected();

		let clientData = new ClientData(this.currentUser.id,
										this.getUsername(),
										this.currentUser.profilePictureUrl,
										this.getRole(this.publisherAlreadyPresent),
										'subject',
										'camera');

		let localParticipant = this.OV.initPublisher(undefined, {
			audioSource: streamConfig.audioDeviceId, // The source of audio. If undefined default microphone
			videoSource: streamConfig.videoDeviceId, // The source of video. If undefined default webcam
			publishAudio: streamConfig.audioEnabled, // Whether you want to start publishing with your audio unmuted or not
			publishVideo: streamConfig.videoEnabled, // Whether you want to start publishing with your video enabled or not
			resolution: this.isConferencePublisher() || this.isConferenceSubstitute() || this.isConferencePresenter() ? PRESENTER_RESOLUTION : PARTICIPANT_RESOLUTION, // The resolution of your video
			frameRate: 25, // The frame rate of your video
			mirror: false 
		});

		this.localParticipantVideoDeviceId = streamConfig.videoDeviceId;
		this.localParticipantAudioDeviceId = streamConfig.audioDeviceId;

		this.hasScreenShareCapabilities = this.OV.checkScreenSharingCapabilities();

		(<StreamManager>localParticipant).on('publisherStopSpeaking', () =>  this._isSpeaking = false);
		(<StreamManager>localParticipant).on('publisherStartSpeaking', () => {

			this._isSpeaking = true;
			this.updatePriority("add");

		});

		this.localParticipant = new ClientStream(localParticipant, clientData);

        this.session.connect(this.token, clientData)
            .then(async () => {

				this.initialSelection = false;
				this.lockJoin = false;

				await this.session.publish(localParticipant);

				this.joinConferenceStream(this.session.connection.connectionId,
										  this.session.connection?.stream?.streamId,
										  1);

				clearInterval(this.conferenceInterval);
				this.conferenceInterval = null;

				clearInterval(this.checkConferenceStopDateInterval);
				this.checkConferenceStopDateInterval = null;

				if (!this.isPublisher() && !this.isPresenter())
					this.participantsListOpened = true;

				this.startStreamingQualityCheck();

            }).catch(error => {
                console.log('There was an error connecting to the session', error.code, error.message);
				this.ngOnInit();
            });

	}

	async changeSource(deviceId: string | false, type: 'video' | 'audio') {

		try {

			let audioDevice = type === 'audio' ? deviceId : this.localParticipantAudioDeviceId;
			let videoDevice = type === 'video' ? deviceId : this.localParticipantVideoDeviceId;

			let hasAudioActive = this.localParticipant.manager.stream.audioActive;
			let hasVideoActive = this.localParticipant.manager.stream.videoActive;

			await this.session.unpublish(this.localParticipant.manager as Publisher);

			await this.disposePublisher(this.localParticipant.manager as Publisher);

			this.localParticipant.manager = this.OV.initPublisher(undefined, {
				audioSource: audioDevice, // The source of audio. If undefined default microphone
				videoSource: videoDevice, // The source of video. If undefined default webcam
				publishAudio: hasAudioActive, // Whether you want to start publishing with your audio unmuted or not
				publishVideo: hasVideoActive, // Whether you want to start publishing with your video enabled or not
				resolution: this.isConferencePublisher() || this.isConferenceSubstitute() || this.isConferencePresenter() ? PRESENTER_RESOLUTION : PARTICIPANT_RESOLUTION, // The resolution of your video
				frameRate: 25, // The frame rate of your video
				mirror: false 
			});

			this.localParticipantAudioDeviceId = audioDevice;
			this.localParticipantVideoDeviceId = videoDevice;

			await this.session.publish(this.localParticipant.manager as Publisher);

		} catch (err) {
			console.error(err);

			this.snackBar.open(`error changing ${type} source`, 'Dismiss', {
				duration: 5000,
				verticalPosition: 'top'
			});
		}

	}

	askUserToBePresenter(userId: number, name: string) {
		let data: SignalAskTempPresenter = {
			destUserId: userId,
			destUserName: name,
			askingUserId: this.currentUser.id,
			askingUserName: this.getUsername(),
			enable: true,
			position: 0
		};

		this.sendData(JSON.stringify(data), [], SignalType.askTempPresenter);
	}

	setUserAsPresenter(userId: number, enable: boolean, connection?: Connection) {
		let data: SignalTempPresenter = {
		  	userId: userId,
		  	enable: enable,
			position: 0,
		  	activate: false,
			type: this.isExclusivePresenter() ? 'exclusive' : this.isSharedPresenters() ? 'shared' : 'all'
		};
	
		this.sendData(JSON.stringify(data), connection ? [connection] : [], SignalType.tempPresenter);
	}

	toggleHand(userId: number, raise: boolean) {
		let data: SignalRaiseHand = {
			userId: userId,
			raise: raise
		};

		this.sendData(JSON.stringify(data), [], SignalType.toggleHand);
	}

	getAllNotifications() {
		return this.newChatMessages + this.newHandNotifications;
	}

	getPresenterHeight() {
		let perc = 100;
		let gap = 0;

		if (this.presenters.length > 1) {
			perc /= this.presenters.length;
			gap = (5 * (this.presenters.length - 1)) / this.presenters.length;
		}

		return `calc(var(--vh, 1vh) * ${perc} - ${gap}px)`;
	}

	conferenceElapsedMinutes() {
		if (!this.conference)
			return 0;

		let minutes = differenceInMinutes(new Date(), this.conferenceStartDate);

		return minutes < 0
			 ? 0
			 : minutes;
	}

	conferenceRemainingMinutes() {
		if (!this.conference)
			return 0;

		let minutes = differenceInMinutes(this.conferenceEndDate, new Date());

		return minutes > 0
			 ? minutes
			 : 0;
	}

	getDevices(type: 'video' | 'audio', mode: 'subject' | 'share' | 'all') {
		let devices = type === 'video'
					? this.videoDevices
					: type === 'audio'
					? this.audioDevices
					: [];

		if (mode === 'subject')
			return devices.filter(d => d.deviceId !== this.localParticipantShareVideoDeviceId);

		if (mode === 'share')
			return devices.filter(d => d.deviceId !== this.localParticipantVideoDeviceId
									&& d.deviceId !== this.localParticipantAudioDeviceId);

		return devices;
	}

	isVideoOverride() {
		return !this.isPublisher()
			&& !this.isPresenter()
			&& !this.isPublishing()
			&& this.priorityPosition >= PRIORITY_QUEUE_LENGTH;
	}

	async kickParticipant(connectionId: string) {
		if (!connectionId)
		  return;
	
		let participant = this.publisher?.manager?.stream?.connection?.connectionId === connectionId
						? this.publisher
						: undefined;
	
		if (!participant)
		  	participant = this.presenters.find(p => p.manager?.stream?.connection?.connectionId === connectionId);
	
		if (!participant)
		  	participant = this.participants.find(p => p.manager?.stream?.connection?.connectionId === connectionId);
	
		if (!participant)
		  	return;
	
		const dialogRef = this.dialog.open(GenericPopupComponent,
		{
			width: '400px',
			data: <GenericPopupData>{
			  	title: await firstValueFrom(this.translate.get('Kick user')),
			  	body: await firstValueFrom(this.translate.get('Are you sure you want to kick user?', { name: participant.name }))
			}
		});
	  
		dialogRef.afterClosed().subscribe(async res => {
			if (!res)
			  	return;
	  
			this.videoSessionService.kickUser(connectionId)
				.catch(err => {
	
					console.error(err);
					this.snackBar.open(err.error.Message, undefined, { duration: 3000, verticalPosition: 'top' });
	
				});
		});
	}

	async toggleRecording(toggle: boolean) {

		const dialogRef = this.dialog.open(GenericPopupComponent,
		{
			width: '400px',
			data: <GenericPopupData>{
				title: await firstValueFrom(this.translate.get(toggle ? 'Start recording' : 'Stop recording')),
				body: await firstValueFrom(this.translate.get(toggle ? 'Are you sure to start the recording?' : 'Are you sure to stop the recording?'))
			}
		});

		dialogRef.afterClosed()
			.subscribe(async res => {

				if (!res)
					return;

				this.togglingRecording = true;

				try {

					toggle ?
					await this.videoSessionService.startRecording(this.conference.idLesson) :
					await this.videoSessionService.stopRecording(this.conference.idLesson);

					this.isRecording = toggle;

					this.snackBar.open(
						await firstValueFrom(this.translate.get(toggle ? 'Recording started' : 'Recording stopped')),
						undefined,
						{ duration: 3000, verticalPosition: 'top' }
					);

				} catch (err) {
					console.error(err);

					let msg = err.error.Message ?? await firstValueFrom(this.translate.get(toggle ? 'Cannot start recording' : 'Cannot stop recording'));

					this.snackBar.open(msg, undefined, { duration: 5000, verticalPosition: 'top' });
				}

				this.togglingRecording = false;

			});
	}

	lostConnection() {
		return this.auth.lostConnection;
	}

	private isConferencePublisher(userId: number = this.currentUser.id) {
		return this.conference?.lessonSession.teacherId === userId; 
	}

	private isConferenceSubstitute(userId: number = this.currentUser.id) {
		return this.conference?.conferencePresenter.findIndex(p => p.idPresenter === userId && p.role === ConferencePresenterRole.Substitute) !== -1;
	}

	private isConferencePresenter(userId: number = this.currentUser.id) {
		return this.conference?.conferencePresenter.findIndex(p => p.idPresenter === userId && p.role === ConferencePresenterRole.Presenter) !== -1;
	}

	private sendData(data: string, remoteConnections: Connection[], signalType: SignalType) {
		if (!remoteConnections || data == null)
			return;

		this.session.signal({ data: data, to: remoteConnections, type: signalType })
			.catch(err => console.error(err));
	}

	private async getToken() {
		try {

			return await firstValueFrom(this.conferenceService.generateTokenConference(this.id, this.virtualRoomId));

		} catch (e) {

			console.error(e);

			if (e.status === 507) {

				this.dialog.open(GenericPopupComponent,
				{
					width: '400px',
					data: <GenericPopupData>{
						title: await firstValueFrom(this.translate.get('Warning')),
						body: e.error,
						hideCancelBtn: true,
						btnAlign: 'center'
					}
				});

				return;

			}

			if (this.isConferencePublisher() || this.isConferenceSubstitute())
				return;

			let title = this.conference.lessonSession.state !== 1
					  ? 'Conference not started'
					  : 'Conference paused';

			let body = this.conference.lessonSession.state !== 1
				     ? 'The organizer has not started the conference yet. Wait here until the conference begins.'
					 : 'The organizer paused the conference. Wait here until the conference begins again.';

			const dialogRef = this.dialog.open(GenericPopupComponent, {
				width: '320px',
				height: '270px',
				autoFocus: false,
				hasBackdrop: true,
				disableClose: true,
				data: <GenericPopupData>{
					title: await firstValueFrom(this.translate.get(title)),
					body: await firstValueFrom(this.translate.get(body)),
					hideOkBtn: true,
					page: 'conferences',
					btnMarginTop: '10px',
					hideCancelBtn: true,
					btnAlign: 'center',
					showGoBtn: true
				}
			});
	
			if (!this.virtualRoomId)
				this.checkOnlineStreaming(dialogRef);	

		}

		return null;
	}

	private checkOnlineStreaming(dialogRef: any) {
		this.conferenceInterval = setInterval(() => {

			this.conferenceService.getOnlinePresenters()
				.subscribe({
					next: async presenters => {
						let presentersFiltered = presenters?.filter(p => p.lessonsId === this.conference.idLesson);

						if (this.conference.lessonSession.state === 1) {
							try {

								await firstValueFrom(this.conferenceService.generateTokenConference(this.id));

							} catch (err) {
								console.error(err);

								return;
							}
						}

						if (presentersFiltered.length > 0) {
							dialogRef.close();

							this.snackBar.open('Conference started', 'Dismiss', {
								duration: 3000,
								verticalPosition: 'top'
							});

							clearInterval(this.conferenceInterval);
							this.conferenceInterval = null;

							this.ngOnInit();
						}

					},
					error: err => console.error(err)
				});

		}, CONFERENCE_START_CHECK_INTERVAL);
	}

	private checkConferenceIsFinished() {
		if (this.checkConferenceStopDateInterval != null) {
			clearInterval(this.checkConferenceStopDateInterval);
			this.checkConferenceStopDateInterval = null;
		} 

		this.checkConferenceStopDateInterval = setInterval(() => {
			
			this.conferenceService.getConference(this.id)
				.subscribe({
					next: async conference => {
				
						if (conference.lessonSession.stopDate != null) {

							this.dialog.closeAll();
							clearInterval(this.conferenceInterval);
							this.conferenceInterval = null;

							const dialogRef = this.dialog.open(GenericPopupComponent, {
								width: '290px',
								height: '200px',
								autoFocus: false,
								hasBackdrop: true,
								disableClose: true,
								data: <GenericPopupData>{
									title: await firstValueFrom(this.translate.get('Conference finished')),
									body: await firstValueFrom(this.translate.get('The conference is over')),
									hideOkBtn: true,
									page: `conference-report/${this.conference.idLesson}`,
									btnMarginTop: '15px',
									hideCancelBtn: true,
									titleMarginTop: '-10px',
									btnAlign: 'center',
									showGoBtn: true
								}
							});

							clearInterval(this.checkConferenceStopDateInterval);
							this.checkConferenceStopDateInterval = null;
						}
					},
					error: err => console.error(err)
				});
			
		}, CONFERENCE_START_CHECK_INTERVAL);
	}

	private InitOpenviduSession() {

        this.session = this.OV.initSession();

        this.session.on('streamCreated', async (event: StreamEvent) => {
            console.warn('STREAM CREATED!');
			console.warn(event);

			let clientData = <ClientData>JSON.parse(event.stream.connection.data);

			if (clientData.hidden)
				return;

			if (clientData.userId === this.currentUser.id)
				return;

			if (clientData.mode === 'share') {

				if (this.publisherShare)
					this.publisherShare.manager = this.session.subscribe(event.stream, undefined);

				return;

			} else {

				if (this.publisher && this.publisher.userId === clientData.userId) {
					this.publisher.manager = this.session.subscribe(event.stream, undefined);
					return;
				}

				let index = this.participants.findIndex(p => p.userId === clientData.userId);

				if (index !== -1) {
					this.participants[index].manager = this.session.subscribe(event.stream, undefined);
					return;
				}
		
				index = this.presenters.findIndex(p => p.userId === clientData.userId);
		
				if (index !== -1) {
					this.presenters[index].manager = this.session.subscribe(event.stream, undefined);
					return;
				}

			}

        });

        this.session.on('streamDestroyed', async (event: StreamEvent) => {
            console.warn('STREAM DESTROYED!');

			let clientData = <ClientData>JSON.parse(event.stream.connection.data);

			if (clientData.hidden)
				return;

			if (this.publisherShare?.manager?.stream.streamId === event.stream.streamId) {
				this.publisherShare.manager = undefined;
				return;
			}

			if (this.publisher?.manager?.stream.streamId === event.stream.streamId) {
				this.publisher.manager = undefined;
				return;
			}
		
			let index = this.participants.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);
		
			if (index !== -1) {
				this.participants[index].manager = undefined;
				return;
			}
		
			index = this.presenters.findIndex(p => p.manager?.stream.streamId === event.stream.streamId);
		
			if (index !== -1) {
				this.presenters[index].manager = undefined;
				return;
			}

        });

        this.session.on('connectionCreated', async (event: ConnectionEvent) => {
			console.warn(event.connection.connectionId === this.session.connection.connectionId ?
						 'YOUR OWN CONNECTION CREATED!' :
						 'OTHER USER\'S CONNECTION CREATED!');

			let clientData = <ClientData>JSON.parse(event.connection.data);

			if (clientData.hidden)
				return;

			if (clientData.mode === 'share') {
	
				//Il connectionId di sessionShare risulta ancora a null in questo punto quindi identifico per userId
				let participant = clientData.userId === this.currentUser.id
								? this.localParticipantShare
								: new ClientStream(undefined, clientData);
		
				this.publisherShare = participant;
	
			} else {
	
				let participant = event.connection.connectionId === this.session.connection.connectionId
								? this.localParticipant
								: new ClientStream(undefined, clientData);
		
				if (this.isPublisher(clientData) && !this.publisherShare) {
		
					await this.setPublisher(participant);
			
				} else if (this.isPresenter(clientData) && this.presenters.findIndex(p => p.userId === clientData.userId) === -1) {
			
					this.presenters.push(participant);
			
				} else if (this.participants.findIndex(p => p.userId === clientData.userId) === -1) {
		
					this.participants.push(participant);
			
				}
		
				if (clientData.userId !== this.currentUser.id) {
		
					if (this._isHandRaised)
						setTimeout(() => this.toggleHand(this.currentUser.id, this._isHandRaised), MIN_OPERATION_LOCK_TIME);
			
					if (clientData.mode === "subject" && this.localParticipant)
						this.updatePriority("remove");
			
					// Se sto trasmettendo come publisher e non sono il publisher allora comunico che sto presentando io
					if (this.isPublishing() && !this.isPublisher())
						setTimeout(() => this.setUserAsPresenter(this.publisher.userId, true), MIN_OPERATION_LOCK_TIME);
			
				}
	
			}
        });

		this.session.on('connectionDestroyed', async (event: ConnectionEvent) => {
			console.warn(event.connection.connectionId === this.session.connection.connectionId ?
						 'YOUR OWN CONNECTION DESTROYED!' :
						 'OTHER USER\'S CONNECTION DESTROYED!');
	  
			let clientData = <ClientData>JSON.parse(event.connection.data);

			if (clientData.hidden)
				return;
	  
			if (clientData.mode === 'share') {
	  
			  	this.publisherShare = null;
	  
			} else {

				if (this.publisher?.userId === clientData.userId) {
					this.publisherLeft();

					await this.setPublisher(null);
				}
	  
				let index = this.participants.findIndex(p => p.userId === clientData.userId);
		
				if (index !== -1)
					this.participants.splice(index, 1);
		
				index = this.presenters.findIndex(p => p.userId === clientData.userId);
		
				if (index !== -1)
					this.presenters.splice(index, 1);

			}
	  
			if (!this.publisher && !this.publisherShare)
			  	await this.setPublisherReplacement();
			
		});

        this.session.on('sessionDisconnected', async (event: SessionDisconnectedEvent) => {
            console.warn('SESSION DISCONNECT!');

			if (event.reason !== "disconnect" && !this.leaving) {

				let msg = event.reason === "networkDisconnect"
						? await firstValueFrom(this.translate.get('No connection. Go to an area with a better connection'))
						: `Disconnected from conference: ${event.reason}`;
				
				this.snackBar.open(msg, 'Dismiss', {
					duration: 10000,
					verticalPosition: 'top'
				});

				this.conferenceService.getConference(this.id)
					.subscribe({
						next: res => {
							let route = undefined;
							
							if (!res.lessonSession.stopDate && this.virtualRoomId)
								route = ['/conference', this.id];

							if (res.lessonSession.stopDate)
								route = ['/conference-report', res.idLesson];

							this.leave(undefined, route);
						},
						error: err => {
							console.error(err);
							this.leave();
						}
					});
			
			}

			this.leaving = true;

        });

		this.session.on('recordingStarted', () => {
			console.warn('RECORDING STARTED');

			this.isRecording = true;
		});

		this.session.on('recordingStopped', () => {
			console.warn('RECORDING STOPPED');

			this.isRecording = false;
		});

		this.session.on('reconnecting', () => {
			console.warn('RECONNECTING!');
	  
			this.auth.lostConnection = true;
		});
	  
		this.session.on('reconnected', () => {
			console.warn('RECONNECTED!');
	  
			this.auth.lostConnection = false;
		});

		this.session.on(SignalType.askTempPresenter, async (event: SignalEvent) => {

			let data: SignalAskTempPresenter = JSON.parse(event.data);

			if (data.enable) {

				if (this.switchPublisherLock)
					return;

				this.switchPublisherLock = true;
				setTimeout(() => this.switchPublisherLock = false, MAX_OPERATION_LOCK_TIME);

				if (data.destUserId === this.currentUser.id) {

					let pubInfo = this.publishingInfo;

					this.publishingInfo = await firstValueFrom(this.translate.get('x invited you to present the conference', { user: data.askingUserName }));

					const dialogRef = this.dialog.open(GenericPopupComponent,
					{
						width: '400px',
						data: <GenericPopupData>{
							title: await firstValueFrom(this.translate.get('Present request')),
							body: this.publishingInfo
						}
					});

					let waitTimeout = setTimeout(() => dialogRef.close(false), MAX_OPERATION_LOCK_TIME);
				
					if (!(await firstValueFrom(dialogRef.afterClosed()))) {
						clearTimeout(waitTimeout);

						data.enable = false;
						this.sendData(JSON.stringify(data), [], SignalType.askTempPresenter);

						this.publishingInfo = pubInfo;

						return;
					}
	
					this.setUserAsPresenter(data.destUserId, true);

				} else if (data.askingUserId === this.currentUser.id) {

					this.snackBar.open(`request sent to ${data.destUserName}`, 'Dismiss', {
						duration: 5000,
						verticalPosition: 'top'
					});

				}

			} else {

				let msg = data.askingUserId === this.currentUser.id
						? `${data.destUserName} canceled the present request`
						: data.destUserId === this.currentUser.id
						? `present request from ${data.askingUserName} canceled`
						: null;

				if (msg != null)
					this.snackBar.open(msg, 'Dismiss', {
						duration: 5000,
						verticalPosition: 'top'
					});

			}

		});

		this.session.on(SignalType.tempPresenter, async (event: SignalEvent) => {

			let data: SignalTempPresenter = JSON.parse(event.data);
			let index = -1;

			let refUsers = this.isConferencePublisher(data.userId) || this.isConferenceSubstitute(data.userId) || this.isConferencePresenter(data.userId)
						 ? this.presenters
						 : this.participants;

			index = refUsers.findIndex(p => data.enable ? p.userId === data.userId : this.isPublisher(p));

			if (this.presenters.length > 0 && this.isExclusivePresenter())
				this.participants = this.participants.concat(this.presenters.splice(0, this.presenters.length));

			if (index === -1)
				return;

			if (this.publisher != null)
				((this.isPublisher(this.publisher) || this.isPresenter(this.publisher)) && this.isSharedPresenters() ? this.presenters : this.participants).push(this.publisher);

			await this.stopShare();

			let newPublisher = refUsers.splice(index, 1)[0];

			await this.setPublisher(newPublisher);

			this.snackBar.open(`${newPublisher.name} is presenting`, 'Dismiss', {
				duration: 5000,
				verticalPosition: 'top'
			});

		});

		this.session.on(SignalType.addUserToRoom, async (event: SignalEvent) => {

			let data: SignalUserRoom = JSON.parse(event.data);

			await this.leave(undefined, ['/conference', this.id], data.action === 'add' ? { virtualRoomId: data.virtualRoomId } : undefined);

		});

    }

	private async joinConferenceStream(connectionId: string, streamId: string, position: number, prevStreamId: string = null) {
		try {
			await firstValueFrom(this.conferenceService.joinConferenceStream(this.room?.id ?? this.conference.idLesson,
																			 connectionId,
																			 streamId,
																			 "PUBLISHER",
																			 position,
																			 prevStreamId));
		} catch (e) {
			console.error(e);
		}
	}

	private async leaveConferenceStream(connectionId?: string, streamId?: string) {
		try {

			this.leaving = true;

			if (connectionId != null && streamId != null)
				await firstValueFrom(this.conferenceService.leaveConferenceStream(this.room?.id ?? this.conference.idLesson, connectionId, streamId));
			else if (this.room != null)
				await this.lessonService.leaveLesson(String(this.room.id));
			else if (this.conference != null)
				await this.lessonService.leaveLesson(String(this.conference.idLesson));

		} catch (e) {
			console.error(e);
		}
	}

	private getRole(foundPublisher: boolean = false): "publisher" | "presenter" | "participant" {
		return (this.isConferencePublisher() || this.isConferenceSubstitute()) && !foundPublisher
			 ? 'publisher'
			 : this.isConferencePresenter()
			 ? 'presenter'
			 : 'participant';
	}

	private async setPublisher(newPublisher: ClientStream) {
		this.publisher = newPublisher;
		this.publishingInfo = null;

		if (this.publisher != null) {
			this.publisher.handRaised = false;
			this.publisher.priority = PRIORITY_MIN_VALUE;
			this.publishingInfo = await firstValueFrom(this.translate.get('x is presenting', { user: newPublisher.name }));
		}
	}

	private publisherLeft() {
		this.publisherName = this.publisher?.name;

		this.snackBar.open(`Publisher ${this.publisherName} has left the conference`, 'Dismiss', {
			duration: 5000,
			verticalPosition: 'top'
		});
	}

	private async setPublisherReplacement() {
		let refArray: ClientStream[] = [];
		let index: number = -1;

		if (this.presenters.length > 0) {
			refArray = this.presenters;
			index = this.presenters.findIndex(p => this.isPublisher(p));
		}

		if (index === -1 && this.participants.length > 0) {
			refArray = this.participants;
			index = this.participants.findIndex(p => this.isPublisher(p));
		}

		if (index === -1 && this.presenters.length > 0) {
			refArray = this.presenters;
			index = 0;
		}

		if (index !== -1)
			await this.setPublisher(refArray.splice(index, 1)[0]);

		if (this.participants.length === 0)
			this.participantsListOpened = false;
	}

	private updatePriority(operation: "add" | "remove") {
		let updatedPriority = this.localParticipant.priority;

		if (operation === "add")
			updatedPriority += PRIORITY_ADD_VALUE;

		if (operation === "remove")
			updatedPriority -= PRIORITY_REMOVE_VALUE;

		if (updatedPriority < PRIORITY_MIN_VALUE)
			updatedPriority = PRIORITY_MIN_VALUE;

		if (updatedPriority > PRIORITY_MAX_VALUE)
			updatedPriority = PRIORITY_MAX_VALUE;

		this.localParticipant.priority = updatedPriority;

		this.sendData(JSON.stringify(<SignalPriorityUpdate>{ priority: this.localParticipant.priority }), [], SignalType.priorityUpdate);
		this.setPriorityTimeout();
	}

	private setPriorityTimeout() {
		if (this.priorityCheckTimeout != null) {
			clearTimeout(this.priorityCheckTimeout);
			this.priorityCheckTimeout = null;
		}

		this.priorityCheckTimeout = setTimeout(() => {

			if (this.localParticipant.priority === PRIORITY_MIN_VALUE)
				return;

			//this.localParticipant.priority === this._priorityCurrentValue
			if (!this._isHandRaised && !this._isSpeaking) {
	
				this.updatePriority("remove");
	
			} else if (this._isHandRaised || this._isSpeaking) {
	
				this.updatePriority("add");
	
			}

			this.setPriorityTimeout();

		}, PRIORITY_CHECK_TIMEOUT);
	}

	private startStreamingQualityCheck() {
		this.stopStreamingQualityCheck();

		this.calendar.addStreamingQuality(this.conference.idLesson)
			.subscribe({
				next: () => console.log('Stream quality check'),
				error: err => console.error(err)
			});
	
		this.streamingQualityInterval = setInterval(() => {

			if (this.platform.BLINK) { // Se Chrome o derivato

				let connType = NetworkInformation.connectionType();
	
				if (this.validStreamingQualities.includes(connType)) {
		  
				  this.streamingQualityValues = [];
		  
				} else {
		  
				  if (this.streamingQualityValues.length >= 3)
					this.leave();
		  
				  this.streamingQualityValues.push(connType);
		  
				}

			}

		  	this.calendar.addStreamingQuality(this.conference.idLesson)
				.subscribe({
					next: () => console.log('Stream quality check'),
					error: err => console.error(err)
				});
	
		}, STREAMING_QUALITY_CHECK_INTERVAL);

		console.log('Stream quality check start');
	}

	private stopStreamingQualityCheck() {
		if (this.streamingQualityInterval)
			clearInterval(this.streamingQualityInterval);

		this.streamingQualityInterval = undefined;
		this.streamingQualityValues = [];

		console.log('Stream quality check stop');
	}

	private async checkForPublisherAlreadyConnected(): Promise<boolean> {

		return new Promise(async (resolve, reject) => {

			let checkTimeout = setTimeout(() => resolve(false), CHECK_PUBLISHER_CONNECTED_TIMEOUT);

			try {

				let previewClientData = new ClientData(
					this.currentUser.id,
					`${this.currentUser.name} ${this.currentUser.surname}`,
					this.currentUser.profilePictureUrl,
					'publisher',
					'subject',
					'camera',
					undefined,
					true
				);
	
				let previewToken =  await firstValueFrom(this.conferenceService.generateTokenConference(this.id, this.virtualRoomId, true));
				
				let previewOV = new OpenVidu();
	
				let previewSession = previewOV.initSession();
	
				previewSession.on('connectionCreated', (event: ConnectionEvent) => {
					let clientData = <ClientData>JSON.parse(event.connection.data);
	
					if (clientData.hidden)
						return;
	
					if (clientData.userId !== this.currentUser.id && clientData.role === 'publisher') {
						clearTimeout(checkTimeout);

						previewSession.disconnect();

						previewOV = undefined;
						previewSession = undefined;

						resolve(true);
					}
				});
	
				await previewSession.connect(previewToken.token, previewClientData);
		  
			} catch (e) {
				reject(e);
			}

		});
		
	}

	private async disposePublisher(publisher: Publisher) {
		if (publisher?.stream.getMediaStream() == undefined)
			return;

		if (publisher.stream.hasVideo)
			await publisher.publishVideo(false, true);

		publisher.stream.getMediaStream()?.getTracks().forEach(t => t.stop());
	}

	handleDataEvent(event: { data: SignalUserRoom, selectedUsers: number[]}) {
		event.selectedUsers.forEach(selectedUser => this.handleJoinEvent(event.data, selectedUser));
	}

	async handleJoinEvent(data: SignalUserRoom, userId: number) {
		if (!this.isConferencePublisher() && this.isConferencePresenter()) {

			await firstValueFrom(this.conferenceService.createConferenceRoom(this.conference.id, data.virtualRoomId));

      		await firstValueFrom(this.conferenceService.addUsersToRoom(this.conference.id, data.virtualRoomId, [userId]));

		}

		let user = this.publisher;

			if (user?.userId !== userId) {

				user = this.presenters.find(u => u.userId === userId);

				if (!user)
					user = this.participants.find(u => u.userId === userId);

			}
			
		this.sendData(JSON.stringify(data), [user.manager.stream.connection], SignalType.addUserToRoom);
	}

	handleConnectedUsers(participants: ClientStream[]) {
		this.connectedUsers = participants.map(p => p.userId);
	}

	toggleRoomsToolbar() {
		this.toolbarRoomsOpened = !this.toolbarRoomsOpened;
		this.participantsListOpened = this.participants.length > 0
								   && this.toolbarRoomsOpened
								   && this.isPublisher();
	}

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogcomment',
  templateUrl: './dialogcomment.component.html',
  styleUrls: ['./dialogcomment.component.scss']
})
export class DialogcommentComponent implements OnInit {

  subComment : string

  constructor(public dialogRef: MatDialogRef<DialogcommentComponent>) { }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}

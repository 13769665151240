<div class="component-main">

    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>{{ 'Survey Templates' | translate }}</h2>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
      <button mat-fab color="accent" 
          [matTooltip]="'New surveys' | translate" matTooltipPosition="after"
          (click)="newSurveys()">
             <mat-icon>add</mat-icon>
      </button>    
    </div> 
  
    <table mat-table class="full-width-table mat-elevation-z8" matSort aria-label="Elements">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>
  
       <!-- Name Column -->
       <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Description' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>
  
   <!-- Name Column -->
   <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Author' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ row.user.surname}}  {{row.user.name}}</td>
  </ng-container>
      
  
      <!-- created Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Created' | translate }}</th>
        <td mat-cell *matCellDef="let row" >
          {{ row.created | date: "HH:mm  dd/MM/YYYY" }}
        </td>
      </ng-container>
  
       <!-- Name Column -->
       <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let row"> 
          <button [matTooltip]="row.idAuthor === currentUser.id ? ( 'Edit' | translate ) : ( 'Look' | translate )" mat-icon-button (click)="editSurvey(row)"> 
            <mat-icon *ngIf="row.idAuthor === currentUser.id" class="green">edit</mat-icon> 
            <mat-icon *ngIf="row.idAuthor !== currentUser.id" class="green">remove_red_eye</mat-icon> 
          </button>
        </td>
      </ng-container>
  
      
  
  
      <!-- Header row first group -->
      <ng-container matColumnDef="header-search">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.text-align]="'center'"
          [attr.colspan]="5"
        >
        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-form-field fxFlex color="primary">
          <mat-label>{{ "search" | translate }}</mat-label>
          <input matInput type="text" (keyup)="doFilter($event)" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
        </th>
      </ng-container>
       
  
      <tr mat-header-row *matHeaderRowDef="['header-search']"></tr>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    
  <mat-paginator
      #paginator
      [length]="dataSource?.data.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[25, 50, 100, 250]"
      color="primary"
    >
    </mat-paginator>
</div>

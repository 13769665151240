import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppLessonPopUpComponent } from 'src/app/pages/lessons/app-lesson-pop-up/app-lesson-pop-up.component';
import { Helper } from 'src/app/helpers/helper';
import { ClassroomContentDTO } from 'src/app/models/dto/classroomContentDTO';
import { LessonSessionDTO, SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { TeacherClassroomDTO } from 'src/app/models/dto/teacherClassroomDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { EventDialogData } from 'src/app/models/eventDialogData';
import { MasterState } from 'src/app/models/masterState';
import { User } from 'src/app/models/user';
import { MasterAddTeacherComponent } from './master-add-teacher/master-add-teacher.component';
import { CalendarService } from 'src/app/services/calendar.service';
import { MasterService } from 'src/app/services/master.service';
import { MasterAddComponent } from './master-add/master-add.component';
import { MasterPopupComponent } from './master-popup/master-popup.component';
import { AttendanceDTO } from 'src/app/models/dto/attendanceDTO';
import { firstBy } from 'thenby';
import { ChartDataset, ChartOptions } from 'chart.js';
import { AddSurveyPopUpComponent } from 'src/app/survey/add-survey-pop-up/add-survey-pop-up.component';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { MasterAddPreviewComponent } from './master-add-preview/master-add-preview.component';
import { ResDTO } from 'src/app/models/dto/resDTO';
import { CartService } from 'src/app/ecommerce/service/cart.service';
import { ContentVisualizerComponent } from 'src/app/popup/content-visualizer/content-visualizer.component';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { AddSubscriptionComponent, CrudSubscription, SubscriptionType } from 'src/app/popup/add-subscription/add-subscription.component';
import { QuickViewerComponent } from 'src/app/popup/quick-viewer/quick-viewer.component';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { SharePopupComponent } from 'src/app/popup/share-popup/share-popup.component';
import { LessonService } from 'src/app/services/lessons.service';
import { TranslationService } from 'src/app/services/translation.service';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { AppConferencePopUpComponent } from '../conferences/app-conference-pop-up/app-conference-pop-up.component';
import { ConferenceService } from 'src/app/services/conference.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { differenceInDays, differenceInMilliseconds } from 'date-fns';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, AfterViewInit {

  private _master: MasterDTO;

  @Input() 
  get master (): MasterDTO { return this._master; }
  set master (value: MasterDTO) {
    this._master = value;
    this.remainingDays = this.getRemainingDays();
    this.populateFiles();
  }

  @Input() set participants (value: ClassroomDTO) {
    this.initParticipants(value);
  }

  @Input() lessonsPlannedDuration: number[] = [0, 0];

  @Input() currentUserHavePaid: boolean = false;

  @Input() availablePlaces: number = 0;

  currentUser: User;
  rooms: RoomDTO[] = [];
  customerTeachers: UserDTO[] = [];
  teachersLesson: UserDTO[] = [];
  lessonsCol: string[] = ['name', 'date', 'teacher', 'room'];
  teachersCol: string[] = ['participant', 'joined', 'customer'];
  particpantsCol: string[] = ['participant', 'joined', 'customer'];
  filesCol: string[] = ['file', 'view'];
  today: Date;
  currentUserIsParticipant: boolean = false;
  currentUserIsTeacher: boolean = false;
  lessons: MatTableDataSource<LessonSessionDTO>;
  teachers: MatTableDataSource<TeacherClassroomDTO>;
  students: MatTableDataSource<ClassroomContentDTO>;
  files: MatTableDataSource<string>;
  updateCalendar: boolean = false;
  remainingDays: number = 0;

  href: string = "";

  //KPI
  lessonAverageDuration: string = '0';
  lessonAveragePlannedDuration: string = '0';
  lessonAverageAttendanceTime: number[] = [0, 0];
  lessonAverageAttendance: number = 0.00;
  lessonsDuration: number[] = [0, 0];
  lessonsAttendance: AttendanceDTO[] = [];
  doneLessons: number = 0;
  lessonsDurationSeconds: number = 0;
  spinnerValues: number[] = [0, 0]; //0 => numero di lezioni eseguite, 1 => numero di tempo eseguito. Tutto in percentuale
  currentSpinnerValue: number = 0; //Indice per spinnerValues

  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point: {
        radius: 0,
        hitRadius: 0
      }
    },
    scales: {
      x: {
        display: true,
        ticks: {
          stepSize: 50  
        },
        max: 100,
        min: 0
      },
      y: {
        display: true,
        ticks: {
          stepSize: 50
        },
        max: 100,
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
  public lineChartType = 'line';
  public lineChartData: ChartDataset[] = [{ data: [0], label: '%' }];
  public lineChartLabels: string[] = [];

  @ViewChild('lessonsPag') lessonsPag: MatPaginator;
  @ViewChild('teachersPag') teachersPag: MatPaginator;
  @ViewChild('studentsPag') studentsPag: MatPaginator;

  constructor(private auth: AuthService,
    private router: Router,
    private location: Location,
    private masterService: MasterService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private calendar: CalendarService,
    private lesson: LessonService,
    private conferenceService: ConferenceService,
    private cart: CartService,
    private selectionService: SelectionService,
    public darkService: DarkThemeService,
    private translation: TranslationService,
    private translate: TranslateService) {

      this.today = new Date();

      this.lessons = new MatTableDataSource([]);
      this.teachers = new MatTableDataSource([]);
      this.students = new MatTableDataSource([]);
      this.files = new MatTableDataSource([]);

      this.currentUser = this.auth.getCurrentUser();
  }

  ngOnInit(): void {

    this.href = Helper.getUrl();

    if(this.master.idAuthor === this.currentUser.id || this.currentUserIsTeacher) {
      this.lessonsCol.push('actions');
      this.particpantsCol.push('status');
    }
    
    this.lessonsCol.unshift('type');

    //this.getParticipants();
    this.getAttendanceClassroom();

    //this.getMaster();
    //this.getLessons(true); spostata in getAttendanceClassroom()
  }

  ngAfterViewInit () {
    this.lessons.paginator = this.lessonsPag;
    this.teachers.paginator = this.teachersPag;
    this.students.paginator = this.studentsPag;
  }

  getMaster(checkParticipants: boolean = false) {

    this.masterService.getMaster(this.master.id, this.auth.isAuthenticated())
      .subscribe(output => {
        this.master = output;

        //this.getParticipants();
        //this.getAttendanceClassroom();

        if (checkParticipants)
          this.matchSubscriptionToUser();

        if(!this.lessonsCol.includes('actions') && (this.master.idAuthor === this.currentUser.id || this.currentUserIsTeacher))
          this.lessonsCol.push('actions');
      });
  }

  getLessons (init?: boolean) {
    this.masterService.getLessonsOfMaster(this.master.id)
      .subscribe(output => {
        this.lessons.data = output;
        this.lessons.paginator = this.lessonsPag;
        this.doneLessons = this.lessons.data.filter((l: LessonSessionDTO) => l.stopDate).length;

        let plannedDuration = 0;
        output.forEach(l => {
          plannedDuration += this.getTime(l.startPlanned, l.endPlanned);     
        });

        this.spinnerValues[0] = Math.round((this.doneLessons / this.lessons.data.length) * 10000) / 100;
        this.spinnerValues[1] = Math.round((this.lessonsDurationSeconds / plannedDuration) * 10000) / 100;

        this.lessonsPlannedDuration = Helper.convertToTime(plannedDuration / 1000);

        if (!init)
          this.updateCalendar = !this.updateCalendar;

      });
  }

  getParticipants () {
    this.masterService.getParticipantsOfMaster(this.master.id)
      .subscribe(output => {

        this.initParticipants(output);

      });
  }

  private initParticipants (classroom: ClassroomDTO) {
    let author = classroom.teacherClassroom.splice(classroom.teacherClassroom.findIndex(t => t.userId === this.master.idAuthor), 1);
    classroom.teacherClassroom.unshift(author[0]);

    this.teachers.data = classroom.teacherClassroom;
    this.teachers.paginator = this.teachersPag;

    this.students.data = classroom.classroomContent;
    this.students.paginator = this.studentsPag;

    this.currentUserIsTeacher = classroom.teacherClassroom.findIndex(t => t.userId === this.currentUser.id) !== -1;
    this.currentUserIsParticipant = classroom.classroomContent.findIndex(s => s.userId === this.currentUser.id) !== -1;

    if(this.currentUserIsTeacher) {
      this.matchSubscriptionToUser();

      this.getTeachers();
      this.getRooms();
    }
  }

  private getRooms(): void {
    this.calendar.getRoomsForAll()
      .subscribe(output => {
        this.rooms = output;
      });
  }

  getTeachers () {
    this.calendar.getTeachersForAll(this.currentUser.idCustomer)
      .subscribe(output => {
        
        this.customerTeachers = output;

        let currentTeachers = this.teachers.data.map(t => t.userId);
        this.teachersLesson = output.filter(t => !currentTeachers.includes(t.id));

      });
  }

  changeState () {
    let newState = this.isTemplate() ? this.master.state + 2 : this.master.state + 1;

    const dialogRef = this.dialog.open(MasterPopupComponent, {
      data: { name: this.master.name, newState: newState }, width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;

      this.masterService.setMasterState(newState, this.master.id)
        .subscribe({
          next: () => {
            this.showMessage("Master state changed");
            this.getMaster();
          },
          error: err => this.showMessage(err.error.Message)
        });
    });
  }

  updateMaster () {
    const dialogRef = this.dialog.open(MasterAddComponent, {
      data: { master: this.master, isTemplate: this.master.type === MasterType.template }, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getMaster();
      }
    });
  }

  addPreview () {
    const dialogRef = this.dialog.open(MasterAddPreviewComponent, {
      data: { master: this.master }, width: '600px'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getMaster();
    });
  }

  viewFile (fileUrl: string) {
    this.dialog.open(QuickViewerComponent, {
      data: fileUrl, width: '80vw'
    });
  }

  unjoinMaster () {
    this.masterService.unjoinMaster(this.master.id)
      .subscribe({
        next: () => {
          this.showMessage('Master unjoined');
        },
        error: err => {
          this.showMessage(err.message);
        }
      });
  }

  addToCart () {
    this.cart.addMaster(this.master);
    this.router.navigateByUrl('/cart');
  }

  addSubscription () {
    let data: CrudSubscription = new CrudSubscription();
    data.entityId = this.master.id;
    data.entityType = SubscriptionType.master;
    data.subscription = this.master.subscription;
    
    const dialogRef = this.dialog.open(AddSubscriptionComponent, {
      data: data, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getMaster();
      }
    });
  }

  addTeachers () {
    const dialogRef = this.dialog.open(MasterAddTeacherComponent, {
      data: { master: this.master, teachers: this.teachersLesson }, width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getParticipants();
      }
    });
  }

  lessonContents(lesson: LessonSessionDTO) {
    if (lesson.state === 2 &&
        !this.todayGreaterThanPlanned(lesson.endPlanned) &&
        (lesson.teacherId === this.currentUser.id || this.currentUser.id === this.master.idAuthor)
       ) {

      this.selectionService.startSelection(lesson.id, SelectionMode.masterContents, this.master.id);
    } else {
      const dialogref = this.dialog.open(ContentVisualizerComponent, {
        data: { lesson: lesson }, width: '500px'
      });
    }
  }

  goToAuthorProfile (id: number) {
    this.router.navigate(['/authorprofile/' + id]);
  }

  addLesson(): void {
    let start = new Date();
    let end = new Date(start);

    start.setHours(start.getHours() + 2);
    end.setHours(end.getHours() + 4);

    let eventData: EventDialogData = {
      startData: start,
      endData: end,
      classId: null,
      rooms: this.rooms,
      teacherId: this.master.idAuthor,
      teachers: this.customerTeachers,
      type: 3
    };
    
    const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
      width: '500px',
      data: { eventData, page: "masterNoCalendar", dateOrTime: true }
    });

    dialogRef.afterClosed().subscribe((result: EventDialogData) => {

      if (!result)
        return;

      let lesson: LessonSessionDTO = new LessonSessionDTO();

      lesson.teacherId = result.teacherId;
      lesson.startPlanned = result.startData;
      lesson.endPlanned = result.endData;
      lesson.name = result.title;
      lesson.description = result.description;
      lesson.recodingPlanned = result.recordPlan;
      lesson.privateRecording = result.private;
      lesson.roomId = result.roomId;
      lesson.classId = this.master.idClassroom;

      this.masterService.postLesson(this.master.id, lesson)
        .subscribe({
          next: () => {
            this.getLessons();
            this.getParticipants();
            this.snackbar.open('Lesson added!', '', { duration: 3000 });
          },
          error: err => this.snackbar.open(err.message, '', { duration: 3000 })
        });
    });
  }

  getMasterState() {
    return MasterState;
  }

  showMessage (message: string) {
    this.snackbar.open(message, 'Dismiss', { duration: 3000 });
  }

  todayGreaterThanPlanned(date: Date | string) {
    return this.today > new Date(date);
  }

  addConference(): void {
    const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
      width: '600px',
      data: { mode: "private", page: "master", idMaster: this.master.id },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (!res)
        return;

      this.getLessons();
    });
  }

  updateConference(id: number) {
    this.conferenceService.getConference(id).subscribe({
      next: res => {
        const dialogRef = this.dialog.open(AppConferencePopUpComponent, {
          data: { conference: res },
          width: '600px',
          autoFocus: false,
          restoreFocus: false
        });

        dialogRef.afterClosed().subscribe(res => {
          if (!res)
            return;

          this.getLessons();
        });
      }
    });
  }

  async deleteConference(conference: ConferenceDTO) {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete conference')),
        body:  await firstValueFrom(this.translate.get('Are you sure you want to delete conference?', { name: conference.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;
      
        this.conferenceService.deleteConference(conference.id)
          .subscribe({
            next: async () => {
             this.snackbar.open(await firstValueFrom(this.translate.get('Conference deleted')), undefined, { duration: 3000 });
             this.getLessons();
            },
            error: err => this.snackbar.open('Error deleting conference', undefined, { duration: 3000 })
          });
    });
  }

  async deleteMaster() {
    const dialogRef = this.dialog.open(GenericPopupComponent, {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete master')),
        body:  await firstValueFrom(this.translate.get('Are you sure you want to delete master?', { name: this.master.name }))
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result)
          return;
      
        this.masterService.deleteMaster(this.master.id)
          .subscribe({
            next: async () => {
             this.snackbar.open(await firstValueFrom(this.translate.get('Master deleted')), undefined, { duration: 3000 });
             this.router.navigate(['/masters']);
            },
            error: err => this.snackbar.open(err?.error?.Message ?? 'error deleting master', undefined, { duration: 5000 })
          });
    });
  }

  //KPI

  spinnerValue (value: number) {
    if (value.toFixed(1).split('.')[1] == "00")
      return value + "%";
    
    return value.toFixed(1) + "%";
  }

  getTime (startDate: string | Date, endDate: string | Date) {
    let diff = differenceInMilliseconds(new Date(endDate), new Date(startDate));
    return diff < 1000 ? 0 : diff;
  }

  getAttendanceClassroom () {
    this.calendar.getClassroomAttendance(this.master.idClassroom)
      .subscribe(output => {
        
        var attendancePercentage = 0;
        var actualDurationPercentage = 0;
        var lessonsDuration = 0;
        var lessonsPlannedDuration = 0;

        var lessonsTotalsSeconds = 0;
        var lessonsActualDurationSeconds = 0;

        this.lessonsAttendance = output;
        this.lessonsAttendance.sort(
          firstBy((a: AttendanceDTO) => {
            var t = new Date(a.startDate);
            t.getFullYear();
          }, 1)
            .thenBy((a: AttendanceDTO) => {
              var t = new Date(a.startDate);
              t.getMonth();
            }, 1));

        output.forEach(l => {
          attendancePercentage += l.attendancePercentage;
          actualDurationPercentage += l.actualDurationPercentage;
          lessonsPlannedDuration += this.getTime(l.startPlanned, l.endPlanned); //Durata pianificata delle lezioni GIA eseguite
          lessonsDuration += this.getTime(l.startDate, l.stopDate);

          lessonsTotalsSeconds += l.attendanceTotalSeconds;
          lessonsActualDurationSeconds += l.actualDurationSeconds;
        });

        if(lessonsTotalsSeconds !== 0 && lessonsActualDurationSeconds !== 0)
          this.lessonAverageAttendanceTime = Helper.convertToTime(lessonsTotalsSeconds / output.length);

        this.lessonsDuration = Helper.convertToTime(lessonsDuration / 1000);
        //this.lessonsPlannedDuration = ((lessonsPlannedDuration / 1000) / 60 / 60).toFixed(2);

        this.lessonAverageAttendance = Number((attendancePercentage / output.length).toFixed(2));
        if (!this.lessonAverageAttendance)
          this.lessonAverageAttendance = 0;

        this.lessonsDurationSeconds = lessonsDuration;
        //this.durationSpinnerValue = ((lessonsDuration / lessonsPlannedDuration) * 100).toFixed(2);

        this.lessonAverageDuration = Helper.convertToHMS(lessonsDuration / output.length);
        this.lessonAveragePlannedDuration = Helper.convertToHMS(lessonsPlannedDuration / output.length);

        this.setChartData();
        this.getLessons(true);
      });
  }

  setChartData () {
    var data = [];
    var date;

    for(var i=0; i<this.lessonsAttendance.length; i++) {
      if(this.lessonsAttendance[i].actualDurationPercentage >= 10)
      {
        data.push(this.lessonsAttendance[i].attendancePercentage);
        date = new Date(this.lessonsAttendance[i].startDate);

        this.lineChartLabels.push(date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear());
      }
    }

    this.lineChartData = [{ data: data, label: "%", borderColor: 'rgba(103, 58, 183, 1)', backgroundColor: 'rgba(103, 58, 183, 0.5)' }];
  }

  showSurvey(id:number) {
    this.router.navigate(['/survey', id], { queryParams: { view: 'preview' } });
  }

  editLessonElement(elem: LessonSessionDTO): void {
    elem.idSurvey > 0 ? this.editLessonSurvey(elem) : this.editLesson(elem);
  }

  editLesson(elem: LessonSessionDTO): void {
    let eventData: EventDialogData = {
      startData: new Date(elem.startPlanned),
      endData: new Date(elem.endPlanned),
      title: elem.name,
      description: elem.description,
      recordPlan: elem.recodingPlanned,
      private: elem.privateRecording,
      roomId: elem.roomId,
      classId: elem.classId,
      rooms: this.rooms,
      teacherId: elem.teacherId,
      teachers: this.customerTeachers,
      type: elem.typeId
    };

    const dialogRef = this.dialog.open(AppLessonPopUpComponent, {
      width: '500px',
      data: { eventData, page: "masterNoCalendar", dateOrTime: true }
    });

    dialogRef.afterClosed().subscribe((result: EventDialogData) => {

      if (!result)
        return;

      elem.teacherId = result.teacherId;
      elem.startPlanned = result.startData;
      elem.endPlanned = result.endData;
      elem.name = result.title;
      elem.description = result.description;
      elem.recodingPlanned = result.recordPlan;
      elem.privateRecording = result.private;
      elem.roomId = result.roomId;

      this.masterService.putLesson(elem.id, elem)
        .subscribe({
          next: () => {
            this.getLessons();
            this.getParticipants();
            this.snackbar.open('Lesson edited', '', { duration: 3000 });
          },
          error: err => this.snackbar.open(err.message, '', { duration: 3000 })
        });
    });
  }

  async deleteLesson(lesson: LessonSessionDTO) {
    let msg = lesson.recodingPlanned
            ? `Are you sure you want to delete lesson? All associated records will be removed`
            : `Are you sure you want to delete lesson?`;

    const dialogRef = this.dialog.open(GenericPopupComponent,
    {
      width: '400px',
      data: <GenericPopupData>{
        title: await firstValueFrom(this.translate.get('Delete lesson')),
        body: await firstValueFrom(this.translate.get(msg, { name: lesson.name }))
      }
    });

    dialogRef.afterClosed().subscribe(async res => {

      if (!res)
        return;

      if (lesson.state === 1) {
        this.lesson.deleteLesson(lesson.id)
          .then(() => {
            this.getLessons();
            this.snackbar.open('Lesson deleted', '', { duration: 3000 });
          })
          .catch(err => this.snackbar.open(err.error.Message, '', { duration: 3000 }));
      } else {
        this.calendar.deleteLesson(lesson.id)
          .subscribe({
            next: () => {
              this.getLessons();
              this.snackbar.open('Lesson deleted', '', { duration: 3000 });
            },
            error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
          });
      }

    });
  }

  addSurvey(): void {
    let eventData: EventDialogData = {
      dialogTitle : 'Add new survey',
      classId: this.master.idClassroom,
      rooms: this.rooms,
      teacherId: this.master.idAuthor,
      teachers: this.customerTeachers,
      type: 4
    };

    const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
      width: '500px',
      data: { eventData, page: "master" }
    });
    
    dialogRef.afterClosed().subscribe(result => {

      if (!result)
        return;
      
      let surveyLesson: SurveyLessonDTO = new SurveyLessonDTO();

      surveyLesson.name = result.title;
      surveyLesson.description = result.description;
      surveyLesson.teacherId = result.teacherId;
      surveyLesson.startPlanned = result.startData;
      surveyLesson.endPlanned = result.endData;
      surveyLesson.typeId = 4;
      surveyLesson.classId = this.master.idClassroom;
      surveyLesson.survey = new SurveyDTO();
      surveyLesson.survey.surveyJSON = '';

      if (result.survey) {
        surveyLesson.name = result.survey.name;
        surveyLesson.description = result.survey.description;
        surveyLesson.survey.surveyJSON = result.survey.surveyJSON;
      }

      this.masterService.postSurvey(this.master.id, surveyLesson)
        .subscribe({
          next: (res: ResDTO) => {

            if (res.Message)
              this.router.navigate(['/survey-creator', res.Message]);

          },
          error: err => this.snackbar.open(err.message, '', { duration: 3000 })
        });
    }); 
  }

  editLessonSurvey(elem: LessonSessionDTO){
    let eventData: EventDialogData = {
      dialogTitle: 'Edit survey',
      startData: new Date(elem.startPlanned),
      endData: new Date(elem.endPlanned),
      title: elem.name,
      description: elem.description,
      classId: elem.classId,
      rooms: this.rooms,
      teacherId: elem.teacherId,
      teachers: this.customerTeachers,
      type: elem.typeId
    };

    const dialogRef = this.dialog.open(AddSurveyPopUpComponent, {
      width: '500px',
      data: { eventData, page: "master" }
    });
    
    dialogRef.afterClosed().subscribe(result => {

      if (!result)
        return;

      elem.teacherId = result.teacherId;
      elem.startPlanned = result.startData;
      elem.endPlanned = result.endData;
      elem.name = result.title;
      elem.description = result.description;
      elem.classId = this.master.idClassroom;
      // elem.type = 4;
      // surveyLesson.survey=new SurveyDTO();
      // surveyLesson.survey.surveyJSON='';
      
      this.masterService.putLesson(elem.id , elem)
        .subscribe({
          next: () => {
            this.getLessons();
            this.getParticipants();
            this.snackbar.open('Lesson edited', '', { duration: 3000 });
          },
          error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
        });
    });
  }

  editSurvey(element: LessonSessionDTO) {
    this.router.navigate(['/survey-creator', element.idSurvey]);
  }

  showResultSurvey(element: LessonSessionDTO) {
    this.router.navigate(['/survey-results', element.idSurvey], { queryParams: { idLesson: element.id, idClassroom: this.master.idClassroom } });
  }

  matchSubscriptionToUser() {
    this.students.data.forEach(classcontent => {
      if (this.master.subscription.subscriptionUser.findIndex(s => s.idUser === classcontent.userId) !== -1)
      //if (Helper.getLatestSubscriptionUser(this.master.subscription.subscriptionUser, classcontent.userId) != null)
        classcontent.user.havePaid = true;
    });
  }

  addUserAsPayed(idUser: number) {
    this.masterService.buyMaster(this.master.idSubscription, idUser)
      .subscribe({
        next: () => {
          this.getMaster(true);
          this.snackbar.open('User set as paid', '', { duration: 3000 });
        },
        error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
      });
  }

  payMasterFree() {
    this.masterService.buyMaster(this.master.idSubscription)
      .subscribe({
        next: () => {

          //this.snackbar.open('Master paid', '', { duration: 3000 });
          this.router.navigate(['/master', this.master.id], { queryParams: { page: 'sidenav' } });

        },
        error: err => this.snackbar.open(err.error.Message, '', { duration: 3000 })
      });
  }

  isOneToOne() {
    return this.master.type === MasterType.oneToOne;
  }

  isTemplate() {
    return this.master.type === MasterType.template;
  }

  getDisabledPayBtn() {
    if (this.isOneToOne())
      return !this.currentUserHavePaid || this.getRemainingPercentage() > 20;

    return this.availablePlaces <= 0 || this.currentUserHavePaid;
  }

  getDisplayPayBtn() {
    if (this.isOneToOne())
      return this.currentUser.isStudent &&
             this.master.subscription != null;
    
    return this.master.state === this.getMasterState().planned &&
           this.currentUser.isStudent &&
           this.master.subscription != null;
  }

  goBack() {
    this.location.back();
  }

  getHelper() {
    return Helper;
  }

  //Inizio - Utilizzati per il titolo
  getTitile() {
    if (this.master.type === MasterType.standard)
      return 'Live Digital Academy Dashboard';

    return 'Personal Live Digital Academy';
  }

  getTitleConn() {
    if (this.isOneToOne())
      return this.currentUser.isStudent ? ' Dashboard' : ' of ';
    
    return '';
  }

  getOneToOneUser() {
    return this.students.data != null && this.students.data.length > 0 ?
           `${this.students.data[0].user.name} ${this.students.data[0].user.surname}` :
           null;
  }
  //Fine

  selectSpinnerValue() {
    this.currentSpinnerValue = this.currentSpinnerValue ? 0 : 1;
  }

  getSpinnerTip() {
    return this.currentSpinnerValue ? 'Done time' : 'Done lessons';
  }

  getRemainingDays() {
    if (!this.master)
      return 0;

    if (this.master.state === MasterState.finished)
      return 0;

    let now = new Date();
    let startDate = new Date(this.master.startDate);

    if (now < startDate)
      return this.master.durationInDays;

    let stopDate = new Date(this.master.stopDate);
    
    if (now < stopDate)
      return differenceInDays(stopDate, now);

    return 0;
  }

  getRemainingPercentage() {
    return (this.remainingDays / this.master.durationInDays) * 100;
  }

  getRemainingColor() {
    let remainingPercentage = this.getRemainingPercentage();

    if (remainingPercentage < 10)
      return 'red';

    if (remainingPercentage < 20)
      return 'orange';

    if (remainingPercentage < 30)
      return 'yellow';
  }

  share() {
    this.dialog.open(SharePopupComponent, {
      width: '400px', data: { title: this.master.name }
    });
  }

  private populateFiles() {
    this.files.data = [];

    for (let i=1; i<6; i++) {
      let file = this.master[`document${i}Url`];

      if (!Helper.isNullOrEmpty(file))
        this.files.data.push(file);
    }

    //Forzatura aggiornamento tabella
    this.files._updateChangeSubscription();
  }

  getSubTitle() {
    return this.translation.currentLang === 'it' ? 'Iva inclusa' : null;
  }

  getLessonType(typeId: number) {
    if (typeId === 8)
      return 'Conference';

    if (typeId === 4)
      return 'Survey';

    return 'Lesson';
  }

  getLessonIcon(typeId: number) {
    if (typeId === 8)
      return 'interpreter_mode';

    if (typeId === 4)
      return 'assignment';

    return 'school';
  }

}

<mat-menu #addEventMenu="matMenu" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="addExecute(item)" *ngIf="createLesson" [disabled]="!currentUser.streamingEnabled">{{ 'Add a scheduled lesson' | translate }}</button>
        <button mat-menu-item (click)="addSurvey(item)" *ngIf="createSurvey">{{ 'Add survey' | translate }}</button>
        <button mat-menu-item (click)="addWebinar(item)" *ngIf="createWebinar" [disabled]="!currentUser.streamingEnabled">{{ 'Add webinar' | translate }}</button>
        <button mat-menu-item (click)="addPublicConference(item)" *ngIf="createConference" [disabled]="!currentUser.streamingEnabled">{{ 'Add public conference' | translate }}</button>
        <button mat-menu-item (click)="addPrivateConference(item)" *ngIf="createConference" [disabled]="!currentUser.streamingEnabled">{{ 'Add private conference' | translate }}</button>
        <button mat-menu-item (click)="pasteElement(item)" *ngIf="pasteItem">{{ 'Paste' | translate }}</button>
    </ng-template>
</mat-menu>

<!-- Teacher Owner Eventi Futuri -->
<mat-menu #editAndDeleteMenu="matMenu" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="addContents(item.id)" *ngIf="!isConference(item)" [disabled]="!currentUser.streamingEnabled">{{ 'Contents' | translate }}</button>
        <button mat-menu-item (click)="copyElement(item)" [disabled]="!currentUser.streamingEnabled">{{ 'Copy' | translate }}</button>
        <button mat-menu-item (click)="editElement(item)" [disabled]="!currentUser.streamingEnabled">{{ 'Edit' | translate }}</button>
        <button mat-menu-item (click)="editSurvey(item)" *ngIf="item.idSurvey">{{ 'Edit survey' | translate }}</button>
        <button mat-menu-item (click)="deleteElement(item)">{{ 'Delete' | translate }}</button>
    </ng-template>
</mat-menu>

<!-- Creatore Owner Eventi Futuri -->
<mat-menu #openAndDeleteMenu="matMenu" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="openElement(item)">{{ 'Open' | translate }}</button>
        <button mat-menu-item (click)="copyElement(item)" *ngIf="isLesson(item) || isSurvey(item) || isConference(item)" [disabled]="!currentUser.streamingEnabled">{{ 'Copy' | translate }}</button>
    </ng-template>
</mat-menu>

<!-- Creatore Not Owner Eventi Futuri -->
<mat-menu #onlyOpenMenu="matMenu" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="openElement(item)">{{ 'Open' | translate }}</button>
    </ng-template>
</mat-menu>

<mat-menu #copyMenu="matMenu" backdropClass="mat-menu-font">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="copyElement(item)" *ngIf="isLesson(item) || isSurvey(item) || isConference(item)" [disabled]="!currentUser.streamingEnabled">{{ 'Copy' | translate }}</button>
    </ng-template>
</mat-menu>

<div fxLayout="column" fxLayoutAlign="center center" [class.component-main]="isCalendar()">
    <h2 *ngIf="isCalendar()">{{ 'Calendar' | translate }}</h2>
    <div *ngIf="isCalendar()" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">

        <mat-form-field *ngIf="isAdmin()" color="primary">
            <mat-label>{{ 'Customer' | translate }}</mat-label>
            <mat-select [formControl]="customer" (selectionChange)="customerChange()">
                <mat-option [value]="0">{{ 'All Customer' | translate }}</mat-option>
                <mat-option *ngFor="let item of customers" [value]="item?.id">
                    {{ item?.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!isStudent() && !isSpeaker()" color="primary">
            <mat-label>{{ 'Virtual Classroom' | translate }}</mat-label>
            <mat-select [formControl]="classroom" (selectionChange)="classroomChange()">
                <mat-option [value]="0">{{ 'All Classroom' | translate }}</mat-option>
                <mat-option *ngFor="let item of classrooms" [value]="item?.id">
                    {{ item?.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!isStudent() && !isSpeaker()" color="primary">
            <mat-label>{{ 'Teacher' | translate }}</mat-label>
            <mat-select [formControl]="teacher" (selectionChange)="teacherChange()">
                <mat-option [value]="0" >{{ 'All Teacher' | translate }}</mat-option>
                <mat-option *ngFor="let item of teachers" [value]="item?.id">
                    {{ item?.name }} {{ item?.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!isStudent() && !isSpeaker()" color="primary">
            <mat-label>{{ 'Room' | translate }}</mat-label>
            <mat-select [formControl]="room" (selectionChange)="roomChange()" >
                <mat-option [value]="0">{{ 'All Rooms' | translate }}</mat-option>
                <mat-option *ngFor="let item of rooms" [value]="item?.id">
                    {{ item?.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field color="primary">
            <mat-label>{{ 'Live Lessons' | translate }}</mat-label>
            <mat-select [formControl]="lessonType" (selectionChange)="lessonTypeChange()">
                <mat-option [value]="0">{{ 'All Lessons' | translate }}</mat-option>
                <mat-option [value]="1">{{ 'Performed' | translate }}</mat-option>
                <mat-option [value]="2">{{ 'Planned' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <calendar-header [(view)]="view"
                     [(viewDate)]="viewDate"
                     (viewDateChange)="fetchEvents()"
                     (viewChange)="fetchEvents()"
                     style="width: 100%">
    </calendar-header>

    <div [class.dark-theme]="isDark()" [class.mat-elevation-z8]="isCalendar()" *ngIf="events$ | async; let events" style="width: 100%">
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="'month'"
                                     [viewDate]="viewDate"
                                     [events]="events"
                                     (dayClicked)="dayClicked($event.day)"
                                     (eventClicked)="eventClicked($event.sourceEvent, $event.event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="'week'"
                                    [viewDate]="viewDate"
                                    [events]="events"
                                    (eventClicked)="eventClicked($event.sourceEvent, $event.event)"
                                    (hourSegmentClicked)="hourSegmentClicked($event.sourceEvent, $event.date)"
                                    [dayStartHour]="8"
                                    [dayEndHour]="22">
            </mwl-calendar-week-view>
        </div>
    </div>
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="addEventMenu"
     #openAddEvent="matMenuTrigger">
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="editAndDeleteMenu"
     #openEditDelete="matMenuTrigger">
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="openAndDeleteMenu"
     #openOpenDelete="matMenuTrigger">
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="onlyOpenMenu"
     #openOnlyOpenMenu="matMenuTrigger">
</div>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="copyMenu"
     #openCopyMenu="matMenuTrigger">
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { CalendarService } from 'src/app/services/calendar.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { firstBy } from 'thenby';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { Helper } from 'src/app/helpers/helper';
import { firstValueFrom } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-calendar-user-list',
    templateUrl: './calendar-user-list.component.html',
    styleUrls: ['./calendar-user-list.component.scss']
})
export class CalendarUserListComponent implements OnInit {
    lessonId: number = 0;
    lesson: LessonSessionDTO;
    lessonDuration: string;
    lessonPlannedDuration: string;
    dataSource: MatTableDataSource<AirTimeDTO>;
    students: number = 0;
    //dataSource: AirTimeDTO[] = [];
    columnsToDisplay = ['surname', 'name', 'startDate', 'endDate', 'timeInLessons', 'room', 'station'];

    @ViewChild(MatSort, {static: false}) sort: MatSort;


    constructor(
        private calendar: CalendarService,
        private exporter: CsvExporterService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private location: Location,
        private snackBar: MatSnackBar,
        private classroomService: ClassroomService) {
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;

    ngOnInit() {
        this.lessonId = Number(this.route.snapshot.paramMap.get('id'));

        if (this.lessonId == undefined) 
            this.router.navigate(['/calendar']);

        this.getLesson();
        this.updatePage(); 
    }

    getLesson() {
        this.calendar.getLesson(this.lessonId)
            .subscribe(output => {
                this.lesson = output;
                this.lessonDuration = Helper.convertToHMS(this.getTime(this.lesson.startDate, this.lesson.stopDate));
                this.lessonPlannedDuration = Helper.convertToHMS(this.getTime(this.lesson.startPlanned, this.lesson.endPlanned));

                this.getUsersByClassroom();
        });
    }

    updatePage() {
        this.dataSource = null;
        this.calendar.getAirTimeOfLesson(this.lessonId)
            .subscribe({
                next: output => {
                   // this.dataSource = output;

                    this.dataSource = new MatTableDataSource(output);
  
                    this.dataSource.sortingDataAccessor = (element, property:string) => {
                       switch (property) {
                        case 'name':
                          return `${element.user.name}`;
                        case 'surname':
                          return `${element.user.surname}`;
                        case 'station':
                          return `${element.station?.name}`;
                        case 'room':
                          return `${element.station?.room.name}`;
                        default: 
                           return element[property];
                       }
                    };

                    setTimeout(() => {
                        this.dataSource.sort = this.sort; 
                      })

                      this.dataSource.sort = this.sort; 

                   
                    if (this.dataSource.data.length == 0)
                        this.openSnackBar('No users were connected to the lesson');
                }, error: err => {
                    this.dataSource.data = [];
                    console.error(err);
                }
            });
    }

    openSnackBar(message: string) {
        let snakbarRef;

        snakbarRef = this.snackBar.open(message, '', {
            duration: 2000,
        });

        snakbarRef.afterDismissed().subscribe(() => {
            this.goBack();
        });
    };

    goBack() {
        this.location.back();
    }

    /*
    getHours(startDate: Date, endDate: Date){
        var start = new Date(startDate);
        var end = new Date(endDate);
        if(end.getHours() == start.getHours())
            return end.getMinutes() - start.getMinutes() + "M";
        else
            if(end.getHours() - start.getHours() == 1)
                return end.getHours() - start.getHours() + "H";
            else
                return end.getHours() - start.getHours() + "Hs";
    }*/

    getPercentage(startDateL: Date, endDateL: Date, startDateS: Date, endDateS: Date) {
        var startL = new Date(startDateL);
        var endL = new Date(endDateL);
        var startS = new Date(startDateS);
        var endS = new Date(endDateS);

        var StudentTime = endS.getTime() - startS.getTime();
        var LessonTime = endL.getTime() - startL.getTime();

        if (LessonTime < 1000)
            return 0;

        return (StudentTime * 100) / LessonTime;
    }

    getTime(startDate: Date | string, endDate: Date | string) {
        var start = new Date(startDate);
        var end = new Date(endDate);

        if(end.getTime() - start.getTime() < 1000)
            return 0;

        return end.getTime() - start.getTime();
    }

    getAvgPercentage() {
        var totalTime = 0;

        this.dataSource?.data.forEach(data => {
            totalTime += this.getTime(data.startDate, data.endDate);
        });

        return Math.round(((totalTime / this.students) / this.getTime(this.lesson.startDate, this.lesson.stopDate)) * 10000) / 100;
    }

    getUsersByClassroom() {
        this.students = null;
        firstValueFrom(this.classroomService.getUsersPerClassroom(this.lesson.classId)).then((res) => {
            var users = res.userList;
            users.sort(
            firstBy((a: any) => (a.isStudent) ? 1 : -1)
              .thenBy((a: any, b: any) => a.surname.toUpperCase() > b.surname.toUpperCase() ? 1 : -1))
          this.students = users.filter(x => x.isStudent ? 1 : 0).length;
        });
    }

    lessonExportToCsv() {
        this.calendar.getLesson(this.lessonId)
            .subscribe(lesson => {

                if (!lesson)
                    return;

                this.exporter.exportAirTimesToCsv([{ data: this.dataSource.data, lesson: lesson }]);
            });
    }

    spinnerValue(value: number) {
        if(value.toFixed(2).split('.')[1] == "00")
            return value + "%";
        else
            return value.toFixed(2) + "%";
      }
}

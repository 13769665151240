import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormControl, Validators } from '@angular/forms';
import { StudentData } from 'src/app/models/studentData';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { StudentsAnswerTableDataSource } from 'src/app/survey/survey-components/students-answer-table/students-answer-table-datasource';

@Component({
  selector: 'app-course-view',
  templateUrl: './course-view.component.html',
  styleUrls: ['./course-view.component.scss']
})
export class CourseViewComponent implements OnInit {

  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        grid: { 
          color: "#6f6f6f"
        },
        ticks: {
          color: "#868585"
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#6f6f6f"
        }, 
        ticks: {
          color: "#868585"
        } 
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    },
  };
  barChartLabels: string[] = [];
  barChartLabels2: string[] = [];
  barChartLabels3: string[] = [];
  barChartType: ChartType = 'bar';
  barChartPlugins = [DataLabelsPlugin];

  barChartData: ChartDataset[] = [{ data: [0], label: 'null', backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];
  barChartData2: ChartDataset[] = [{ data: [0], label: 'null', backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];
  barChartData3: ChartDataset[] = [{ data: [0], label: 'null', backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' }];

  monthsName: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  year: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
  contentId: number;
  contentTitle:string
  userData: StudentData[] = [];
  years: number[] = [];
  months: number[] = [];
  name: string;
  surname: string;
  contentDuration: number
  viewCount: number[] = []
  viewCountbyId: number[] = []
  dataTracker: UserDTO[] = [];
  studSelected: string
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  dataSource: StudentsAnswerTableDataSource;
  dataSrc = new MatTableDataSource<any>();
  displayedColumns = ['name', 'survey'];
  
  constructor(private location: Location,
    private route: ActivatedRoute,
    private courseService: CourseService) { }

  ngOnInit(): void {
    this.contentId = +this.route.snapshot.paramMap.get('id');
    
    this.dataSource = new StudentsAnswerTableDataSource();
    if (this.route.snapshot.queryParamMap.has('title'))
      this.contentTitle = this.route.snapshot.queryParamMap.get('title');
    if (this.route.snapshot.queryParamMap.has('duration'))
      this.contentDuration = Number(this.route.snapshot.queryParamMap.get('duration'));
  }

  dateDiffInHours(a: string | Date, b: string | Date) {
    // Esclude l'ora ed il fuso orario
    let date1 = new Date(a)
    let date2 = new Date(b)
    var utc1 = Math.floor(date1.getTime() / 1000.0)
    var utc2 = Math.floor(date2.getTime() / 1000.0)


    return Math.floor((utc2 - utc1));
  }

  yearChange() {
    var time: number[] = [];
    /*  let allTrackMinutes: number[] = []; */
    this.months = [];
    this.barChartData = [];
    this.barChartLabels = [];
    this.years = []
    this.dataTracker.forEach(out => {
      out.courseContentTracker.forEach(usertrack => {
        let dateCreate = new Date(usertrack.created)
        /* allTrackMinutes.push(this.dateDiffInHours(usertrack.startDate, usertrack.stopDate)); */
        //controllo se l'anno è corretto
        if (this.year.value == 0) {
          //controllo se l'anno è già stato inserito
          if (!this.years.includes(dateCreate.getFullYear())) {
            this.years.push(dateCreate.getFullYear());
            time.push(this.dateDiffInHours(usertrack.startDate, usertrack.stopDate));
            this.barChartLabels.push(dateCreate.getFullYear().toString());
          } else {//se già inserito aggiungo il tempo all'anno
            time[time.length - 1] += this.dateDiffInHours(usertrack.startDate, usertrack.stopDate)
          }

        } else {
          if (!this.years.includes(dateCreate.getFullYear())) {
            this.years.push(dateCreate.getFullYear())
          }
          if (dateCreate.getFullYear() == this.year.value) {
            //controllo se il mese è già stato inserito
            if (!this.months.includes(dateCreate.getMonth())) {
              this.months.push(dateCreate.getMonth());
              this.barChartLabels.push(this.monthsName[dateCreate.getMonth()]);
              time.push(this.dateDiffInHours(usertrack.startDate, usertrack.stopDate));
            } else {//se già inserito aggiungo il tempo al mese
              time[time.length - 1] += this.dateDiffInHours(usertrack.startDate, usertrack.stopDate)
            }
          }
        }
      })
    })
    for (let i = 0; i < time.length; i++) {
       time[i] = this.convertToTime(time[i])
      
    }
    this.barChartData.push({ data: time, label: "Minutes", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' });
  
  }

  convertToTime(seconds: number) {
    if (seconds != null) {
      /* var hours = seconds / 3600;
      console.log(seconds / 3600) */
      var minutes = (seconds % 3600) / 60;
      return Number(/* hours.toFixed(0) + "." + */ minutes.toFixed(1));
    } else {
      return 0;
    }
  }

  goBack() {
    this.location.back();
  }

  viewMost() {
    this.studSelected = null;
    this.barChartData2 = [];
    this.barChartLabels2 = [];
    this.viewCount = []
    this.barChartData3 = [];
    this.barChartLabels3 = [];
    let cont: number = 0

    for (let x = 0; x < this.contentDuration; x += 10) {
      cont += 10
      let contView: number = 0
      this.barChartLabels2.push((cont - 10).toString() + "-" + (cont).toString())
      this.barChartLabels3.push((cont - 10).toString() + "-" + (cont).toString())
      this.dataTracker.forEach(userTrack => {
        userTrack.courseContentTracker.forEach(out => {
          if ((out.startPosition <= cont) && (cont - 10 <= out.stopPosition)) {
            contView += 1
          }
        });

      });
      this.viewCount.push(contView)
    }
    this.barChartData2.push({ data: this.viewCount, label: "Views", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' });
    this.barChartLabels2[this.barChartLabels2.length-1]  = (cont - 10).toString() + "-" + this.contentDuration.toString()
    this.barChartLabels3[this.barChartLabels3.length-1]  = (cont - 10).toString() + "-" + this.contentDuration.toString()
    this.barChartData3 = this.barChartData2
  
  }
  viewForStudent(searchId: number) {

    this.barChartData3 = [];
    this.barChartLabels3 = [];
    this.viewCountbyId = []
    let cont: number = 0

    for (let x = 0; x < this.contentDuration; x += 10) {
      cont += 10
      let contView: number = 0
      this.barChartLabels3.push((cont - 10).toString() + "-" + (cont).toString())
      this.dataTracker.forEach(userTrack => {
        if (userTrack.id == searchId) {
          this.studSelected = userTrack.name + " " + userTrack.surname

          userTrack.courseContentTracker.forEach(out => {
            if ((out.startPosition <= cont) && (cont - 10 <= out.stopPosition)) {
              contView += 1
            }
          });
        }
      });
      this.viewCountbyId.push(contView)
    }
    this.barChartData3.push({ data: this.viewCountbyId, label: "Views", backgroundColor: 'rgba(103, 58, 183, 0.5)', hoverBackgroundColor: 'rgba(103, 58, 183, 0.8)' });

    this.barChartLabels3[this.barChartLabels3.length-1]  = (cont - 10).toString() + "-" + this.contentDuration.toString()
  }

  ngAfterViewInit() {
    this.courseService.getContentTrackerAll(this.contentId).subscribe(res => {
      this.dataTracker = res
      this.dataSrc.data = res;
      this.dataSrc.sort = this.sort;
      this.dataSrc.paginator = this.paginator;
      this.table.dataSource = this.dataSrc;

      this.yearChange()
      this.viewMost()
    });
  }

  doFilter(e) {
    this.dataSrc.filter = e.target.value.trim().toLowerCase();
  }
}

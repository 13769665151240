import { Component, HostListener, OnInit } from '@angular/core';
import { PackageDTO } from 'src/app/models/dto/packageDTO';
import { AuthService } from 'src/app/services/auth.service';
import { Location, LocationStrategy } from '@angular/common';
import { User } from 'src/app/models/user';
import { PackageContentType, PackageState } from 'src/app/models/packageState';
import { PackageService } from 'src/app/services/package.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helpers/helper';
import { AddSubscriptionComponent, CrudSubscription, SubscriptionType } from 'src/app/popup/add-subscription/add-subscription.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectionMode, SelectionService } from 'src/app/services/selection.service';
import { PackageContentDTO } from 'src/app/models/dto/packageContentDTO';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { CartService } from 'src/app/ecommerce/service/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionUserDTO } from 'src/app/models/dto/subscriptionUserDTO';
import { SharePopupComponent } from 'src/app/popup/share-popup/share-popup.component';
import { PackageAddComponent } from './package-add/package-add.component';
import { TranslationService } from 'src/app/services/translation.service';
import { AddUserListPopupComponent } from 'src/app/popup/add-user-list-popup/add-user-list-popup.component';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MasterState } from 'src/app/models/masterState';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { CARDS_MARGIN, COMPACT_CARD_WIDTH, STANDARD_CARD_WIDTH } from '../showcase/showcase.component';
import { differenceInDays } from 'date-fns';

const PAGE_PADDING: number = 40; //px
const CARDS_GAP: number = 40; //px

const PACKAGE_CARD_SIZE: number = 450; //px

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.rowSize = Math.floor((window.innerWidth - (PAGE_PADDING * 2) - (this.hidePackageCard ? 0 : PACKAGE_CARD_SIZE)) / this.cardSize);
  }

  id: number;
  currentUser: User = null;
  package: PackageDTO = null;
  elements: PackageContentDTO[] = [];
  elementsCol: string[] = ['type', 'name', 'price', 'goto'];
  currentUserHavePaid: boolean = false;
  currentUserSubscription: SubscriptionUserDTO = null;
  currentUserAlreadyHaveEntities: boolean = true;
  remainingDays: number = 0;
  tableList: boolean = false;
  isMobile: boolean = false;
  showDeepLinkButton: boolean = false;
  hidePackageCard: boolean = false;

  compactMode: boolean = false;
  cardSize: number = 0;
  rowSize: number = 0;
  
  constructor(private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private location: Location,
              private packageService: PackageService,
              private cart: CartService,
              private selectionService: SelectionService,
              private navBar: NavBarService,
              private deviceService: DeviceDetectorService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private locationStrategy: LocationStrategy,
              private translation: TranslationService) { }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.currentUser = this.auth.getCurrentUser();
    this.compactMode = this.currentUser.frontEndType === 1;
    this.isMobile = this.deviceService.isMobile();
    this.hidePackageCard = this.isDT();
    
    this.cardSize = CARDS_GAP + CARDS_MARGIN + (this.compactMode ? COMPACT_CARD_WIDTH : STANDARD_CARD_WIDTH);
    this.onResize(undefined);

    if (this.isMobile) {

      this.navBar.hide('all');

      history.pushState(null, null, window.location.href);
      // check if back or forward button is pressed.
      this.locationStrategy.onPopState(() => {
          history.pushState(null, null, window.location.href);
      });
    }

    this.getPackage();
  }

  getPackage () {
    this.packageService.getPackage(this.id, this.auth.isAuthenticated())
      .subscribe(output => {
        this.package = output;
        this.elements = this.package.packageContent;

        if(this.elements.length === 0)
          this.tableList = true;
          
        if (!this.auth.isAuthenticated())
          return;

        if (this.package.subscription != null && this.package.subscription.subscriptionUser != null) {
          //Filtro i record per user e prendo il più recente
          this.currentUserSubscription = Helper.getLatestSubscriptionUser(this.package.subscription.subscriptionUser, this.currentUser.id);

          this.currentUserHavePaid = this.currentUserSubscription != null;
          if (this.isMobile)
            if (this.currentUserHavePaid || this.currentUser?.id === this.package?.idAuthor) {
              this.showDeepLinkButton = true;
            }

          this.remainingDays = this.getRemainingDays(this.currentUserSubscription);
        }

        this.checkEntitiesForUser();
      });
  }

  addSubscription () {
    let data: CrudSubscription = {
      entityId: this.package.id,
      entityType: SubscriptionType.package,
      subscription: this.package.subscription
    };
    
    const dialogRef = this.dialog.open(AddSubscriptionComponent, {
      data: data, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPackage();
      }
    });
  }

  addToCart () {
    this.cart.addPackage(this.package);
    this.router.navigateByUrl('/cart');
  }

  payPackageFree() {
    this.packageService.buyPackage(this.package.idSubscription)
      .subscribe({
        next: async () => {
          this.snackBar.open(await firstValueFrom(this.translate.get(this.isMobile ? 'Package added to your entities' : 'Package paid, you can now access the elements from the sidebar')), '', { duration: 5000 });
          this.getPackage();
        },
        error: err => this.snackBar.open(err.error.message, '', { duration: 3000 })
      });
  }

  addElements () {
    this.selectionService.startSelection(this.package.id, SelectionMode.package, this.package.id, this.elements);
  }

  changeState () {
    let newState = this.package.state === PackageState.online
                 ? this.package.state - 1
                 : this.package.state + 1;

    this.packageService.setPackageState(newState, this.package.id)
      .subscribe({
        next: () => {
          this.snackBar.open('Package state changed', 'Dismiss', { duration: 3000 });
          this.getPackage();
        },
        error: err => {
          this.snackBar.open(err.error.Message, 'Dismiss', { duration: 3000 });
        }
      });
  }

  deletePackage() {
    const dialog = this.dialog.open(GenericPopupComponent,
      {
        width: '400px',
        data: <GenericPopupData>{
          title: 'Warning',
          body: `Are you sure to delete ${this.package.name}?`
        }
      });

    dialog.afterClosed()
      .subscribe(res => {

        if (!res)
          return;

        this.packageService.deletePackage(this.package.id)
          .subscribe({
            next: () => {
              this.snackBar.open('Package deleted successfully', null, { duration: 3000 });
              this.goBack();
            },
            error: err => {
              console.error(err);
              this.snackBar.open(err.error.Message);
            }
          });
      });
  }

  getPackageState() {
    return PackageState;
  }

  getHelper() {
    return Helper;
  }

  goBack() {
    this.location.back();
  }

  //Table functions
  getTypeTitle(type: PackageContentType) {
    if (type === PackageContentType.master) return 'Live Digital Academy';
    if (type === PackageContentType.course) return 'Training Course';

    return null;
  }

  getTypeIcon(type: PackageContentType) {
    if (type === PackageContentType.master) return 'people';
    if (type === PackageContentType.course) return 'book';

    return null;
  }

  getEntityPicture(entity: PackageContentDTO) {
    if (entity.type === PackageContentType.master) return entity.master.imageUrl;
    if (entity.type === PackageContentType.course) return entity.course.imageUrl;

    return null;
  }

  getEntityName(entity: PackageContentDTO) {
    if (entity.type === PackageContentType.master) return entity.master.name;
    if (entity.type === PackageContentType.course) return entity.course.name;

    return null;
  }

  getEntityHeader(entity: PackageContentDTO) {
    if (entity.type === PackageContentType.master) return entity.master.header;
    if (entity.type === PackageContentType.course) return entity.course.header;

    return null;
  }

  getEntityPrice(entity: PackageContentDTO) {
    let subscription: SubscriptionDTO = null;

    if (entity.type === PackageContentType.master) subscription = entity.master.subscription;
    if (entity.type === PackageContentType.course) subscription = entity.course.subscription;

    if (subscription != null)
      return subscription.isFree ? 'Free' : `${subscription.subscriptionFee.euro} €`;

    return '-';
  }

  getEntityState(entity: PackageContentDTO) {
    if (entity.type === PackageContentType.master) return MasterState.getState(entity.master.state);
    if (entity.type === PackageContentType.course) return entity.course.state ? 'Online' : 'Offline';

    return null;
  }

  goTo(entity: PackageContentDTO) {
    let navigateTo = [];

    if (entity.type === PackageContentType.master) navigateTo = ['/master', entity.master.id];
    if (entity.type === PackageContentType.course) navigateTo = ['/course', entity.course.id];

    this.router.navigate(navigateTo, { queryParams: this.getQueryParams() });
  }

  getEntityProfileId(entity: PackageContentDTO) {
    if (entity.type === PackageContentType.master) return entity.master.idAuthor;
    if (entity.type === PackageContentType.course) return entity.course.idAuthor;

    return null;
  }

  getEntityDescription(entity: PackageContentDTO) {
    if (entity.type === PackageContentType.master) return entity.master.description;
    if (entity.type === PackageContentType.course) return entity.course.description;

    return null;
  }

  share() {
    this.dialog.open(SharePopupComponent, {
      width: '400px', data: { title: this.package.name }
    });
  }

  getDisabledBuyBtn() {
    if (this.currentUserHavePaid)
      return this.package.state !== PackageState.online || this.remainingDays > 10;

    return this.currentUserAlreadyHaveEntities;
  }

  private getQueryParams() {
    return { page: this.currentUserHavePaid ? 'sidenav' : 'showcase', from: 'package' };
  }

  private checkEntitiesForUser() {
    this.currentUserAlreadyHaveEntities = false;

    /* Tolto check di verifica se un utente ha già acquistato alcune entita del package
    this.packageService.userAlreadyHasSomeEntities(this.package.id)
      .subscribe({
        next: () => this.currentUserAlreadyHaveEntities = false,
        error: () => this.currentUserAlreadyHaveEntities = true
      });
    */
  }

  private getRemainingDays(userSubscription: SubscriptionUserDTO) {
    if (!this.currentUserHavePaid)
      return 0;

    if (this.package.state !== PackageState.online)
      return 0;

    let now = new Date();
    let startDate = new Date(userSubscription.stateLastChangedDate);

    if (now < startDate)
      return this.package.durationDays;

    let stopDate = startDate;
    stopDate.setDate(stopDate.getDate() + this.package.durationDays);

    if (now < stopDate)
      return differenceInDays(stopDate, now);

    return 0;
  }

  getRemainingColor() {
    let remainingPercentage = (this.remainingDays / this.package.durationDays) * 100;

    if (remainingPercentage < 10)
      return 'red';

    if (remainingPercentage < 20)
      return 'orange';

    if (remainingPercentage < 30)
      return 'yellow';
  }

  updatePackage() {
    const dialogRef = this.dialog.open(PackageAddComponent, {
      data: { package: this.package }, width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getPackage();
    });
  }

  getSubTitle() {
    return this.translation.currentLang === 'it' ? 'Iva inclusa' : null;
  }

  addPaidUsers() {
    let users = [];

    if (this.package != null && this.package.subscription != null)
      users = this.package.subscription.subscriptionUser.map(u => u.idUser);

    let data: any = {
      type: "package",
      subscriptionId: this.package.idSubscription,
      subscriptionType: this.package.subscription.isFree,
      alreadyPaidUsers: users,
      subscription : this.package.subscription,
      training: this.package.name
    };

    const dialogRef = this.dialog.open(AddUserListPopupComponent, { data: data });

    dialogRef.afterClosed()
      .subscribe(() => this.getPackage());
  }

  changeVisibility($event: any) {
    this.packageService.updatePackageVisibility(this.package.id, $event.value)
      .subscribe({
        next: () => this.snackBar.open('Visibility changed', '', { duration: 3000 }),
        error: err => this.snackBar.open(err.error.message, '', { duration: 3000 })
      });
  }
  
  showList() {
    this.tableList === false ? this.tableList = true : this.tableList = false;
  }

  isDT() {
    return this.auth.isDT();
  }

  createDeepLink(packageId: number) {
    window.location.href =`${EnvironmentHelper.getConfigAuto(this.auth, 'url')}/mobile-app-link/package/${packageId}`
  }

  isDev() {
    return EnvironmentHelper.isDev();
  }

  isAixp() {
    return EnvironmentHelper.isAixp();
  }

  gridTemplateColumns() {
    let total = this.rowSize;

    if (this.elements.length < total)
      total = this.elements.length;

    let columns = '';

    for (let i=0; i<total; i++)
      columns += 'auto ';

    return columns;
  }

}

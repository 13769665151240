import { Component, OnInit } from '@angular/core';
import { LessonSessionDTO } from 'src/app/models/dto/lessonSessionDTO';
import { firstValueFrom, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AirTimeDTO } from 'src/app/models/dto/airTimeDTO';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { RoomDTO } from 'src/app/models/dto/roomDTO';
import { CsvExporterService } from 'src/app/services/csv-exporter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user';
import { ActivatedRoute } from '@angular/router';
import { subDays } from 'date-fns';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
    selector: 'app-registry',
    templateUrl: './registry.component.html',
    styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {
    currentUser: User;
    exportMode: boolean = false;
    dataSource: AirTimeDTO[] = [];
    lessons: LessonSessionDTO[] = [];
    startDate: UntypedFormControl = new UntypedFormControl(subDays(new Date(), 2), [Validators.required]);
    endDate: UntypedFormControl = new UntypedFormControl(new Date(), [Validators.required]);
    classroom: UntypedFormControl = new UntypedFormControl(0);
    teacher: UntypedFormControl = new UntypedFormControl(0);
    room: UntypedFormControl = new UntypedFormControl(0);
    event: UntypedFormControl = new UntypedFormControl('');

    classrooms: ClassroomDTO[] = [];
    teachers: UserDTO[] = [];
    rooms: RoomDTO[] = [];
    events: { eventId: string, classId: number }[] = [];

    columnsToDisplay = ['surname', 'name', 'startDate', 'endDate', 'timeInLessons', 'room', 'station'];
    constructor(private auth: AuthService,
                private calendar: CalendarService,
                private classroomService: ClassroomService,
                private exporter: CsvExporterService,
                private snackBar: MatSnackBar,
                private route: ActivatedRoute) { }

    ngOnInit() {
        this.currentUser = this.auth.getCurrentUser();

        if (this.route.snapshot.queryParamMap.has('mode'))
            this.exportMode = this.route.snapshot.queryParamMap.get('mode') === 'export';

        this.fetchTeacher(this.currentUser.idCustomer);
        this.fetchClassRoom();
        this.fetchRooms();
        this.fetchEvents();
    }

    fetchEvents(): void {
        this.lessons = null;

        this.calendar.getLessons(
            this.startDate.value,
            this.endDate.value,
            this.classroom.value === 0 ? undefined : this.classroom.value,
            this.teacher.value === 0 ? undefined : this.teacher.value,
            this.room.value === 0 ? undefined : this.room.value,
            1,
            !this.currentUser.isAdmin ?
            this.currentUser.idCustomer :
            undefined
        ).subscribe({
            next: lesson => this.lessons = lesson,
            error: err => {
                console.log(err)
                this.lessons = [];
            }
        });
    }

    fetchClassRoom() {
        this.classroomService.getClassrooms()
            .subscribe(output => {
                this.classrooms = output as ClassroomDTO[];

                if (this.exportMode)
                    this.events = this.classrooms
                        .filter(c => c.eventId != null)
                        .map(c => ({ eventId: c.eventId, classId: c.id }));
                    
            });
    }

    fetchTeacher(userOrCustumerId: number) { //roomId: number, 
        let result: Observable<UserDTO[]>;
        this.teachers = undefined;

        result = this.calendar.getTeachersForAll(userOrCustumerId);

        if (result !== undefined) {
            result.subscribe(output => (this.teachers = output));
        }
    }

    fetchRooms() {
        let result: Observable<RoomDTO[]>;

        result = this.calendar.getRoomsForAll();

        if (result !== undefined)
            result.subscribe(output => (this.rooms = output));
    }

    getUsers(lessonId: number) {
        this.dataSource = null;
        this.calendar.getAirTimeOfLesson(lessonId)
            .subscribe({
                next: output => {
                    this.dataSource = output;
                },
                error: err => {
                    this.dataSource = [];
                    console.error(err);
                }
            });
    }

    getPercentage(startDateL: Date, endDateL: Date, startDateS: Date, endDateS: Date) {
        let startL = new Date(startDateL);
        let endL = new Date(endDateL);
        let startS = new Date(startDateS);
        let endS = new Date(endDateS);

        let StudentTime = endS.getTime() - startS.getTime();
        let LessonTime = endL.getTime() - startL.getTime();

        if (LessonTime < 1000)
            return 0;

        return (StudentTime / LessonTime) * 100;
    }

    lessonOpen(lesson: LessonSessionDTO) {
        this.dataSource = [];
        if (lesson.state != 2)
            this.getUsers(lesson.id);
    }

    classroomChange() {
        this.teacher.setValue(0);
        this.fetchEvents();
    }

    teacherChange() {
        this.classroom.setValue(0);
        this.fetchEvents();
    }

    lessonExportToCsv(event: Event, lesson: LessonSessionDTO) {
        event.stopPropagation();

        if (lesson.state === 2) {
            this.snackBar.open("The Lesson has not yet started.", "Dismiss", {
                duration: 3000,
                verticalPosition: 'top'
            });

            return;
        }

        this.calendar.getAirTimeOfLesson(lesson.id)
            .subscribe(output => {
                if (output.length == 0) {
                    this.snackBar.open("No users were connected to the lesson", "Dismiss", {
                        duration: 3000,
                        verticalPosition: 'top'
                    });
                    
                    return;
                }

                this.exporter.exportAirTimesToCsv([{ data: output, lesson: lesson }]);
            });
    }

    async lessonExportAllToCsv() {
        if (!this.lessons || this.lessons.length === 0)
            return;

        let exportable = [];

        for (let i = 0; i < this.lessons.length; i++) {
            await firstValueFrom(this.calendar.getAirTimeOfLesson(this.lessons[i].id))
                .then(output => {
                    if (!output || output.length === 0)
                        return;

                    exportable.push({ data: output, lesson: this.lessons[i] });
                })
                .catch(err => console.error(err));
        }

        if (exportable.length === 0)
            return;

        let start = new Date(this.startDate.value).toLocaleString();
        let end = new Date(this.endDate.value).toLocaleString();

        this.exporter.exportAirTimesToCsv(exportable, `All lessons from ${start} to ${end}`, `All_lessons_${start}_${end}`);
    }
}

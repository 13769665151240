import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentHelper } from 'src/app/models/environmentVariables';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gammadonna-login',
  templateUrl: './gammadonna-login.component.html',
  styleUrls: ['./gammadonna-login.component.scss']
})
export class GammadonnaLoginComponent {

  isMobile: boolean = false;
  mode: 'login' | 'signup' = 'login';
  idCustomer: number = 8;
  customerCode: string = 'gammadonna2030';
  logo: string = EnvironmentHelper.getConfig('gd', this.themeService.isSetDark ? 'logoLight' : 'logoDark');
  background: string = EnvironmentHelper.getConfig(undefined, 'backgroundImage');
  name: string = EnvironmentHelper.getConfig(undefined, 'name');
  motto: string = environment.applicationMotto;

  constructor(private themeService: DarkThemeService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
  }

  changeMode(newMode: 'login' | 'signup') {
    this.mode = newMode;
  }

  isLoginMode() {
    return this.mode === 'login';
  }

  isSignUpMode() {
    return this.mode === 'signup';
  }
}

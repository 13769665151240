
<h1  [style.margin-top]="popupSettings.titleMarginTop" mat-dialog-title>{{ popupSettings.title }}</h1>
<mat-dialog-content  style="white-space: pre-line">
    {{ popupSettings.body }}
</mat-dialog-content>
<mat-dialog-actions *ngIf="!popupSettings.noButtons" fxLayoutAlign="{{popupSettings.btnAlign}}" [style.margin-top]="popupSettings.btnMarginTop">
    <button mat-stroked-button  *ngIf="!popupSettings.hideCancelBtn" (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent"   *ngIf="!popupSettings.hideOkBtn" (click)="closePopUp()" cdkFocusInitial>Ok</button>
    <button mat-flat-button color="primary"  *ngIf="popupSettings.showGoBtn"  (click)="goToPage(popupSettings.page)">{{'Leave' | translate}}</button>
</mat-dialog-actions>


import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SurveyService } from 'src/app/services/survey.service';
import { ActivatedRoute } from '@angular/router';
import { SurveyDTO } from 'src/app/models/dto/surveyDTO';
import { SurveyAnswerDTO } from 'src/app/models/dto/surveyAnswerDTO';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyLessonDTO } from 'src/app/models/dto/lessonSessionDTO';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserDTO } from 'src/app/models/dto/userDTO';

@Component({
  selector: 'app-survey-results-page',
  templateUrl: './survey-results-page.component.html',
  styleUrls: ['./survey-results-page.component.scss']
})
export class SurveyResultsPageComponent implements OnInit {

  surveyLesson: SurveyLessonDTO;
  surveyDto: SurveyDTO;
  idClassroom: number;
  idSurvey: number;
  idLesson: number;
  surveyAnswers: SurveyAnswerDTO[];
  risposta: string;
  students: SurveyAnswerDTO[];

  particpantsCol: string[] = ['participant', 'joined', 'customer'];
  risposte: string;
  SurveyVisible = false;
  selectedStudent: string;
  selectedScore: number;
  selectedScoreA: number;
  selectedScoreNote: string;
  selectedIdAnswer:number;
  surveyModel: Model;
  analyticsLoaded = false;

  constructor(private location: Location,
              private surveyService: SurveyService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar) { }

  ngOnInit(): void {

    if (this.route.snapshot.params['id']) {

      this.idSurvey = this.route.snapshot.params['id'];
      this.idLesson = this.route.snapshot.queryParams['idLesson'];
      this.idClassroom = this.route.snapshot.queryParams['idClassroom'];

      this.surveyService.getSurvey(this.idSurvey)
        .subscribe(output => this.surveyDto = output);

      this.surveyService.getSurveyAnswers(this.idLesson)
        .subscribe(output =>  this.students = output as SurveyAnswerDTO[]);

    } else {

        if (this.surveyService.currentSurveyLesson)
          this.surveyLesson = this.surveyService.currentSurveyLesson;

    }

  }

  goBack() {
    this.location.back();
  }

  showAnswer(studentAnswer: UserDTO) {
   
    this.risposta = JSON.stringify(studentAnswer.surveyAnswer[0].surveyJSON);

    this.selectedStudent = studentAnswer.surname + ' ' + studentAnswer.name;
    this.selectedScore = studentAnswer.surveyAnswer[0].score;
    this.selectedScoreA = studentAnswer.surveyAnswer[0].scoreA;
    this.selectedScoreNote = studentAnswer.surveyAnswer[0].scoreNote;
    this.selectedIdAnswer = studentAnswer.surveyAnswer[0].id;

    this.risposta = studentAnswer.surveyAnswer[0].surveyJSON;
  
    this.showSurvey(this.surveyDto.surveyJSON,studentAnswer.surveyAnswer[0].surveyJSON);
  }

  async showSurvey(mySurvey: string, risposta: string) {
    this.surveyModel = new Model(mySurvey);

    this.surveyModel.mode = "display";
    this.surveyModel.questionsOnPageMode = "singlePage";
    this.surveyModel.showNavigationButtons = "none";
    this.surveyModel.showProgressBar = "off";
    this.surveyModel.showTimerPanel = "none";
    this.surveyModel.maxTimeToFinishPage = 0;
    this.surveyModel.maxTimeToFinish = 0;
    let correctStr = await firstValueFrom(this.translate.get("Correct"));
    let inCorrectStr = await firstValueFrom(this.translate.get("Incorrect"));
    this.surveyModel.data = JSON.parse(risposta);
      
    function getTextHtml(text, str, isCorrect) {
        if (text.indexOf(str) < 0) return undefined;
        var color = isCorrect ? 'green' : 'red';
        return text.substring(0, text.indexOf(str)) + "<span style='color: " + color + "'>" + str + "</span>";
      }

    function isAnswerCorrect(q) {
        let right=q.correctAnswer;
        if(right === undefined || q.isEmpty()) return undefined;
        var left=q.value;
        if(!Array.isArray(right)) return right == left;
        if(!Array.isArray(left)) left=[left];
        for(var i=0; i < left.length; i ++) {
            if(right.indexOf(left[i]) < 0) return false;
        }
        return true;
    }
    
    function renderCorrectAnswer(q) {
        if(!q) return;
        let isCorrect = isAnswerCorrect(q);
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        else
            q.title =  q.prevTitle + ' ' + (isCorrect ? correctStr : inCorrectStr);
    }
    
    this.surveyModel.onValueChanged.add((sender, options) => {
        renderCorrectAnswer(options.question);
    });
    
    this.surveyModel.onTextMarkdown.add((sender, options) => {
        var text = options.text;
        var html = getTextHtml(text, correctStr, true);
        if (!html) {
            html = getTextHtml(text, inCorrectStr, false);
        }
        if (!!html) {
            options.html = html;
        }
    });
    
    this.surveyModel.getAllQuestions().forEach(q => renderCorrectAnswer(q));
    this.surveyModel.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
    });

    this.surveyModel.data = JSON.parse(risposta);

    this.SurveyVisible = true;
  }

  loadAnalytics() {

    if (this.analyticsLoaded)
      return;

    let surveyResultNode = document.getElementById("surveyResult");
    surveyResultNode.innerHTML = "";

    this.surveyService.getSurveyAnswersClassroom(this.idClassroom, this.idLesson)
      .subscribe(res => {

        let validAnswers = res
          .filter(r => r.surveyAnswer.length > 0)
          .map(r => JSON.parse(r.surveyAnswer[0].surveyJSON));

          let surveyModel = new Model(this.surveyDto.surveyJSON);

          let visPanel = new VisualizationPanel(
            surveyModel.getAllQuestions(),
            validAnswers,
            {
              labelTruncateLength: 27,
            }
          );

          visPanel.showToolbar = true;
          visPanel.render(surveyResultNode);

          this.analyticsLoaded = true;
      });
  }

  saveScore() {
    this.surveyService.putSurveyScore(this.selectedIdAnswer, this.selectedScore, this.selectedScoreNote)
    .subscribe({
      next: data => {
        console.log(data);
        this.snackbar.open('score saved!', '', { duration: 3000 });
      },
      error: err => {
        console.log(err);
        this.snackbar.open(err.error.Message, '', { duration: 3000 } );
      }
    });
  }

}

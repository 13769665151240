import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';
import { SubscriptionSessionDTO, SubscriptionSessionList } from 'src/app/models/dto/subscriptionSessionDTO';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {
    bckEndUrlOrders = `${environment.apiUrl}/api/v2/orders`;

    constructor(private http: HttpClient) { }

    getAll(): Observable<SubscriptionSessionList> {
        var param: HttpParams = new HttpParams()
            .set("PageLimit", `10000`)
            .set("PageIndex", `1`);

        return this.http.get<SubscriptionSessionList>(`${this.bckEndUrlOrders}`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    getPaymentsList(): Observable<SubscriptionSessionList> {
        var param: HttpParams = new HttpParams()
            .set("PageLimit", `10000`)
            .set("PageIndex", `1`);

        return this.http.get<SubscriptionSessionList>(`${this.bckEndUrlOrders}/payments`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
    }

    getSubSessions(subscriptionSessionId: number) {
        return this.http.get<SubscriptionSessionDTO>(`${this.bckEndUrlOrders}/order/${subscriptionSessionId}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

}

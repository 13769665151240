<span>
  <div class="post-action" style="margin-bottom: 20px">
    <mat-card appearance="outlined" class="mat-elevation-z0 outline">
      <mat-card-header style="align-items: center; margin-bottom: 20px;">
        <app-profile-picture mat-card-avatar
            [url]="profilePicture"
            size="52px">
          </app-profile-picture>
        <mat-card-title>
          {{ "Update Status" | translate }}
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-form-field appearance="outline" style="width: 100%;" color="primary">
          <mat-label>{{ "What's on your mind" | translate }}?</mat-label>
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5" [formControl]="postText"></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%" *ngIf="postText.value.length > 0" color="primary">
          <mat-label>{{ 'Title' | translate }}</mat-label>
          <input matInput [formControl]="postTitle" />
          <mat-error *ngIf="postTitle.hasError('maxlength')">
            {{ 'The title must be a maximum of 256 characters' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field [style.margin-top]="postTitle.hasError('maxlength') ? '10px' : ''" appearance="outline" *ngIf="postText.value.length > 0 && classroomId < 0" color="primary">
          <mat-label>{{ 'Who should see this' | translate }}?</mat-label>
          <!--  [(value)]="visibility.value" -->
          <mat-select [formControl]="visibility">
            <!-- <mat-option value="2"
              *ngIf="currentUser.isCustomerAdmin || currentUser.isAdmin">
              {{ 'Global' | translate }}
            </mat-option> -->
            <!--*ngIf="userAllowed.global"-->
            <mat-option value="1" *ngIf="currentUser.isCustomerAdmin || currentUser.isAdmin || currentUser.isAuthor">
              {{ 'Public' | translate }}
            </mat-option>
            <mat-option value="0">{{ 'Private' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="
            postText.value.length > 0 &&
            visibility.value == 0 &&
            classroomId < 0" 
            style="border: solid 1px rgb(192, 192, 192, 0.5); border-radius: 5px">
          <mat-selection-list [formControl]="selectClassroom" appearance="outline">
            <!-- selected -->
            <mat-list-option  *ngFor="let item of classrooms" [value]="item.id">
              {{ item.name }}
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div *ngIf="
            postText.value.length > 0 &&
            visibility.value == 1 &&
            classroomId < 0 &&
            currentUser.isAdmin
          " style="border: solid 1px rgb(192, 192, 192, 0.5); border-radius: 5px">
          <mat-selection-list [formControl]="selectCustomer" appearance="outline">
            <!-- [selected]="item.id == currentUser.idCustomer" -->
            <mat-list-option *ngFor="let item of customersArr" [value]="item.id">
              {{ item.name }}
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div>
          <p *ngIf="filePicture.length > 0">{{ 'List of Attached Pictures' | translate }}</p>
          <table>
            <tr *ngFor="let item of filePicture" [style.color]="darkService.isSetDark ? 'white' : ''">
              <td>{{ item.name }}</td>
              <td>
                <button mat-icon-button (click)="removePic(item)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div>
          <p *ngIf="fileVideo.length > 0">{{ 'List of Attached Videos' | translate }}</p>
          <table>
            <tr *ngFor="let item of fileVideo" [style.color]="darkService.isSetDark ? 'white' : ''">
              <td>{{ item.name }}</td>
              <td>
                <button mat-icon-button (click)="removeVideo(item)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div>
          <p *ngIf="attachLink.length > 0">{{ 'List of Attached Links' | translate }}</p>
          <table>
            <tr *ngFor="let item of attachLink" [style.color]="darkService.isSetDark ? 'white' : ''">
              <td>
                <a target="_blank" [href]="item.contentLink">{{
                  item.description
                  }}</a>
              </td>
              <td>
                <button mat-icon-button (click)="removeLink(item)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
              <!-- <td><button mat-icon-button (click)="removeVideo(item)">
                <mat-icon>close</mat-icon>
              </button></td>
          </tr> -->
            </tr>
          </table>
        </div>

        <div>
          <p *ngIf="attachEtcLink.length > 0">{{ 'List of Attached Contents' | translate }}</p>
          <table>
            <tr *ngFor="let item of attachEtcLink" >
              <td>
                <a>{{ item.description }}</a> <!--[href]="item.contentLink" non funzionante-->
              </td>
              <td>
                <button mat-icon-button (click)="removeEtcLink(item)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </div>

        <link-preview [fromString]="userInputLinks"></link-preview>
      </mat-card-content>

      <mat-card-actions align="end" *ngIf="postText.value.length > 0">
        <button mat-icon-button style="margin: 0; padding: 0" (click)="addCourseLink()" [matTooltip]="'Add content' | translate">
          <mat-icon>school</mat-icon>
        </button>
        <button mat-icon-button style="margin: 0; padding: 0" (click)="fileInput.click()" [matTooltip]="'Add pictures' | translate">
          <input style="display: none" type="file" #fileInput (change)="onFileChange($event)" accept="image/*" />
          <mat-icon>add_a_photo</mat-icon>
        </button>
        <button mat-icon-button style="margin: 0; padding: 0" (click)="fileInputVideo.click()" [matTooltip]="'Add videos' | translate">
          <input style="display: none" type="file" #fileInputVideo (change)="onFileChange($event)" accept="video/*" />
          <mat-icon>video_call</mat-icon>
        </button>
        <!--
        <button
          mat-icon-button
          style="margin: 0; padding: 0"
          (click)="addLink()"
        >
          <mat-icon>bookmark</mat-icon>
        </button>
        -->
        <button mat-raised-button color="accent"
          [disabled]="postBtnDisabled()"
          (click)="postSocialWall()">
          <mat-icon>done</mat-icon>
          {{ 'Post' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card appearance="outlined" class="mat-elevation-z0 outline" style="margin-top: 20px;">
      <mat-card-header>
        <mat-card-title>
          {{ 'Search' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayoutAlign="center center">
        <!--mat-form-field appearance="legacy" color="primary">
            <mat-label>{{ 'Hashtags' | translate }}</mat-label>
            <mat-select [(ngModel)]="hashTag">
                <mat-option value="all">{{ 'All hashtags' | translate }}</mat-option>
                <mat-option *ngFor="let item of hashTags" [value]="item">{{item}}</mat-option>
            </mat-select>
        </mat-form-field-->
        <mat-form-field color="primary" fxFlex>
          <mat-label>{{ 'Hashtags' | translate }}</mat-label>
          <input matInput type="text"
            [placeholder]="('Search' | translate) + '...'"
            [formControl]="hashTag"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of filteredHashTags | async" [value]="item">
              {{item}}
            </mat-option>
          </mat-autocomplete>
          <button mat-icon-button matSuffix aria-label="Clear" *ngIf="hashTag.value" (click)="hashTag.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- end of post-action-->

  <div>
    <mat-card *ngFor="let item of socialWallArr | hashTagsFilter: hashTag.value"
      appearance="outlined" 
      class="mat-elevation-z0"
      [ngStyle]="{ 'background-color': item.type == 0 ? 'none' : '#bfbbbb' }"
      [style.color]="darkService.isSetDark && item.type == 1 ? 'black': ''"
      style="margin-bottom: 10px">

      <mat-card-header>

        <app-profile-picture mat-card-avatar
          [url]="item.idauthorNavigation.profilePictureUrl"
          size="52px">
        </app-profile-picture>

        <mat-card-title class="card-title"  [innerHTML]="item.title" [style.color]="darkService.isSetDark && item.type == 1 ? 'black': ''"></mat-card-title>
        <mat-card-subtitle [style.color]="darkService.isSetDark  && item.type == 1 ? 'black': ''" style="margin-right:35px;">
          <span style="margin-right: 10px">
            {{ getHtml(item.postText) }}
            {{ item.created | date: "dd/MMMM/yyyy" }}
            -
            {{ item.created | date: "HH:mm" }}
          </span>
          <mat-icon aria-hidden="false" style="vertical-align: middle" *ngIf="item.visibility == 2">
            public
          </mat-icon>
          <mat-icon aria-hidden="false" style="vertical-align: middle" *ngIf="item.visibility == 1">
            people
          </mat-icon>
          <mat-icon aria-hidden="false" style="vertical-align: middle" *ngIf="item.visibility == 0">
            public_off
          </mat-icon>
          <span style="float: right">{{ item.idauthorNavigation.name }} {{ item.idauthorNavigation.surname }}</span>
        </mat-card-subtitle>

      </mat-card-header>

      <!-- *ngIf="item.socialWallContents.length >= 1" -->
      <mat-card-content class="content">

        <div class="pre-content" style="margin-right: 52px;" [innerHTML]="getDescription(item.postText)"></div>

        <div style="margin: 0 auto;width:82%;">
          <link-preview [fromString]="extractLinksFromText(item.postText)"></link-preview>
        </div>

        <div style="height: auto">
          <mat-carousel timings="250ms ease-in" [autoplay]="false" [interval]="5000" color="accent" maxWidth="auto"
            [loop]="true" [hideArrows]="countCarousel(item) <= 1" [hideIndicators]="countCarousel(item) <= 1"
            [useKeyboard]="countCarousel(item) > 1" [useMouseWheel]="false" [maintainAspectRatio]="true"
            [proportion]="56" *ngIf="needCarousel(item)">
            <mat-carousel-slide *ngFor="let content of getCarouselSlides(item, 2)" [hideOverlay]="true">
              <!-- VIDEO -->
              <video class="videoInsert" controls>
                <source [src]="content.contentLink" type="video/mp4" />
              </video>
            </mat-carousel-slide>

            <mat-carousel-slide *ngFor="let content of getCarouselSlides(item, 1)" class="tw-padding" [hideOverlay]="true">
              <!-- PICTURE -->
              <img [src]="content.contentLink" style="width: 100%; object-fit: cover" loading="lazy" />
            </mat-carousel-slide>
          </mat-carousel>

          <div *ngIf="item.socialWallContents.length >= 1">
            <!-- ETC LINK -->
            <div style="text-align: center; margin-top: 20px" *ngFor="let content of item.socialWallContents">
              <div *ngIf="(content.type == 3 || content.type == 5 || content.type == 4) && content.contentLink.split('@@')[0] != ''"
                style="width: 100%; height: 300px; overflow: hidden;">
                <img *ngIf="content.contentLink.split('@@')[0]" [src]="content.contentLink.split('@@')[0]"
                  style="object-fit: contain; width: 82%;" loading="lazy" />
              </div>
              <div *ngIf="content.type == 3 || content.type == 5 || content.type == 4" style="font-size: medium; margin-top: 25px"
                [innerHTML]="content.description"></div>
              <div *ngIf="(content.type == 3 || content.type == 5 || content.type == 4) && content.contentLink.split('@@')[1] != ''"
                style="margin-top: 25px">
                <a mat-raised-button color="accent" (click)="goToEtcContent(content.contentLink.split('@@')[1])"
                  target="_blank">{{ 'Read More' | translate }}</a>
              </div>
            </div>

            <!-- EXTERNAL LINK -->
            <div style="text-align: center; margin-top: 20px" *ngFor="let content of item.socialWallContents">
              <div *ngIf="content.type == 0" [innerHTML]="content.description"></div>
              <div *ngIf="content.type == 0" style="margin-top: 25px">
                <a mat-raised-button [href]="content.contentLink" target="_blank">{{ 'Read More' | translate }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-padding">
          <div style="margin-top: 5px">
            <button mat-button
              (click)="item.isLikeUser ? unlikePost(item.likeid, item.id) : likePost(item.id)"
              [style.color]="darkService.isSetDark && item.type == 1 && !item.isLikeUser ? 'black' : item.isLikeUser ? '#24a0ed' : ''">
              <mat-icon>{{ item.isLikeUser ? 'thumb_up_alt' : 'thumb_up_off_alt' }}</mat-icon>
              {{ 'Like' | translate }}
            </button>

            <button mat-button
              *ngIf="isCustomerAdmin() || item.idauthor === currentUser.id"
              (click)="editPost(item)"
              [style.color]="darkService.isSetDark && item.type == 1  ? 'black': ''">
              <mat-icon>edit</mat-icon>
              {{ 'Edit' | translate }}
            </button>

            <button mat-button
              *ngIf="isCustomerAdmin() || item.idauthor === currentUser.id"
              (click)="deletePost(item.id)"
              [style.color]="darkService.isSetDark && item.type == 1  ? 'black': ''">
              <mat-icon>delete</mat-icon>
              {{ 'Delete' | translate }}
            </button>

            <div style="margin-top:5px" [style.color]="darkService.isSetDark && item.type == 1  ? 'black': ''">
              <a style="cursor: pointer;" [style.color]="darkService.isSetDark ? 'white' : 'black'" *ngIf="item.socialWallLikes.length === 1">
                <span *ngFor="let like of item.socialWallLikes">
                  <span *ngIf="like.iduserNavigation.id === currentUser.id;else otherUser" >
                  {{ like.iduserNavigation.name }} {{ 'like this' | translate }}
                  </span>
                  <ng-template #otherUser>
                    {{ 'like this element' | translate:{ user: like.iduserNavigation.name } }}
                  </ng-template>
                </span>
              </a>
              <a style="cursor: pointer;" [style.color]="darkService.isSetDark ? 'white' : 'black'" *ngIf="item.socialWallLikes.length > 1" (click)="showWhoLike(item.id)">
                <span>{{ item.socialWallLikes[0].iduserNavigation.name }} {{ 'and' | translate}}
                  {{ item.socialWallLikes.length - 1 }} {{ 'other/s like this' | translate }}</span>
              </a>
            </div>

            <button
              mat-button
              (click)="writeAComment(item.id)"
              style="float: right;margin-right: 48px;"
              [disabled]="txtComment.value.length == 0"
              [style.color]="darkService.isSetDark && item.type == 1  ? 'black': ''">
            <mat-icon>comment</mat-icon>
              {{ 'Comment' | translate }}
            </button>
            <!--
          <a
            style="
              display: inline;
              margin-top: 5px;
              float: right;
              cursor: pointer;
            "
            (click)="writeAComment(item.id)"
            ><mat-icon
              aria-hidden="false"
              aria-label=""
              style="vertical-align: middle"
              >comment</mat-icon
            >
            Comment
          </a>
          -->
          </div>
        </div>

        <div style="width: 82%" [style.color]="darkService.isSetDark && item.type == 1 ? 'black': ''" > 
          <mat-form-field
            appearance="outline"
            style="margin-bottom: -20px !important"
            class="tw-padding tw-comment-input"
            color="primary">
            <mat-label [style.color]="darkService.isSetDark && item.type == 1 ? 'black': ''"> {{ 'Write a comment' | translate }}</mat-label>
            <input
              matInput
              (keydown.enter)="writeAComment(item.id)"
              [formControl]="txtComment"
              [style.color]="darkService.isSetDark && item.type==1 ? 'black': ''"/>
          </mat-form-field>
        </div>

        <!-- parent comment-->
        <div *ngFor="let comment of item.socialWallBlogs" class="tw-padding" style="
            background-color: gainsboro;
            border-radius: 20px;
            display: flex;
            padding: 12px;
            margin-top: 20px;
            margin-left: 52px;
            margin-right: 52px;"
          [style.color]="darkService.isSetDark ? 'black': ''"
          [style.background-color]="darkService.isSetDark ? '#9c9b9b ' : '#9c9b9b !important'">
          <app-profile-picture
            [url]="comment.idauthorNavigation?.profilePictureUrl"
            size="52px"
            style="margin-right: 10px">
          </app-profile-picture>
          <div style="flex: 1">
            <div style="font-weight: bold; padding-bottom: 5px" *ngIf="comment.idauthorNavigation">
              {{ comment.idauthorNavigation.name }} -
              {{ comment.created | date: "dd MMMM YYYY" }} -
              {{ comment.created | date: "HH:mm" }}
            </div>
            <div [innerHTML]="comment.postContent" style="
                padding-bottom: 5px;
                padding-top: 5px;
                word-break: break-all;
                font-size: 14px;
              ">
            </div>
            <div>
              <button mat-button (click)="replyComment(comment.id, comment.idsocialWall)" style="color: black">
                <mat-icon style="vertical-align: middle">reply</mat-icon>
                {{ 'Reply' | translate }}
              </button>
              <span style="float:right;">
                <button mat-button  (click)="editMode(comment)" *ngIf="comment.idauthor === currentUser.id || 
                      (currentUser.isCustomerAdmin && currentUser.idCustomer === item.idauthorNavigation.customerId) || currentUser.isAdmin"
                      style="color: black">
                      <mat-icon>edit</mat-icon>
                      <span> {{ 'Edit' | translate }}</span>
                </button>
                  <button mat-button  *ngIf="comment.idauthor === currentUser.id ||
                      (currentUser.isCustomerAdmin && currentUser.idCustomer === item.idauthorNavigation.customerId) || currentUser.isAdmin"
                      (click)="deleteBlog(comment.id)"
                      style="color: black">
                      <mat-icon>delete</mat-icon>
                      <span> {{ 'Delete' | translate }}</span>
                  </button>
                </span>
            </div>

            <!-- subcomment-->
            <ng-container>
              <div *ngFor="
                  let subcomment of item.SocialWallBlogChild;
                  first as isFirst
                " style="margin-top: 10px">
                <!--<hr *ngIf="isFirst" />-->

                <div *ngIf="subcomment.idparent == comment.id" style="display: flex">
                  <app-profile-picture
                    [url]="subcomment.idauthorNavigation?.profilePictureUrl"
                    size="52px"
                    style="margin-right: 10px">
                  </app-profile-picture>
                  <div style="flex: 1">
                    <div style="font-weight: bold; padding-bottom: 5px" *ngIf="subcomment.idauthorNavigation">
                      {{ subcomment.idauthorNavigation.name }} -
                      {{ subcomment.created | date: "dd MMMM YYYY" }} -
                      {{ subcomment.created | date: "HH:mm" }}
                    </div>
                    <div [innerHTML]="subcomment.postContent" style="
                        padding-bottom: 5px;
                        padding-top: 5px;
                        word-break: break-all;
                        font-size: 14px;">
                    </div>
                    <div>
                      <!-- <button mat-button (click)="replyComment(subcomment.id, subcomment.idsocialWall)">
                        <mat-icon style="vertical-align: middle">reply</mat-icon>
                        {{ 'Reply' | translate }}
                      </button> -->
                      <span style="float:right;">
                        <button (click)="editMode(subcomment)" mat-button
                            *ngIf="subcomment.idauthor === currentUser.id || (currentUser.isCustomerAdmin && currentUser.idCustomer === item.idauthorNavigation.customerId) || currentUser.isAdmin"
                            style="color: black">
                              <mat-icon>edit</mat-icon>
                              <span> {{ 'Edit' | translate }}</span>
                        </button>
                          <button mat-button  *ngIf="subcomment.idauthor === currentUser.id ||
                              (currentUser.isCustomerAdmin && currentUser.idCustomer === item.idauthorNavigation.customerId) || currentUser.isAdmin"
                              (click)="deleteBlog(subcomment.id)"
                              style="color: black">
                              <mat-icon>delete</mat-icon>
                              <span> {{ 'Delete' | translate }}</span>
                          </button>
                        </span>
                    </div>
                  </div>

                  <div style="clear: both"></div>
                  <!--<hr />-->
                  <br />
                </div>
              </div>
            </ng-container>
          </div>
          <div style="clear: both"></div>
          <br />
        </div>
      </mat-card-content>

      <mat-card-actions> </mat-card-actions>
    </mat-card>
  </div>
  <!-- end of post list-->

  <!--
  <div style="text-align: center;margin-top:20px;">
    <button mat-raised-button (click)="loadMore()">Load More..</button>
  </div>
  -->
</span>

import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from 'src/app/services/orders.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomerDTO } from 'src/app/models/dto/customerDTO';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { SubscriptionSessionDTO } from 'src/app/models/dto/subscriptionSessionDTO';
import { Helper } from 'src/app/helpers/helper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CsvExporterService,  HASHTAGS_HEADERS,  Payment } from 'src/app/services/csv-exporter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { SubscriptionDTO } from 'src/app/models/dto/subscriptionDTO';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class OrdersComponent implements OnInit {
  currentUser: User = null;
  currentYear = new Date().getFullYear().valueOf();

  orders: SubscriptionSessionDTO[] = [];
  customers: CustomerDTO[] = [];
  statuses: string[] = [];
  types: string[] = [];
  sources: string[] = [];

  selectedCustomer: number = 0;
  selectedStatus: string = "all";
  selectedType: string = "all";
  selectedSource: string = "all";
  selectedYear: number = this.currentYear;
  selectedSubscription: SubscriptionDTO;

  dataSource: MatTableDataSource<SubscriptionSessionDTO>;
  dataSource2:  MatTableDataSource<SubscriptionSessionDTO>;
  displayedColumns: string[] = ['picture', 'created', 'user', 'cartItemName', 'amount', 'currency', 'paymentStatus', 'paymentType', 'paymentSource', 'action'];
  selectedSubSession: SubscriptionSessionDTO = null;

  subDisplayedColumns: string[] = ['picture', 'cartItemName', 'cartItemDescription'];

  years: number[] = [
    this.currentYear,
    this.currentYear - 1,
    this.currentYear - 2,
    this.currentYear - 3,
    this.currentYear - 4,
    this.currentYear - 5
  ]

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private auth: AuthService,
              private orderService: OrdersService,
              private exporter: CsvExporterService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private calendar: CalendarService) { }

  ngOnInit () {
    this.currentUser = this.auth.getCurrentUser();

    this.orders = null;
    this.orderService.getAll()
      .subscribe(res => {
        this.orders = res.subscriptionList;
        this.orders.sort((a, b) => {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });
        
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'user':
              return `${item.user.name} ${item.user.surname}`;
            default:
              return item[property];
          }
        };

        this.dataSource.filterPredicate = (data, filter) => {
          return `${data.user.name} ${data.user.surname}`.toLowerCase().includes(filter)
              || data.cartItemName.toLowerCase().includes(filter)
              || String(data.amount).includes(filter)
              || data.currency.includes(filter)
              || data.paymentStatus.toLowerCase().includes(filter)
              || data.paymentType.toLowerCase().includes(filter)
              || data.paymentSource.toLowerCase().includes(filter);
        };

        this.statuses = this.orders
          .map(o => o.paymentStatus)
          .filter((value, index, self) => self.indexOf(value) === index);

        this.types = this.orders
          .map(o => o.paymentType)
          .filter((value, index, self) => self.indexOf(value) === index);

        this.sources = this.orders
          .map(o => o.paymentSource)
          .filter((value, index, self) => self.indexOf(value) === index);

        if (this.statuses.includes('paid')) {
          this.selectedStatus = 'paid';
          this.getOrders();
        }
          
    });

    if (this.currentUser.isAdmin)
      this.getCustomers();
  }

  getCustomers() {
    this.customers = [];

    this.calendar.getCustomerForAdmin()
      .subscribe(output => this.customers = output);
  }

  applyFilter (event: Event) {
    let value = (event.target as HTMLInputElement).value;

    this.dataSource.filter = value.trim().toLowerCase();
  }

  getOrders() {
    let orders = this.orders;

    if (this.selectedCustomer !== 0)
      orders = orders.filter(o => o.subscription.idCustomer === this.selectedCustomer);

    if (this.selectedStatus !== "all")
      orders = orders.filter(o => o.paymentStatus === this.selectedStatus);

    if (this.selectedType !== "all")
      orders = orders.filter(o => o.paymentType === this.selectedType);

    if (this.selectedSource !== "all")
      orders = orders.filter(o => o.paymentSource === this.selectedSource);

    orders = orders.filter(o => new Date(o.created).getFullYear().valueOf() === this.selectedYear);
    this.dataSource.data = orders;
  }

  getTotal() {
    return this.dataSource?.filteredData
        .filter(d => d.paymentStatus === 'paid')
        .map(d => d.amount)
        .reduce((a, b) => a + b, 0);
  }

  goToStripe(intent: string) {
    window.open(`https://dashboard.stripe.com/test/payments/${intent}`, "_blank");
  }

  isNullOrEmpty(value: string) {
    return Helper.isNullOrEmpty(value);
  }

  toggleRow(subSession: SubscriptionSessionDTO) {
    this.selectedSubSession = subSession.subscriptionSessions.length === 0
                            ? null
                            : this.selectedSubSession === subSession
                            ? null
                            : subSession;
  }

  collapseRow(subSession: SubscriptionSessionDTO) {
    if (subSession !== this.selectedSubSession)
      this.selectedSubSession = null;
  }

  async paymentsExportToCsv() {
    let data: Payment[] = [];
    let datePipe = new DatePipe('en-US');
    let csvData = this.dataSource.filteredData;
  
    csvData.sort((a, b) => {
      return new Date(b.created).valueOf() - new Date(a.created).valueOf();
    });

    if (csvData.length != 0) {
      csvData.forEach(u => {
        data.push({
          date: datePipe.transform(u.created, 'dd/MM/yyyy'),
          time: datePipe.transform(u.created, 'HH:mm'),
          name: u.user.name,
          surname: u.user.surname,
          email: u.user.email,
          province: u.user?.idprovinceNavigation?.name === undefined ? null : u.user?.idprovinceNavigation?.name ,
          qualification: u.user?.idqualificationNavigation?.name === undefined ? null : u.user?.idqualificationNavigation?.name,
          telephone: u.user.telephone,
          product: u.cartItemName,
          amount: u.amount,
          currency: u.currency,
          type: u.paymentType,
          source: u.paymentSource,
          hashtag1: this.getHashtags(u.subscription.type,u.subscription)[0] ?? '',
          hashtag2: this.getHashtags(u.subscription.type,u.subscription)[1] ?? '',
          hashtag3: this.getHashtags(u.subscription.type,u.subscription)[2] ?? '',
          hashtag4: this.getHashtags(u.subscription.type,u.subscription)[3] ?? '',
          hashtag5: this.getHashtags(u.subscription.type,u.subscription)[4] ?? '',
          hashtag6: this.getHashtags(u.subscription.type,u.subscription)[5] ?? '',
          hashtag7: this.getHashtags(u.subscription.type,u.subscription)[6] ?? '',
          hashtag8: this.getHashtags(u.subscription.type,u.subscription)[7] ?? '',
          hashtag9: this.getHashtags(u.subscription.type,u.subscription)[8] ?? '',
          hashtag10: this.getHashtags(u.subscription.type,u.subscription)[9] ?? ''
        });                     
      });          
    }

    if (!data || data.length === 0) {
      this.snackBar.open(await firstValueFrom(this.translate.get('There are no payments')),undefined, { duration: 3000 });
      return;
    }

    this.exporter.exportDataToCsv(
      data,
      ["Date", "Time", "Name", "Surname", "Email", "Province", "Qualification", "Telephone", "Product", "Amount", "Currency", "Type", "Source", ...HASHTAGS_HEADERS],
      `Received payments of ${this.currentUser.name} ${this.currentUser.surname}`
    );
  }

  getById(element: SubscriptionSessionDTO) {
    this.orderService.getSubSessions(element.id).subscribe({
      next: async res => {
        this.selectedSubSession = res?.subscriptionList[0]?.subscriptionsSessions?.length === 0 ? null : this.selectedSubSession?.id === element.id ? null : res.subscriptionList[0];
        this.dataSource2 = new MatTableDataSource(res.subscriptionList[0]);
        if (this.selectedSubSession?.subscriptionSessions?.length === 0) {
          this.snackBar.open(await firstValueFrom(this.translate.get('No subcontents for this order')), 'Dismiss', { duration: 3000, verticalPosition: 'bottom' });
          this.selectedSubSession = null;
        }
      },
      error: err => console.log(err)
    })
  }

  getHashtags(type:number,subscription: SubscriptionDTO) {
    if(type === 4)
      return subscription.conference.hashTag ?? [];
    if(type === 1)
      return subscription.course.hashTag ?? [];
    if(type === 2) 
      return subscription.package.hashTags?.split(" ") ?? [];
    return [];
  }
}

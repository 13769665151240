import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from 'src/app/helpers/helper';
import { MasterDTO, MasterType } from 'src/app/models/dto/masterDTO';
import { MasterService } from 'src/app/services/master.service';
import { AzureStorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-master-add-preview',
  templateUrl: './master-add-preview.component.html',
  styleUrls: ['./master-add-preview.component.scss']
})
export class MasterAddPreviewComponent implements OnInit {

  master: MasterDTO = null;
  previewToEdit: MasterDTO = null;

  videoSrc: UntypedFormControl = new UntypedFormControl(undefined);
  document1Src: UntypedFormControl = new UntypedFormControl(undefined);
  document2Src: UntypedFormControl = new UntypedFormControl(undefined);
  document3Src: UntypedFormControl = new UntypedFormControl(undefined);
  document4Src: UntypedFormControl = new UntypedFormControl(undefined);
  document5Src: UntypedFormControl = new UntypedFormControl(undefined);
  videoName: string;
  document1Name: string;
  document2Name: string;
  document3Name: string;
  document4Name: string;
  document5Name: string;

  constructor(public dialogRef: MatDialogRef<MasterAddPreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private snackBar: MatSnackBar,
              private azureService: AzureStorageService,
              private masterService: MasterService) { }

  ngOnInit(): void {
    this.master = this.data.master;

    if (this.master.videoPreviewUrl)
      this.videoName = Helper.getFileNameFromURI(this.master.videoPreviewUrl);

    for (var i=1; i<6; i++) {
      var file = this.master[`document${i}Url`];
      if (file) this[`document${i}Name`] = Helper.getFileNameFromURI(file);
    }
  }

  savePreview() {
    this.azureService.postPreview(this.master.id,
                                  this.videoSrc.value,
                                  this.document1Src.value,
                                  this.document2Src.value,
                                  this.document3Src.value,
                                  this.document4Src.value,
                                  this.document5Src.value);

    this.onNoClick();
  }

  deletePreview (previewId: number) {
    this.masterService.deletePreview(this.master.id, previewId)
      .subscribe({
        next: () => {

          if (previewId === 0) {
            this.videoName = null;
            this.master.videoPreviewUrl = null;
          } else {
            this[`document${previewId}Name`] = null;
            this.master[`document${previewId}Url`] = null;
          }

          this.snackBar.open('File deleted', '', { duration: 3000 });
        },
        error: err => this.snackBar.open(err.error.Message, '', { duration: 3000 })
      });
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  isOneToOne() {
    return this.master.type === MasterType.oneToOne;
  }

  getHelper() {
    return Helper;
  }
}

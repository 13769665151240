import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Helper } from 'src/app/helpers/helper';
import { SubscriptionSessionList } from 'src/app/models/dto/subscriptionSessionDTO';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchasedService {
  bckEndUrlPurchased = `${environment.apiUrl}/api/v2/purchased`;

  constructor(private http: HttpClient) { }

  getAll (): Observable<SubscriptionSessionList> {
    var param: HttpParams = new HttpParams()
      .set("PageLimit", `10000`)
      .set("PageIndex", `1`);

    return this.http.get<SubscriptionSessionList>(`${this.bckEndUrlPurchased}`, { headers: Helper.getAuthHeader(), params: param }).pipe(tap(console.log));
  }

  getSinglePurchased (userId: number, subscriptionId: number) {
    return this.http.get<boolean>(`${this.bckEndUrlPurchased}/getSinglePurchased/${userId}/${subscriptionId}`,
      { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
  }
}

 <app-master
    [master]="master"
    [participants]="classroom"
    [availablePlaces]="availablePlaces"
    [lessonsPlannedDuration]="plannedDuration"
    [currentUserHavePaid]="currentUserHavePaid"
    *ngIf="choiceMaster === 'sidenav' && !receiving">
</app-master> 

 <app-master-showcase 
    [master]="master"
    [availablePlaces]="availablePlaces"
    [lessonPlannedDuration]="plannedDuration"
    [currentUserIsParticipant]="currentUserIsParticipant"
    [currentUserIsTeacher]="currentUserIsTeacher"
    [currentUserHavePaid]="currentUserHavePaid"
    *ngIf="choiceMaster === 'showcase' && !receiving">
</app-master-showcase>

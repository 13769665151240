import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/services/calendar.service';
import { ClassroomService } from 'src/app/services/classroom.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { ChartDataset, ChartOptions } from 'chart.js';
import { AttendanceDTO } from 'src/app/models/dto/attendanceDTO';
import { firstBy } from 'thenby';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Helper } from 'src/app/helpers/helper';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-classroom-status',
  templateUrl: './classroom-status.component.html',
  styleUrls: ['./classroom-status.component.scss']
})
export class ClassroomStatusComponent implements OnInit {

  currentUser: User;
  classroomId;
  classroomName: string;
  classUsers = [];
  dataSource = [];
  today: Date = new Date();
  lessonAverageDuration: string = '0';
  lessonAveragePlannedDuration: string = '0';
  lessonAverageAttendance: number = 0.00;
  lessonsAttendance: AttendanceDTO[] = [];
  durationSpinnerValue: number = 0;
  authorName: string;
  created: Date;
  
  columnsToDisplay = ['surname', 'name', 'time', 'date', 'status', 'info'];

  lineChartOptions: ChartOptions = {
    responsive: true,
    elements: {
      line: {
        fill: 'origin',
        tension: 0.4
      },
      point:{
        radius: 0,
        hitRadius: 0
      }
    },
    scales:{
      x: {
          display: false
      },
      y: {
        display: true,
        ticks: {
          stepSize: 50
        },
          max : 100,
          min: 0
        }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };
  public lineChartType = 'line';
  public lineChartData: ChartDataset[] = [{ data: [0], label: '%' }];
  public lineChartLabels: string[] = [];

  constructor(private auth: AuthService,
              private calendar: CalendarService,
              private classroomService: ClassroomService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              public darkService: DarkThemeService) { }

  ngOnInit() {
    this.currentUser = this.auth.getCurrentUser();

    this.classroomId = this.route.snapshot.paramMap.get('id');

    if (this.route.snapshot.queryParamMap.has('classroomName'))
      this.classroomName = this.route.snapshot.queryParamMap.get('classroomName');

    this.getUsersByClassroom();
    this.getAttendanceClassroom();

    if (this.route.snapshot.queryParamMap.has('authorName'))
      this.authorName = this.route.snapshot.queryParamMap.get('authorName');

    if (this.route.snapshot.queryParamMap.has('created'))
      this.created = new Date(this.route.snapshot.queryParamMap.get('created'));
  }

  getUsersByClassroom() {
    firstValueFrom(this.classroomService.getUsersPerClassroom(this.classroomId)).then((res) => {
        if(res.userList)
          this.classUsers = res.userList.filter(u => u.isStudent);
          
      this.fetchOnlineStudents();
    });
  }

  setChartData() {
    var data = [];
    var date;

    for(var i=0; i<this.lessonsAttendance.length; i++) {
      if(this.lessonsAttendance[i].actualDurationPercentage >= 10) {
        data.push(this.lessonsAttendance[i].attendancePercentage);
        date = new Date(this.lessonsAttendance[i].startDate);

        this.lineChartLabels.push((date.getMonth() + 1) + "/" + date.getFullYear());
      }
    }

    this.lineChartData = [{data: data, label: "%", borderColor: 'rgba(103, 58, 183, 1)', backgroundColor: 'rgba(103, 58, 183, 0.5)'}];
  }

  fetchOnlineStudents() {
    this.dataSource = null;
    this.calendar.getStatusStudents(this.classroomId)
    .subscribe({
      next: output => {
        if(output.length == 0){
          console.log('No user online');
          this.dataSource = this.classUsers;

          this.dataSource.forEach(s => {
            s.endDate = "--";
          });
        } else {
          this.dataSource = output;
          this.dataSource.forEach(u => {
            u.timeInSeconds = this.convertToTime(u.timeInSeconds, true);
          });

          var present;
          for(var i=0; i< this.classUsers.length; i++) {
            present = false;

            if(this.classUsers[i].isStudent) {
              for(var j=0; j<this.dataSource.length; j++) {
                if(this.dataSource[j].userId == this.classUsers[i].id)
                  present = true;
              }
    
              if(!present) {
                this.dataSource.push(this.classUsers[i]);
                this.dataSource[this.dataSource.length - 1].timeInSeconds = "00:00";
                this.dataSource[this.dataSource.length - 1].endDate = "--";
              }
            }
          }
        }
      },
      error: err => {
        this.dataSource = [];
        console.log(err);
      }
    });
  }

  convertToTime(seconds: number, string: boolean) {

    if(seconds != null) {
      var hours = seconds/3600;
      var minutes = (seconds % 3600)/ 60;
  
      if(string)
        return hours.toFixed(0) + ":" + minutes.toFixed(0);
      else
        return Number(hours.toFixed(0) + "." + minutes.toFixed(0));
    } else {
      if(string)
        return "00:00";
      else
        return 0;
    }
  }

  goBack() {
    this.location.back();
  }

  updatePage() {
    this.dataSource = null;
    this.getUsersByClassroom();
  }

  getPercentage(actualTime: number, plannedTime: number) {
    if (actualTime < 1000)
        return 0;

    return (actualTime * 100) / plannedTime;
  }

  getAvgPercentage(attendaceTime: number, lessonTime: number) {
    return ((attendaceTime / this.dataSource.length) / lessonTime) * 100;
  }

  spinnerValue(value: number) {
    if(value.toFixed(2).split('.')[1] == "00")
        return value + "%";
    else
        return value.toFixed(2) + "%";
  }

  userStats(id: number, name: string, surname: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
          name: name,
          surname: surname
      }
    };

    this.router.navigate(['/userstatus/', id], navigationExtras);
  }

  getTime(startDate: Date, endDate: Date) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    if(end.getTime() - start.getTime() < 1000)
        return 0;

    return end.getTime() - start.getTime();
  }

  getAttendanceClassroom() {
    this.calendar.getClassroomAttendance(this.classroomId)
      .subscribe(output => {
        var attendancePercentage = 0;
        var actualDurationPercentage = 0;
        var plannedLessonTime = 0;
        var actualLessonTime = 0;

        this.lessonsAttendance = output;
        this.lessonsAttendance.sort(
          firstBy((a: AttendanceDTO) => new Date(a.startDate).getFullYear(), 1)
          .thenBy((a: AttendanceDTO) => new Date(a.startDate).getMonth(), 1));

        output.forEach(l => {
          attendancePercentage += l.attendancePercentage;
          actualDurationPercentage += l.actualDurationPercentage;
          plannedLessonTime += this.getTime(l.startPlanned, l.endPlanned);
          actualLessonTime += this.getTime(l.startDate, l.stopDate);
        });

        this.lessonAverageAttendance = Number((attendancePercentage / output.length).toFixed(2));
        if(!this.lessonAverageAttendance)
          this.lessonAverageAttendance = 0;

        this.durationSpinnerValue = Math.round((actualLessonTime / plannedLessonTime) * 10000) / 100;
        this.lessonAverageDuration = Helper.convertToHMS(actualLessonTime / output.length);
        this.lessonAveragePlannedDuration = Helper.convertToHMS(plannedLessonTime / output.length);

        this.setChartData();
      });
  }
}

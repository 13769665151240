import { Component, OnInit, ViewChild } from '@angular/core';
import { PurchasedService } from 'src/app/services/purchased.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SubscriptionSessionDTO } from 'src/app/models/dto/subscriptionSessionDTO';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-purchased',
  templateUrl: './purchased.component.html',
  styleUrls: ['./purchased.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class PurchasedComponent implements OnInit {
  purchases: SubscriptionSessionDTO[] = [];
  types: string[] = [];
  sources: string[] = [];

  selectedType: string = "all";
  selectedSource: string = "all";

  dataSource: MatTableDataSource<SubscriptionSessionDTO>;  
  displayedColumns: string[] = ['picture', 'created', 'cartItemName', 'amount', 'currency', 'paymentType', 'paymentSource', 'action'];
  selectedSubSession: SubscriptionSessionDTO = null;

  subDisplayedColumns: string[] = ['picture', 'cartItemName', 'cartItemDescription'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private purchasedService: PurchasedService) { }

  ngOnInit() {
    this.purchases = null;
    this.purchasedService.getAll()
      .subscribe(res => {
        this.purchases = res.subscriptionList;
        this.purchases.sort((a, b) => {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });

        this.dataSource = new MatTableDataSource(this.purchases);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'amount':
              return `${item.currency} ${item.amount}`;
            default:
              return item[property];
          }
        };

        this.dataSource.filterPredicate = (data, filter) => {
          return data.cartItemName.toLowerCase().includes(filter)
              || String(data.amount).includes(filter)
              || data.currency.includes(filter)
              || data.paymentType.toLowerCase().includes(filter)
              || data.paymentSource.toLowerCase().includes(filter);
        };

        this.types = this.purchases
          .map(o => o.paymentType)
          .filter((value, index, self) => self.indexOf(value) === index);

        this.sources = this.purchases
          .map(o => o.paymentSource)
          .filter((value, index, self) => self.indexOf(value) === index);
    });
  }

  applyFilter (event: Event) {
    let value = (event.target as HTMLInputElement).value;

    this.dataSource.filter = value.trim().toLowerCase();
  }

  getPurchases() {
    let purchases = this.purchases;

    if (this.selectedType !== "all")
      purchases = purchases.filter(o => o.paymentType === this.selectedType);

    if (this.selectedSource !== "all")
      purchases = purchases.filter(o => o.paymentSource === this.selectedSource);

    this.dataSource.data = purchases;
  }

  toggleRow(subSession: SubscriptionSessionDTO) {
    this.selectedSubSession = subSession.subscriptionSessions.length === 0
                            ? null
                            : this.selectedSubSession === subSession
                            ? null
                            : subSession;
  }

  collapseRow(subSession: SubscriptionSessionDTO) {
    if (subSession !== this.selectedSubSession)
      this.selectedSubSession = null;
  }
}

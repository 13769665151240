import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthorProfileDTO } from 'src/app/models/dto/authorProfileDTO';
import { tap } from 'rxjs/operators';
import { AuthorProfileEdit, AuthorContact } from 'src/app/models/authorProfileEdit';
import { Helper } from 'src/app/helpers/helper';
import { TranslationService } from './translation.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorProfileService {
    private bckEndUrlBaseUrl = `${environment.apiUrl}/api/v2`;
    private bckEndUrlAuthor = `${this.bckEndUrlBaseUrl}/authorprofile`;
    
    constructor(private http: HttpClient, private translationService: TranslationService) { }

    public getAuthorProfileOfUser(userId: number): Observable<AuthorProfileDTO> {
        return this.http.get<AuthorProfileDTO>(`${this.bckEndUrlAuthor}/${userId}/${this.translationService.getLanguage()}`, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public postAuthorProfile(profile: AuthorProfileEdit) {
        profile.Language = this.translationService.getLanguage();

        return this.http.post(`${this.bckEndUrlAuthor}`, profile, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public putAuthorProfile(id: number, profile: AuthorProfileEdit) {
        profile.Language = this.translationService.getLanguage();
        
        return this.http.put(`${this.bckEndUrlAuthor}/${id}`, profile, { headers: Helper.getAuthHeader() }).pipe(tap(console.log));
    }

    public contactAuthor(form: AuthorContact) {
        return this.http.post(`${this.bckEndUrlAuthor}/contact`, form, { headers: Helper.getAuthHeader() });
    }
}

<div *ngIf="package" class="component-main">
    <div *ngIf="!isMobile; else mobileView" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row">
            <div fxLayoutAlign="start center" fxFlex="25" fxLayoutGap="20px">
                <button mat-stroked-button (click)="goBack()">
                    <mat-icon matListIcon>arrow_back</mat-icon>
                    {{ 'Back' | translate }}
                </button>

                <mat-form-field *ngIf="currentUser?.isAdmin" color="primary">
                    <mat-select [(ngModel)]="package.visibility"
                        (selectionChange)="changeVisibility($event)">
                        <!--mat-option [value]="0">{{ 'Private' | translate }}</mat-option-->
                        <mat-option [value]="1">{{ 'Public' | translate }}</mat-option>
                        <mat-option [value]="2">{{ 'Public to all customers' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <span fxFlex="50" fxLayoutAlign="center center" style="font-size: 26px; font-weight: bold;">{{ 'Package' | translate }}</span>
            <div fxLayoutAlign="end center" fxFlex style="font-weight: bold"
                [style.color]="getRemainingColor()"
                *ngIf="currentUserHavePaid">
                {{ ('Expires on' | translate) + ' '
                + (currentUserSubscription.dateTo | date: 'dd/MM/YYYY') + ' ('
                + remainingDays + ' ' + ('Oof' | translate) + ' '
                + this.package.durationDays + ' '
                + ('days remaining' | translate)
                + ')'}}
            </div>
            <div *ngIf="!currentUserHavePaid" fxFlex></div>
        </div>
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex="50">
                <mat-card-header>
                    <mat-card-title>
                        {{ package.name }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="25px" fxFlex>
                    <div fxLayoutAlign="center end" fxFlex="20">
                        <button mat-fab style="background-color: #ffb22b;" class="mat-elevation-z0 btnNoHover">
                            <mat-icon style="color: white;">
                                local_offer
                            </mat-icon>
                        </button>
                    </div>
                    <div fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="5px" class="kpiText" fxFlex [innerHtml]="package.description"></div>
                </mat-card-content>
            </mat-card>
            <app-price-card class="mat-elevation-z4" fxFlex="50"
                [subscription]="package.subscription"
                [subCrudShow]="currentUser?.id === package.idAuthor && package.state === getPackageState().offline"
                [subtitle]="getSubTitle()"
                [buyShow]="package.state === getPackageState().online && (currentUser?.isStudent || currentUser?.isSpeaker || currentUser?.isTeacher || currentUser?.isTutor) && currentUser?.id !== package.idAuthor"
                [buyDisabled]="getDisabledBuyBtn()"
                [renewShow]="currentUserHavePaid"
                (subAdd)="addSubscription()"
                (subEdit)="addSubscription()"
                (buyPay)="addToCart()"
                (buyFree)="payPackageFree()"
                [logInToBuy]="true">
            </app-price-card>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button mat-fab (click)="showList()" [matTooltip]="( 'Change contents view' | translate)" 
                color="primary">
                <mat-icon>view_list</mat-icon>
            </button>

            <button mat-fab color="accent"
                *ngIf="currentUser.id === package.idAuthor"
                (click)="addElements()" [matTooltip]="'Add elements' | translate">
                <mat-icon>playlist_add</mat-icon>
            </button>

            <button mat-fab color="primary" (click)="addPaidUsers()" [matTooltip]="'Users' | translate"
                *ngIf="currentUser?.isCustomerAdmin && currentUser?.idCustomer === package.idCustomer"
                [disabled]="package.state !== getPackageState().online">
                <mat-icon>people</mat-icon>
            </button>

            <button mat-fab color="primary"
                *ngIf="currentUser?.id === package.idAuthor && currentUser?.mastersEnabled && currentUser?.coursesEnabled"
                (click)="updatePackage()"
                [matTooltip]="'Edit' | translate">
                <mat-icon>edit</mat-icon>
            </button>

            <button mat-fab color="warn"
                *ngIf="currentUser.id === package.idAuthor && currentUser.mastersEnabled && currentUser.coursesEnabled && package.state === getPackageState().offline"
                (click)="deletePackage()"
                [matTooltip]="'Delete' | translate">
                <mat-icon>delete</mat-icon>
            </button>

            <mat-slide-toggle color="primary" [checked]="package.state === 2 ? true : false"
                (change)="changeState()"
                *ngIf="currentUser?.id === package.idAuthor && currentUser?.mastersEnabled && currentUser?.coursesEnabled">
                {{ package.state === 2 ? 'Online' : 'Offline' }}
            </mat-slide-toggle>

            <!--button mat-button [matTooltip]="'Share' | translate" (click)="share()">
                <mat-icon>share</mat-icon>
            </button-->

        </div>

        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px">
            <mat-card appearance="outlined" *ngIf="package && !hidePackageCard" class="mat-elevation-z4" fxLayout="column" fxFlex="20" class="package-card">
                <img mat-card-image [src]="package.imageUrl" [alt]="package.name" loading="lazy">
                <div class="statusText mat-elevation-z3">
                    <h5 style="margin: 0%;">{{ (getPackageState().getState(package.state) | translate).toUpperCase() }}
                    </h5>
                </div>
                <mat-card-header>
                    <mat-card-title>
                        {{ package.name }}
                    </mat-card-title>
                    <mat-card-subtitle style="font-size: 12px;">
                        {{ package.header }}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content >
                    <div [innerHtml]="getHelper().getTruncateShowcase(package.description, 'No description', 250)" style="overflow: hidden;"></div>
                </mat-card-content>
            </mat-card>

            <mat-card appearance="outlined" class="mat-elevation-z4" fxLayout="column" fxFlex *ngIf="tableList; else showContents">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'Contents' | translate }}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="elements" style="width: 100%;">

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Type' | translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon [matTooltip]="getTypeTitle(element.type) | translate">
                                    {{ getTypeIcon(element.type) }}
                                </mat-icon>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
                            <td mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="start center"
                                fxLayoutGap="10px" style="padding: 5px">
                                <div>
                                    <app-profile-picture [url]="getEntityPicture(element)" size="65px">
                                    </app-profile-picture>
                                </div>
                                <div fxLayoutAlign="center start">
                                    {{ getEntityName(element) }}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="header">
                            <th mat-header-cell *matHeaderCellDef>Relatore</th>
                            <td mat-cell *matCellDef="let element">
                                {{ getEntityHeader(element) }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef>{{ 'Price' | translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                <span [style.color]="getEntityPrice(element) === 'Free' ? '#228B22' : ''" style="font-weight: bold">
                                    {{ getEntityPrice(element) | translate }}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="goto">
                            <th mat-header-cell *matHeaderCellDef>{{ 'View' | translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-stroked-button (click)="goTo(element)">
                                    <mat-icon>arrow_forward</mat-icon>
                                    {{ 'View' | translate }}
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="elementsCol"></tr>
                        <tr mat-row *matRowDef="let row; columns: elementsCol;"></tr>
                    </table>
                </mat-card-content>
                <div fxFlex></div>
            </mat-card>
        </div>

        <ng-template #showContents>
            <div *ngIf="elements.length > 0" [style.grid-template-columns]="gridTemplateColumns()" class="grid-container">
                <app-showcase-card *ngFor="let element of elements;"
                    [routeUrl]="getTypeTitle(element.type) === 'Training Course' ? '/course' : '/master'"
                    [routeId]="getTypeTitle(element.type) === 'Training Course' ? element.idCourse : element.idMaster"
                    [routeMessage]="getTypeTitle(element.type) === 'Training Course' ? 'Go to course' : 'Go to master'"
                    [imgUrl]="getEntityPicture(element)"
                    [queryParams]="getQueryParams()"
                    [title]="getEntityName(element)"
                    [subtitle_extra]="getEntityHeader(element)"
                    [subtitle]="element.created | date: 'dd/MM/YYYY'"
                    [description]="getEntityDescription(element)"
                    [status]="getEntityState(element)"
                    [subscription]="getTypeTitle(element.type) === 'Training Course' ? element.course.subscription : element.master.subscription"
                    [compact]="compactMode"
                    [enableHighlight]="compactMode">
                
                    <app-showcase-card-author-info bodyBottom
                        [author]="getTypeTitle(element.type) === 'Training Course' ? element.course.author : element.master.author"
                        mode="author"
                        padding="0 16px">
                    </app-showcase-card-author-info>
                
                </app-showcase-card>
            </div>
        </ng-template>
    </div>

    <ng-template #mobileView>
        <p *ngIf="isDev()">DEV Version</p>
        <div style="display:flex;justify-content: center;flex-direction:column;align-items:center; background-size: 100%;"  [style.height]="!showDeepLinkButton ? '90vh' : ''">
            <app-showcase-card
                [routeUrl]="'/package'"
                [routeId]="package.id"
                [queryParams]="{ page: 'showcase' }"
                [imgUrl]="package.imageUrl"
                [title]="package.name"
                [subtitle]="package.hashTags"
                [description]="package.description"
                [isMobile]="true"
                [subscription]="package?.subscription"
                [buyShow]="package.state === getPackageState().online && (currentUser?.isStudent || currentUser?.isSpeaker || currentUser?.isTeacher || currentUser?.isTutor)"
                [buyDisabled]="getDisabledBuyBtn() || currentUser?.id === package.idAuthor"
                [renewShow]="currentUserHavePaid"
                [logInToBuy]="true"
                (buyPay)="addToCart()"
                (buyFree)="payPackageFree()">

                <app-showcase-card-author-info bodyBottom
                    [author]="package.author"
                    mode="author"
                    padding="0 16px">
                </app-showcase-card-author-info>

            </app-showcase-card>
            <ng-container *ngIf="showDeepLinkButton && (isDT() || isAixp() || isDev())">
                <button style="margin-top:25px;height:90px;width:180px;"
                    color="primary"
                    (click)="createDeepLink(package.id)"
                    mat-raised-button>
                   {{ "Open in app" | translate }}
                </button>
            </ng-container>
        </div>
    </ng-template>
</div>

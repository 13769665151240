<h1 mat-dialog-title>{{ 'Edit Post' | translate }}</h1>
<div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Title' | translate }}</mat-label>
            <input matInput type="text" [formControl]="title" required>
            <mat-error *ngIf="title.hasError('required')">
                {{ 'Title is required' | translate }}
              </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" style="margin-top:10px;" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea matInput [formControl]="description" style="height: 100px;" required></textarea>
            <mat-error *ngIf="description.hasError('required')">
                {{ 'Description is required' | translate }}
              </mat-error>
        </mat-form-field>
    </div>
    <div fxLayout="row" style="margin-top:10px;" fxLayoutAlign="center center">
        <mat-form-field color="primary">
            <mat-label>{{ 'Header' | translate }}</mat-label>
            <input matInput type="text" [formControl]="header">
        </mat-form-field>
    </div>
    
</div>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button color="accent" 
        [disabled]="hasErrors()"
        (click)="saveEdit()">Ok
    </button>
</div>


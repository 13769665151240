import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { addMinutes, differenceInMinutes, isBefore } from 'date-fns';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConferencePost } from 'src/app/models/conferencePost';
import { UserDTO } from 'src/app/models/dto/userDTO';
import { ConferenceService } from 'src/app/services/conference.service';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AzureStorageService } from 'src/app/services/storage.service';
import { ConferenceDTO } from 'src/app/models/dto/conferenceDTO';
import { ConferencePresenterRole } from 'src/app/models/conference-session/conferencePresenterRole';
import { TranslationService } from 'src/app/services/translation.service';
import { Router } from '@angular/router';
import { ClassroomDTO } from 'src/app/models/dto/classroomDTO';
import { Helper } from 'src/app/helpers/helper';
import { CONFERENCE_DURATION_MINUTES } from 'src/app/models/conference-session/conferenceConstants';
import { TranslationDTO, TranslationEdit } from 'src/app/models/dto/translationDTO';
import { UserService } from 'src/app/services/user.service';
import { UserRole } from 'src/app/models/userRole';
import { ClassroomService } from 'src/app/services/classroom.service';

@Component({
  selector: 'app-conference-webinar-popup',
  templateUrl: './conference-webinar-popup.component.html',
  styleUrls: ['./conference-webinar-popup.component.scss']
})
export class ConferenceWebinarPopupComponent implements OnInit {

  currentUser: User;
  sending: boolean = false;
  webinarToEdit: ConferenceDTO = undefined;
  showDateOrTime: boolean = true;
  startDateBackup: Date = undefined;

  classrooms: ClassroomDTO[] = [];
  tutors: UserDTO[] = [];
  speakers: UserDTO[] = [];

  moderatorSearchForm: FormControl = new FormControl(undefined);
  presenterListSearchForm: FormControl = new FormControl(undefined);
  classroomListSearchForm: FormControl = new FormControl(undefined);
  
  startDateForm: FormControl = new FormControl(undefined, [Validators.required, this.dateValidator]);
  endDateForm: FormControl = new FormControl(undefined, [Validators.required]);
  moderatorForm: FormControl = new FormControl(undefined, [Validators.required]);
  presenterListForm: FormControl = new FormControl([], [Validators.required]);
  nameForm: FormControl = new FormControl(undefined);
  descriptionForm: FormControl = new FormControl(undefined);
  headerForm: FormControl = new FormControl(undefined);
  footerForm: FormControl = new FormControl(undefined);
  summaryForm: FormControl = new FormControl(undefined);
  imageSrc: FormControl = new FormControl(undefined, [Validators.required]);
  isPrivateForm: FormControl = new FormControl(false, [Validators.required]);
  classroomsListForm: FormControl = new FormControl([], [Validators.required]);
  recordForm: FormControl = new FormControl(false, [Validators.required]);

  nameTranslation: TranslationDTO = undefined;
  descriptionTranslation: TranslationDTO = undefined;
  headerTranslation: TranslationDTO = undefined;
  footerTranslation: TranslationDTO = undefined;
  summaryTranslation: TranslationDTO = undefined;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private datePipe: DatePipe,
              private auth: AuthService,
              private dialogRef: MatDialogRef<ConferenceWebinarPopupComponent>,
              private cdr: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private azureService: AzureStorageService,
              private translate: TranslateService,
              private classroomService: ClassroomService,
              private router: Router,
              private translation: TranslationService,
              private conferenceService: ConferenceService,
              private userService: UserService,
              public darkService: DarkThemeService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getCurrentUser();

    this.showDateOrTime = this.data?.dateOrTime ?? true;

    let startDate = addMinutes(new Date(), 5);
    let endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);

    if (this.data.webinar != undefined) {

      let webinarToEdit: ConferenceDTO = this.data.webinar;
  
      this.nameForm.setValue(webinarToEdit.name);
      this.descriptionForm.setValue(webinarToEdit.description);
      this.headerForm.setValue(webinarToEdit.header);
      this.footerForm.setValue(webinarToEdit.footer);
      this.summaryForm.setValue(webinarToEdit.showcaseSummary);

      this.isPrivateForm.setValue(webinarToEdit.visibility === 0);
      this.recordForm.setValue(webinarToEdit.lessonSession.recodingPlanned);
      this.classroomsListForm.setValue(webinarToEdit.conferenceClassroom.map(c => c.idClassroom));
      this.presenterListForm.setValue(webinarToEdit.conferencePresenter.filter(p => p.role === ConferencePresenterRole.Presenter).map(a => a.idPresenter));
      this.moderatorForm.setValue(webinarToEdit.conferencePresenter.find(p => p.role === ConferencePresenterRole.Moderator)?.idPresenter);

      this.nameTranslation = webinarToEdit.nameTranslation;
      this.descriptionTranslation = webinarToEdit.descriptionTranslation;
      this.headerTranslation = webinarToEdit.headerTranslation;
      this.footerTranslation = webinarToEdit.footerTranslation;
      this.summaryTranslation = webinarToEdit.summaryTranslation;

      startDate = new Date(webinarToEdit.lessonSession.startPlanned);
      endDate = new Date(webinarToEdit.lessonSession.endPlanned);

      if (!(this.data.isCopy ?? false))
        this.webinarToEdit = webinarToEdit;
    }

    if (this.data.startData) {
      startDate = new Date(this.data.startData);
      endDate = addMinutes(startDate, (this.data.isCopy ?? false) ? differenceInMinutes(endDate, startDate) : CONFERENCE_DURATION_MINUTES);
    }

    if (startDate < new Date()) {
      startDate = addMinutes(new Date(), 5);
      endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);
    }

    let diff = differenceInMinutes(endDate, startDate);

    if (diff > CONFERENCE_DURATION_MINUTES || diff < 0)
      endDate = addMinutes(startDate, CONFERENCE_DURATION_MINUTES);

    this.startDateBackup = startDate;

    this.startDateForm.setValue(this.datePipe.transform(startDate, this.showDateOrTime ? 'yyyy-MM-ddTHH:mm' : 'HH:mm'));
    this.endDateForm.setValue(this.datePipe.transform(endDate, 'HH:mm'));

    this.fetchClassRoom();
    this.getUsers();
  }

  dateValidator(control: FormControl): { [s: string]: boolean } {
    if (!control.value)
      return null;

    if (isBefore(new Date(control.value), new Date()))
      return { 'invalidDate': true };
    
    return null;
  }

  negativeSpan(): boolean {
    let startDate = this.getStartDate();
    let endDate = this.getEndDate();

    if (endDate <= startDate) {
       this.endDateForm.setErrors({ negativeSpan: this.endDateForm.value });
       this.cdr.detectChanges();
       return true;
    }

    if (differenceInMinutes(endDate, startDate) > CONFERENCE_DURATION_MINUTES) {
      this.endDateForm.setErrors({ moreThanMaxDuration: this.endDateForm.value });
      this.cdr.detectChanges();
      return true;   
    }

    return false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getUsers() {
    this.userService.getAllUsers([UserRole.Speaker, UserRole.Tutor])
      .subscribe(res => {

        res = res as UserDTO[];

        this.speakers = res.filter(u => u.isSpeaker);
        this.tutors = res.filter(u => u.isTutor);

      });
  }

  presenterOptionDisabled(userId: number): boolean {
    return this.presenterListForm.value.length >= 3
        && this.presenterListForm.value.findIndex((p: number) => p === userId) === -1;
  }

  okBtnDisabled() {
    let check = !this.startDateForm.valid ||
                !this.endDateForm.valid ||
                !this.moderatorForm.valid ||
                this.sending || 
                this.negativeSpan();

    if (this.isPrivateForm.value)
      check = check ||
              !this.classroomsListForm.valid;

    if (!this.webinarToEdit)
      check = check ||
              !this.imageSrc.valid;
      
    return check;
  }

  nextBtnDisabled() {
    return !this.endDateForm.valid || this.negativeSpan() || !this.moderatorForm.valid || !this.presenterListForm.valid;
  }

  secondNextBtnDisabled() {
    return !this.webinarToEdit ? !this.imageSrc.valid : false;
  }

  async saveWebinar() {
    this.sending = true;

    let webinar = new ConferencePost();

    webinar.startPlanned = this.getStartDate().toUTCString();
    webinar.endPlanned = this.getEndDate().toUTCString();
    webinar.name = Helper.isNullOrEmpty(this.nameForm.value) ? 'Webinar' : this.nameForm.value;
    webinar.description = this.descriptionForm.value;
    webinar.header = this.headerForm.value;
    webinar.footer = this.footerForm.value;
    webinar.summary = this.summaryForm.value;
    webinar.moderator = this.moderatorForm.value;
    webinar.presenters = this.presenterListForm.value.length === 0 ? [] : this.presenterListForm.value;
    webinar.idMode = 3;
    webinar.language = this.translation.getLanguage();
    webinar.visibility = this.isPrivateForm.value ? 0 : 1;
    webinar.classrooms = webinar.visibility === 0 ? this.classroomsListForm.value : [];
    webinar.recodingPlanned = this.recordForm.value;
    webinar.imageUrl = await this.azureService.uploadFile(this.imageSrc.value);
    webinar.nameTranslation = TranslationEdit.fromDTO(this.nameTranslation);
    webinar.descriptionTranslation = TranslationEdit.fromDTO(this.descriptionTranslation);
    webinar.headerTranslation = TranslationEdit.fromDTO(this.headerTranslation);
    webinar.footerTranslation = TranslationEdit.fromDTO(this.footerTranslation);
    webinar.summaryTranslation = TranslationEdit.fromDTO(this.summaryTranslation);

    if (!this.webinarToEdit)
      this.conferenceService.createConference(webinar).subscribe({
        next: async (success) => {
          console.log('New webinar added');
          this.snackBar.open(await firstValueFrom(this.translate.get('Webinar added')), undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close(true);

          this.router.navigate([`/webinar-showcase/${(success as any).Message}`], { queryParams: { page: 'showcase' } });
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message,undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close();
        }
      });
    else
      this.conferenceService.putConference(this.webinarToEdit.id, webinar).subscribe({
        next: async () => {
          console.log('Webinar edited');
          this.snackBar.open(await firstValueFrom(this.translate.get('Webinar edited')), undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close(true);
        },
        error: err => {
          console.log(err);
          this.snackBar.open(err.error.Message, undefined, { duration: 3000 });

          this.sending = false;
          this.dialogRef.close();
        }
      });
  }

  fetchClassRoom() {
    this.classroomService.getClassrooms()
      .subscribe(output => this.classrooms = output as ClassroomDTO[]);
  }

  getSelectedUsers() {
    return this.speakers?.filter(u => this.presenterListForm.value.findIndex((ul: number) => ul === u.id) !== -1) ?? [];
  }

  getSelectedClassrooms() {
    return this.classrooms?.filter(c => this.classroomsListForm.value.findIndex((cl: number) => cl === c.id) !== -1) ?? [];
  }

  private getStartDate() {
    if (this.showDateOrTime)
      return new Date(this.startDateForm.value);

    let startDate = new Date(this.startDateBackup);
    let startTime = Helper.convertTimeToHMS(this.startDateForm.value);

    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);
    startDate.setSeconds(startTime[2]);

    return startDate;
  }
  
  private getEndDate() {
    let endTime = Helper.convertTimeToHMS(this.endDateForm.value);
    let endDate = this.getStartDate();

    endDate.setHours(endTime[0]);
    endDate.setMinutes(endTime[1]);
    endDate.setSeconds(endTime[2]);

    return endDate;
  }

  maxDurationHours() {
    return CONFERENCE_DURATION_MINUTES / 60;
  }

}

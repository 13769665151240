import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { firstValueFrom, from } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CsvExporterService, ExportDtUserCsv, ExportUserCsv } from 'src/app/services/csv-exporter.service';
import { FormControl } from '@angular/forms';
import { GenericPopupComponent, GenericPopupData } from 'src/app/popup/generic-popup/generic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AddUserComponent } from './add-user/add-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { RolePipe } from 'src/app/pipes/rolePipe';
import { UserRole } from 'src/app/models/userRole';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
    state: FormControl = new FormControl(false);
    currentUser: User;
    env = environment;

    customers: any[] = [];
    currentCustomerId: number = null;
    currentCustomerName: string = null;

    datasource: MatTableDataSource<User>;
    displayedColumns: string[] = ['thumbnail', 'username', 'surname', 'name', 'email', 'role', 'date', 'actions'];
    displayedColumnsTeacher: string[] = ['thumbnail', 'username', 'surname', 'name', 'email', 'role', 'date'];
    paginatorDefault: number = 25;
    paginatorPages: number[] = [10, 25, 50, 100];

    @ViewChild('usersSort') usersSort: MatSort;
    @ViewChild('usersPaginator') usersPaginator: MatPaginator;

    constructor(private auth: AuthService,
        private userService: UserService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private translate: TranslateService,
        private exporter: CsvExporterService,
        private rolePipe: RolePipe) { }

    ngOnInit() {
        this.currentUser = this.auth.getCurrentUser();
        this.getUsers();
    }
    
    isStudent() {
        return this.currentUser.isStudent;
    }

    isTeacher() {
        return this.currentUser.isTeacher;
    }

    isCustomerAdmin() {
        return this.currentUser.isCustomerAdmin;
    }

    isAdmin() {
        return this.currentUser.isAdmin;
    }

    getUsers(inactive?: boolean) {
        let roles = [];

        if (this.currentUser.isTeacher || this.currentUser.isTutor || this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
            roles.push(UserRole.Student);
        }            

        if (this.currentUser.isCustomerAdmin || this.currentUser.isAdmin) {
            roles.push(UserRole.Speaker);
            roles.push(UserRole.Teacher);
            roles.push(UserRole.Tutor);
        }

        //if (this.currentUser.isAdmin) {
        //    roles.push(UserRole.CustomerAdmin);
        //}

        this.userService.getAllUsers(roles, undefined, inactive ? 0 : null, true)
            .subscribe(res => this.groupByClients(res as User[]));
    }

    setUsersSource(data: User[]) {
        data.sort((a, b) => new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime());

        if (!this.datasource) {
            this.datasource = new MatTableDataSource();

            if (!this.currentUser.isAdmin) {
                this.datasource.sort = this.usersSort;
                this.datasource.paginator = this.usersPaginator;
            }
    
            this.datasource.sortingDataAccessor = (item, property) => {
                switch (property) {
                  case 'email':
                    return item.email;
                  case 'role':
                    return this.getRole(item);
                  case 'date':
                    return item.timeStamp;
                  default:
                    return item[property];
                };
            }
        }

        this.datasource.data = data;
    }

    getRole(user: User) {
        return this.rolePipe.transform(UserRole.role(user));
    }

    editUser(id: number) {
        const dialogRef = this.dialog.open(AddUserComponent,
        {
            width: '600px',
            data: {
                userId: id,
                customerName: this.currentCustomerName,
                customerId: this.currentCustomerId
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;
    
            this.getUsers();
        });
    }

    addUser() {
        const dialogRef = this.dialog.open(AddUserComponent,
        {
            width: '600px',
            data: {
                customerName: this.currentCustomerName,
                customerId: this.currentCustomerId
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;
    
            this.getUsers();
        });
    }

    inviteUser() {
        const dialogRef = this.dialog.open(InviteUserComponent,
        {
            width: '600px',
            data: {
                customerName: this.currentCustomerName,
                customerId: this.currentCustomerId
            }
        });
    
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;
    
            this.getUsers();
        });
    }

    async deleteUser(id: number) {
        const dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom(this.translate.get('Disable user')),
                body: await firstValueFrom(this.translate.get('Are you sure you want to disable this user?'))
            }
        });
        
        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;
    
            this.userService.deleteUser(id)
                .subscribe( async () => {
                    this.getUsers(),
                    this.snackbar.open(await firstValueFrom(this.translate.get('User disabled!')), undefined, {duration: 3000})
            });
        });
    }

    async enableUser(id:number) {
        const dialogRef = this.dialog.open(GenericPopupComponent,
        {
            width: '400px',
            data: <GenericPopupData>{
                title: await firstValueFrom (this.translate.get('Enable user')),
                body: await firstValueFrom (this.translate.get('Are you sure you want to enable this user?'))
            }
        });

        dialogRef.afterClosed().subscribe(async res => {
            if (!res)
                return;

            this.userService.enableUser(id)
                .subscribe( { next: async () => {
                    this.getUsers(true),
                    this.snackbar.open(await firstValueFrom(this.translate.get('User enabled!')), undefined, {duration: 3000});
                },
                error: async () => {
                    this.snackbar.open(await firstValueFrom(this.translate.get('Error, an user with this email or username already exists')), undefined, {duration: 3000});
                }
            })  
            
        });
    }

    groupByClients(data: User[]) {
        this.customers = [];

        if (!data || data.length === 0) {
            this.setCustomer([]);
            return;
        }

        from(data)
            .pipe(
                groupBy(person => person.customerName),
                mergeMap(group => group.pipe(toArray()))
            )
            .subscribe(val => this.customers.push(val))
            .add(() => this.setCustomer(this.customers[0]));
    }

    setCustomer(users: User[]) {
        this.setUsersSource(users);

        this.currentCustomerId = users.length > 0 ? users[0].idCustomer : this.currentUser.idCustomer;
        this.currentCustomerName = users.length > 0 ? users[0].customerName : this.currentUser.customerName;
    }

    searchWord(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.datasource.filter = value.trim().toLowerCase();
    }

    exportToCsv() {
        let data: ExportUserCsv[] = [];

        this.datasource.data.forEach(u => {
            data.push({
                username: u.username,
                surname: u.surname,
                name: u.name,
                email: u.email,
                role: this.getRole(u),
                date: new Date(u.timeStamp).toLocaleString()
            });
        });

        this.exporter.exportDataToCsv(
            data,
            ["Username", "Surname", "Name", "Email", "Role", "Creation Date"],
            `User list of ${this.auth.getCurrentUser().customerName}`
        );
    }

    exportDtUserToCsv() {
        let data: ExportDtUserCsv[] = [];

        this.datasource.data.forEach(u => {
            data.push({
                email: u.email,
                name: u.name,
                surname: u.surname,
                province: u.province,
                phoneNumber: u.telephone,
                qualification: u.qualification
            });
        });

        this.exporter.exportDataToCsv(
            data,
            ["Email", "Name", "Surname", "Province", "Phone Number", "Qualification"],
            `User list of Dental Trey E-Learning`
        );
    }

    isAixpMode() {
        return this.env.mode === 'aixp';
    }

    isSaratogaMode() {
        return this.env.mode === 'saratoga';
    }

    isDT() {
        return this.auth.isDT();
    }
}

import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionEvent, OpenVidu, Publisher, PublisherProperties, Session, StreamEvent, SessionDisconnectedEvent, SignalEvent, Connection } from 'openvidu-browser';
import { Lesson } from 'src/app/models/lesson';
import { AuthService } from 'src/app/services/auth.service';
import { VideoSessionService } from 'src/app/services/video-session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LessonService } from 'src/app/services/lessons.service';
import { firstValueFrom, timer } from 'rxjs';
import { NavBarService } from 'src/app/services/nav-bar.service';
import { map } from 'rxjs/operators';
import { Device } from 'openvidu-browser';
import { MatDialog } from '@angular/material/dialog';
import { joinLessonConfig } from 'src/app/models/joinLessonConfig';
import { DOCUMENT } from '@angular/common';
import { CalendarService } from 'src/app/services/calendar.service';
import { LessonContentEdit } from 'src/app/models/dto/lessonSessionDTO';
import { CourseContentDTO } from 'src/app/models/dto/courseContentDTO';
import { ChatService } from 'src/app/services/chat.service';
import { User } from 'src/app/models/user';

/* parte di Luca */
import { StudentsVideoStatus } from 'src/app/models/studentsVideoStatus';
import { TeacherFeedbackService, StudentStreamData, StudentVideoDialogData } from 'src/app/services/teacher-feedback.service';
import { environment } from 'src/environments/environment';
import { DarkThemeService } from 'src/app/services/dark-theme.service';
import { CourseService } from 'src/app/services/course.service';
import { ResizeEvent } from 'angular-resizable-element';
/* parte di Luca */


@Component({
    selector: 'app-video-session',
    templateUrl: './video-session.component.html',
    styleUrls: ['./video-session.component.scss']
})
export class VideoSessionComponent implements OnInit, OnDestroy, AfterViewInit {

    OV1: OpenVidu;
    session1: Session;
    OV2: OpenVidu;
    session2: Session;
    OV3: OpenVidu;
    session3: Session;
    OV4: OpenVidu;                                          /* parte di Luca */
    session4: Session;                                      /* parte di Luca */
    publisher1: Publisher;
    publisher2: Publisher;
    publisher3: Publisher;
    publisher4: Publisher;                                  /* parte di Luca */
    token1: string;
    token2: string;
    token3: string;
    token4: string;                                         /* parte di Luca */
    today: number = Date.now();
    lessonActive: any;
    cameraOptions1: PublisherProperties;
    cameraOptions2: PublisherProperties;
    cameraOptions3: PublisherProperties;
    cameraOptions4: PublisherProperties;                    /* parte di Luca */    
    currentUser: User;
    localVideoActivated: boolean;
    localAudioActivated: boolean;
    studentAudioActivated: boolean = true;                  /* parte di Luca */
    videoIcon: string;
    //keepAliveInterval;
    audioIcon: string;
    studentAudioIcon: string;
    lesson: Lesson;
    id: string;
    classId: number;
    connectionInterval: any;
    lessonOpened: any;
    countDown;
    counter = 1;
    tick = 1000;
    stream1: StreamData = new StreamData();
    stream2: StreamData = new StreamData();
    stream3: StreamData = new StreamData();
    stream4: StreamData = new StreamData();                 /* parte di Luca */
    resolutionWidth: number;
    resolutionHeight: number;
    recording: string;
    easyLesson: any;
    videoDeviceArr: Array<Device> = [];
    cameraSwitchTimeout1: any; // Usato per segnare un cambio di stream per stream1
    cameraSwitch1: boolean = false;
    cameraSwitchTimeout2: any; // Usato per segnare un cambio di stream per stream2
    cameraSwitch2: boolean = false;
    cameraSwitchTimeout3: any; // Usato per segnare un cambio di stream per stream3
    cameraSwitch3: boolean = false;
    // cameraSwitchTimeout4: any;                              /* parte di Luca */
    // cameraSwitch4: boolean = false;                         /* parte di Luca */
    lessonStartDate: Date;
    isOver: boolean = false;
    scenario: number = 1;
    p1ShowSS: string = 'none';
    p2ShowSS: string = 'none';
    p3ShowSS: string = 'none';
    p1Show: string = 'none';
    p2Show: string = 'none';
    p3Show: string = 'none';
    p4Show: string = 'none';                                /* parte di Luca */
    player1Width: string = '100%';
    player3Width: string = '33%';
    player4Width: string = '100%';                          /* parte di Luca */
    p1CssPip: boolean = false;                              /* parte di Luca */
    p4CssPip: boolean = false;                              /* parte di Luca */
    pipToggle: boolean = true;
    pipAttribute: Attr;
    studentFeedbackPipActive: boolean = false;
    studentFeedbackPipAvailable: boolean = false;
    studentConnection: Connection;                          /* parte di Luca */
    visualType: string = 'contain';
    disableStudentAskBtn: boolean = false;
    lessonClosed: boolean = false;

    contents: CourseContentDTO[] = [];
    currentContent: CourseContentDTO = null;
    contentShow: boolean = false;

    contentMarker: number = 0;
    contentPause: boolean = false;
    videoTime: number = 0;

    //Per tracker
    trackerStartDate: Date = null;
    trackerStopPosition: number = 0;

    player1: HTMLVideoElement = null;
    player2: HTMLVideoElement = null;
    player3: HTMLVideoElement = null;
    player4: HTMLVideoElement = null;

    publishToggle: boolean = true;
    publishAudioMem: boolean = true;
    publishVideoMem: boolean = true;

    @ViewChild('player1Container', { static: true }) player1Container: ElementRef;
    @ViewChild('player2Container', { static: true }) player2Container: ElementRef;
    @ViewChild('player3Container', { static: true }) player3Container: ElementRef;
    @ViewChild('player4Container', { static: true }) player4Container: ElementRef; /* parte di Luca */
    @ViewChild('videoplayer1', { static: true }) videoElement1;
    @ViewChild('videoplayer2', { static: true }) videoElement2;
    @ViewChild('videoplayer3', { static: true }) videoElement3;
    @ViewChild('videoplayer4', { static: true }) videoElement4;                     /* parte di Luca */

    constructor(
        public location: Location,
        private auth: AuthService,
        private videoSessionService: VideoSessionService,
        private snackBar: MatSnackBar,
        private routeActive: ActivatedRoute,
        private lessonSrvc: LessonService,
        private calendar: CalendarService,
        private navBar: NavBarService,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        /* parte di Luca */
        public teacherFeedbackService: TeacherFeedbackService,
        /* parte di Luca */
        public chatService: ChatService,
        private courseService: CourseService, //Solo per tracker
        public darkService: DarkThemeService,
        @Inject(DOCUMENT) private document) {
        this.easyLesson = localStorage.getItem('easyLesson');
        this.pipAttribute = document.createAttribute("disablepictureinpicture");

        var vt = localStorage.getItem('visualTypeConfig');
        this.visualType = vt ? vt : this.visualType;
    }
    
    OPEN_VIDU_CONNECTION1() {

        // 0) Obtain 'token' from server
        // In this case, the method ngOnInit takes care of it
        // 1) Initialize OpenVidu and your Session
        this.OV1 = new OpenVidu();
        this.session1 = this.OV1.initSession();

        // 2) Specify the actions when events take place
        this.session1.on('streamCreated', async (event: StreamEvent) => {
            console.warn('STREAM CREATED!');
            console.log(event.stream.connection.data);
            console.warn(event.stream);
            console.log(event.stream.videoDimensions.height, event.stream.videoDimensions.width);

            var idStream = event.stream.streamId;

            /* parte di Luca */
            var dropIt = false;

            if (event.stream.connection.data != null) {
                if (event.stream.connection.data.includes("student publisher")) {
                    dropIt = true;
                }
            }

            if (!dropIt) {

                var idConn = event.stream.connection.connectionId;
                if(idConn != this.stream1.connId && idConn != this.stream2.connId && idConn != this.stream3.connId) {
                    this.stream1.connId = idConn;
                    this.stream1.streamId = event.stream.streamId;
                }

                if (!this.auth.isTeacher() && this.stream1.connId == idConn) {
                    this.clearCameraSwitchTimeout(1);

                    if (event.stream) {
                        if (event.stream.videoDimensions) {
                            this.resolutionHeight = event.stream.videoDimensions.height;
                            this.resolutionWidth = event.stream.videoDimensions.width;
                        }
    
                        // Avvio espicito del player in quanto su safari questo non parte, inotre visualizza i controlli che di defoult sono nascosti.
                        var subscriber = this.session1.subscribe(event.stream, undefined);
                        subscriber.addVideoElement(this.player1);
                        this.player1.controls = true;
                        await this.player1.play()
                            .then(async () => {
                                //await player.requestFullscreen()
                                    //.catch(error => console.error(error));
                            })
                            .catch((error) => console.error(error));
                    }
                }
            }

            /* parte di Luca */
        });

        this.session1.on('streamDestroyed', async (event: StreamEvent) => {
            console.warn('STREAM DESTROYED!');
            console.warn(event.stream);

            if (this.cameraSwitch1) {
                this.cameraSwitch1 = false;
                this.cameraSwitchTimeout1 = setTimeout(async () => {
                    if (event.stream.connection.connectionId != this.stream1.connId)
                        return;

                    await this.cameraSwitchTimeout('interrupt');
                }, 60000);
            } else {
                if (event.stream.connection.connectionId != this.stream1.connId)
                    return;

                await this.cameraSwitchTimeout('interrupt');
            }

        });

        this.session1.on('connectionCreated', (event: ConnectionEvent) => {
            if (event.connection.connectionId === this.session1.connection.connectionId) {
                console.warn('YOUR OWN CONNECTION CREATED!');
            } else {
                console.warn('OTHER USER\'S CONNECTION CREATED!');
                /* parte di Luca */
                if (this.auth.isTeacher()) {

                    if (!event.connection.data.includes("student publisher"))
                        this.teacherFeedbackService.connectionCreated(event.connection);

                    if (this.currentContent) {
                        this.session1.signal({
                            data: JSON.stringify(<ContentSignal>{ content: this.currentContent, enable: true, marker: this.currentContent.type === 1 ? this.videoTime : this.contentMarker, pause: this.contentPause }),
                            to: [event.connection],
                            type: 'content-set'
                        });
                    }
                        
                }
                /* parte di Luca */
            }

            console.warn(event.connection);
        });

        this.session1.on('connectionDestroyed', (event: ConnectionEvent) => {
            // this.teacherFeedbackService.disposeService();
            console.warn('connection Destroyed');
            /* parte di Luca */
            if (this.auth.isTeacher() && !event.connection.data.includes("student publisher")) {
                this.teacherFeedbackService.connectionDestroyed(event.connection);
                // ETTORE  
                this.chatService.sendDisconected(event.connection);
            }
            /* parte di Luca */
        });

        this.session1.on("sessionDisconnected", async (event: SessionDisconnectedEvent) => {
            console.warn('session Disconnect');
            
            /* parte di Luca */
            this.teacherFeedbackService.disposeService();
            /* parte di Luca */

            this.clearCameraSwitchTimeout(1);
            this.router.navigateByUrl('/lessons');
        });

        this.session1.on('signal:camera-switch', (event: SignalEvent) => {
            console.log(event.data); // Message
            console.log(event.from); // Connection object of the sender
            console.log(event.type); // The type of message ("my-chat")

            this.cameraSwitch1 = true;
        });

        this.session1.on('signal:content-set', (event: SignalEvent) => {

            if (!this.isTeacher()) {
                var signal = <ContentSignal>JSON.parse(event.data);
                //Aggiunta di un contenuto
                if (signal.enable && (this.currentContent == null || signal.content.id != this.currentContent.id)) {
                    
                    //Azioni riguardanti il contenuto corrente
                    if (this.currentContent) {
                        
                        this.contentShow = false;
    
                        this.contentMarker = 0;
                        this.contentPause = false;
                    }
                    
                    //Azioni riguardanti il nuovo contenuto
                    this.contentShow = true;
                    this.contentMarker = signal.marker;
                    this.contentPause = signal.pause;
                    
                    this.currentContent = signal.content;
    
                //Rimozione di un contenuto
                } else if (!signal.enable) {
    
                    this.contentShow = false;
    
                    this.contentMarker = 0;
                    this.contentPause = false;
                    this.currentContent = null;
                }
            }
        });

        this.session1.on('signal:content-update', (event: SignalEvent) => {
            if (!this.isTeacher()) {
                var signal = <ContentUpdateSignal>JSON.parse(event.data);

                this.contentMarker = signal.marker;
                this.contentPause = signal.pause != null ? signal.pause : this.contentPause;
            }
        });

        this.cameraSwitchTimeout1 = setTimeout(async () => await this.cameraSwitchTimeout('screenshare'), 60000);

        // 3) Connect to the session
        this.session1.connect(this.token1, this.scenario)
            .then(async () => {

               
                /* parte di Luca */
                this.teacherFeedbackService.setupService(this.session1, this.auth.isTeacher(), this.session1.connection.connectionId);
                
                /* ETTORE */
                this.chatService.setupService(this.session1);

                this.teacherFeedbackService.closeVideoStreamEvent.subscribe((event: Event) => {
                    if (this.session4) {
                        this.CLOSE_VIDU_CONNECTION4();
                    }
                })

                if (!this.auth.isTeacher()) {
                    this.teacherFeedbackService.startStudentStreamEvent.subscribe((videoOptions: StudentVideoDialogData) => {
                        this.studentConnection = videoOptions.from;
                        this.cameraOptions4 = videoOptions.cameraOptions;
                        this.OPEN_VIDU_CONNECTION4();
                    });    
                } else {
                    this.teacherFeedbackService.receivingStudentStreamEvent.subscribe((streamData: StudentStreamData) => {
                        //this.cameraOptions4 = videoOptions.cameraOptions;
                        this.token4 = streamData.token;
                        this.cameraOptions4 = streamData.cameraOptions;
                        this.stream4.streamId = streamData.streamId;
                        this.OPEN_VIDU_CONNECTION4();
                    });  
                }

                /* parte di Luca */

                if (this.auth.isTeacher()) {

                    // 4) Get your own camera stream with the desired resolution and publish it, only if the user is supposed to do so
                    this.cameraOptions1.mirror = false;
                    this.videoSessionService.setStreamingSettings(this.cameraOptions1, this.currentUser);

                    this.publisher1 = this.OV1.initPublisher(undefined, this.cameraOptions1);

                    this.publisher1.on('streamCreated', (event: StreamEvent) => {
                        console.warn('STREAM CREATED BY PUBLISHER!');
                        console.warn(event.stream);
                        this.resolutionHeight = event.stream.videoDimensions.height;
                        this.resolutionWidth = event.stream.videoDimensions.width;
                    })

                    // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                    this.publisher1.addVideoElement(this.player1);
                    this.player1.controls = true;
                    await this.player1.play()
                        .then(async () => {
                            if(this.scenario == 1) {
                                await this.player1.requestFullscreen()
                                    .catch(error => console.error(error));
                            }
                        })
                        .catch((error) => console.error(error));

                    //Hide player if videosource is screensharing
                    this.checkIfScreenSharing(1);

                    // 5) Publish your stream
                    await this.session1.publish(this.publisher1);
                    this.joinLesson(this.id, this.session1.connection.connectionId, this.session1.connection.stream.streamId, 1);

                    this.OV1.getDevices().then(res => {
                        if (this.OV1.checkScreenSharingCapabilities())
                            this.videoDeviceArr.push({ kind: 'videoinput', deviceId: "screen", label: "Screen Sharing" });

                        for (let i = 0; i < res.length; i++) {
                            if (res[i].kind === 'videoinput') {
                                this.videoDeviceArr.push(res[i]);
                            }
                        }
                    });
                } else {
                    // join lesson but from the student prospective
                    // if (localStorage.getItem('easyLesson') !== 'true') {
                    this.joinLesson(this.id, this.session1.connection.connectionId, this.stream1.streamId, 1);
                }
            }).catch(error => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    }

    OPEN_VIDU_CONNECTION2() {

        // 0) Obtain 'token' from server
        // In this case, the method ngOnInit takes care of it
        // 1) Initialize OpenVidu and your Session
        this.OV2 = new OpenVidu();
        this.session2 = this.OV2.initSession();

        // 2) Specify the actions when events take place
        this.session2.on('streamCreated', async (event: StreamEvent) => {
            console.warn('STREAM CREATED!');
            console.warn(event.stream);
            console.log(event.stream.videoDimensions.height, event.stream.videoDimensions.width);

            var idConn = event.stream.connection.connectionId;
            if(idConn != this.stream1.connId && idConn != this.stream2.connId && idConn != this.stream3.connId) {
                this.stream2.connId = idConn;
                this.stream2.streamId = event.stream.streamId;
            }

            if (!this.auth.isTeacher() && this.stream2.connId == idConn) {
                this.clearCameraSwitchTimeout(2);

                if (event.stream) {
                    // Avvio espicito del player in quanto su safari questo non parte, inotre visualizza i controlli che di defoult sono nascosti.
                    var subscriber = this.session2.subscribe(event.stream, undefined);
                    subscriber.addVideoElement(this.player2);
                    this.player2.controls = true;
                    await this.player2.play()
                        .then(async () => {
                            //await player.requestFullscreen()
                              //  .catch(error => console.error(error));
                        })
                        .catch((error) => console.error(error));
                }
            }
        });

        this.session2.on('streamDestroyed', async (event: StreamEvent) => {
            console.warn('STREAM DESTROYED!');
            console.warn(event.stream);

            if (this.cameraSwitch2) {
                this.cameraSwitch2 = false;
                this.cameraSwitchTimeout2 = setTimeout(async () => {
                    if (event.stream.connection.connectionId != this.stream2.connId)
                        return;

                    await this.cameraSwitchTimeout('interrupt');
                }, 60000);
            } else {
                if (event.stream.connection.connectionId != this.stream2.connId)
                    return;

                await this.cameraSwitchTimeout('interrupt');
            }

        });

        this.session2.on('connectionCreated', (event: ConnectionEvent) => {
            if (event.connection.connectionId === this.session2.connection.connectionId) {
                console.warn('YOUR OWN CONNECTION CREATED!');
            } else {
                console.warn('OTHER USER\'S CONNECTION CREATED!');
            }
            console.warn(event.connection);

        });

        this.session2.on('connectionDestroyed', (event: ConnectionEvent) => {
            console.warn('connection Destroyed');
        });

        this.session2.on("sessionDisconnected", async (event: SessionDisconnectedEvent) => {
            console.warn('connection Disconnect');

            this.clearCameraSwitchTimeout(2);
            this.router.navigateByUrl('/lessons');
        });

        this.session2.on('signal:camera-switch', (event: SignalEvent) => {
            console.log(event.data); // Message
            console.log(event.from); // Connection object of the sender
            console.log(event.type); // The type of message ("my-chat")

            this.cameraSwitch2 = true;
        });

        this.cameraSwitchTimeout2 = setTimeout(async () => await this.cameraSwitchTimeout('screenshare'), 60000);

        // 3) Connect to the session
        this.session2.connect(this.token2)
            .then(async () => {
                if (this.auth.isTeacher()) {

                    // 4) Get your own camera stream with the desired resolution and publish it, only if the user is supposed to do so
                    this.cameraOptions2.mirror = false;
                    this.videoSessionService.setStreamingSettings(this.cameraOptions2, this.currentUser);

                    this.publisher2 = this.OV2.initPublisher(undefined, this.cameraOptions2);

                    this.publisher2.on('streamCreated', (event: StreamEvent) => {
                        console.warn('STREAM CREATED BY PUBLISHER!');
                        console.warn(event.stream);
                    })

                    // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                    this.publisher2.addVideoElement(this.player2);
                    this.player2.controls = true;
                    await this.player2.play()
                        .then(async () => {
                            //await player.requestFullscreen()
                              //  .catch(error => console.error(error));
                        })
                        .catch((error) => console.error(error));

                    //Hide player if videosource is screensharing
                    this.checkIfScreenSharing(2);

                    // 5) Publish your stream
                    await this.session2.publish(this.publisher2);
                    this.joinLesson(this.id, this.session2.connection.connectionId, this.session2.connection.stream.streamId, 2);

                    /*
                    this.OV2.getDevices().then(res => {
                        if (this.OV2.checkScreenSharingCapabilities() == 1)
                            this.videoDeviceArr.push({ kind: 'videoinput', deviceId: "screen", label: "Screen Sharing" });

                        for (let i = 0; i < res.length; i++) {
                            if (res[i].kind === 'videoinput') {
                                this.videoDeviceArr.push(res[i]);
                            }
                        }
                    });*/
                } else {
                    // join lesson but from the student prospective
                    // if (localStorage.getItem('easyLesson') !== 'true') {
                    this.joinLesson(this.id, this.session2.connection.connectionId, this.stream2.streamId, 2);
                }
            }).catch(error => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    }

    OPEN_VIDU_CONNECTION3() {

        // 0) Obtain 'token' from server
        // In this case, the method ngOnInit takes care of it
        // 1) Initialize OpenVidu and your Session
        this.OV3 = new OpenVidu();
        this.session3 = this.OV3.initSession();

        // 2) Specify the actions when events take place
        this.session3.on('streamCreated', async (event: StreamEvent) => {
            console.warn('STREAM CREATED!');
            console.warn(event.stream);
            console.log(event.stream.videoDimensions.height, event.stream.videoDimensions.width);

            var idConn = event.stream.connection.connectionId;
            if(idConn != this.stream1.connId && idConn != this.stream2.connId && idConn != this.stream3.connId) {
                this.stream3.connId = idConn;
                this.stream3.streamId = event.stream.streamId;
            }

            if (!this.auth.isTeacher() && this.stream3.connId == idConn) {
                this.clearCameraSwitchTimeout(3);

                if (event.stream) {

                    // Avvio espicito del player in quanto su safari questo non parte, inotre visualizza i controlli che di defoult sono nascosti.
                    var subscriber = this.session3.subscribe(event.stream, undefined);
                    subscriber.addVideoElement(this.player3);
                    this.player3.controls = true;
                    await this.player3.play()
                        .then(async () => {
                            //await player.requestFullscreen()
                              //  .catch(error => console.error(error));
                        })
                        .catch((error) => console.error(error));
                }
            }
        });

        this.session3.on('streamDestroyed', async (event: StreamEvent) => {
            console.warn('STREAM DESTROYED!');
            console.warn(event.stream);

            if (this.cameraSwitch3) {
                this.cameraSwitch3 = false;
                this.cameraSwitchTimeout3 = setTimeout(async () => {
                    if (event.stream.connection.connectionId != this.stream3.connId)
                        return;

                    await this.cameraSwitchTimeout('interrupt');
                }, 60000);
            } else {
                if (event.stream.connection.connectionId != this.stream3.connId)
                    return;

                await this.cameraSwitchTimeout('interrupt');
            }

        });

        this.session3.on('connectionCreated', (event: ConnectionEvent) => {
            if (event.connection.connectionId === this.session3.connection.connectionId) {
                console.warn('YOUR OWN CONNECTION CREATED!');
            } else {
                console.warn('OTHER USER\'S CONNECTION CREATED!');
            }
            console.warn(event.connection);

        });

        this.session3.on('connectionDestroyed', (event: ConnectionEvent) => {
            console.warn('connection Destroyed');
        });

        this.session3.on("sessionDisconnected", async (event: SessionDisconnectedEvent) => {
            console.warn('connection Disconnect');
            
            this.clearCameraSwitchTimeout(3);
            this.router.navigateByUrl('/lessons');
        });

        this.session3.on('signal:camera-switch', (event: SignalEvent) => {
            console.log(event.data); // Message
            console.log(event.from); // Connection object of the sender
            console.log(event.type); // The type of message ("my-chat")

            this.cameraSwitch3 = true;
        });

        this.cameraSwitchTimeout3 = setTimeout(async () => await this.cameraSwitchTimeout('screenshare'), 60000);

        // 3) Connect to the session
        this.session3.connect(this.token3)
            .then(async () => {
                if (this.auth.isTeacher()) {

                    // 4) Get your own camera stream with the desired resolution and publish it, only if the user is supposed to do so
                    this.cameraOptions3.mirror = false;
                    this.videoSessionService.setStreamingSettings(this.cameraOptions3, this.currentUser);

                    this.publisher3 = this.OV3.initPublisher(undefined, this.cameraOptions3);

                    this.publisher3.on('streamCreated', (event: StreamEvent) => {
                        console.warn('STREAM CREATED BY PUBLISHER!');
                        console.warn(event.stream);
                    })

                    // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                    this.publisher3.addVideoElement(this.player3);
                    this.player3.controls = true;
                    await this.player3.play()
                        .then(async () => {
                            //await player.requestFullscreen()
                              //  .catch(error => console.error(error));
                        })
                        .catch((error) => console.error(error));

                    //Hide player if videosource is screensharing
                    this.checkIfScreenSharing(3);

                    // 5) Publish your stream
                    await this.session3.publish(this.publisher3);
                    this.joinLesson(this.id, this.session3.connection.connectionId, this.session3.connection.stream.streamId, 3);

                    /*
                    this.OV3.getDevices().then(res => {
                        if (this.OV3.checkScreenSharingCapabilities() == 1)
                            this.videoDeviceArr.push({ kind: 'videoinput', deviceId: "screen", label: "Screen Sharing" });

                        for (let i = 0; i < res.length; i++) {
                            if (res[i].kind === 'videoinput') {
                                this.videoDeviceArr.push(res[i]);
                            }
                        }
                    });*/
                } else {
                    // join lesson but from the student prospective
                    // if (localStorage.getItem('easyLesson') !== 'true') {
                    this.joinLesson(this.id, this.session3.connection.connectionId, this.stream3.streamId, 3);
                }
            }).catch(error => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    }

    /* parte di Luca */
    async OPEN_VIDU_CONNECTION4() {

        if (!this.auth.isTeacher()) {
            var resp = await this.videoSessionService.generateTokenStudentPublisher(this.id);
            this.token4 = resp.token;
            console.log("Student token:");
            console.log(resp.token);

            this.studentAudioActivated = true;
            this.cameraOptions4.publishAudio = this.studentAudioActivated;
            this.studentAudioIcon = this.studentAudioActivated ? 'mic' : 'mic_off';
        } else {
            var resp = await this.videoSessionService.generateTokenTeacherSubscriber(this.id);
            this.token4 = resp.token;
            console.log("Student token:");
            console.log(resp.token);
        }

        //this.p4Show = 'block';
        //this.player1Width = '50%';
        //this.player4Width = '50%';

        // 0) Obtain 'token' from server
        // In this case, the method ngOnInit takes care of it
        // 1) Initialize OpenVidu and your Session
        this.OV4 = new OpenVidu();
        this.session4 = this.OV4.initSession();
    
        // 2) Specify the actions when events take place
        this.session4.on('streamCreated', async (event: StreamEvent) => {
            console.warn('STREAM CREATED!');
            console.log(event.stream.connection.data);
            console.warn(event.stream);
            console.log(event.stream.videoDimensions.height, event.stream.videoDimensions.width);

            var dropIt = false;

            if (event.stream.connection.data != null) {
                if (!event.stream.connection.data.includes("student publisher")) {
                    dropIt = true;
                }
            }

            if (!dropIt) {
    
                var idStream = event.stream.streamId;
                if (this.stream4.streamId == undefined && 
                    idStream != this.stream1.streamId && 
                    idStream != this.stream2.streamId && 
                    idStream != this.stream3.streamId
                   )
                    this.stream4.streamId = idStream;
        
                if (this.auth.isTeacher() && this.stream4.streamId == idStream) {
                    if (event.stream) {
        
                        // Avvio espicito del player in quanto su safari questo non parte, inotre visualizza i controlli che di defoult sono nascosti.
                        var subscriber = this.session4.subscribe(event.stream, undefined);
                        subscriber.addVideoElement(this.player4);
                        this.clearFeedbackPip();
                        this.studentFeedbackPipAvailable = true;
                        this.studentFeedbackPipActive = false;
                        this.studentFeedbackPip();
                        this.player4.controls = true;
                        await this.player4.play()
                            .then(async () => {
                            })
                            .catch((error) => console.error(error));
                    }
                }
            }
        });
    
        this.session4.on('streamDestroyed', async (event: StreamEvent) => {
            console.warn('STREAM DESTROYED!');
            console.warn(event.stream);
    
            if (event.stream.streamId == this.stream4.streamId) {
                if (this.auth.isTeacher()) {
                    this.snackBar.open('The stream was interrupted by the Student.', 'Dismiss', {
                        duration: 10000
                    });
                    console.log('student stream closed, going to list of lessons...');
                }
                this.teacherFeedbackService.studentLeft();
                this.CLOSE_VIDU_CONNECTION4();
            }
    
        });
    
        this.session4.on('connectionCreated', (event: ConnectionEvent) => {
            if (event.connection.connectionId === this.session4.connection.connectionId) {
                console.warn('YOUR OWN CONNECTION CREATED!');
            } else {
                console.warn('OTHER USER\'S CONNECTION CREATED!');
            }
            console.warn(event.connection);
        });
    
        this.session4.on('connectionDestroyed', (event: ConnectionEvent) => {
            console.warn('connection Destroyed');
        });
    
        this.session4.on("sessionDisconnected", async (event: SessionDisconnectedEvent) => {
            console.warn('session Disconnect');
        });
    
        // 3) Connect to the session
        this.session4.connect(this.token4)
            .then(async () => {
    
                if (!this.auth.isTeacher()) {
    
                    // 4) Get your own camera stream with the desired resolution and publish it, only if the user is supposed to do so
                    this.cameraOptions4.mirror = false;
                    this.videoSessionService.setStreamingSettings(this.cameraOptions4, this.currentUser);
    
                    this.publisher4 = this.OV4.initPublisher(undefined, this.cameraOptions4);
    
                    this.publisher4.on('streamCreated', (event: StreamEvent) => {
                        console.warn('STREAM CREATED BY PUBLISHER!');
                        console.warn(event.stream);
                    })
    
                    // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                    this.publisher4.addVideoElement(this.player4);
                    this.clearFeedbackPip();
                    this.studentFeedbackPipAvailable = true;
                    this.studentFeedbackPipActive = false;
                    this.studentFeedbackPip();
                    this.player4.controls = false;
                    await this.player4.play()
                        .then(async () => {
                            /*if(this.scenario == 1) {
                                await player.requestFullscreen()
                                    .catch(error => console.error(error));
                            }*/
                        })
                        .catch((error) => console.error(error));
    
                    // 5) Publish your stream
                    await this.session4.publish(this.publisher4);
                    
                    this.joinStudentFeedback(this.id, this.session4.connection.connectionId, this.session4.connection.stream.streamId, 4);

                    this.stream4.streamId = this.session4.connection.stream.streamId;
    
                    this.OV4.getDevices().then(res => {
                        if (this.OV4.checkScreenSharingCapabilities())
                            this.videoDeviceArr.push({ kind: 'videoinput', deviceId: "screen", label: "Screen Sharing" });
    
                        for (let i = 0; i < res.length; i++) {
                            if (res[i].kind === 'videoinput') {
                                this.videoDeviceArr.push(res[i]);
                            }
                        }
                    });

                    this.teacherFeedbackService.sendStudentSessionInfo(
                        this.session4.connection.connectionId,
                        this.session4.connection.stream.streamId,
                        this.token4,
                        this.cameraOptions4,
                        this.studentConnection
                    );

                } else {
                    this.joinStudentFeedback(this.id, this.session4.connection.connectionId, this.stream4.streamId, 4);
                }
            }).catch(error => {
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    }

    CLOSE_VIDU_CONNECTION4() {
        if (this.session4) {

            if (this.publisher4) {
                this.session4.unpublish(this.publisher4);
            }

            this.studentFeedbackPipAvailable = false;
            this.studentFeedbackPipActive = false;

            this.p4Show = 'none';
            this.player1Width = '100%';
            this.player4Width = '0px';

            this.clearFeedbackPip();

            if (environment.supportsBidirectional) {
                let timeOut = Math.random() * 500;
                setTimeout(() => {
                    this.lessonSrvc.leaveStudentFeedback(this.id).then(() => {
                        if(this.session4 != null)
                            this.session4.disconnect();
                    });
                }, timeOut);
            } else {
                if(this.session4 != null)
                    this.session4.disconnect();
            }

            //Hide player if videosource is screensharing
            if(this.isTeacher())
                this.checkIfScreenSharing(1);
        }
    }

    closeStudentFeedback() {
        if (this.session4) {
            this.teacherFeedbackService.closeStudentFeedback();
            this.CLOSE_VIDU_CONNECTION4();
        }
    }

    clearFeedbackPip() {
        if (environment.useNativePip4Student) {
            return this.clearFeedbackPipNative();
        }
        this.p1CssPip = false;
        this.p4CssPip = false;
    }

    studentFeedbackPip() {
        if (environment.useNativePip4Student) {
            return this.studentFeedbackPipNative();
        }

        if(!this.studentFeedbackPipActive) {
            if (this.isTeacher()) {
                this.p1Show = 'block';
                this.p4Show = 'block';
                this.player1Width = '100%';
                this.player4Width = '100%';
                this.p1CssPip = true;
                this.studentFeedbackPipActive = true;
                this.player1.controls = false;

                //Hide player if videosource is screensharing
                this.p1ShowSS = 'none';
            } else {
                this.p1Show = 'block';
                this.p4Show = 'block';
                this.player1Width = '100%';
                this.player4Width = '100%';
                this.p4CssPip = true;
                this.studentFeedbackPipActive = true;
                this.player4.controls = false;
            }
        } else {
            this.p1CssPip = false;
            this.p4CssPip = false;
            this.p1Show = 'block';
            this.p4Show = 'block';
            this.player1Width = '100%';
            this.player1Width = '100%';
            this.studentFeedbackPipActive = false;
            this.player1.controls = true;
        }
    }

    studentFeedbackPipLayout() {
        if (environment.useNativePip4Student) {
            return this.studentFeedbackPipLayoutNative();
        }
    }

    leaveStudentPictureInPicture: any;

    clearFeedbackPipNative() {
        try {
            if(this.document.pictureInPictureElement)
                this.document.exitPictureInPicture();
        } catch (error) {}
    }

    studentFeedbackPipNative() {
        if(!this.studentFeedbackPipActive) {
            this.clearFeedbackPip();
            if (this.isTeacher()) {
                if (this.videoElement1.nativeElement.attributes.getNamedItem("disablepictureinpicture")) {
                    this.videoElement1.nativeElement.attributes.removeNamedItem("disablepictureinpicture");
                }
                this.videoElement1.nativeElement.click();
                this.videoElement1.nativeElement.focus();
                this.videoElement1.nativeElement.requestPictureInPicture().then(() => {
                    if(this.document.pictureInPictureElement) {
                        this.leaveStudentPictureInPicture = this.studentFeedbackPipLayout.bind(this);
                        this.videoElement1.nativeElement.addEventListener('leavepictureinpicture', this.leaveStudentPictureInPicture);
                        this.studentFeedbackPipLayout();
                    }
                }).catch((error) => {
                    this.snackBar.open("Impossible to open the Picture in Picture automatically. Please try with the apposite icon.", "Dismiss");
                })
            } else {
                this.videoElement4.nativeElement.requestPictureInPicture().then(() => {
                    if(this.document.pictureInPictureElement) {
                        this.leaveStudentPictureInPicture = this.studentFeedbackPipLayout.bind(this);
                        this.videoElement4.nativeElement.addEventListener('leavepictureinpicture', this.leaveStudentPictureInPicture);
                        this.studentFeedbackPipLayout();
                    }
                }).catch((error) => {
                    this.snackBar.open("Impossible to open the Picture in Picture window automatically. Please try with the apposite icon.", "Dismiss");
                })
            }
        } else {
            if(this.document.pictureInPictureElement)
                this.document.exitPictureInPicture();
            this.studentFeedbackPipLayout();
        }
    }

    studentFeedbackPipLayoutNative() {
        if(!this.studentFeedbackPipActive) {
            if (this.isTeacher()) {
                this.p1Show = 'none';
                this.p4Show = 'block';
                this.player1Width = '0%';
                this.player4Width = '100%';
                this.studentFeedbackPipActive = true;
            } else {
                this.p1Show = 'block';
                this.p4Show = 'none';
                this.player1Width = '100%';
                this.player4Width = '0%';
                this.studentFeedbackPipActive = true;
            }
        } else {
            if (this.isTeacher()) {
                this.videoElement1.nativeElement.attributes.setNamedItem(this.pipAttribute);
                this.videoElement1.nativeElement.removeEventListener('leavepictureinpicture', this.leaveStudentPictureInPicture);
            } else {
                this.videoElement4.nativeElement.removeEventListener('leavepictureinpicture', this.leaveStudentPictureInPicture);
            }
            this.p1Show = 'block';
            this.p4Show = 'block';
            this.player1Width = '50%';
            this.player1Width = '50%';
            this.studentFeedbackPipActive = false;
        }
    }

    /* parte di Luca */

    async ngOnInit() {
        this.player1 = this.videoElement1.nativeElement as HTMLVideoElement;
        this.player2 = this.videoElement2.nativeElement as HTMLVideoElement;
        this.player3 = this.videoElement3.nativeElement as HTMLVideoElement;
        this.player4 = this.videoElement4.nativeElement as HTMLVideoElement;

        this.auth.stopInactivityCheck();

        // ETTORE
        this.chatService.chatList = [];
        this.chatService.askChatList = [];

        this.currentUser = this.auth.getCurrentUser();

        this.videoElement1.nativeElement.attributes.setNamedItem(this.pipAttribute);

        if (this.isTeacher()) {

            var startedUp = false;
            setTimeout(() => {
                startedUp = true;
            }, 1000);
    
            this.player1.onplay = () => {
                if (startedUp && !this.publishToggle)
                    this.togglePublish(true);
            }
    
            this.player2.onplay = () => {
                if (startedUp && !this.publishToggle)
                    this.togglePublish(true);
            }
    
            this.player3.onplay = () => {
                if (startedUp && !this.publishToggle)
                    this.togglePublish(true);
            }
    
            this.player1.onpause = () => {
                if (startedUp && this.publishToggle)
                    this.togglePublish(false);
            }
    
            this.player2.onpause = () => {
                if (startedUp && this.publishToggle)
                    this.togglePublish(false);
            }
    
            this.player3.onpause = () => {
                if (startedUp && this.publishToggle)
                    this.togglePublish(false);
            }
        }

        if (this.isStudent()) {
            if (this.route.snapshot.queryParamMap.has('lessonStartDate')){
                this.lessonStartDate = new Date(this.route.snapshot.queryParamMap.get('lessonStartDate'));
            }
        }

        this.navBar.hide('all');

        /*
        this.keepAliveInterval = setInterval(() => {
            // Keep alive
            this.lessonSrvc.keepAlive();
        }, 2000);*/
        this.easyLesson = localStorage.getItem('easyLesson');

        // Specific aspects of this concrete application
        this.getLessonActive();
        this.id = this.routeActive.snapshot.paramMap.get('id');
        this.lessonOpened = 0;

        if (this.auth.isTeacher()) {
            this.classId = Number(localStorage.getItem('chosenClass'));

            this.scenario = this.videoSessionService.scenario;
            this.previousConnectionStuff();
            this.videoSessionService.setLessonScenario(Number(this.id), this.scenario);

            if(this.scenario == 1 && this.easyLesson == 'false')
                this.getLessonContents();

            this.connectionInterval = setInterval(() => {
                this.lessonSrvc.getOpenLesson(this.id).then(data => {
                    if (data) {
                        if(data.recordingPlanned)
                            this.recording = data.recordingPlanned;
                        if(data.connectedUsers)
                            this.lessonOpened = ((data.connectedUsers / this.scenario) - (this.teacherFeedbackService.connectionStatus === 2 ? 2 : 0)).toFixed(0);
                    }
                });
            }, 2000);
            if (this.easyLesson == 'true') {
                this.token1 = JSON.parse(localStorage.getItem('quickLesson')).token
                this.OPEN_VIDU_CONNECTION1();
                this.p1Show = 'block';
                this.afterConnectionStuff();
            } else {
                try {
                    var resp = await this.videoSessionService.generateTokenTeacher(this.id);
                    this.token1 = resp.token;
                    this.OPEN_VIDU_CONNECTION1();
                    this.p1Show = 'block';

                    if(this.scenario > 1) {
                        resp = await this.videoSessionService.generateTokenTeacher(this.id);
                        this.token2 = resp.token;
                        this.OPEN_VIDU_CONNECTION2();
                        this.p2Show = 'block';
                        this.player1Width = '50%';
                    }
                    
                    if(this.scenario > 2) {
                        resp = await this.videoSessionService.generateTokenTeacher(this.id);
                        this.token3 = resp.token;
                        this.OPEN_VIDU_CONNECTION3();
                        this.p3Show = 'block';
                        this.player1Width = '33%';
                        this.player3Width = '33%';
                    }
                } catch (error) {
                    console.log(error);
                }

                this.afterConnectionStuff();
            }
        } else {
            await firstValueFrom(this.videoSessionService.getLesson(Number(this.id)))
                .then(success => {
                    this.scenario = success.scenario;
                }).catch(error => {
                    console.log("Error getting lesson scenario value");
                    console.log(error);
                });
            this.previousConnectionStuff();

            try {
                var resp = await this.videoSessionService.generateTokenStudent(this.id);
                this.token1 = resp.token;
                this.OPEN_VIDU_CONNECTION1();
                this.p1Show = 'block';

                if(this.scenario > 1) {
                    resp = await this.videoSessionService.generateTokenStudent(this.id);
                    this.token2 = resp.token;
                    this.OPEN_VIDU_CONNECTION2();
                    this.p2Show = 'block';
                    this.player1Width = '50%';
                }

                if(this.scenario > 2) {
                    resp = await this.videoSessionService.generateTokenStudent(this.id);
                    this.token3 = resp.token;
                    this.OPEN_VIDU_CONNECTION3();
                    this.p3Show = 'block';
                    this.player1Width = '33%';
                    this.player3Width = '33%';
                }
            } catch (error) {
                console.log(error);

                this.snackBar.open('The teacher has not opened the lesson yet!', 'Dismiss', {
                    duration: 3000
                });
                this.location.back();
            }

            this.afterConnectionStuff();
        }

        if(this.scenario > 1)
            this.videoElement1.nativeElement.addEventListener('leavepictureinpicture', this.pip.bind(this));

        // Specific aspects of this concrete application
    }

    ngAfterViewInit() {
        this.toggleScrollPage('hidden');
        this.getLessonActive();
    }

    ngOnDestroy() {
        this.teacherFeedbackService.closeStudentVideoDialog();
        this.navBar.show('topbar');
        this.navBar.show('bottombar');
        //clearInterval(this.keepAliveInterval);
        clearInterval(this.connectionInterval);
        this.connectionInterval = null;
        localStorage.removeItem('lessonActive');
        localStorage.removeItem('joinResponse');
        console.log('exited lesson')
        this.toggleScrollPage('auto');

        if (this.easyLesson == 'true' && this.isTeacher())
            this.closeLesson(true);

        this.auth.startInactivityCheck();
    }

    toggleScrollPage(scroll: string) {
        const content = <HTMLElement>document.getElementsByClassName('mat-sidenav-content')[0];
        content.style.overflow = scroll;
    }

    toggleLocalVideo(enable?: boolean) {
        var activate: boolean = enable != null ? enable : !this.localVideoActivated;

        this.localVideoActivated = activate;
        this.cameraOptions1.publishVideo = activate;
        this.publisher1.publishVideo(activate);
        this.videoIcon = activate ? 'videocam' : 'videocam_off';

        if (this.scenario > 1) {
            this.cameraOptions2.publishVideo = activate;
            this.publisher2.publishVideo(activate);
            this.videoIcon = activate ? 'videocam' : 'videocam_off';
        }

        if (this.scenario > 2) {
            this.cameraOptions3.publishVideo = activate;
            this.publisher3.publishVideo(activate);
            this.videoIcon = activate ? 'videocam' : 'videocam_off';
        }
    }

    toggleLocalAudio(enable?: boolean) {
        var activate: boolean = enable != null ? enable : !this.localAudioActivated;

        this.localAudioActivated = activate;
        this.cameraOptions1.publishAudio = activate;
        this.publisher1.publishAudio(activate);
        this.audioIcon = activate ? 'mic' : 'mic_off';

        if (!activate) {
            if (this.scenario > 1) {
                this.cameraOptions2.publishAudio = false;
                this.publisher2.publishAudio(false);
            }

            if (this.scenario > 2) {
                this.cameraOptions3.publishAudio = false;
                this.publisher3.publishAudio(false);
            }
        }
    }

    toggleStudentAudio() {
        this.studentAudioActivated = !this.studentAudioActivated;
        this.cameraOptions4.publishAudio = this.studentAudioActivated;
        this.publisher4.publishAudio(this.studentAudioActivated);
        this.studentAudioIcon = this.studentAudioActivated ? 'mic' : 'mic_off';
    }

    toggleFullScreen() {
        const document: any = window.document;
        const fs = document.getElementsByTagName('html')[0];
        if (!document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement) {
            console.log('enter FULLSCREEN!');
            if (fs.requestFullscreen) {
                fs.requestFullscreen();
            } else if (fs.msRequestFullscreen) {
                fs.msRequestFullscreen();
            } else if (fs.mozRequestFullScreen) {
                fs.mozRequestFullScreen();
            } else if (fs.webkitRequestFullscreen) {
                fs.webkitRequestFullscreen();
            }
        } else {
            console.log('exit FULLSCREEN!');
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    exitFullScreen() {
        const document: any = window.document;
        const fs = document.getElementsByTagName('html')[0];
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    previousConnectionStuff() {
        this.lesson = this.videoSessionService.lesson;

        if(this.easyLesson == 'true' && this.isTeacher())
            this.cameraOptions1 = this.videoSessionService.cameraOptions[0][0];
        else
            this.cameraOptions1 = this.videoSessionService.cameraOptions[0];

        if(this.videoSessionService.cameraOptions[1])
            this.cameraOptions2 = this.videoSessionService.cameraOptions[1];

        if(this.videoSessionService.cameraOptions[2])
            this.cameraOptions3 = this.videoSessionService.cameraOptions[2];
    }

    afterConnectionStuff() {
        if (this.auth.isTeacher()) {
            this.localVideoActivated = this.cameraOptions1.publishVideo !== false;
            this.localAudioActivated = this.cameraOptions1.publishAudio !== false;
            this.videoIcon = this.localVideoActivated ? 'videocam' : 'videocam_off';
            this.audioIcon = this.localAudioActivated ? 'mic' : 'mic_off';
        }

        this.countDown = timer(0, this.tick)
            .pipe(map(() => ++this.counter))
    }

    leaveLesson() {
        this.clearFeedbackPip();

        this.closeStudentFeedback();

        if(this.document.pictureInPictureElement) {
            this.document.exitPictureInPicture();
        }

        this.ovDisconnect();

        return this.lessonSrvc.leaveLesson(this.id).then(() => {
            this.router.navigateByUrl('/lessons');
        });
    }

    closeLesson(force: boolean) {
        if (this.lessonClosed)
            return;

        if (force || confirm(`Are you sure to close the streaming?`)) {
            this.lessonClosed = true;

            this.CLOSE_VIDU_CONNECTION4();
            this.ovDisconnect();
            this.lessonSrvc.closeLesson(this.id).then(() => console.log("Lesson closed"));

            this.router.navigateByUrl('/lessons');
        }
    }

    ovDisconnect() {
        try {
            this.player1.pause();
            this.player1.removeAttribute('src');
            this.player1.load();

            if(this.scenario > 1) {
                this.player2.pause();
                this.player2.removeAttribute('src');
                this.player2.load();
            }

            if(this.scenario > 2) {
                this.player3.pause();
                this.player3.removeAttribute('src');
                this.player3.load();
            }

            if(this.session4 != null) {
                this.player4.pause();
                this.player4.removeAttribute('src');
                this.player4.load();
            }

        } catch (error) {
            console.warn("Cleaning VideoPlayer error: ", error);
        }
        try {
            if(this.session1 != null)
                this.session1.disconnect();

            if(this.scenario > 1 && this.session2 != null)
                this.session2.disconnect();

            if(this.scenario > 2 && this.session3 != null)
                this.session3.disconnect();

            /* parte di Luca */
            if(this.session4 != null)
                this.session4.disconnect();
            /* parte di Luca */
        } catch (error) {
            console.warn("Disconnection error: ", error);
        } finally {
            this.session1 = null;
            this.publisher1 = null;

            if(this.scenario > 1) {
                this.session2 = null;
                this.publisher2 = null;
            }

            if(this.scenario > 2) {
                this.session3 = null;
                this.publisher3 = null;
            }

            /* parte di Luca */
            this.session4 = null;
            this.publisher4 = null;
            /* parte di Luca */
        }
    }

    isTeacher() {
        return this.currentUser.isTeacher;
    }

    isStudent() {
        return this.currentUser.isStudent;
    }

    joinLesson(id: string, connectionId: string, streamId: string, position: number, prevStreamId?: string) {
        if (this.auth.isTeacher()) {
            if (localStorage.getItem('easyLesson') == 'true') {
                return this.videoSessionService.joinQuickLesson(id, connectionId, streamId, "PUBLISHER", 0, position, prevStreamId);
            } else {
                return this.videoSessionService.joinLesson(id, connectionId, streamId, "PUBLISHER", 0, position, prevStreamId);
            }
        } else {
            return this.videoSessionService.joinLesson(id, connectionId, streamId, "SUBSCRIBER", 0, position, prevStreamId);
        }
    }

    joinStudentFeedback(id: string, connectionId: string, streamId: string, position: number, prevStreamId?: string) {
        if (environment.supportsBidirectional) {
            if (this.auth.isTeacher()) {
                return this.videoSessionService.joinLesson(id, connectionId, streamId, "SUBSCRIBER", 1, position, prevStreamId);
            } else {
                return this.videoSessionService.joinLesson(id, connectionId, streamId, "PUBLISHER", 1, position, prevStreamId);
            }
        }
    }

    getLessonActive() {
        this.lessonActive = JSON.parse(localStorage.getItem('lessonActive'));
    }

    pip() {
        if(this.pipToggle) {
            this.videoElement1.nativeElement.attributes.removeNamedItem("disablepictureinpicture");
            this.videoElement1.nativeElement.requestPictureInPicture();
            this.p1Show = 'none';
            this.pipToggle = false;
        } else {
            this.p1Show = 'block';
            //this.document.exitPictureInPicture();
            this.videoElement1.nativeElement.attributes.setNamedItem(this.pipAttribute);
            this.pipToggle = true;
        }
    }

    async switchTo(device: Device, scenario: number) {
        if(scenario == 1) {
            var prevStreamId = this.session1.connection.stream.streamId;

            this.cameraOptions1.videoSource = device.deviceId;
            var predecessorPublish = this.publisher1;
            this.publisher1 = await this.createPublisher(this.cameraOptions1, this.OV1, true);

            // Cleaning old stream.
            if (predecessorPublish != null) {
                try {
                    predecessorPublish.off('streamCreated');
                } catch (error) {
                    console.error(error);
                }

                try {
                    await this.session1.signal({
                        data: "",
                        to: [],
                        type: "camera-switch"
                    });
                } catch (error) {
                    console.error(error);
                }

                try {
                    console.warn("UNPUBLISH: ", predecessorPublish)
                    await this.session1.unpublish(predecessorPublish);
                } catch (error) {
                    console.error(error);
                    return;
                }

                await this.session1.publish(this.publisher1);
                this.joinLesson(this.id, this.session1.connection.connectionId, this.session1.connection.stream.streamId, 1, prevStreamId);

                // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                this.publisher1.addVideoElement(this.player1);
                this.player1.controls = true;
                await this.player1.play()
                    .then(async () => {
                        //await player.requestFullscreen()
                        //  .catch(error => console.error(error));
                    })
                    .catch((error) => console.error(error));

                //Hide player if videosource is screensharing
                this.checkIfScreenSharing(1);
            }
        }

        if(scenario == 2) {
            var prevStreamId = this.session2.connection.stream.streamId;

            this.cameraOptions2.videoSource = device.deviceId;
            var predecessorPublish = this.publisher2;
            this.publisher2 = await this.createPublisher(this.cameraOptions2, this.OV2);

            // Cleaning old stream.
            if (predecessorPublish != null) {
                try {
                    predecessorPublish.off('streamCreated');
                } catch (error) {
                    console.error(error);
                }

                try {
                    await this.session2.signal({
                        data: "",
                        to: [],
                        type: "camera-switch"
                    });
                } catch (error) {
                    console.error(error);
                }

                try {
                    console.warn("UNPUBLISH: ", predecessorPublish)
                    await this.session2.unpublish(predecessorPublish);
                } catch (error) {
                    console.error(error);
                    return;
                }

                await this.session2.publish(this.publisher2);
                this.joinLesson(this.id, this.session2.connection.connectionId, this.session2.connection.stream.streamId, 2, prevStreamId);

                // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                this.publisher2.addVideoElement(this.player2);
                this.player2.controls = true;
                await this.player2.play()
                    .then(async () => {
                        //await player.requestFullscreen()
                        //  .catch(error => console.error(error));
                    })
                    .catch((error) => console.error(error));
                
                //Hide player if videosource is screensharing
                this.checkIfScreenSharing(2);
            }
        }

        if(scenario == 3) {
            var prevStreamId = this.session3.connection.stream.streamId;

            this.cameraOptions3.videoSource = device.deviceId;
            var predecessorPublish = this.publisher3;
            this.publisher3 = await this.createPublisher(this.cameraOptions3, this.OV3);

            // Cleaning old stream.
            if (predecessorPublish != null) {
                try {
                    predecessorPublish.off('streamCreated');
                } catch (error) {
                    console.error(error);
                }

                try {
                    await this.session3.signal({
                        data: "",
                        to: [],
                        type: "camera-switch"
                    });
                } catch (error) {
                    console.error(error);
                }

                try {
                    console.warn("UNPUBLISH: ", predecessorPublish)
                    await this.session3.unpublish(predecessorPublish);
                } catch (error) {
                    console.error(error);
                    return;
                }

                await this.session3.publish(this.publisher3);
                this.joinLesson(this.id, this.session3.connection.connectionId, this.session3.connection.stream.streamId, 3, prevStreamId);

                // Linking videoplayer per visualizzare controlli e nascondere eventuale schermata bianca
                this.publisher3.addVideoElement(this.player3);
                this.player3.controls = true;
                await this.player3.play()
                    .then(async () => {
                        //await player.requestFullscreen()
                        //  .catch(error => console.error(error));
                    })
                    .catch((error) => console.error(error));

                //Hide player if videosource is screensharing
                this.checkIfScreenSharing(3);
            }
        }

        if(scenario > 1)
            this.setJoinLessonConfig();
    }

    async createPublisher(cameraOptions: PublisherProperties, OV: OpenVidu, firstConn: boolean = false): Promise<Publisher> {
        console.warn("Create or update publisher with: ", cameraOptions);

        this.videoSessionService.setStreamingSettings(cameraOptions, this.currentUser);
        var publisher: Publisher = OV.initPublisher(undefined, cameraOptions);
        
        publisher.on('streamCreated', (event: StreamEvent) => {
            console.warn('STREAM CREATED BY PUBLISHER!');
            console.warn(event.stream);

            if (firstConn) {
                this.resolutionHeight = event.stream.videoDimensions.height;
                this.resolutionWidth = event.stream.videoDimensions.width;
            }
        });

        return publisher;
    }

    askedStudentToStartVideo ($event: StudentsVideoStatus) {
        if (this.scenario == 1) {
            this.teacherFeedbackService.askStudentToStartVideo($event);
        } else {
            this.snackBar.open("This feature is not available in Scenario mode.", "Dismiss", { duration: 3000 })
        }
    }

    askedStudentToCloseVideo ($event: StudentsVideoStatus) {
        if (this.scenario == 1) {
            this.teacherFeedbackService.askStudentToCloseVideo($event);
        }
    }

    onResizeEnd1(event: ResizeEvent): void {
        console.log('Element was resized', event);

        if (this.scenario <= 1)
            return;

        if (event.rectangle.width >= 300 && ((this.player2Container.nativeElement as HTMLElement).offsetWidth - Number(event.edges.right))>= 300)
            this.player1Width = event.rectangle.width + 'px';
    }

    onResizeEnd3(event: ResizeEvent): void {
        console.log('Element was resized', event);

        if (event.rectangle.width >= 300 && ((this.player2Container.nativeElement as HTMLElement).offsetWidth + Number(event.edges.left)) >= 300)
            this.player3Width = event.rectangle.width + 'px';
    }

    // ETTORE
    askChatToTeacher() {
        this.disableStudentAskBtn = true;
        this.chatService.askChatToTeacher();

        // Riabilito il bottone di richiesta di chat dopo 30 secondi
        setTimeout(() => {
            this.disableStudentAskBtn = false;
        }, 30000);
    }

    setJoinLessonConfig() {
        var config: joinLessonConfig = new joinLessonConfig();

        if(this.scenario == 2)
            config = JSON.parse(localStorage.getItem('joinLessonConfig2'));
        if(this.scenario == 3)
            config = JSON.parse(localStorage.getItem('joinLessonConfig3'));
        
        config.audioEnabled = this.localAudioActivated;
        config.videoEnabled = this.localVideoActivated;

        if(this.cameraOptions1.videoSource == 'screen')
            config.source1 = 'screenSharing';
        else {
            config.source1 = 'webcam';
            config.video1 = this.videoDeviceArr.find(d => d.deviceId == this.cameraOptions1.videoSource).label;
        }
        
        if(this.cameraOptions2.videoSource == 'screen')
            config.source2 = 'screenSharing';
        else {
            config.source2 = 'webcam';
            config.video2 = this.videoDeviceArr.find(d => d.deviceId == this.cameraOptions2.videoSource).label;
        }

        if(this.scenario == 3) {
            if(this.cameraOptions3.videoSource == 'screen')
                config.source3 = 'screenSharing';
            else {
                config.source3 = 'webcam';
                config.video3 = this.videoDeviceArr.find(d => d.deviceId == this.cameraOptions3.videoSource).label;
            }

            localStorage.setItem('joinLessonConfig3', JSON.stringify(config));
        } else {
            localStorage.setItem('joinLessonConfig2', JSON.stringify(config));
        }
    }

    chooseLessonContent(contentId: number) {
        this.setLessonContent(1, contentId);
    }

    setLessonContent(playmode: number, contentId: number) {
        var lessonContent = new LessonContentEdit();
        lessonContent.playMode = playmode;
        lessonContent.idContent = contentId;
        lessonContent.idConnection = this.OV1.session.connection.connectionId;

        //Se tolgo i contenuti mi salvo il tracking
        if (!playmode || (this.currentContent && contentId !== this.currentContent.id))
            this.addTracker();

        this.calendar.setCurrentLessonContent(Number(this.id), lessonContent)
            .subscribe({
                next: output => {

                    this.session1.signal({
                        data: JSON.stringify(<ContentSignal>{ content: output, enable: Boolean(playmode), marker: 0, pause: false }),
                        to: [],
                        type: 'content-set'
                    });

                    //Aggiunta di un contenuto
                    if (Boolean(playmode)) {
                    
                        //Azioni riguardanti il contenuto corrente
                        if (this.currentContent) {

                            this.contentShow = false;

                            this.contentMarker = 0;
                            this.contentPause = false;
                        }

                        //Azioni riguardanti il nuovo contenuto
                        this.contentShow = true;

                        this.contentPause = false;
                        this.currentContent = output;
                        this.contentMarker = output.courseContentTracker && output.courseContentTracker.length > 0 ? output.courseContentTracker[0].stopPosition : 0;

                        //Se è un pdf avvio il tracking, sennò lo fa già il componente del player
                        if (output.type === 2)
                            this.startTracking();

                    //Rimozione di un contenuto
                    } else {

                        this.contentShow = false;

                        this.contentMarker = 0;
                        this.contentPause = false;
                        this.currentContent = null;
                    }
                    
                },
                error: () => {
                    this.snackBar.open('No content for this lesson', 'Dismiss', { duration: 3000 });
                }
            });
    }

    getLessonContents() {
        this.calendar.getLessonContents(Number(this.id))
            .subscribe(output => {
                this.contents = output;
            });
    }

    updateContent(marker: number, pause?: boolean) {
        if (this.isTeacher()) {
            this.contentPause = pause;
            this.trackerStopPosition = marker;
            
            this.session1.signal({
                data: JSON.stringify(<ContentUpdateSignal>{ marker: marker, pause: pause }),
                to: [],
                type: 'content-update'
            });
        }
    }

    /**
     * Mostra o nasconde il player video durante lo screen-sharing evitando l'effetto ricorsione
     * @param playerNumber Player da controllare
     */
    checkIfScreenSharing(playerNumber: number): void {
        if(playerNumber === 1) {
            if(this.cameraOptions1.videoSource === 'screen' && !this.studentFeedbackPipActive) {
                this.p1ShowSS = 'flex';
                this.p1Show = 'none';
            } else {
                this.p1ShowSS = 'none';
                this.p1Show = 'block';
            }
        }

        if(playerNumber === 2) {
            if(this.cameraOptions2.videoSource === 'screen') {
                this.p2ShowSS = 'flex';
                this.p2Show = 'none';
            } else {
                this.p2ShowSS = 'none';
                this.p2Show = 'block';
            }
        }

        if(playerNumber === 3) {
            if(this.cameraOptions3.videoSource === 'screen') {
                this.p3ShowSS = 'flex';
                this.p3Show = 'none';
            } else {
                this.p3ShowSS = 'none';
                this.p3Show = 'block';
            }
        }
    }
    
    setVisualType(type: string) {
        this.visualType = type;
        localStorage.setItem('visualTypeConfig', this.visualType);
    }

    updateTime($event) {
        this.videoTime = $event;
    }

    togglePublish(enable: boolean) {
        if (this.isTeacher()) {

            this.publishToggle = !this.publishToggle;

            //Se abilito
            if (enable) {
                if (this.publishAudioMem)
                    this.toggleLocalAudio(enable);
                
                if (this.publishVideoMem)
                    this.toggleLocalVideo(enable);
            } else { //Se disabilito
                this.publishAudioMem = this.localAudioActivated;
                this.publishVideoMem = this.localVideoActivated;

                this.toggleLocalAudio(enable);
                this.toggleLocalVideo(enable);
            }
    
            enable ? this.player1.play() : this.player1.pause();
    
            if (this.scenario > 1)
                enable ? this.player2.play() : this.player2.pause();
            
            if (this.scenario > 2)
                enable ? this.player3.play() : this.player3.pause();
        }
    }

    private async cameraSwitchTimeout(type: 'interrupt' | 'screenshare') {
        if (this.auth.isTeacher())
            return;
        
        let msg = type ?
                  'The stream was interrupted by the Teacher.' :
                  'If your teacher was trying to share the screen with you, it was too slow. Please help him!';

        this.snackBar.open(msg, 'Dismiss', {
            duration: 10000
        });

        console.log('lesson closed, going to list of lessons...');
        await this.leaveLesson();
    }

    private clearCameraSwitchTimeout(scenario: number) {

        if (scenario === 1 && this.cameraSwitchTimeout1 != null) {
            clearTimeout(this.cameraSwitchTimeout1);
            this.cameraSwitchTimeout1 = undefined;
            this.cameraSwitch1 = false;
        }

        if (scenario === 2 && this.cameraSwitchTimeout2 != null) {
            clearTimeout(this.cameraSwitchTimeout2);
            this.cameraSwitchTimeout2 = undefined;
            this.cameraSwitch2 = false;
        }

        if (scenario === 3 && this.cameraSwitchTimeout3 != null) {
            clearTimeout(this.cameraSwitchTimeout3);
            this.cameraSwitchTimeout3 = undefined;
            this.cameraSwitch3 = false;
        }

    }
    
    //Per tracking
    startTracking() {
        if (this.isTeacher()) {
            console.log('tracking set');
            this.trackerStartDate = new Date();
            this.trackerStopPosition = this.contentMarker;
        }
    }

    addTracker(seeked?: number[]) {
        if (this.isTeacher()) {
            if (this.trackerStartDate != null) {
    
                if (this.currentContent.type === 1)
                  this.trackerStopPosition = Math.round(seeked ? seeked[0] : this.videoTime);
          
                if (this.contentMarker != this.trackerStopPosition) { //console.warn(this.contentMarker + ' ' + this.trackerStopPosition);
                    this.courseService.postTracker(this.currentContent.id, this.trackerStartDate, this.contentMarker, this.trackerStopPosition)
                        .subscribe({
                            next: () => {
                                console.log('tracked');
                
                                this.startTracking();
                            },
                            error: err => console.log(err.error.Message)
                        });
                }
            }
        }
    }

    getNumberId() {
        return Number(this.id);
    }
}

export class StreamData {
    public connId: string;
    public streamId: string;

    constructor() {
        this.connId = null;
        this.streamId = null;
    }
}

//Per content-set
export class ContentSignal {
    public content: CourseContentDTO;
    public enable: boolean = true;
    public marker: number = 0;
    public pause: boolean = false;
}

//Per content-update
export class ContentUpdateSignal {
    public marker: number;
    public pause?: boolean;
}

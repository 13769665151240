<div class="component-main">
  <div style="margin-bottom: 20px;">

    <div fxLayout="row" fxLayoutAlign="center center">
      <h2>{{ 'Users' | translate }}</h2>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-bottom: 20px;">
      <button mat-fab color="accent" *ngIf="isCustomerAdmin() && isAixpMode() && !isDT()" (click)="inviteUser()"
        [matTooltip]="'Invite user' | translate">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-fab color="accent" *ngIf="!isStudent() && (isSaratogaMode() || isDT())" (click)="addUser()"
        [matTooltip]="'Add user' | role | translate">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-fab color="primary" *ngIf="isCustomerAdmin()"
        (click)="isDT() ? exportDtUserToCsv() : exportToCsv()" [matTooltip]="'Save csv of users' | translate">
        <mat-icon>save</mat-icon>
      </button>
      <mat-checkbox *ngIf="currentUser.isAdmin || currentUser.isCustomerAdmin"
        [formControl]="state"
        (change)="getUsers($event.checked)">
        {{ 'Inactive users' | translate }}
      </mat-checkbox>
    </div>

  </div>

  <ng-container *ngIf="!currentUser.isAdmin; else adminList" [ngTemplateOutlet]="usersTable">

    <div fxLayoutAlign="center center">
      <mat-form-field color="primary" fxFlex>
        <mat-label>{{ 'Search' | translate }}</mat-label>
        <input matInput (keyup)="searchWord($event)" #searchInput>
      </mat-form-field>
    </div>
    
  </ng-container>
</div>

<ng-template #adminList>
  <div class="center-item" style="width: 100%;">
    <mat-accordion class="mat-table" is-open="true">
      <div *ngFor="let users of customers" style="margin: 10px;">
        <mat-expansion-panel (afterExpand)="setCustomer(users)">
          <mat-expansion-panel-header class="mat-row">
            <mat-panel-title>
              <div style="text-align: start; align-self: center;" fxFlex>
                {{ users[0].customerName }}
              </div>
              <div style="text-align: end;" fxFlex="20">
                <mat-icon *ngIf="currentCustomerId === users[0].idCustomer"
                  [matTooltip]="'Selected' | translate"
                  [matTooltipPosition]="'before'">
                  done
                </mat-icon>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ng-container [ngTemplateOutlet]="usersTable"></ng-container>
          </ng-template>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
</ng-template>

<ng-template #usersTable>
  <div>
    <table mat-table [dataSource]="datasource" #usersSort="matSort" style="width: 100%;" matSort>

      <ng-container matColumnDef="thumbnail">
        <th mat-header-cell *matHeaderCellDef class="imgProfile">{{ 'Profile picture' | translate }}</th>
        <td mat-cell *matCellDef="let element" style="text-align:center">
          <app-profile-picture [url]="element.profilePictureUrl">
          </app-profile-picture>
        </td>
      </ng-container>

      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.surname }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'username' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.username }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ getRole(element) | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User creation' | translate }}</th>
        <td [style.color]="element.state === 0 ? '#d3d3d3' : '' " mat-cell *matCellDef="let element">{{ element.timeStamp | date: 'dd/MM/YYYY HH:mm' }}</td>
      </ng-container>

      <ng-container matColumnDef="actions" >
        <th mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button
            *ngIf="isSaratogaMode() || isDT()"
            (click)="editUser(element.id)"
            [matTooltip]="'edit' | translate">
            <mat-icon>
              edit
            </mat-icon>
          </button>
          <button *ngIf="(isAdmin() || isCustomerAdmin()) && element.state === 1;" mat-icon-button color="warn"
            (click)="deleteUser(element.id)"
            [matTooltip]="'Disable' | translate">
            <mat-icon>
              not_interested
            </mat-icon>
          </button>
          <button *ngIf="(isAdmin() || isCustomerAdmin()) && element.state === 0;" mat-icon-button style="color:green"
            (click)="enableUser(element.id)"
            [matTooltip]="'Enable user' | translate">
            <mat-icon>
              done
            </mat-icon>
          </button>

        </td>
      </ng-container>
    
    <ng-container *ngIf="isAdmin() || isCustomerAdmin(); else teacherTemplate">
      <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr  mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </ng-container>

    <ng-template #teacherTemplate>
      <tr  mat-header-row *matHeaderRowDef="displayedColumnsTeacher"></tr>
      <tr  mat-row *matRowDef="let row; columns: displayedColumnsTeacher;"></tr>
    </ng-template>
    </table>
    
    <mat-paginator *ngIf="!currentUser.isAdmin"
      [pageSizeOptions]="paginatorPages"
      [pageSize]="paginatorDefault"
      #usersPaginator>
    </mat-paginator>
  </div>
</ng-template>

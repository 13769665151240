<h1 mat-dialog-title>{{ 'Users' | translate }}</h1>
<div mat-dialog-content>
  <mat-form-field color="primary">
    <mat-label>{{ 'Search' | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)">
    <button matSuffix mat-icon-button [matTooltip]="'Search' | translate">
        <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
  <table mat-table [dataSource]="datasource" #usersSort="matSort" style="width: 100%;" matSort>

    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell *matHeaderCellDef>{{ 'Profile picture' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <app-profile-picture [url]="element.profilePictureUrl">
        </app-profile-picture>
      </td>
    </ng-container>

    <ng-container matColumnDef="surname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Surname' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.surname }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'username' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.username}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Actions' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button
          (click)="addUserAsPaid(element)"
          [disabled]="hasAlreadyPaid(element.id) || isFree"
          [matTooltip]="'Set as paid' | translate">
          <mat-icon>
            payments
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator
      [pageSizeOptions]="paginatorPages"
      [pageSize]="paginatorDefault"
      color="primary"
      #usersPaginator>
  </mat-paginator>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">{{ 'Close' | translate }}</button>
</div>

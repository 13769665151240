<h1 mat-dialog-title>{{ (webinarToEdit ? 'Edit webinar' : 'Create a new webinar') | translate }}</h1>

<div mat-dialog-content>

    <mat-stepper>

        <mat-step>

            <div class="stepper-gap">
                <app-translations-form
                    [label]="'Title' | translate"
                    [disabled]="sending"
                    [(translation)]="nameTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Title' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="nameForm" type="text">
                    </mat-form-field>

                </app-translations-form>
    
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'Start' | translate }}</mat-label>
                        <input matInput
                            [type]="showDateOrTime ? 'datetime-local' : 'time'"
                            [style.color]="startDateForm.hasError('invalidDate') ? 'red' : '' "
                            [formControl]="startDateForm">
                        <mat-error *ngIf="startDateForm.hasError('invalidDate')">
                            {{ 'The date must be a future day' | translate }} 
                        </mat-error>
                        <mat-error *ngIf="startDateForm.hasError('required')">
                            {{ 'The date is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'End' | translate }}</mat-label>
                        <input matInput
                            type="time"
                            [formControl]="endDateForm" 
                            (input)="negativeSpan()"
                            [style.color]="endDateForm.hasError('negativeSpan') || endDateForm.hasError('moreThanMaxDuration') ? 'red' : '' ">
                        <mat-error *ngIf="endDateForm.hasError('required')">
                            {{ 'The date is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="endDateForm.hasError('negativeSpan')">
                            {{ 'The difference in dates must' | translate }} <strong> {{ 'not be negative' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="endDateForm.hasError('moreThanMaxDuration')">
                            {{ 'The conference can last a maximum of value hours' | translate: { value: maxDurationHours() } }}
                        </mat-error>
                    </mat-form-field>
                </div>
    
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <h3 fxFlex>{{ 'Moderator' | translate }} </h3>
                    <mat-form-field fxFlex>
                        <mat-label >{{ 'Choose an option' | translate }}</mat-label>
                        <mat-select [formControl]="moderatorForm">
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="moderatorSearchForm"
                                    [placeholderLabel]="'Search' | translate"
                                    [noEntriesFoundLabel]="'No data matching the word' | translate">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of tutors | search: moderatorSearchForm.value: ['name', 'surname']"
                                [style.color]="darkService.isSetDark ? 'white' : ''" 
                                [value]="user.id"> 
                                {{ user.name }} {{ user.surname }} 
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="moderatorForm.hasError('required')">
                            {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field> 
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                    <h3 fxFlex>{{ 'Presenters list' | translate }}</h3>
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                        <mat-select [formControl]="presenterListForm" multiple>
                            <mat-option>
                                <ngx-mat-select-search
                                    [formControl]="presenterListSearchForm"
                                    [placeholderLabel]="'Search' | translate"
                                    [noEntriesFoundLabel]="'No data matching the word' | translate">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of speakers | search: presenterListSearchForm.value: ['name', 'surname']"
                                [disabled]="presenterOptionDisabled(user.id)"
                                [value]="user.id"
                                [style.color]="darkService.isSetDark ? 'white' : ''">
                                {{ user.name }} {{ user.surname }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="presenterListForm.hasError('required')">
                            {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
    
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                    <div *ngFor="let user of getSelectedUsers()" style="font-size: 14px">
                        {{ user.name }} {{ user.surname }}
                    </div>
                </div>
    
                <div fxLayout="row" fxLayoutGap="10px">
                    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button> 
                    <button mat-flat-button color="primary" [disabled]="nextBtnDisabled()" matStepperNext>{{ 'Next' | translate }}</button>
                </div>
            </div>
            
        </mat-step>

        <mat-step>

            <div class="stepper-gap" style="gap: 0px">
                <app-translations-form
                    [label]="'Header' | translate"
                    [disabled]="sending"
                    [(translation)]="headerTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Header' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="headerForm" type="text">
                    </mat-form-field>

                </app-translations-form>
                <app-translations-form
                    [label]="'Description' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="descriptionTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Description' | translate }}</mat-label>
                        <textarea matInput [formControl]="descriptionForm" style="height: 100px"></textarea>
                    </mat-form-field>

                </app-translations-form>
                <app-translations-form
                    [label]="'Footer' | translate"
                    [disabled]="sending"
                    [(translation)]="footerTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Footer' | translate }}</mat-label>
                        <input matInput autofocus [formControl]="footerForm" type="text">
                    </mat-form-field>

                </app-translations-form>
                <app-translations-form
                    [label]="'Showcase Summary' | translate"
                    [disabled]="sending"
                    [mode]="'textarea'"
                    [(translation)]="summaryTranslation">

                    <mat-form-field currentLanguageForm>
                        <mat-label>{{ 'Showcase Summary' | translate }}</mat-label>
                        <textarea matInput [formControl]="summaryForm" style="height: 100px"></textarea>
                    </mat-form-field>

                </app-translations-form>
                <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px">
                    <div fxFlex>
                        <mat-label>{{ 'Media' | translate }}</mat-label>
                        <app-file-input
                            [control]="imageSrc"
                            [maxSize]="2000"
                            [acceptedTypes]="'image/*'"
                            [aspectRatio]="'8:5'"
                            [maxHeight]="2000"
                            [maxWidth]="2000"
                            [rejectedErrorMessage]="'The file must be a picture with format formatValue, less than heightValue high, less than widthValue wide and not exceed sizeValue' | translate: { format: '8:5', height: '2000px', width: '2000px', size: '2MB' }"
                            [deletedErrorMessage]="'Please select a file' | translate"
                            style="width: 100%">
                        </app-file-input>
                    </div>
                    <app-picture-preview *ngIf="webinarToEdit"
                        [src]="webinarToEdit.imageUrl"
                        [tooltip]="'Current image' | translate"
                        fxFlex="50">
                    </app-picture-preview> 
                </div>
    
                <div fxLayout="row" fxLayoutGap="10px">
                    <button mat-stroked-button matStepperPrevious>{{ 'Back' | translate }}</button>
                    <button mat-flat-button color="primary" [disabled]="secondNextBtnDisabled()"  matStepperNext>{{ 'Next' | translate }}</button>
                    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button> 
                </div>
            </div>

        </mat-step>

        <mat-step>

            <div class="stepper-gap">
                <div fxLayout="row">
                    <mat-checkbox matInput
                        [formControl]="recordForm"
                        fxFlex="50">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <mat-icon color="warn">fiber_manual_record</mat-icon>
                            <span style="font-size: larger;" [matTooltip]="'Enable recording for the session' | translate">{{ 'Record' | translate }}</span>
                        </div>
                    </mat-checkbox>
                    <mat-checkbox matInput
                        [formControl]="isPrivateForm"
                        fxFlex>
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <mat-icon>lock</mat-icon>
                            <span style="font-size: larger;" [matTooltip]="'Change visibility' | translate">{{ 'Private' | translate }}</span>
                        </div>
                    </mat-checkbox>
                </div>
    
                <div fxLayout="column" *ngIf="isPrivateForm.value === true">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                        <h3 fxFlex>{{ 'Classrooms list' | translate }} </h3>
                        <mat-form-field fxFlex>
                            <mat-label>{{ 'Choose an option' | translate }}</mat-label>
                            <mat-select [formControl]="classroomsListForm" multiple>
                                <mat-option>
                                    <ngx-mat-select-search
                                        [formControl]="classroomListSearchForm"
                                        [placeholderLabel]="'Search' | translate"
                                        [noEntriesFoundLabel]="'No data matching the word' | translate">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let class of classrooms | search: classroomListSearchForm.value: ['name', 'description']"
                                    [style.color]="darkService.isSetDark ? 'white' : ''"   
                                    [value]="class.id">
                                    {{ class.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="classroomsListForm.hasError('required')">
                                {{ 'The choose is' | translate }} <strong>{{ 'required' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
    
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="20px grid">
                        <div *ngFor="let classroom of getSelectedClassrooms()" style="font-size: 14px">
                            {{ classroom.name }}
                        </div>
                    </div> 
                </div>
                
                <div fxLayout="row" fxLayoutGap="10px">
                    <button mat-stroked-button matStepperPrevious>{{ 'Back' | translate}}</button>
                    <button mat-flat-button color="accent" 
                        (click)="saveWebinar()"
                        [disabled]="okBtnDisabled()">
                        Ok
                    </button>
                    <button mat-stroked-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button> 
                </div>
            </div>
            
        </mat-step>

    </mat-stepper>

</div>

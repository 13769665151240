import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lesson } from 'src/app/models/lesson';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Helper } from 'src/app/helpers/helper';
import { AuthService } from './auth.service';
import { LessonSessionDTO } from '../models/dto/lessonSessionDTO';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LessonService {
    bckEndUrlSessions: string = `${environment.apiUrl}/api/classrooms`
    startDate: string | number | Date;
    stopDate: string | number | Date;

    constructor(private http: HttpClient, private auth: AuthService) { }

    // post
    // create new lesson
    createLesson(name: string, description: string, classSelected: any, roomId: any, recording: boolean, privateR: boolean, smilyRec: boolean) {
        // add classId to url
        var customBody = {
            name,
            description,
            roomId,
            recording,
            privateR,
            smilyRec
        };

        return this.http
            .post(
                `${this.bckEndUrlSessions}/${classSelected}/lessons`,
                customBody,
                { headers: Helper.getAuthHeader() })
            .pipe(map(classroom => {
                console.log(classroom);
                if (classroom) { return classroom; }
            }));
    }

    createQuickLesson(name: string, description: string, classSelected: any) {
        // add classId to url
        var customBody = {
            name,
            description,
            kurentoOptions: this.auth.getCurrentUser().streamInfo.logo != 0 ? { allowedFilters: ['GStreamerFilter'] } : null
        };

        return this.http
            .post(
                `${this.bckEndUrlSessions}/${classSelected}/quickLessons`,
                customBody,
                { headers: Helper.getAuthHeader() })
            .pipe(map(classroom => {
                console.log(classroom);
                if (classroom) { return classroom; }
            }));
    }

    getOpenLesson(lessonId: string) {
        return firstValueFrom(this.http.get<any>(`${this.bckEndUrlSessions}/lessons/${lessonId}`, { headers: Helper.getAuthHeader() }))
    }

    getLessons() {
        return this.http.get<Lesson[]>(`${this.bckEndUrlSessions}/lessons`, { headers: Helper.getAuthHeader() });
    }

    getLessonsQuick() {
        return this.http.get<any[any]>(`${this.bckEndUrlSessions}/lessons?quickLesson=true`, { headers: Helper.getAuthHeader() })
            .pipe(map(lessons => {
                return lessons;
            }));
    }

    getLessonsByClassId(classId) {
        return this.http.get<Lesson[]>(`${this.bckEndUrlSessions}${classId}/lessons`, { headers: Helper.getAuthHeader() })
            .pipe(map(lessons => {
                if (!lessons) { return console.log('no lessons in this classroom') }
                console.log('works, but no lessons');
                return lessons;
            }));
    }

    exitClassroom() {
        localStorage.removeItem('currentClass');
    }

    closeLesson(id) {
        return firstValueFrom(this.http.delete(`${this.bckEndUrlSessions}/lessons/${id}`, { headers: Helper.getAuthHeader() }));
    }

    deleteLesson(id: number) {
        return firstValueFrom(this.http.delete(`${this.bckEndUrlSessions}/lessons/${id}/delete`, { headers: Helper.getAuthHeader() }));
    }

    leaveLesson(id: string) {
        return firstValueFrom(this.http.delete<Lesson[]>(`${this.bckEndUrlSessions}/lessons/${id}/leave`, { headers: Helper.getAuthHeader() }));
    }

    leaveStudentFeedback(id: string) {
        return firstValueFrom(this.http.delete<Lesson[]>(`${this.bckEndUrlSessions}/lessons/${id}/leaveStudentFeedback`, { headers: Helper.getAuthHeader() }));
    }

    async addLessonNote(lessonId: number, note: string) {

        const body = {
            note
        };

        return firstValueFrom(this.http.put(`${this.bckEndUrlSessions}/lessons/${lessonId}/note`, body, { headers: Helper.getAuthHeader() }));
    }

    getActiveStreamingLessons(): Observable<LessonSessionDTO[]> {
        return this.http.get<LessonSessionDTO[]>( `${this.bckEndUrlSessions}/lessons/activeStreaming`,{ headers: Helper.getAuthHeader() });
    }

    /*
    keepAlive() {
        this.setHeader();
        return firstValueFrom(this.http.get(`${environment.keepAliveUrl}`, {
            headers: {
                Authorization: `Bearer ${Helper.getUserToken()}`
            }
        }));
    }*/
}
